<template>
  <div id="oekaki_all">
    <div id="oekaki_header">
      <router-link to="/main">
        <div id="oekaki_header_return"></div>
      </router-link>
      <div id="oekaki_header_title">おえかきアプリ</div>
    </div>
    <iframe class="frame_center" src="./oekaki/index.html" frameborder="0">
    </iframe>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "../css/oekaki.scss";
</style>
