<template>
  <!-- ハンター編集削除画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- 画面タイトル -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ハンター管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- メインタイトル -->
      <div class="page_title">
        <p>ハンター編集</p>
      </div>
      <!-- フォーム -->
      <form class="scroll_form" @submit.prevent>
        <!-- ハンター情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">ハンター情報</legend>
          <!-- ハンター名 -->
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_name"
              class="input_contents_label label_border_blue"
            >
              ハンター名
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_hunter_name"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_hunter_name"
              placeholder="ハンター田中"
              tabindex="1"
              maxlength="13"
              @keyup="check_hunter_name"
              v-model="d_hunter_data.itf_hunter_hunter_name"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[0] }}</label>
          </p>
          <!-- ログインID -->
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_login_id"
              class="input_contents_label label_border_blue"
            >
              ログインID
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_hunter_login_id"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_hunter_login_id"
              placeholder="tanaka01"
              tabindex="2"
              maxlength="8"
              @keyup="check_hunter_login_id"
              v-model="d_hunter_data.itf_hunter_login_id"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[1] }}</label>
          </p>
          <!-- パスワード -->
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_pass"
              class="input_contents_label label_border_glay"
            >
              パスワード
            </label>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_hunter_data.itf_hunter_password
            }}</span>
          </p>
          <!-- 新しいパスワード -->
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_new_password"
              class="input_contents_label label_border_blue"
            >
              新しいパスワード
            </label>
            <br />
            <input
              type="text"
              name="f_hunter_new_password"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_hunter_new_password"
              tabindex="3"
              maxlength="12"
              @keyup="check_hunter_new_password"
              v-model="d_hunter_data.itf_hunter_new_password"
            />
          </p>
          <!-- 所属ギルド -->
          <p class="f_fieldset_contents" id="guild_list">
            <label
              for="f_hunter_guild"
              class="input_contents_label label_border_blue"
            >
              所属ギルド
            </label>
            <span class="required_icon"></span>
            <br />
            <select v-model="d_hunter_data.itf_hunter_guild">
              <option
                v-for="guild_list in d_guild_list_data"
                v-bind:key="guild_list.id"
                :value="guild_list"
              >
                {{ guild_list.name }}
              </option>
            </select>
            <br />
            <label class="err_message_red">{{ d_error_message[12] }}</label>
          </p>
          <!-- ハンターランク -->
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_hunter_rank"
              class="input_contents_label label_border_glay"
            >
              ハンターランク
            </label>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_hunter_data.itf_hunter_rank
            }}</span>
          </p>
          <!-- 経験値 -->
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_point"
              class="input_contents_label label_border_glay"
            >
              経験値
            </label>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_hunter_data.itf_hunter_exp
            }}</span>
          </p>
          <!-- ダイヤ -->
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_dia"
              class="input_contents_label label_border_glay"
            >
              ダイヤ
            </label>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_hunter_data.itf_hunter_dia
            }}</span>
          </p>
          <!-- 利用状況 -->
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_status"
              class="input_contents_label label_border_blue"
            >
              利用状況
            </label>
            <br />
            <span class="radio_selection_item">
              <label>
                <input
                  type="radio"
                  name="f_hunter_status"
                  class="f_input_radio f_input_radio_left"
                  value="1"
                  v-model="d_hunter_data.itf_hunter_status"
                  required
                />
                利用
              </label>
            </span>
            <span class="radio_selection_item">
              <label>
                <input
                  type="radio"
                  name="f_hunter_status"
                  class="f_input_radio"
                  value="2"
                  v-model="d_hunter_data.itf_hunter_status"
                  required
                />
                停止
              </label>
            </span>
            <span class="radio_selection_item">
              <label v-if="d_hunter_data.itf_hunter_status_farst === '0'">
                <input
                  type="radio"
                  name="f_hunter_status"
                  class="f_input_radio"
                  value="0"
                  v-model="d_hunter_data.itf_hunter_status"
                  required
                />
                登録待ち
              </label>
            </span>
          </p>
        </fieldset>

        <!-- 個人情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">個人情報</legend>
          <!-- お名前(漢字) -->
          <p class="f_fieldset_contents">
            <label
              for="f_individual_name"
              class="input_contents_label label_border_blue"
            >
              お名前(漢字)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">姓</span>
                <input
                  type="text"
                  name="f_individual_name_last"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_individual_name"
                  placeholder="田中"
                  tabindex="4"
                  maxlength="10"
                  @keyup="check_individual_name_last"
                  v-model="d_hunter_data.itf_individual_name_last"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[2]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">名</span>
                <input
                  type="text"
                  name="f_individual_name_first"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_individual_name"
                  placeholder="太郎"
                  tabindex="5"
                  maxlength="10"
                  @keyup="check_individual_name_first"
                  v-model="d_hunter_data.itf_individual_name_first"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[3]
              }}</label>
            </span>
          </p>
          <!-- お名前(よみがな) -->
          <p class="f_fieldset_contents">
            <label
              for="f_individual_name_kana"
              class="input_contents_label label_border_blue"
            >
              お名前(よみがな)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">せい</span>
                <input
                  type="text"
                  name="f_individual_name_last_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_individual_name_kana"
                  placeholder="たなか"
                  tabindex="6"
                  maxlength="10"
                  @keyup="check_individual_name_last_kana"
                  v-model="d_hunter_data.itf_individual_name_last_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[4]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">めい</span>
                <input
                  type="text"
                  name="f_individual_name_first_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_individual_name_kana"
                  placeholder="たろう"
                  tabindex="7"
                  maxlength="10"
                  @keyup="check_individual_name_first_kana"
                  v-model="d_hunter_data.itf_individual_name_first_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[5]
              }}</label>
            </span>
          </p>
          <!-- 誕生日 -->
          <p class="f_fieldset_contents">
            <label
              for="f_birthday_individual"
              class="input_contents_label label_border_blue"
            >
              誕生日
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="date"
              name="f_birthday_individual"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_birthday_individual"
              max="9999-12-31"
              tabindex="8"
              v-model="d_hunter_data.itf_individual_birthday"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[6] }}</label>
          </p>
          <!-- 性別 -->
          <p class="f_fieldset_contents" id="guild_list">
            <label
              for="f_gender_individual"
              class="input_contents_label label_border_blue"
            >
              性別
            </label>
            <br />
            <select v-model="d_hunter_data.itf_individual_gender" tabindex="9">
              <option></option>
              <option
                v-for="gender_list in d_gender_list_data"
                v-bind:key="gender_list.id"
                :value="gender_list"
              >
                {{ gender_list.name }}
              </option>
            </select>
          </p>
          <!-- メールアドレス -->
          <p class="f_fieldset_contents">
            <label
              for="f_individual_mail"
              class="input_contents_label label_border_blue"
            >
              メールアドレス
            </label>
            <br />
            <input
              type="email"
              name="f_individual_mail"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_individual_mail"
              placeholder="mailadress123@example.com"
              tabindex="10"
              maxlength="50"
              @keyup="check_individual_mail"
              v-model="d_hunter_data.itf_individual_mail"
            />
          </p>
          <!-- 電話番号 -->
          <p class="f_fieldset_contents">
            <label
              for="f_individual_tel"
              class="input_contents_label label_border_blue"
            >
              電話番号
            </label>
            <br />
            <input
              type="tel"
              name="f_individual_tel"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_individual_tel"
              placeholder="08012345678"
              tabindex="11"
              maxlength="15"
              @keyup="check_individual_tel"
              v-model="d_hunter_data.itf_individual_tel"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[14] }}</label>
          </p>
          <!-- 住所 -->
          <fieldset class="input_fieldset input_fieldset_1">
            <legend class="legend_style">住所</legend>
            <p class="f_fieldset_contents">
              <span class="f_fieldset_contents_position_row">
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_individual_zipcode"
                    class="input_contents_label label_border_blue"
                  >
                    郵便番号
                  </label>
                  <input
                    type="text"
                    name="f_individual_zipcode"
                    class="f_input_text f_input_size_3 manage_registmodifydelete_f_input_text"
                    id="f_individual_zipcode"
                    placeholder="9041234"
                    tabindex="12"
                    maxlength="7"
                    inputmode="numeric"
                    @keyup="check_individual_zipcode"
                    v-model="d_hunter_data.itf_individual_zipcode"
                  />
                  <label class="err_message_red">
                    {{ d_error_message[13] }}
                  </label>
                </span>
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_individual_prefecture"
                    class="input_contents_label label_border_blue"
                  >
                    都道府県
                  </label>
                  <select
                    v-model="d_hunter_data.itf_individual_prefectures"
                    tabindex="13"
                    class="prefecture"
                  >
                    <option></option>
                    <option
                      v-for="prefecture_list in d_prefecture_list_data"
                      v-bind:key="prefecture_list.id"
                      :value="prefecture_list"
                    >
                      {{ prefecture_list.name }}
                    </option>
                  </select>
                </span>
              </span>
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_individual_address"
                class="input_contents_label label_border_blue"
              >
                市区町村・番地
              </label>
              <br />
              <input
                type="text"
                name="f_individual_address"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_individual_address"
                placeholder="沖縄市999番地"
                tabindex="14"
                maxlength="100"
                v-model="d_hunter_data.itf_individual_address"
              />
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_individual_building"
                class="input_contents_label label_border_blue"
              >
                建物名・部屋番号
              </label>
              <br />
              <input
                type="text"
                name="f_individual_building"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_individual_building"
                placeholder="コーポ123"
                tabindex="15"
                maxlength="50"
                v-model="d_hunter_data.itf_individual_building"
              />
            </p>
          </fieldset>
        </fieldset>

        <!-- 保護者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">保護者情報</legend>
          <!-- 保護者情報_お名前(漢字) -->
          <p class="f_fieldset_contents">
            <label
              for="f_parents_name"
              class="input_contents_label label_border_blue"
            >
              お名前(漢字)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">姓</span>
                <input
                  type="text"
                  name="f_parents_name_last"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_parents_name"
                  placeholder="田中"
                  tabindex="16"
                  maxlength="10"
                  @keyup="check_parents_name_last"
                  v-model="d_hunter_data.itf_parents_name_last"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[7]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">名</span>
                <input
                  type="text"
                  name="f_parents_name_first"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_parents_name"
                  placeholder="信長"
                  tabindex="17"
                  maxlength="10"
                  @keyup="check_parents_name_first"
                  v-model="d_hunter_data.itf_parents_name_first"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[8]
              }}</label>
            </span>
          </p>
          <!-- 保護者情報_お名前(よみがな) -->
          <p class="f_fieldset_contents">
            <label
              for="f_parents_name_kana"
              class="input_contents_label label_border_blue"
            >
              お名前(よみがな)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">せい</span>
                <input
                  type="text"
                  name="f_parents_name_last_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_parents_name_kana"
                  placeholder="たなか"
                  tabindex="18"
                  maxlength="10"
                  @keyup="check_parents_name_last_kana"
                  v-model="d_hunter_data.itf_parents_name_last_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[9]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">めい</span>
                <input
                  type="text"
                  name="f_parents_name_first_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_parents_name_kana"
                  placeholder="のぶなが"
                  tabindex="19"
                  maxlength="10"
                  @keyup="check_parents_name_first_kana"
                  v-model="d_hunter_data.itf_parents_name_first_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[10]
              }}</label>
            </span>
          </p>
          <!-- 保護者情報_誕生日 -->
          <p class="f_fieldset_contents">
            <label
              for="f_parents_birthday"
              class="input_contents_label label_border_blue"
            >
              誕生日
            </label>
            <br />
            <input
              type="date"
              name="f_parents_birthday"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_parents_birthday"
              max="9999-12-31"
              tabindex="20"
              v-model="d_hunter_data.itf_parents_birthday"
            />
          </p>
          <!-- 保護者情報_性別 -->
          <p class="f_fieldset_contents" id="guild_list">
            <label
              for="f_parents_gender"
              class="input_contents_label label_border_blue"
            >
              性別
            </label>
            <br />
            <select v-model="d_hunter_data.itf_parents_gender" tabindex="21">
              <option></option>
              <option
                v-for="gender_list in d_gender_list_data"
                v-bind:key="gender_list.id"
                :value="gender_list"
              >
                {{ gender_list.name }}
              </option>
            </select>
            <br />
          </p>
          <!-- 保護者情報_メールアドレス -->
          <p class="f_fieldset_contents">
            <label
              for="f_parents_mail"
              class="input_contents_label label_border_blue"
            >
              メールアドレス
            </label>
            <br />
            <input
              type="email"
              name="f_parents_mail"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_parents_mail"
              placeholder="mailadress123@example.com"
              tabindex="23"
              maxlength="50"
              @keyup="check_parents_mail"
              v-model="d_hunter_data.itf_parents_mail"
            />
          </p>
          <!-- 保護者情報_電話番号 -->
          <p class="f_fieldset_contents">
            <label
              for="f_parents_tel"
              class="input_contents_label label_border_blue"
            >
              電話番号
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="tel"
              name="f_parents_tel"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_parents_tel"
              placeholder="09012345678"
              tabindex="24"
              maxlength="15"
              @keyup="check_parents_tel"
              v-model="d_hunter_data.itf_parents_tel"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[11] }}</label>
            <label class="err_message_red">{{ d_error_message[15] }}</label>
          </p>
        </fieldset>

        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_削除 -->
          <GenericButton
            :class_name="class_name"
            :message="message"
            v-on:click="delete_data_hunter()"
          />
          <!-- ボタン_更新 -->
          <GenericButton
            :class_name="class_name2"
            :message="message2"
            v-on:click="update_data_hunter()"
          />
        </div>
      </form>
    </div>

    <!-- モーダル画面 -->
    <ModalWindow
      modal_title="確認"
      :msg_1="'ハンター名 :' + d_hunter_data.itf_hunter_hunter_name"
      msg_2="のデータを削除しますか?"
      button_no="キャンセル"
      button_yes="削除"
      v-if="flg_show_modal_delete"
      @modal-no="modalnodelete()"
      @modal-yes="modalyesdelete()"
    />
    <ModalWindow
      modal_title="削除完了"
      :msg_1="'ハンター名 :' + d_hunter_data.itf_hunter_hunter_name"
      msg_2="を削除完了しました。"
      button_ok="OK"
      v-if="flg_show_modal_delete_comp"
      @modal-ok="modalnodeletecomp()"
    />
    <ModalWindow
      modal_title="削除失敗"
      :msg_1="'ハンター名 :' + d_hunter_data.itf_hunter_hunter_name"
      msg_2="クエスト参加中のため削除できません。"
      button_ok="OK"
      v-if="flg_show_modal_delete_failure"
      @modal-ok="modalnodeletefailure()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import ApiService from "../service/ApiService";
import GenericButton from "../components/GenericButton.vue";
import ModalWindow from "../components/Modal_2.vue";

/////////////////////////////
// interface(データ入れ物)
/////////////////////////////
// 更新用データ_ハンターデータ
interface input_hunter_data {
  //////////////
  // ハンター情報
  //////////////
  // ハンター名
  itf_hunter_hunter_name: string;
  // ログインID
  itf_hunter_login_id: string;
  // パスワード(現在)
  itf_hunter_password: string;
  // パスワード(更新)
  itf_hunter_new_password: string;
  // 所属ギルド
  itf_hunter_guild: {
    id: number;
    name: string;
  };
  // ハンターランク
  itf_hunter_rank: string;
  // 経験値
  itf_hunter_exp: number;
  // ダイヤ
  itf_hunter_dia: number;
  // 利用状況
  itf_hunter_status: string;
  // 利用状況(初期値保存用)
  itf_hunter_status_farst: string;
  //////////////
  // 個人情報
  //////////////
  // 名前(漢字)_名字
  itf_individual_name_last: string;
  // 名前(漢字)_名前
  itf_individual_name_first: string;
  // 名前(よみがな)_名字
  itf_individual_name_last_kana: string;
  // 名前(よみがな)_名前
  itf_individual_name_first_kana: string;
  // 誕生日
  itf_individual_birthday: string;
  // 性別
  itf_individual_gender: {
    id: number;
    name: string;
  };
  // メールアドレス
  itf_individual_mail: string;
  // 電話番号
  itf_individual_tel: string;
  // 住所(郵便番号)
  itf_individual_zipcode: string;
  // 住所(都道府県)
  itf_individual_prefectures: {
    id: number;
    name: string;
  };
  // 住所(市区町村・番地)
  itf_individual_address: string;
  // 住所(建物名・部屋番号)
  itf_individual_building: string;
  //////////////
  // 保護者情報
  //////////////
  // 名前(漢字)_名字
  itf_parents_name_last: string;
  // 名前(漢字)_名前
  itf_parents_name_first: string;
  itf_parents_name_last_kana: string;
  // 名前(よみがな)_名前
  itf_parents_name_first_kana: string;
  // 誕生日
  itf_parents_birthday: string;
  // 性別
  itf_parents_gender: {
    id: number;
    name: string;
  };
  // メールアドレス
  itf_parents_mail: string;
  // 電話番号
  itf_parents_tel: string;
}
interface ModifyHunterData {
  hunter_data: input_hunter_data;
}

// 所属ギルドリスト用データ
interface input_guild_list_data {
  // ギルドID(画面非表示)
  id: number;
  // ギルド名
  name: string;
}
interface ModifyGuildListData {
  guild_list_data: input_guild_list_data[];
}

// 都道府県リスト用データ
interface input_prefecture_list_data {
  // 県ID(画面非表示)
  id: number;
  // 県名
  name: string;
}
interface ModifyPrefectureListData {
  prefecture_list_data: input_prefecture_list_data[];
}

export default defineComponent({
  // データ
  data() {
    /////////////////////////////
    // 変数宣言と初期化
    /////////////////////////////
    // エラーメッセージ用
    var error_message: string[] = new Array(12);
    /////////////////////////////
    // データ取得(ローカルストレージ)
    /////////////////////////////
    // ギルドデータ
    const st_guild_data = JSON.parse(localStorage.getItem("guild_data")!);
    // ハンターデータ
    const st_hunter_data = JSON.parse(
      localStorage.getItem("hunter_modify_data")!
    );

    /////////////////////////////
    // 性別リスト用データ設定
    /////////////////////////////
    var gender_list_data = [
      { id: 1, name: "男" },
      { id: 2, name: "女" },
      { id: 3, name: "その他" },
    ];

    /////////////////////////////
    // データ取得処理
    // ハンターデータ
    /////////////////////////////
    // reactive(データ変更監視)
    // 更新用データ_ハンターデータ
    var r_modify_hunter_data = reactive<ModifyHunterData>({
      hunter_data: {
        itf_hunter_hunter_name: "",
        itf_hunter_login_id: "",
        itf_hunter_password: "",
        itf_hunter_new_password: "",
        itf_hunter_guild: {
          id: 0,
          name: "",
        },
        itf_hunter_rank: "",
        itf_hunter_exp: 0,
        itf_hunter_dia: 0,
        itf_hunter_status: "",
        itf_hunter_status_farst: "",
        itf_individual_name_last: "",
        itf_individual_name_first: "",
        itf_individual_name_last_kana: "",
        itf_individual_name_first_kana: "",
        itf_individual_birthday: "",
        itf_individual_gender: {
          id: 0,
          name: "",
        },
        itf_individual_mail: "",
        itf_individual_tel: "",
        itf_individual_zipcode: "",
        itf_individual_prefectures: {
          id: 0,
          name: "",
        },
        itf_individual_address: "",
        itf_individual_building: "",
        itf_parents_name_last: "",
        itf_parents_name_first: "",
        itf_parents_name_last_kana: "",
        itf_parents_name_first_kana: "",
        itf_parents_birthday: "",
        itf_parents_gender: {
          id: 0,
          name: "",
        },
        itf_parents_mail: "",
        itf_parents_tel: "",
      },
    });
    const hunter_data = computed(() => r_modify_hunter_data.hunter_data);

    /////////////////////////////
    // 確認画面から戻ってきたか確認
    /////////////////////////////
    if (localStorage.getItem("update_hunter_data")) {
      // 確認画面から戻ってきた場合
      /////////////////////////////
      // データ取得処理
      // ハンターデータ(ローカルストレージ)
      /////////////////////////////
      // ハンターデータ_変更後データ
      const st_hunter_data_update = JSON.parse(
        localStorage.getItem("update_hunter_data")!
      );

      r_modify_hunter_data.hunter_data.itf_hunter_hunter_name =
        st_hunter_data_update.ls_hunter_name;
      r_modify_hunter_data.hunter_data.itf_hunter_login_id =
        st_hunter_data_update.ls_account_id;
      r_modify_hunter_data.hunter_data.itf_hunter_password =
        st_hunter_data_update.ls_account_password_now;
      r_modify_hunter_data.hunter_data.itf_hunter_new_password =
        st_hunter_data_update.ls_account_password;
      r_modify_hunter_data.hunter_data.itf_hunter_guild.id =
        st_hunter_data_update.ls_guild_id;
      r_modify_hunter_data.hunter_data.itf_hunter_guild.name =
        st_hunter_data_update.ls_guild_name;
      r_modify_hunter_data.hunter_data.itf_hunter_rank =
        st_hunter_data_update.ls_hunter_rank;
      r_modify_hunter_data.hunter_data.itf_hunter_exp =
        st_hunter_data_update.ls_hunter_point;
      r_modify_hunter_data.hunter_data.itf_hunter_dia =
        st_hunter_data_update.ls_hunter_dia;
      r_modify_hunter_data.hunter_data.itf_hunter_status = String(
        st_hunter_data_update.ls_hunter_status
      );
      r_modify_hunter_data.hunter_data.itf_hunter_status_farst = String(
        st_hunter_data_update.ls_hunter_status_farst
      );
      r_modify_hunter_data.hunter_data.itf_individual_name_last =
        st_hunter_data_update.ls_account_last_name;
      r_modify_hunter_data.hunter_data.itf_individual_name_first =
        st_hunter_data_update.ls_account_first_name;
      r_modify_hunter_data.hunter_data.itf_individual_name_last_kana =
        st_hunter_data_update.ls_account_last_name_kana;
      r_modify_hunter_data.hunter_data.itf_individual_name_first_kana =
        st_hunter_data_update.ls_account_first_name_kana;
      r_modify_hunter_data.hunter_data.itf_individual_birthday =
        st_hunter_data_update.ls_account_birthday;
      r_modify_hunter_data.hunter_data.itf_individual_gender.id =
        st_hunter_data_update.ls_account_gender_id;
      r_modify_hunter_data.hunter_data.itf_individual_gender.name =
        st_hunter_data_update.ls_account_gender_name;
      r_modify_hunter_data.hunter_data.itf_individual_mail =
        st_hunter_data_update.ls_account_mail;
      r_modify_hunter_data.hunter_data.itf_individual_tel =
        st_hunter_data_update.ls_account_tel;
      r_modify_hunter_data.hunter_data.itf_individual_zipcode =
        st_hunter_data_update.ls_account_zipcode;
      r_modify_hunter_data.hunter_data.itf_individual_prefectures.id =
        st_hunter_data_update.ls_account_prefecture_id;
      r_modify_hunter_data.hunter_data.itf_individual_prefectures.name =
        st_hunter_data_update.ls_account_prefecture_name;
      r_modify_hunter_data.hunter_data.itf_individual_address =
        st_hunter_data_update.ls_account_address;
      r_modify_hunter_data.hunter_data.itf_individual_building =
        st_hunter_data_update.ls_account_building;
      r_modify_hunter_data.hunter_data.itf_parents_name_last =
        st_hunter_data_update.ls_parent_last_name;
      r_modify_hunter_data.hunter_data.itf_parents_name_first =
        st_hunter_data_update.ls_parent_first_name;
      r_modify_hunter_data.hunter_data.itf_parents_name_last_kana =
        st_hunter_data_update.ls_parent_last_name_kana;
      r_modify_hunter_data.hunter_data.itf_parents_name_first_kana =
        st_hunter_data_update.ls_parent_first_name_kana;
      r_modify_hunter_data.hunter_data.itf_parents_birthday =
        st_hunter_data_update.ls_parent_birthday;
      r_modify_hunter_data.hunter_data.itf_parents_gender.id =
        st_hunter_data_update.ls_parent_gender_id;
      r_modify_hunter_data.hunter_data.itf_parents_gender.name =
        st_hunter_data_update.ls_parent_gender_name;
      r_modify_hunter_data.hunter_data.itf_parents_mail =
        st_hunter_data_update.ls_parent_mail;
      r_modify_hunter_data.hunter_data.itf_parents_tel =
        st_hunter_data_update.ls_parent_tel;
    } else {
      // 初期表示の場合
      /////////////////////////////
      // データ取得処理
      // ハンターデータ
      /////////////////////////////
      // ギルドID取得(ローカルストレージ)
      const param = {
        guildId: st_guild_data.ls_guild_id,
      };

      // データ取得処理呼び出し
      ApiService.getHunterModifyData(st_hunter_data.ls_hunter_id, param)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            const hunter = res.data[0];
            r_modify_hunter_data.hunter_data.itf_hunter_hunter_name =
              hunter.hunter_name;
            r_modify_hunter_data.hunter_data.itf_hunter_login_id =
              hunter.account_id;
            r_modify_hunter_data.hunter_data.itf_hunter_password =
              hunter.account_password;
            r_modify_hunter_data.hunter_data.itf_hunter_guild.id =
              hunter.guild_id;
            r_modify_hunter_data.hunter_data.itf_hunter_guild.name =
              hunter.guild_name;
            r_modify_hunter_data.hunter_data.itf_hunter_rank =
              hunter.hunter_rank;
            r_modify_hunter_data.hunter_data.itf_hunter_exp =
              hunter.hunter_point;
            r_modify_hunter_data.hunter_data.itf_hunter_dia =
              hunter.hunter_diamond;
            r_modify_hunter_data.hunter_data.itf_hunter_status = String(
              hunter.hunter_status
            );
            r_modify_hunter_data.hunter_data.itf_hunter_status_farst = String(
              hunter.hunter_status
            );
            r_modify_hunter_data.hunter_data.itf_individual_name_last =
              hunter.account_last_name;
            r_modify_hunter_data.hunter_data.itf_individual_name_first =
              hunter.account_first_name;
            r_modify_hunter_data.hunter_data.itf_individual_name_last_kana =
              hunter.account_last_name_kana;
            r_modify_hunter_data.hunter_data.itf_individual_name_first_kana =
              hunter.account_first_name_kana;
            // 個人情報_生年月日_フォーマット処理と設定(yyyy-mm-dd)
            let individual_birthday_Y = new Date(
              hunter.account_birthday
            ).getFullYear();
            let individual_birthday_M =
              new Date(hunter.account_birthday).getMonth() + 1;
            let individual_birthday_M2 = individual_birthday_M
              .toString()
              .padStart(2, "0");
            let individual_birthday_D = new Date(hunter.account_birthday)
              .getDate()
              .toString()
              .padStart(2, "0");
            r_modify_hunter_data.hunter_data.itf_individual_birthday =
              individual_birthday_Y +
              "-" +
              individual_birthday_M2 +
              "-" +
              individual_birthday_D;
            r_modify_hunter_data.hunter_data.itf_individual_gender.id =
              hunter.account_gender;
            r_modify_hunter_data.hunter_data.itf_individual_gender.name =
              hunter.account_genderStr;
            r_modify_hunter_data.hunter_data.itf_individual_mail =
              hunter.account_mail;
            r_modify_hunter_data.hunter_data.itf_individual_tel =
              hunter.account_tel;
            r_modify_hunter_data.hunter_data.itf_individual_zipcode =
              hunter.account_zipcode;
            r_modify_hunter_data.hunter_data.itf_individual_prefectures.id =
              hunter.prefecture_id;
            r_modify_hunter_data.hunter_data.itf_individual_prefectures.name =
              hunter.prefecture_name;
            r_modify_hunter_data.hunter_data.itf_individual_address =
              hunter.account_address;
            r_modify_hunter_data.hunter_data.itf_individual_building =
              hunter.account_building;
            r_modify_hunter_data.hunter_data.itf_parents_name_last =
              hunter.parent_last_name;
            r_modify_hunter_data.hunter_data.itf_parents_name_first =
              hunter.parent_first_name;
            r_modify_hunter_data.hunter_data.itf_parents_name_last_kana =
              hunter.parent_last_name_kana;
            r_modify_hunter_data.hunter_data.itf_parents_name_first_kana =
              hunter.parent_first_name_kana;
            // 誕生日が登録されているか確認
            if (hunter.parent_birthday != "0000-00-00 00:00:00") {
              // 誕生日が登録されている場合
              // 保護者情報_生年月日_フォーマット処理と設定(yyyy-mm-dd)
              let parents_birthday_Y = new Date(
                hunter.parent_birthday
              ).getFullYear();
              let parents_birthday_M =
                new Date(hunter.parent_birthday).getMonth() + 1;
              let parents_birthday_M2 = parents_birthday_M
                .toString()
                .padStart(2, "0");
              let parents_birthday_D = new Date(hunter.parent_birthday)
                .getDate()
                .toString()
                .padStart(2, "0");
              r_modify_hunter_data.hunter_data.itf_parents_birthday =
                parents_birthday_Y +
                "-" +
                parents_birthday_M2 +
                "-" +
                parents_birthday_D;
            }
            r_modify_hunter_data.hunter_data.itf_parents_gender.id =
              hunter.parent_gender;
            r_modify_hunter_data.hunter_data.itf_parents_gender.name =
              hunter.parent_genderStr;
            r_modify_hunter_data.hunter_data.itf_parents_mail =
              hunter.parent_mail;
            r_modify_hunter_data.hunter_data.itf_parents_tel =
              hunter.parent_tel;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    /////////////////////////////
    // データ取得処理
    // ギルド一覧(ドロップダウン用)
    /////////////////////////////
    // reactive(データ変更監視)
    // 所属ギルドリスト用データ
    var r_modify_guild_list_data = reactive<ModifyGuildListData>({
      guild_list_data: [],
    });
    const guild_list_data = computed(
      () => r_modify_guild_list_data.guild_list_data
    );

    // データ取得処理呼び出し
    ApiService.getGuildListData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const guildlist = res.data;
          for (var i = 0; i < guildlist.length; i++) {
            if (guildlist[i].status != 1) continue;
            r_modify_guild_list_data.guild_list_data.push({
              id: guildlist[i].guild_id,
              name: guildlist[i].guild_name,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /////////////////////////////
    // データ取得処理
    // 都道府県一覧(ドロップダウン用)
    /////////////////////////////
    // reactive(データ変更監視)
    // 都道府県リスト用データ
    var r_modify_prefecture_list_data = reactive<ModifyPrefectureListData>({
      prefecture_list_data: [],
    });
    const prefecture_list_data = computed(
      () => r_modify_prefecture_list_data.prefecture_list_data
    );

    // データ取得処理呼び出し
    ApiService.getPrefectureData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const prefectures = res.data;
          for (var i = 0; i < prefectures.length; i++) {
            r_modify_prefecture_list_data.prefecture_list_data.push({
              // 県ID(画面非表示)
              id: prefectures[i].id,
              // 県名
              name: prefectures[i].name,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /////////////////////////////
    // data設定
    /////////////////////////////
    return {
      // データ_登録更新削除表示対象
      d_hunter_data: hunter_data,
      // 所属ギルド_リスト用データ
      d_guild_list_data: guild_list_data,
      // 性別_リスト用データ
      d_gender_list_data: gender_list_data,
      // 都道府県_リスト用データ
      d_prefecture_list_data: prefecture_list_data,
      // ボタン設定
      class_name: "operation_button button_red",
      message: "削除",
      class_name2: "operation_button button_blue",
      message2: "更新",
      class_name3: "operation_button button_gray",
      // フラグ_表示非表示_削除確認モーダル
      flg_show_modal_delete: false,
      // フラグ_表示非表示_削除完了モーダル
      flg_show_modal_delete_comp: false,
      // フラグ_表示非表示_削除失敗モーダル_クエスト参加中
      flg_show_modal_delete_failure: false,
      // エラーメッセージ保存用
      d_error_message: error_message,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // メソッド
  methods: {
    /////////////////////////////
    // ボタンクリック処理_ヘッダー戻るアイコン
    /////////////////////////////
    oyareturntrantransition() {
      // 画面遷移_ハンター管理(一覧)へ
      this.$router.push({
        name: "managehunterlist",
      });
    },
    /////////////////////////////
    // ボタンクリック処理_削除
    /////////////////////////////
    delete_data_hunter() {
      // モーダル表示_削除確認
      this.flg_show_modal_delete = true;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除確認_キャンセル
    /////////////////////////////
    modalnodelete() {
      // モーダル非表示_削除確認
      this.flg_show_modal_delete = false;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除確認_削除
    /////////////////////////////
    modalyesdelete() {
      /////////////////////////////
      // データ取得(ローカルストレージ)
      /////////////////////////////
      // ギルドデータ
      const st_guild_data = JSON.parse(localStorage.getItem("guild_data")!);
      // ハンターデータ
      const st_hunter_data = JSON.parse(
        localStorage.getItem("hunter_modify_data")!
      );

      /////////////////////////////
      // データ削除処理
      // ハンター削除
      /////////////////////////////
      // ギルドID取得(ローカルストレージ)
      const param = {
        guildId: st_guild_data.ls_guild_id,
      };

      // データ削除処理呼び出し
      ApiService.postDeleteHunterData(st_hunter_data.ls_hunter_id, param)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // モーダル非表示_削除確認
            this.flg_show_modal_delete = false;
            // モーダル表示_削除失敗
            this.flg_show_modal_delete_comp = true;
          }
        })
        .catch((err) => {
          // エラー表示
          console.log(err);
          // モーダル非表示_削除確認
          this.flg_show_modal_delete = false;
          // モーダル表示_削除失敗_参加中クエストあり
          this.flg_show_modal_delete_failure = true;
        });
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除完了
    /////////////////////////////
    modalnodeletecomp() {
      // モーダル非表示_削除完了
      this.flg_show_modal_delete_comp = false;
      // 画面遷移_ハンター管理(一覧)画面
      this.$router.push({ name: "managehunterlist" });
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除失敗_参加中クエストあり
    /////////////////////////////
    modalnodeletefailure() {
      // モーダル非表示_削除失敗
      this.flg_show_modal_delete_failure = false;
    },
    /////////////////////////////
    // ボタンクリック処理_更新ボタン
    /////////////////////////////
    update_data_hunter() {
      /////////////////////////////
      // 変数宣言と初期化
      /////////////////////////////
      // フラグ_エラー有無
      var flg_check = false;

      /////////////////////////////
      // 入力チェック
      /////////////////////////////
      // 入力チェック処理呼び出し
      flg_check = this.check_input();
      // 入力チェックエラーが有った場合
      // 処理終了
      if (flg_check) return;

      // 利用状況文字列設定
      let hunter_status_string = "";
      if (this.d_hunter_data.itf_hunter_status === "1") {
        hunter_status_string = "利用";
      } else if (this.d_hunter_data.itf_hunter_status === "2") {
        hunter_status_string = "停止";
      } else {
        hunter_status_string = "登録待ち";
      }

      /////////////////////////////
      // ローカルストレージ保存
      /////////////////////////////
      localStorage.setItem(
        "update_hunter_data",
        JSON.stringify({
          ls_hunter_name: this.d_hunter_data.itf_hunter_hunter_name,
          ls_account_id: this.d_hunter_data.itf_hunter_login_id,
          ls_account_password_now: this.d_hunter_data.itf_hunter_password,
          ls_account_password: this.d_hunter_data.itf_hunter_new_password,
          ls_guild_id: this.d_hunter_data.itf_hunter_guild.id,
          ls_guild_name: this.d_hunter_data.itf_hunter_guild.name,
          ls_hunter_rank: this.d_hunter_data.itf_hunter_rank,
          ls_hunter_point: this.d_hunter_data.itf_hunter_exp,
          ls_hunter_dia: this.d_hunter_data.itf_hunter_dia,
          ls_hunter_status: this.d_hunter_data.itf_hunter_status,
          ls_status_str: hunter_status_string,

          ls_account_last_name: this.d_hunter_data.itf_individual_name_last,
          ls_account_first_name: this.d_hunter_data.itf_individual_name_first,
          ls_account_last_name_kana:
            this.d_hunter_data.itf_individual_name_last_kana,
          ls_account_first_name_kana:
            this.d_hunter_data.itf_individual_name_first_kana,
          ls_account_birthday: this.d_hunter_data.itf_individual_birthday,
          ls_account_gender_id: this.d_hunter_data.itf_individual_gender.id,
          ls_account_gender_name: this.d_hunter_data.itf_individual_gender.name,
          ls_account_mail: this.d_hunter_data.itf_individual_mail,
          ls_account_tel: this.d_hunter_data.itf_individual_tel,
          ls_account_zipcode: this.d_hunter_data.itf_individual_zipcode,
          ls_account_prefecture_id:
            this.d_hunter_data.itf_individual_prefectures.id,
          ls_account_prefecture_name:
            this.d_hunter_data.itf_individual_prefectures.name,
          ls_account_address: this.d_hunter_data.itf_individual_address,
          ls_account_building: this.d_hunter_data.itf_individual_building,

          ls_parent_last_name: this.d_hunter_data.itf_parents_name_last,
          ls_parent_first_name: this.d_hunter_data.itf_parents_name_first,
          ls_parent_last_name_kana:
            this.d_hunter_data.itf_parents_name_last_kana,
          ls_parent_first_name_kana:
            this.d_hunter_data.itf_parents_name_first_kana,
          ls_parent_birthday: this.d_hunter_data.itf_parents_birthday,
          ls_parent_gender_id: this.d_hunter_data.itf_parents_gender.id,
          ls_parent_gender_name: this.d_hunter_data.itf_parents_gender.name,
          ls_parent_mail: this.d_hunter_data.itf_parents_mail,
          ls_parent_tel: this.d_hunter_data.itf_parents_tel,

          ls_hunter_status_farst: this.d_hunter_data.itf_hunter_status_farst,
        })
      );

      // // 画面遷移_ハンター編集確認画面
      this.$router.push({
        name: "managehuntermodifycheck",
      });
    },
    /////////////////////////////
    // 入力チェック処理
    /////////////////////////////
    check_input() {
      /////////////////////////////
      // 変数宣言と初期化
      /////////////////////////////
      // フラグ_エラー有無
      var flg_check_input = false;
      // エラーメッセージ保存用
      this.d_error_message = new Array(15);

      /////////////////////////////
      // 入力チェック処理
      /////////////////////////////
      // ハンター情報
      if (this.d_hunter_data.itf_hunter_hunter_name.length < 1)
        this.d_error_message[0] = "ハンター名を入力してください";
      if (this.d_hunter_data.itf_hunter_login_id.length < 1)
        this.d_error_message[1] = "ログインIDを入力してください";

      // 個人情報
      if (this.d_hunter_data.itf_individual_name_last.length < 1)
        this.d_error_message[2] = "姓を入力してください";
      if (this.d_hunter_data.itf_individual_name_first.length < 1)
        this.d_error_message[3] = "名を入力してください";
      if (this.d_hunter_data.itf_individual_name_last_kana.length < 1)
        this.d_error_message[4] = "せいを入力してください";
      if (this.d_hunter_data.itf_individual_name_first_kana.length < 1)
        this.d_error_message[5] = "めいを入力してください";
      if (this.d_hunter_data.itf_individual_birthday.length < 1)
        this.d_error_message[6] = "誕生日を入力してください";

      // 保護者情報
      if (this.d_hunter_data.itf_parents_name_last.length < 1)
        this.d_error_message[7] = "姓を入力してください";
      if (this.d_hunter_data.itf_parents_name_first.length < 1)
        this.d_error_message[8] = "名を入力してください";
      if (this.d_hunter_data.itf_parents_name_last_kana.length < 1)
        this.d_error_message[9] = "せいを入力してください";
      if (this.d_hunter_data.itf_parents_name_first_kana.length < 1)
        this.d_error_message[10] = "めいを入力してください";
      if (
        this.d_hunter_data.itf_parents_tel.length < 1 ||
        isNaN(Number(this.d_hunter_data.itf_parents_tel))
      )
        this.d_error_message[11] = "電話番号を入力してください";

      // ギルド情報
      if (this.d_hunter_data.itf_hunter_guild.name.length < 1) {
        this.d_error_message[12] = "所属ギルドを選択してください";
      }
      if (this.d_hunter_data.itf_individual_zipcode.length >= 1) {
        if (this.d_hunter_data.itf_individual_zipcode.length < 7) {
          this.d_error_message[13] = "7桁で入力してください";
        }
      }
      if (this.d_hunter_data.itf_individual_tel.length >= 1) {
        if (this.d_hunter_data.itf_individual_tel.length < 8) {
          this.d_error_message[14] = "8～15桁で入力してください";
        }
      }
      if (this.d_hunter_data.itf_parents_tel.length >= 1) {
        if (this.d_hunter_data.itf_parents_tel.length < 8) {
          this.d_error_message[15] = "8～15桁で入力してください";
        }
      }

      // エラー存在チェック
      this.d_error_message.forEach((d_error_message) => {
        flg_check_input = d_error_message.length > 0 ? true : false;
      });

      // 戻り値を返す
      return flg_check_input;
    },
    /////////////////////////////
    // 入力制御_ハンター情報_ハンター名
    /////////////////////////////
    check_hunter_name() {
      const tmp_value = this.d_hunter_data.itf_hunter_hunter_name;
      if (tmp_value) {
        this.d_hunter_data.itf_hunter_hunter_name = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_ハンター情報_ログインID
    /////////////////////////////
    check_hunter_login_id() {
      const tmp_value = this.d_hunter_data.itf_hunter_login_id;
      if (tmp_value) {
        this.d_hunter_data.itf_hunter_login_id = tmp_value.replace(
          /[^0-9a-zA-Z-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_ハンター情報_新しいパスワード
    /////////////////////////////
    check_hunter_new_password() {
      const tmp_value = this.d_hunter_data.itf_hunter_new_password;
      if (tmp_value) {
        this.d_hunter_data.itf_hunter_new_password = tmp_value.replace(
          /[^0-9a-zA-Z-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_個人情報_お名前(漢字)_姓
    /////////////////////////////
    check_individual_name_last() {
      const tmp_value = this.d_hunter_data.itf_individual_name_last;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_name_last = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_個人情報_お名前(漢字)_名
    /////////////////////////////
    check_individual_name_first() {
      const tmp_value = this.d_hunter_data.itf_individual_name_first;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_name_first = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_個人情報_お名前(よみがな)_せい
    /////////////////////////////
    check_individual_name_last_kana() {
      const tmp_value = this.d_hunter_data.itf_individual_name_last_kana;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_name_last_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_個人情報_お名前(よみがな)_めい
    /////////////////////////////
    check_individual_name_first_kana() {
      const tmp_value = this.d_hunter_data.itf_individual_name_first_kana;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_name_first_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_個人情報_メールアドレス
    /////////////////////////////
    check_individual_mail() {
      const tmp_value = this.d_hunter_data.itf_individual_mail;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_mail = tmp_value.replace(
          /[^0-9a-zA-Z-_.@]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_個人情報_電話番号
    /////////////////////////////
    check_individual_tel() {
      const tmp_value = this.d_hunter_data.itf_individual_tel;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_tel = tmp_value.replace(
          /[^0-9]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_個人情報_郵便番号
    /////////////////////////////
    check_individual_zipcode() {
      const tmp_value = this.d_hunter_data.itf_individual_zipcode;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_zipcode = tmp_value.replace(
          /[^0-9]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_保護者情報_お名前(漢字)_姓
    /////////////////////////////
    check_parents_name_last() {
      const tmp_value = this.d_hunter_data.itf_parents_name_last;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_name_last = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_保護者情報_お名前(漢字)_名
    /////////////////////////////
    check_parents_name_first() {
      const tmp_value = this.d_hunter_data.itf_parents_name_first;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_name_first = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_保護者情報_お名前(よみがな)_せい
    /////////////////////////////
    check_parents_name_last_kana() {
      const tmp_value = this.d_hunter_data.itf_parents_name_last_kana;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_name_last_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_保護者情報_お名前(よみがな)_めい
    /////////////////////////////
    check_parents_name_first_kana() {
      const tmp_value = this.d_hunter_data.itf_parents_name_first_kana;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_name_first_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_保護者情報_メールアドレス
    /////////////////////////////
    check_parents_mail() {
      const tmp_value = this.d_hunter_data.itf_parents_mail;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_mail = tmp_value.replace(
          /[^0-9a-zA-Z-_.@]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_保護者情報_電話番号
    /////////////////////////////
    check_parents_tel() {
      const tmp_value = this.d_hunter_data.itf_parents_tel;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_tel = tmp_value.replace(/[^0-9]/g, "");
      }
    },
  },
});
</script>

<style></style>
