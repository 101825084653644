<template>
  <!-- イラスト画面 -->
  <div>
    <OekakiApp />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OekakiApp from "../components/OekakiApp.vue";

export default defineComponent({
  name: "App",
  // コンポーネント
  components: {
    OekakiApp,
  },
});
</script>

<style></style>
