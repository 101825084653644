<template>
  <!-- ログイン画面 -->
  <!-- メイン_内容 -->
  <div id="main_contents_login">
    <!-- タイトル -->
    <div id="main_title_img">
      <img src="../public/login_img_title.png" alt="タイトル" />
    </div>
    <!-- ログインフォーム -->
    <section id="form_login">
      <div id="form_login_contents">
        <!-- <form action="#" method="post"> -->
        <form type="button" @submit.prevent="login">
          <!-- ログインID -->
          <p>
            <label for="form_text_loginid">ログインID</label><br />
            <input
              type="text"
              id="form_text_loginid"
              name="form_text_loginid"
            />
          </p>
          <!-- パスワード -->
          <p>
            <label for="form_text_password">パスワード</label><br />
            <input
              type="password"
              id="form_text_password"
              name="form_text_password"
            />
          </p>
          <p style="color: red">{{ loginMessage }}</p>
          <GenericButton :id_name="id_name" :message="message" />
        </form>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";
import router from "../router/index";

export default defineComponent({
  name: "App",
  setup() {
    const state = reactive({
      loginMessage: "",
    });
    const loginMessage = computed(() => state.loginMessage);
    const id_name = "form_button_login";
    const message = "ログイン";
    const login = (e: any) => {
      const userId: string = e.target.elements.form_text_loginid.value;
      const passWord: string = e.target.elements.form_text_password.value;
      if (userId.length === 0) {
        state.loginMessage = "ログインIDを入力してください";
        return;
      }
      if (passWord.length === 0) {
        state.loginMessage = "パスワードを入力してください";
        return;
      }
      const data = {
        userId: userId,
        passWord: passWord,
      };
      ApiService.postLogin(data)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            localStorage.removeItem("guild_data");
            localStorage.setItem(
              "guild_data",
              JSON.stringify({
                ls_guild_id: res.data.guild_id,
              })
            );
            localStorage.removeItem("hunter_data");
            localStorage.setItem(
              "hunter_data",
              JSON.stringify({
                ls_hunter_id: res.data.hunter_id,
              })
            );
            router.push("/main");
          }
        })
        .catch((err) => {
          state.loginMessage = err.response.data;
        });
    };
    return { id_name, message, loginMessage, login };
  },
  // コンポーネント
  components: {
    GenericButton,
  },
});
</script>

<style scoped>
@import "../css/style.scss";
@import "../css/login.scss";
</style>
