<template>
  <!-- ハンター管理画面(ハンター一覧) -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ハンター管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear table_main">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>ハンター一覧</p>
      </div>
      <!-- テーブル -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table bordered"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          nextLabel: '次へ',
          prevLabel: '前へ',
          perPageDropdownEnabled: false,
          infoFn: (params) =>
            `${params.totalRecords} 件中 ${params.firstRecordOnPage} ～ ${params.lastRecordOnPage}`,
        }"
        :search-options="{
          enabled: true,
        }"
        @row-click="onRowClick"
      />
      <!-- ボタン_新規登録 -->
      <div class="vgt-registration-button">
        <GenericButton
          type="button"
          :class_name="class_name"
          :message="message"
          v-on:click="transition_hunter_regist()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import { VueGoodTable } from "vue-good-table-next";
import ApiService from "../service/ApiService";
import GenericButton from "../components/GenericButton.vue";

// データ受取用
interface hunter_data_catch {
  // ハンターID(画面非表示)
  itf_hunter_id: number;
  // ハンターランク
  itf_hunter_rank: string;
  // ハンター名
  itf_hunter_name: string;
  // 氏名_名前(漢字)_名字+名前(漢字)_名前
  itf_hunter_individual_name: string;
  // ダイヤ
  itf_hunter_dia: string;
  // 所蔵ギルド(id)(画面非表示)
  itf_hunter_guild_id: string;
  // 所蔵ギルド(ギルド名)
  itf_hunter_guild_name: string;
  // 利用状況
  itf_hunter_Usage_situation: string;
}
interface HunterState {
  hunter_item: hunter_data_catch[];
}

export default defineComponent({
  // データ
  data() {
    //////////////////////
    // ハンター一覧取得処理
    //////////////////////
    const hunter_list = reactive<HunterState>({
      hunter_item: [],
    });
    const hunter_data = computed(() => hunter_list.hunter_item);
    // パラメーター設定
    // 登録データ取得
    const param_data = JSON.parse(localStorage.getItem("guild_data")!);
    const param = {
      guildId: param_data.ls_guild_id,
    };

    // ハンター一覧取得処理
    ApiService.getHunterList(param)
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const hunters = res.data;
          for (var i = 0; i < hunters.length; i++) {
            hunter_list.hunter_item.push({
              itf_hunter_id: hunters[i].hunter_id,
              itf_hunter_rank: hunters[i].hunter_rank,
              itf_hunter_name: hunters[i].hunter_name,
              itf_hunter_individual_name:
                hunters[i].account_last_name + hunters[i].account_first_name,
              itf_hunter_dia: hunters[i].hunter_diamond,
              itf_hunter_guild_id: hunters[i].guild_id,
              itf_hunter_guild_name: hunters[i].guild_name,
              itf_hunter_Usage_situation: hunters[i].hunter_statusStr,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // データ設定
    return {
      // 列ヘッダー
      columns: [
        {
          label: "ランク",
          field: "itf_hunter_rank",
        },
        {
          label: "ハンター名",
          field: "itf_hunter_name",
        },
        {
          label: "氏名",
          field: "itf_hunter_individual_name",
        },
        {
          label: "ダイヤ",
          field: "itf_hunter_dia",
        },
        {
          label: "所属ギルド",
          field: "itf_hunter_guild_name",
        },
        {
          label: "利用状況",
          field: "itf_hunter_Usage_situation",
        },
      ],
      // テーブルデータ
      rows: hunter_data,
      class_name: "operation_button button_black operation_button_quest_regist",
      message: "新規登録",
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    VueGoodTable,
    GenericButton,
  },
  // メソッド
  methods: {
    // テーブル行クリック時処理
    onRowClick(params: any) {
      // ローカルストレージにデータ保存
      localStorage.setItem(
        "hunter_modify_data",
        JSON.stringify({
          ls_hunter_id: params.row.itf_hunter_id,
          ls_guild_id: params.row.itf_hunter_guild_id,
          ls_guild_name: params.row.itf_hunter_guild_name,
        })
      );
      // 以前の更新データが残っている場合削除
      localStorage.removeItem("update_hunter_data");
      // 画面遷移_ハンター編集削除画面
      this.$router.push({
        name: "managehuntermodify",
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "management",
      });
    },
    // ボタンクリック処理_新規登録
    transition_hunter_regist() {
      // 以前の更新データが残っている場合削除
      localStorage.removeItem("regist_hunter_data");
      this.$router.push({
        name: "managehunterregister",
      });
    },
  },
});
</script>

<style></style>
