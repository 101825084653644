import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36513060"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal_all" }
const _hoisted_2 = { id: "modal_window" }
const _hoisted_3 = {
  key: 0,
  id: "modal_title"
}
const _hoisted_4 = { id: "message_group" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 3,
  id: "modal_message_4"
}
const _hoisted_9 = { id: "button_group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.modal_title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.modal_title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.msg_1)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.msg_1), 1))
            : _createCommentVNode("", true),
          (_ctx.msg_2)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.msg_2), 1))
            : _createCommentVNode("", true),
          (_ctx.msg_3)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.msg_3), 1))
            : _createCommentVNode("", true),
          (_ctx.msg_4)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.msg_4), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.button_no)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "button_modai",
                id: "button_modai_no",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.modalno && _ctx.modalno(...args)))
              }, _toDisplayString(_ctx.button_no), 1))
            : _createCommentVNode("", true),
          (_ctx.button_yes)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "button_modai",
                id: "button_modai_yes",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.modalyes && _ctx.modalyes(...args)))
              }, _toDisplayString(_ctx.button_yes), 1))
            : _createCommentVNode("", true),
          (_ctx.button_del)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "button_modai",
                id: "button_modai_del",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.modaldel && _ctx.modaldel(...args)))
              }, _toDisplayString(_ctx.button_del), 1))
            : _createCommentVNode("", true),
          (_ctx.button_ok)
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                class: "button_modai_2",
                id: "button_modai_ok",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.modalok && _ctx.modalok(...args)))
              }, _toDisplayString(_ctx.button_ok), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}