<template>
  <!-- 管理画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>管理画面</template>
      </SmallHeader>
    </div>
    <!-- ボタン_各管理画面遷移 -->
    <div
      class="grid_main2_contents button_management_menu background_border_brueblack"
    >
      <!-- ボタン_ギルド管理 -->
      <router-link
        to="/manageguildlist"
        class="button_management_router"
        v-if="flg_show_button_management === '0'"
      >
        <GenericButton
          :class_name="class_name"
          :message="management_btton_message_1"
        />
      </router-link>
      <!-- ボタン_クエスト管理 -->
      <router-link to="/managequestlist" class="button_management_router">
        <GenericButton
          :class_name="class_name"
          :message="management_btton_message_2"
        />
      </router-link>
      <!-- ボタン_ハンター管理 -->
      <router-link to="/managehunterlist" class="button_management_router">
        <GenericButton
          :class_name="class_name"
          :message="management_btton_message_3"
        />
      </router-link>
      <!-- ボタン_依頼者管理 -->
      <router-link to="/manageclientlist" class="button_management_router">
        <GenericButton
          :class_name="class_name"
          :message="management_btton_message_4"
        />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";

export default defineComponent({
  // データ
  data() {
    var flg_show_button_management_switch = "";
    var cookie = document.cookie;
    var cookiesArray = cookie.split(";");
    for (var c of cookiesArray) {
      var cArray = c.split("=");
      cArray[0] = cArray[0].trim();
      if (cArray[0] === "authority-id") {
        flg_show_button_management_switch = cArray[1];
      }
    }

    return {
      class_name:
        "button_management background_border_black manage_registmodifydelete_operationbutton",
      management_btton_message_1: "ギルド管理",
      management_btton_message_2: "クエスト管理",
      management_btton_message_3: "ハンター管理",
      management_btton_message_4: "依頼者管理",
      // ボタン表示切替用
      flg_show_button_management: flg_show_button_management_switch,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
  },
  // メソッド
  methods: {
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "main",
      });
    },
  },
});
</script>

<style>
/* スタイル読み込み */
@import "../css/style.scss";
@import "../css/Management.scss";
</style>
