<template>
  <!-- クエスト編集画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- 画面タイトル -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>クエスト管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- メインタイトル -->
      <div class="page_title">
        <p>クエスト編集</p>
      </div>
      <!-- フォーム -->
      <form class="scroll_form" @submit.prevent>
        <!-- 対象ギルド -->
        <p
          v-show="d_hunter_list_data.length === 0 && isHunterAsscociation"
          class="f_fieldset_contents"
        >
          <label
            for="f_quest_guild"
            class="input_contents_label label_border_blue"
          >
            対象ギルド
          </label>
          <span class="required_icon"></span>
          <br />
          <select
            v-model="d_quest_data.itf_quest_guild"
            v-on:change="onChangeGuildSelect()"
          >
            <option
              v-for="guild_list in d_guild_list_data"
              v-bind:key="guild_list.id"
              :value="guild_list"
            >
              {{ guild_list.name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ d_error_message[11] }}</label>
        </p>
        <!-- 依頼者 -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_client"
            class="input_contents_label label_border_blue"
          >
            依頼者
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="d_quest_data.itf_quest_client">
            <option
              v-for="client_list in d_client_list_data"
              v-bind:key="client_list.id"
              :value="client_list"
            >
              {{ client_list.name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ d_error_message[4] }}</label>
        </p>
        <!-- クエストタイトル -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_title"
            class="input_contents_label label_border_blue"
          >
            クエストタイトル
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="text"
            name="f_quest_title"
            class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
            id="f_quest_title"
            tabindex="1"
            @keyup="check_quest_title"
            v-model="d_quest_data.itf_quest_title"
            maxlength="12"
          />
          <br />
          <label class="err_message_red">{{ d_error_message[0] }}</label>
        </p>
        <!-- クエストランク -->
        <p class="f_fieldset_contents" id="quest_list">
          <label
            for="f_quest_rank"
            class="input_contents_label label_border_blue"
          >
            クエストランク
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="rank" tabindex="2">
            <option
              v-for="rank_list in d_rank_list_data"
              v-bind:key="rank_list.id"
              :value="rank_list"
            >
              {{ rank_list.name }}
            </option>
          </select>
          <br />
        </p>
        <!-- 獲得経験値 -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_exp"
            class="input_contents_label label_border_blue"
          >
            獲得経験値
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="exp_get_value" tabindex="3">
            <option
              v-for="exp_list in d_exp_list"
              v-bind:key="exp_list.itf_exp_id"
              :value="exp_list"
            >
              {{ exp_list.itf_exp_name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ d_error_message[12] }}</label>
        </p>
        <!-- 獲得ダイヤ -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_dia"
            class="input_contents_label label_border_blue"
          >
            獲得ダイヤ
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="dia_get_value" tabindex="4">
            <option
              v-for="dia_list in d_dia_list"
              v-bind:key="dia_list.itf_dia_id"
              :value="dia_list"
            >
              {{ dia_list.itf_dia_name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ d_error_message[13] }}</label>
        </p>
        <!-- 募集人数 -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_capacity"
            class="input_contents_label label_border_blue"
          >
            募集人数
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="number"
            name="f_quest_capacity_lower"
            class="f_input_text f_input_size_4 manage_registmodifydelete_f_input_text"
            id="f_quest_capacity"
            tabindex="5"
            v-model="d_quest_data.itf_quest_lower_capacity"
            min="0"
            max="999"
          />
          <span class="quest_capacity_word f_fieldset_contents_value">
            名～
          </span>
          <input
            type="number"
            name="f_quest_capacity_upper"
            class="f_input_text f_input_size_4 manage_registmodifydelete_f_input_text"
            id="f_quest_capacity"
            tabindex="6"
            v-model="d_quest_data.itf_quest_upper_capacity"
            min="0"
            max="999"
          />
          <span class="quest_capacity_word f_fieldset_contents_value">
            名
          </span>
          <br />
          <label class="err_message_red">
            {{ d_error_message[1] }}
          </label>
          <label class="err_message_red">
            {{ d_error_message[2] }}
          </label>
          <label class="err_message_red">
            {{ d_error_message[3] }}
          </label>
        </p>
        <!-- カテゴリー -->
        <p class="f_fieldset_contents" id="quest_list">
          <label
            for="f_quest_category"
            class="input_contents_label label_border_blue"
          >
            カテゴリ
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="d_quest_data.itf_category" tabindex="7">
            <option
              v-for="category in d_category_data"
              v-bind:key="category.id"
              :value="category"
            >
              {{ category.name }}
            </option>
          </select>
        </p>
        <!-- 依頼者 -->
        <!-- <p class="f_fieldset_contents">
          <label
            for="f_quest_requester"
            class="input_contents_label label_border_blue"
          >
            依頼者
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="text"
            name="f_quest_requester"
            class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
            id="f_quest_requester"
            tabindex="8"
            @keyup="check_quest_requester"
            v-model="d_quest_data.itf_quest_requester"
            maxlength="12"
          />
          <label class="err_message_red">{{ d_error_message[4] }}</label>
        </p> -->
        <!-- 依頼内容 -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_contents"
            class="input_contents_label label_border_blue"
          >
            依頼内容
          </label>
          <span class="required_icon"></span>
          <br />
          <textarea
            cols="30"
            rows="10"
            name="f_quest_contents"
            class="f_input_text f_input_textarea manage_registmodifydelete_f_input_text"
            id="f_quest_contents"
            tabindex="9"
            @keyup="check_quest_contents"
            v-model="d_quest_data.itf_quest_contents"
            maxlength="255"
          >
          </textarea>
          <br />
          <label class="err_message_red">{{ d_error_message[5] }}</label>
        </p>
        <!-- 作業場所 -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_location"
            class="input_contents_label label_border_blue"
          >
            作業場所
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="text"
            name="f_quest_location"
            class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
            id="f_quest_location"
            tabindex="10"
            @keyup="check_quest_location"
            v-model="d_quest_data.itf_quest_location"
            maxlength="12"
          />
          <br />
          <label class="err_message_red">{{ d_error_message[6] }}</label>
        </p>
        <!-- 対象年齢 -->
        <p class="f_fieldset_contents" id="quest_list">
          <label
            for="f_age_limit"
            class="input_contents_label label_border_blue"
          >
            対象年齢
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="number"
            name="f_age_limit"
            class="f_input_text f_input_size_4 manage_registmodifydelete_f_input_text"
            id="f_age_limit"
            tabindex="11"
            v-model="d_quest_data.itf_quest_min_age"
            min="0"
            max="99"
          />
          <span class="quest_capacity_word f_fieldset_contents_value">
            歳以上
          </span>
          <br />
          <label class="err_message_red">{{ d_error_message[7] }}</label>
        </p>
        <!-- 作業日時 -->
        <fieldset class="input_fieldset input_fieldset_3">
          <legend class="legend_style">
            作業日時
            <span class="required_icon"></span>
          </legend>
          <p class="f_fieldset_contents">
            <label
              for="f_quest_start_date"
              class="input_contents_label label_border_blue"
            >
              開始
            </label>
            <br />
            <input
              type="datetime-local"
              name="f_quest_start_date"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_quest_start_date"
              max="9999-12-31T23:59"
              tabindex="12"
              v-model="d_quest_data.itf_quest_start_date"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[8] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_quest_limit_date"
              class="input_contents_label label_border_blue"
            >
              終了
            </label>
            <br />
            <input
              type="datetime-local"
              name="f_quest_limit_date"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_quest_limit_date"
              max="9999-12-31T23:59"
              tabindex="13"
              v-model="d_quest_data.itf_quest_limit_date"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[9] }}</label>
            <label class="err_message_red">{{ d_error_message[10] }}</label>
          </p>
        </fieldset>
        <!-- クエスト状態 -->
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">
            クエスト状態
          </span>
          <select
            v-model="d_quest_data.itf_status"
            :disabled="d_flg_show_quest_status"
          >
            <option
              v-for="status in d_quest_status"
              v-bind:key="status.id"
              :value="status"
              :disabled="status.disabled"
            >
              {{ status.name }}
            </option>
          </select>
        </p>
        <!-- クエスト参加者 -->
        <div class="f_fieldset_contents">
          <!-- 参加者がいる -->
          <span class="show_contents_label label_border_blue">
            クエスト参加者
          </span>
          <br />
          <table
            id="quest_hunter_list_table"
            v-if="d_hunter_list_data.length !== 0"
          >
            <!-- ヘッダー -->
            <thead>
              <tr>
                <th
                  v-for="(header, index) in d_hunter_list_data_header"
                  v-bind:key="index"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <!-- 内容 -->
            <tbody>
              <tr
                v-for="(hunterlist, index) in d_hunter_list_data"
                v-bind:key="hunterlist.itf_hunter_id"
              >
                <td>
                  <label
                    :for="'f_check_quest_applicant' + hunterlist.itf_hunter_id"
                  >
                    <input
                      :disabled="d_flg_show_quest_status"
                      type="checkbox"
                      name="f_check_quest_applicant"
                      :id="'f_check_quest_applicant' + hunterlist.itf_hunter_id"
                      :value="hunterlist.itf_hunter_id"
                      v-model="d_quest_data.itf_quest_applicant"
                    />
                    {{ hunterlist.itf_hunter_name }}
                  </label>
                </td>
                <td>
                  {{
                    hunterlist.itf_account_last_name +
                    hunterlist.itf_account_first_name
                  }}
                </td>
                <td class="img_cancel_icon">
                  <img
                    src="@/public/icon_Cancel.png"
                    alt=""
                    v-on:click="delete_data_hunter(index)"
                    :class="{ disabled_icon: d_flg_show_quest_status }"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <span class="f_fieldset_contents_value no_applicant" v-else
            >参加者なし<br
          /></span>
          <label class="err_message_red">{{ d_error_message[14] }}</label>
          <label class="err_message_red">{{ d_error_message[15] }}</label>
        </div>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_削除 -->
          <GenericButton
            :class_name="class_name"
            :message="message"
            v-on:click="delete_data_quest()"
          />
          <!-- ボタン_更新 -->
          <GenericButton
            :class_name="class_name2"
            :message="message2"
            v-on:click="update_data_quest()"
          />
        </div>
      </form>
      <ModalWindow
        modal_title="確認"
        :msg_1="'クエスト名 :' + d_quest_data.itf_quest_title"
        msg_2="のデータを削除しますか?"
        button_no="キャンセル"
        button_yes="削除"
        v-if="flg_show_modal_delete"
        @modal-no="modalnodelete()"
        @modal-yes="modalyesdelete()"
      />
      <ModalWindow
        modal_title="削除完了"
        :msg_1="'クエスト名 :' + d_quest_data.itf_quest_title"
        msg_2="を削除完了しました。"
        button_ok="OK"
        v-if="flg_show_modal_delete_comp"
        @modal-ok="modalnodeletecomp()"
      />
      <ModalWindow
        modal_title="確認"
        :msg_1="'クエスト名 :' + d_quest_data.itf_quest_title"
        msg_2="クエストを更新しますか?"
        button_no="キャンセル"
        button_yes="更新"
        v-if="flg_show_modal_update"
        @modal-no="modalnoupdate()"
        @modal-yes="modalyesupdate()"
      />
      <ModalWindow
        modal_title="更新完了"
        :msg_1="'クエスト名 :' + d_quest_data.itf_quest_title"
        msg_2="を更新完了しました。"
        button_ok="OK"
        v-if="flg_show_modal_update_comp"
        @modal-ok="modalnoupdatecomp()"
      />
      <ModalWindow
        modal_title="確認"
        :msg_1="'ハンター名 :' + work_delete_hunter_name"
        msg_2="を参加者から削除しますか?"
        button_no="キャンセル"
        button_yes="削除"
        v-if="flg_show_modal_hunter_delete"
        @modal-no="modalnohunterdelete()"
        @modal-yes="modalyeshunterdelete()"
      />
      <ModalWindow
        modal_title="削除完了"
        :msg_1="'ハンター名 :' + work_delete_hunter_name"
        msg_2="を参加者から削除しました。"
        button_ok="OK"
        v-if="flg_show_modal_hunter_delete_comp"
        @modal-ok="modalhunterdeletecomp()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import ApiService from "../service/ApiService";
import GenericButton from "../components/GenericButton.vue";
import ModalWindow from "../components/Modal_2.vue";

/////////////////////////////
// interface(データ入れ物)
/////////////////////////////
// 更新用データ_クエストデータ
interface input_quest_data {
  itf_quest_id: string;
  itf_quest_title: string;
  itf_quest_rank: string;
  itf_quest_exp: string;
  itf_quest_dia: string;
  itf_quest_lower_capacity: string;
  itf_quest_upper_capacity: string;
  itf_quest_requester: string;
  itf_quest_contents: string;
  itf_quest_location: string;
  itf_quest_start_date: string;
  itf_quest_limit_date: string;
  itf_quest_min_age: string;
  itf_quest_orders_status: number;
  itf_rank: rank;
  itf_category: {
    id: number;
    name: string;
  };
  itf_status: {
    id: number;
    name: string;
    disabled: boolean;
  };
  itf_quest_guild: input_guild_list_data;
  itf_quest_client: input_client_list_data;
  itf_quest_applicant: number[];
  itf_old_quest_status: number;
  itf_old_quest_orders: show_takepart_quest_hunter_list_data[];
}
interface ModifyQuestData {
  quest_data: input_quest_data;
}

interface rank {
  id: number;
  name: string;
}

interface orderStatus {
  id: number;
  status: number;
}

// 表示用データ_クエスト参加者データ
interface show_takepart_quest_hunter_list_data {
  // ハンターID(画面非表示)
  itf_hunter_id: number;
  // ハンター名
  itf_hunter_name: string;
  // 個人名_姓(漢字)
  itf_account_last_name: string;
  // 個人名_名(漢字)
  itf_account_first_name: string;
  // 個人名_姓(ふりがな)
  itf_account_last_name_kana: string;
  // 個人名_名(ふりがな)
  itf_account_first_name_kana: string;
  // クエストID
  itf_order_quest_id: string;
  // 受注ステータス
  itf_orders_status: number;
}
interface HunterListData {
  hunter_list_data: show_takepart_quest_hunter_list_data[];
}

// 所属ギルドリスト用データ
interface input_guild_list_data {
  // ギルドID(画面非表示)
  id: number;
  // ギルド名
  name: string;
}
interface ModifyGuildListData {
  guild_list_data: input_guild_list_data[];
}
// 依頼者用データ
interface input_client_list_data {
  // 依頼者ID(画面非表示)
  id: number;
  // 依頼者名
  name: string;
}
interface ModifyClientListData {
  client_list_data: input_client_list_data[];
}

////////////////////////////////////////
// 取得経験値ドロップダウン用
////////////////////////////////////////
interface exp_data_catch {
  // 取得経験値ID(画面非表示)
  itf_exp_id: number;
  // 取得経験値
  itf_exp_name: string;
}
interface ExpState {
  exp_item: exp_data_catch[];
}
// 取得経験値データ渡す用
interface ExpGetState {
  itf_exp_id: number;
  itf_exp_name: string;
}

////////////////////////////////////////
// 取得ダイヤドロップダウン用
////////////////////////////////////////
interface dia_data_catch {
  // 取得ダイヤID(画面非表示)
  itf_dia_id: number;
  // 取得ダイヤ
  itf_dia_name: string;
}
interface DiaState {
  dia_item: dia_data_catch[];
}
// 取得ダイヤデータ渡す用
interface DiaGetState {
  itf_dia_id: number;
  itf_dia_name: string;
}

export default defineComponent({
  // データ
  data() {
    // ハンター協会管理者フラグ
    let isHunterAsscociation = false;
    const cookie: string = document.cookie;
    const cookiesArray: string[] = cookie.split(";");
    for (let c of cookiesArray) {
      const cArray: string[] = c.split("=");
      cArray[0] = cArray[0].trim();
      if (cArray[0] === "authority-id") {
        isHunterAsscociation = cArray[1] == "0";
      }
    }

    /////////////////////////////
    // 変数宣言_初期化
    /////////////////////////////
    // エラーメッセージ用
    var error_message: string[] = new Array(14);
    // 取得経験値ドロップダウン初期値設定用
    let first_exp_id = 0;
    let first_exp_name = "";
    // ランク別取得経験値デフォルト
    let exp_center_rank_D = 10;
    let exp_center_rank_C = 17;
    let exp_center_rank_B = 27;
    let exp_center_rank_A = 40;
    let exp_center_rank_S = 57;
    let exp_center_rank_SS = 78;
    let exp_center_rank_SSS = 104;
    // ランク別取得経験値範囲
    let exp_range_rank_D = 3;
    let exp_range_rank_C = 3;
    let exp_range_rank_B = 6;
    let exp_range_rank_A = 6;
    let exp_range_rank_S = 10;
    let exp_range_rank_SS = 10;
    let exp_range_rank_SSS = 15;
    // 取得経験値最大値最小値
    let exp_max_number = 0; // 最大値
    let exp_min_number = 0; // 最小値
    let exp_min_to_max = 0; // 最小値～最大値
    // 取得ダイヤドロップダウン初期値設定用
    let first_dia_id = 0;
    let first_dia_name = "";
    // ランク別ダイヤデフォルト
    let dia_center_rank_D = 10;
    let dia_center_rank_C = 21;
    let dia_center_rank_B = 37;
    let dia_center_rank_A = 48;
    let dia_center_rank_S = 79;
    let dia_center_rank_SS = 120;
    let dia_center_rank_SSS = 146;
    // ランク別取得ダイヤ範囲
    let dia_range_rank_D = 5;
    let dia_range_rank_C = 5;
    let dia_range_rank_B = 10;
    let dia_range_rank_A = 10;
    let dia_range_rank_S = 20;
    let dia_range_rank_SS = 20;
    let dia_range_rank_SSS = 25;
    // ダイヤ最大値最小値
    let dia_max_number = 0; // 最大値
    let dia_min_number = 0; // 最小値
    let dia_min_to_max = 0; // 最小値～最大値
    // 現在のクエストステータス
    let ls_quest_status = 0;

    /////////////////////////////
    // データ取得(ローカルストレージ)
    /////////////////////////////
    // クエストデータ
    const get_ls_quest_data = JSON.parse(localStorage.getItem("quest_data")!);

    // 現在のクエスト状態取得(ローカルストレージ)
    // フラグ_クエスト状態表示切替用
    const isQuestCompletedOrFailed: boolean = this.isQuestCompletedOrFailed(
      get_ls_quest_data.ls_quest_status
    );
    ls_quest_status = get_ls_quest_data.ls_quest_status;

    /////////////////////////////
    // クエストランクリスト用データ設定
    /////////////////////////////
    var rank_list_data = [
      { id: 10, name: "D" },
      { id: 15, name: "C" },
      { id: 20, name: "B" },
      { id: 30, name: "A" },
      { id: 50, name: "S" },
      { id: 75, name: "SS" },
      { id: 100, name: "SSS" },
    ];
    /////////////////////////////
    // クエストカテゴリー用データ
    /////////////////////////////
    var category_data = [
      { id: 1, name: "そうじ" },
      { id: 2, name: "べんきょう" },
      { id: 3, name: "あらいもの" },
      { id: 4, name: "うんどう" },
      { id: 5, name: "ごみすて" },
      { id: 6, name: "おつかい" },
      { id: 7, name: "こもり" },
      { id: 8, name: "おはなし" },
      { id: 9, name: "ざつむ" },
    ];
    /////////////////////////////
    // クエスト状態用データ
    /////////////////////////////
    var quest_status = [
      { id: 0, name: "ぼしゅう中", disabled: false },
      { id: 1, name: "おうぼ中", disabled: true },
      { id: 2, name: "しんこう中", disabled: false },
      { id: 3, name: "クリアかくにん中", disabled: true },
      { id: 4, name: "クリア済み", disabled: false },
      { id: 5, name: "クリア失敗", disabled: false },
    ];

    var order_status = [
      { id: 0, status: 4 },
      { id: 1, status: 4 },
      { id: 2, status: 1 },
      { id: 3, status: 1 },
      { id: 4, status: 2 },
      { id: 5, status: 3 },
    ];

    const quest_order_status = computed(() => order_status);

    var rank = reactive<rank>({
      id: 0,
      name: "",
    });
    const quest_rank = computed(() => rank);

    // ギルド情報
    var itf_quest_guild = reactive<input_guild_list_data>({
      id: 0,
      name: "",
    });
    // ローカルストレージより自分のギルドID取得
    let user_guild_id = 0;
    if (localStorage.getItem("guild_data")) {
      user_guild_id = Number(
        JSON.parse(localStorage.getItem("guild_data")!).ls_guild_id
      );
    }
    // 依頼者情報
    var itf_quest_client = reactive<input_client_list_data>({
      id: 0,
      name: "",
    });

    ////////////////////////////////////////
    // 取得経験値データ渡す用
    ////////////////////////////////////////
    var exp_get_value = reactive<ExpGetState>({
      itf_exp_id: 0,
      itf_exp_name: "",
    });
    ////////////////////////////////////////
    // 取得ダイヤデータ渡す用
    ////////////////////////////////////////
    var dia_get_value = reactive<DiaGetState>({
      itf_dia_id: 0,
      itf_dia_name: "",
    });

    /////////////////////////////
    // データ取得処理
    // クエストデータ
    /////////////////////////////
    // reactive(データ変更監視)
    // 更新用データ_クエストデータ
    var r_modify_quest_data = reactive<ModifyQuestData>({
      quest_data: {
        itf_quest_id: "",
        itf_quest_title: "",
        itf_quest_rank: "",
        itf_quest_exp: "",
        itf_quest_dia: "",
        itf_quest_lower_capacity: "",
        itf_quest_upper_capacity: "",
        itf_quest_requester: "",
        itf_quest_contents: "",
        itf_quest_location: "",
        itf_quest_start_date: "",
        itf_quest_limit_date: "",
        itf_quest_min_age: "",
        itf_rank: rank,
        itf_quest_orders_status: 0,
        itf_category: {
          id: 0,
          name: "",
        },
        itf_status: {
          id: 0,
          name: "",
          disabled: false,
        },
        itf_quest_guild: itf_quest_guild,
        itf_quest_client: itf_quest_client,
        itf_quest_applicant: [],
        itf_old_quest_status: 0,
        itf_old_quest_orders: [],
      },
    });
    const quest_data = computed(() => r_modify_quest_data.quest_data);

    // 獲得経験値ドロップダウン
    // 初期化_データ受取用
    var exp_list = reactive<ExpState>({
      exp_item: [],
    });
    const exp_data = computed(() => exp_list.exp_item);
    // 獲得ダイヤドロップダウン
    // 初期化_データ受取用
    var dia_list = reactive<DiaState>({
      dia_item: [],
    });
    const dia_data = computed(() => dia_list.dia_item);

    // データ取得処理呼び出し
    // 中で「獲得経験値ドロップダウン」「獲得ダイヤドロップダウン」の設定も実行
    ApiService.getQuestModifyData(get_ls_quest_data.ls_quest_id)
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const quest = res.data[0];
          r_modify_quest_data.quest_data.itf_quest_id = quest.quest_id;
          r_modify_quest_data.quest_data.itf_quest_title = quest.title;
          r_modify_quest_data.quest_data.itf_quest_rank = quest.rank;
          r_modify_quest_data.quest_data.itf_quest_exp = quest.point;
          r_modify_quest_data.quest_data.itf_quest_dia = quest.diamond;
          rank.id = rank_list_data.filter(
            (rank: any) => rank.name == quest.rank
          )[0].id;
          rank.name = quest.rank;
          r_modify_quest_data.quest_data.itf_quest_lower_capacity =
            quest.lower_capacity;
          r_modify_quest_data.quest_data.itf_quest_upper_capacity =
            quest.upper_capacity;
          r_modify_quest_data.quest_data.itf_quest_requester = quest.owner;
          r_modify_quest_data.quest_data.itf_quest_contents = quest.contents;
          r_modify_quest_data.quest_data.itf_quest_location = quest.location;
          r_modify_quest_data.quest_data.itf_quest_min_age = quest.min_age;
          r_modify_quest_data.quest_data.itf_category.name = quest.sub_title;
          const datas = category_data.filter((val) => {
            return val.name == quest.sub_title;
          });
          r_modify_quest_data.quest_data.itf_category.id = datas[0].id;
          r_modify_quest_data.quest_data.itf_status.id = quest.status;
          // クエスト状態_初期設定用
          if (quest.status === 0) {
            r_modify_quest_data.quest_data.itf_status.name = "ぼしゅう中";
          } else if (quest.status === 1) {
            r_modify_quest_data.quest_data.itf_status.name = "おうぼ中";
            r_modify_quest_data.quest_data.itf_status.disabled = true;
          } else if (quest.status === 2) {
            r_modify_quest_data.quest_data.itf_status.name = "しんこう中";
          } else if (quest.status === 3) {
            r_modify_quest_data.quest_data.itf_status.name = "クリアかくにん中";
            r_modify_quest_data.quest_data.itf_status.disabled = true;
          } else if (quest.status === 4) {
            r_modify_quest_data.quest_data.itf_status.name = "クリア済み";
          } else if (quest.status === 5) {
            r_modify_quest_data.quest_data.itf_status.name = "クリア失敗";
          }
          // 作業日時_開始_フォーマット処理と設定
          let quest_start_date_Y = new Date(quest.start_date).getFullYear();
          let quest_start_date_M = new Date(quest.start_date).getMonth() + 1;
          let quest_start_date_M2 = quest_start_date_M
            .toString()
            .padStart(2, "0");
          let quest_start_date_D = new Date(quest.start_date)
            .getDate()
            .toString()
            .padStart(2, "0");
          let quest_start_date_h = new Date(quest.start_date)
            .getHours()
            .toString()
            .padStart(2, "0");
          let quest_start_date_m = new Date(quest.start_date)
            .getMinutes()
            .toString()
            .padStart(2, "0");
          r_modify_quest_data.quest_data.itf_quest_start_date =
            quest_start_date_Y +
            "-" +
            quest_start_date_M2 +
            "-" +
            quest_start_date_D +
            " " +
            quest_start_date_h +
            ":" +
            quest_start_date_m;
          // 作業日時_終了_フォーマット処理と設定
          let quest_limit_date_Y = new Date(quest.limit_date).getFullYear();
          let quest_limit_date_M = new Date(quest.limit_date).getMonth() + 1;
          let quest_limit_date_M2 = quest_limit_date_M
            .toString()
            .padStart(2, "0");
          let quest_limit_date_D = new Date(quest.limit_date)
            .getDate()
            .toString()
            .padStart(2, "0");
          let quest_limit_date_h = new Date(quest.limit_date)
            .getHours()
            .toString()
            .padStart(2, "0");
          let quest_limit_date_m = new Date(quest.limit_date)
            .getMinutes()
            .toString()
            .padStart(2, "0");
          r_modify_quest_data.quest_data.itf_quest_limit_date =
            quest_limit_date_Y +
            "-" +
            quest_limit_date_M2 +
            "-" +
            quest_limit_date_D +
            " " +
            quest_limit_date_h +
            ":" +
            quest_limit_date_m;
          r_modify_quest_data.quest_data.itf_quest_guild.id = quest.guild_id;
          r_modify_quest_data.quest_data.itf_quest_guild.name =
            quest.guild_name;
          r_modify_quest_data.quest_data.itf_quest_client.id = quest.client_id;
          r_modify_quest_data.quest_data.itf_quest_client.name =
            quest.client_name;
          r_modify_quest_data.quest_data.itf_quest_applicant = [];

          ////////////////////////////////
          // データ設定処理
          // 取得経験値ドロップダウン
          ////////////////////////////////
          // ドロップダウンリスト設定処理_取得経験値範囲計算
          var i;
          switch (rank.name) {
            case "D": {
              exp_max_number = exp_center_rank_D + exp_range_rank_D; // 最大値設定
              exp_min_number = exp_center_rank_D - exp_range_rank_D; // 最小値設定
              exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "C": {
              exp_max_number = exp_center_rank_C + exp_range_rank_C; // 最大値設定
              exp_min_number = exp_center_rank_C - exp_range_rank_C; // 最小値設定
              exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "B": {
              exp_max_number = exp_center_rank_B + exp_range_rank_B; // 最大値設定
              exp_min_number = exp_center_rank_B - exp_range_rank_B; // 最小値設定
              exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "A": {
              exp_max_number = exp_center_rank_A + exp_range_rank_A; // 最大値設定
              exp_min_number = exp_center_rank_A - exp_range_rank_A; // 最小値設定
              exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "S": {
              exp_max_number = exp_center_rank_S + exp_range_rank_S; // 最大値設定
              exp_min_number = exp_center_rank_S - exp_range_rank_S; // 最小値設定
              exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "SS": {
              exp_max_number = exp_center_rank_SS + exp_range_rank_SS; // 最大値設定
              exp_min_number = exp_center_rank_SS - exp_range_rank_SS; // 最小値設定
              exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "SSS": {
              exp_max_number = exp_center_rank_SSS + exp_range_rank_SSS; // 最大値設定
              exp_min_number = exp_center_rank_SSS - exp_range_rank_SSS; // 最小値設定
              exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
          }
          // ドロップダウンリスト設定処理_ループ
          for (i = 0; i < exp_min_to_max; i++) {
            exp_list.exp_item.push({
              // ID(画面非表示)
              itf_exp_id: i,
              // 獲得経験値
              itf_exp_name: String(exp_min_number + i),
            });
          }
          // ドロップダウン初期表示設定
          exp_get_value.itf_exp_id = exp_list.exp_item.filter(
            (exp_list: any) => exp_list.itf_exp_name == quest.point
          )[0].itf_exp_id;
          exp_get_value.itf_exp_name = quest.point;

          ////////////////////////////////
          // データ設定処理
          // 取得ダイアドロップダウン
          ////////////////////////////////
          // ドロップダウンリスト設定処理_取得ダイア範囲計算
          var i2;
          switch (rank.name) {
            case "D": {
              dia_max_number = dia_center_rank_D + dia_range_rank_D; // 最大値設定
              dia_min_number = dia_center_rank_D - dia_range_rank_D; // 最小値設定
              dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "C": {
              dia_max_number = dia_center_rank_C + dia_range_rank_C; // 最大値設定
              dia_min_number = dia_center_rank_C - dia_range_rank_C; // 最小値設定
              dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "B": {
              dia_max_number = dia_center_rank_B + dia_range_rank_B; // 最大値設定
              dia_min_number = dia_center_rank_B - dia_range_rank_B; // 最小値設定
              dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "A": {
              dia_max_number = dia_center_rank_A + dia_range_rank_A; // 最大値設定
              dia_min_number = dia_center_rank_A - dia_range_rank_A; // 最小値設定
              dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "S": {
              dia_max_number = dia_center_rank_S + dia_range_rank_S; // 最大値設定
              dia_min_number = dia_center_rank_S - dia_range_rank_S; // 最小値設定
              dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "SS": {
              dia_max_number = dia_center_rank_SS + dia_range_rank_SS; // 最大値設定
              dia_min_number = dia_center_rank_SS - dia_range_rank_SS; // 最小値設定
              dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
            case "SSS": {
              dia_max_number = dia_center_rank_SSS + dia_range_rank_SSS; // 最大値設定
              dia_min_number = dia_center_rank_SSS - dia_range_rank_SSS; // 最小値設定
              dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

              break;
            }
          }
          // ドロップダウンリスト設定処理_ループ
          for (i2 = 0; i2 < dia_min_to_max; i2++) {
            dia_list.dia_item.push({
              // ID(画面非表示)
              itf_dia_id: i2,
              // 取得ダイヤ
              itf_dia_name: String(dia_min_number + i2),
            });
          }
          // ドロップダウン初期表示設定
          dia_get_value.itf_dia_id = dia_list.dia_item.filter(
            (dia_list: any) => dia_list.itf_dia_name == quest.diamond
          )[0].itf_dia_id;
          dia_get_value.itf_dia_name = quest.diamond;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /////////////////////////////
    // データ取得処理
    // クエスト参加者データ
    /////////////////////////////
    // reactive(データ変更監視)
    // 表示用データ_クエスト参加者データ
    var r_hunter_list_data = reactive<HunterListData>({
      hunter_list_data: [],
    });
    const hunter_list_data = computed(
      () => r_hunter_list_data.hunter_list_data
    );

    // データ取得処理呼び出し
    ApiService.getQuestModifyData(get_ls_quest_data.ls_quest_id)
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const hunters = res.data[0];
          for (var i = 0; i < hunters.hunters.length; i++) {
            r_hunter_list_data.hunter_list_data.push({
              // ハンターID(画面非表示)
              itf_hunter_id: hunters.hunters[i].hunter_hunter_id,
              // ハンター名
              itf_hunter_name: hunters.hunters[i].hunter_name,
              // 個人名_姓(漢字)
              itf_account_last_name: hunters.hunters[i].account_last_name,
              // 個人名_名(漢字)
              itf_account_first_name: hunters.hunters[i].account_first_name,
              // 個人名_姓(ふりがな)
              itf_account_last_name_kana:
                hunters.hunters[i].ccount_last_name_kana,
              // 個人名_名(ふりがな)
              itf_account_first_name_kana:
                hunters.hunters[i].account_first_name_kana,
              // クエストID
              itf_order_quest_id: hunters.hunters[i].order_quest_id,
              // 受注ステータス
              itf_orders_status: hunters.hunters[i].quest_order_orders_status,
            });

            // クエスト応募者チェックボックス
            if (hunters.hunters[i].quest_order_orders_status === 1)
              r_modify_quest_data.quest_data.itf_quest_applicant.push(
                hunters.hunters[i].hunter_hunter_id
              );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /////////////////////////////
    // データ取得処理
    // ギルド一覧(ドロップダウン用)
    /////////////////////////////
    // reactive(データ変更監視)
    // 所属ギルドリスト用データ
    var r_modify_guild_list_data = reactive<ModifyGuildListData>({
      guild_list_data: [],
    });
    const guild_list_data = computed(
      () => r_modify_guild_list_data.guild_list_data
    );

    // データ取得処理呼び出し
    ApiService.getGuildListData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const guildlist = res.data;
          for (var i = 0; i < guildlist.length; i++) {
            if (guildlist[i].status != 1) continue;
            r_modify_guild_list_data.guild_list_data.push({
              id: guildlist[i].guild_id,
              name: guildlist[i].guild_name,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /////////////////////////////
    // データ取得処理
    // 依頼者一覧(ドロップダウン用)
    /////////////////////////////
    // reactive(データ変更監視)
    // 依頼者リスト用データ
    var r_modify_client_list_data = reactive<ModifyClientListData>({
      client_list_data: [],
    });
    const client_list_data = computed(
      () => r_modify_client_list_data.client_list_data
    );
    this.fetchClients(get_ls_quest_data.ls_quest_guild_id);

    /////////////////////////////
    // data設定
    /////////////////////////////
    return {
      // データ_登録更新削除表示対象
      d_quest_data: quest_data,
      // クエストランク_リスト用データ
      d_rank_list_data: rank_list_data,
      // クエストカテゴリー用データ
      d_category_data: category_data,
      // クエスト状態用データ
      d_quest_status: quest_status,
      // フラグ_クエスト状態表示切替用
      d_flg_show_quest_status: isQuestCompletedOrFailed,
      // 参加ハンター表示用
      d_hunter_list_data: hunter_list_data,
      d_hunter_list_data_header: ["ハンター名", "個人名", "削除"],
      // ボタン設定
      class_name:
        "operation_button button_red manage_registmodifydelete_operationbutton",
      message: "削除",
      class_name2:
        "operation_button button_blue manage_registmodifydelete_operationbutton",
      message2: "更新",
      // エラーメッセージ保存用
      d_error_message: error_message,
      // フラグ_削除確認モーダル_表示非表示
      flg_show_modal_delete: false,
      // フラグ_削除完了モーダル_表示非表示
      flg_show_modal_delete_comp: false,
      // フラグ_更新確認モーダル_表示非表示
      flg_show_modal_update: false,
      // フラグ_更新完了モーダル_表示非表示
      flg_show_modal_update_comp: false,
      // フラグ_ハンター削除確認モーダル_表示非表示
      flg_show_modal_hunter_delete: false,
      // フラグ_ハンター削除完了モーダル_表示非表示
      flg_show_modal_hunter_delete_comp: false,
      work_delete_hunter_index: 0,
      work_delete_hunter_id: 0,
      work_delete_hunter_name: "",
      rank,
      order_status,
      d_guild_list_data: guild_list_data,
      d_client_list_data: client_list_data,
      isHunterAsscociation: isHunterAsscociation,
      // 取得経験値_リスト用データ
      d_exp_list: exp_data,
      // 取得経験値_リスト用データ
      exp_get_value,
      // 取得ダイヤ_リスト用データ
      d_dia_list: dia_data,
      // 取得ダイヤデータ渡す用
      dia_get_value,
      // 現在のクエストステータス
      ls_quest_status,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // 監視
  watch: {
    rank() {
      /////////////////////////////
      // クエストランク変更時処理
      /////////////////////////////
      this.change_dropdownlist_exp(); // ドロップダウンリスト変更処理_取得経験値呼び出し
      this.change_dropdownlist_dia(); // ドロップダウンリスト変更処理_取得経験値呼び出し
      // 更新データ(前回データ)初期化_獲得経験値
      this.d_quest_data.itf_quest_exp = "";
      // 更新データ(前回データ)初期化_獲得ダイヤ
      this.d_quest_data.itf_quest_dia = "";
    },
    /////////////////////////////
    // 獲得経験値変更時処理
    /////////////////////////////
    exp_get_value() {
      // 更新データに設定_獲得経験値
      this.d_quest_data.itf_quest_exp = this.exp_get_value.itf_exp_name;
    },
    /////////////////////////////
    // 獲得ダイヤ変更時処理
    /////////////////////////////
    dia_get_value() {
      // 更新データに設定_獲得ダイヤ
      this.d_quest_data.itf_quest_dia = this.dia_get_value.itf_dia_name;
    },
  },
  // メソッド
  methods: {
    // ボタンクリック処理_ヘッダー戻るアイコン
    oyareturntrantransition() {
      this.$router.push({
        name: "managequestlist",
      });
    },
    /////////////////////////////
    // ボタンクリック処理_削除
    /////////////////////////////
    delete_data_quest() {
      // モーダル表示_削除確認
      this.flg_show_modal_delete = true;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除確認_キャンセル
    /////////////////////////////
    modalnodelete() {
      this.flg_show_modal_delete = false;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除確認_削除
    /////////////////////////////
    modalyesdelete() {
      ApiService.deleteQuest(this.d_quest_data.itf_quest_id)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            this.flg_show_modal_delete = false;
            this.flg_show_modal_delete_comp = true;
          } else {
            alert("削除失敗");
          }
        })
        .catch((err) => {
          // エラー表示
          console.log(err);
          alert("削除失敗");
        });
      this.flg_show_modal_delete = false;
    },
    // ボタンクリック処理_モーダル_削除完了
    modalnodeletecomp() {
      // モーダル非表示_削除完了モーダル
      this.flg_show_modal_delete_comp = false;
      this.$router.push({ name: "managequestlist" });
    },
    /////////////////////////////
    // ボタンクリック処理_更新
    /////////////////////////////
    update_data_quest() {
      /////////////////////////////
      // 変数宣言と初期化
      /////////////////////////////
      // フラグ_エラー有無
      var flg_check = false;

      /////////////////////////////
      // 入力チェック
      /////////////////////////////
      // 入力チェック処理呼び出し
      flg_check = this.check_input();
      // 入力チェックエラーが有った場合
      // 処理終了
      if (flg_check) return;
      // モーダル表示_更新確認
      this.flg_show_modal_update = true;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_更新確認_キャンセル
    /////////////////////////////
    modalnoupdate() {
      this.flg_show_modal_update = false;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_更新確認_更新
    /////////////////////////////
    modalyesupdate() {
      this.d_quest_data.itf_quest_orders_status = this.order_status.filter(
        (status: any) => status.id == this.d_quest_data.itf_status.id
      )[0].status;

      this.d_quest_data.itf_old_quest_status = this.ls_quest_status;
      this.d_quest_data.itf_old_quest_orders = this.d_hunter_list_data;
      ApiService.postQuestStatus(this.d_quest_data.itf_quest_id, {
        data: this.d_quest_data,
      })
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // 処理成功の場合
            // モーダル非表示_更新確認
            this.flg_show_modal_update = false;
            // モーダル表示_更新完了
            this.flg_show_modal_update_comp = true;
          } else {
            // 処理失敗の場合
            alert("更新失敗");
          }
        })
        .catch((err) => {
          // エラー表示
          console.log(err);
          alert("更新失敗");
        });
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_更新完了_OK
    /////////////////////////////
    modalnoupdatecomp() {
      this.flg_show_modal_update_comp = false;
      this.$router.push({ name: "managequestlist" });
    },
    /////////////////////////////
    // ボタンクリック処理_ハンター削除
    /////////////////////////////
    delete_data_hunter(index: number) {
      // モーダル表示_削除確認
      this.flg_show_modal_hunter_delete = true;
      this.work_delete_hunter_index = index;
      this.work_delete_hunter_id = this.d_hunter_list_data[index].itf_hunter_id;
      this.work_delete_hunter_name =
        this.d_hunter_list_data[index].itf_hunter_name;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_ハンター削除確認_キャンセル
    /////////////////////////////
    modalnohunterdelete() {
      this.flg_show_modal_hunter_delete = false;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_ハンター削除確認_削除
    /////////////////////////////
    modalyeshunterdelete() {
      ApiService.deleteQuestOrders(
        this.work_delete_hunter_id,
        this.d_quest_data.itf_quest_id
      )
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            this.flg_show_modal_hunter_delete = false;
            // モーダル表示_更新完了
            this.flg_show_modal_hunter_delete_comp = true;
            this.d_hunter_list_data.splice(this.work_delete_hunter_index, 1);
            this.d_quest_data.itf_quest_applicant =
              this.d_quest_data.itf_quest_applicant.filter(
                (hunter_id) => hunter_id !== this.work_delete_hunter_id
              );
          } else {
            alert("削除失敗");
          }
        })
        .catch((err) => {
          // エラー表示
          console.log(err);
          alert("削除失敗");
        });
      this.flg_show_modal_hunter_delete = false;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_ハンター削除完了_OK
    /////////////////////////////
    modalhunterdeletecomp() {
      this.flg_show_modal_hunter_delete_comp = false;
    },
    /////////////////////////////
    // 入力チェック処理
    /////////////////////////////
    check_input() {
      /////////////////////////////
      // 変数宣言と初期化
      /////////////////////////////
      // フラグ_エラー有無
      var flg_check_input = false;
      // エラーメッセージ保存用
      this.d_error_message = new Array(14);

      /////////////////////////////
      // 入力チェック処理
      /////////////////////////////
      // ハンター情報
      if (this.d_quest_data.itf_quest_title.length < 1)
        this.d_error_message[0] = "クエストタイトルを入力してください";
      if (this.d_quest_data.itf_quest_lower_capacity.length < 1)
        this.d_error_message[1] = "募集人数を入力してください";
      if (this.d_quest_data.itf_quest_upper_capacity.length < 1)
        this.d_error_message[1] = "募集人数を入力してください";
      if (this.d_quest_data.itf_quest_lower_capacity !== "") {
        if (
          Number(this.d_quest_data.itf_quest_lower_capacity) < 1 ||
          Number(this.d_quest_data.itf_quest_lower_capacity) > 999
        )
          this.d_error_message[2] = "1～999の間の数値を入力してください";
      }
      if (this.d_quest_data.itf_quest_upper_capacity !== "") {
        if (
          Number(this.d_quest_data.itf_quest_upper_capacity) < 1 ||
          Number(this.d_quest_data.itf_quest_upper_capacity) > 999
        )
          this.d_error_message[2] = "1～999の間の数値を入力してください";
      }
      if (
        this.d_quest_data.itf_quest_upper_capacity !== "" &&
        this.d_quest_data.itf_quest_upper_capacity !== ""
      ) {
        if (
          Number(this.d_quest_data.itf_quest_lower_capacity) >
          Number(this.d_quest_data.itf_quest_upper_capacity)
        )
          this.d_error_message[3] = "最小～最大で入力してください";
      }
      // if (this.d_quest_data.itf_quest_requester.length < 1)
      if (this.d_quest_data.itf_quest_client.name.length < 1)
        this.d_error_message[4] = "依頼者を入力してください";
      if (this.d_quest_data.itf_quest_contents.length < 1)
        this.d_error_message[5] = "依頼内容を入力してください";
      if (this.d_quest_data.itf_quest_location.length < 1)
        this.d_error_message[6] = "作業場所を入力してください";
      if (this.d_quest_data.itf_quest_min_age.length < 1)
        this.d_error_message[7] = "対象年齢を入力してください";
      if (this.d_quest_data.itf_quest_start_date.length < 1)
        this.d_error_message[8] = "作業日時(開始)を入力してください";
      if (this.d_quest_data.itf_quest_limit_date.length < 1)
        this.d_error_message[9] = "作業日時(終了)を入力してください";
      if (
        this.d_quest_data.itf_quest_start_date.length >= 1 &&
        this.d_quest_data.itf_quest_limit_date.length >= 1
      ) {
        if (
          this.d_quest_data.itf_quest_start_date >
          this.d_quest_data.itf_quest_limit_date
        )
          this.d_error_message[10] = "終了は開始以降の日時で入力してください";
      }
      if (this.d_quest_data.itf_quest_guild.name.length < 1)
        this.d_error_message[11] = "対象ギルドを選択してください";
      if (this.exp_get_value.itf_exp_name == "")
        this.d_error_message[12] = "獲得経験値を選択してください";
      if (this.dia_get_value.itf_dia_name == "")
        this.d_error_message[13] = "獲得ダイヤを選択してください";
      if (
        this.d_quest_data.itf_quest_applicant.length <
          Number(this.d_quest_data.itf_quest_lower_capacity) &&
        this.d_quest_data.itf_status.id >= 2
      )
        this.d_error_message[14] = "参加ハンターが少ないです";
      if (
        this.d_quest_data.itf_quest_applicant.length >
          Number(this.d_quest_data.itf_quest_upper_capacity) &&
        this.d_quest_data.itf_status.id >= 2
      )
        this.d_error_message[15] = "参加ハンターが多すぎます";

      // エラー存在チェック
      this.d_error_message.forEach((d_error_message) => {
        flg_check_input = d_error_message.length > 0 ? true : false;
      });

      // 戻り値を返す
      return flg_check_input;
    },
    /////////////////////////////
    // 入力制御_クエストタイトル
    /////////////////////////////
    check_quest_title() {
      const tmp_value = this.d_quest_data.itf_quest_title;
      if (tmp_value) {
        this.d_quest_data.itf_quest_title = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_依頼者
    /////////////////////////////
    check_quest_requester() {
      const tmp_value = this.d_quest_data.itf_quest_requester;
      if (tmp_value) {
        this.d_quest_data.itf_quest_requester = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_依頼内容
    /////////////////////////////
    check_quest_contents() {
      const tmp_value = this.d_quest_data.itf_quest_contents;
      if (tmp_value) {
        this.d_quest_data.itf_quest_contents = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_作業場所
    /////////////////////////////
    check_quest_location() {
      const tmp_value = this.d_quest_data.itf_quest_location;
      if (tmp_value) {
        this.d_quest_data.itf_quest_location = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    ////////////////////////////////
    // ドロップダウンリスト変更処理
    // 獲得経験値
    ////////////////////////////////
    change_dropdownlist_exp() {
      //////////////////
      // 変数宣言
      //////////////////
      // ランク別取得経験値デフォルト
      let exp_center_rank_D = 10;
      let exp_center_rank_C = 17;
      let exp_center_rank_B = 27;
      let exp_center_rank_A = 40;
      let exp_center_rank_S = 57;
      let exp_center_rank_SS = 78;
      let exp_center_rank_SSS = 104;
      // ランク別取得経験値範囲
      let exp_range_rank_D = 3;
      let exp_range_rank_C = 3;
      let exp_range_rank_B = 6;
      let exp_range_rank_A = 6;
      let exp_range_rank_S = 10;
      let exp_range_rank_SS = 10;
      let exp_range_rank_SSS = 15;
      // 取得経験値最大値最小値
      let exp_max_number = 0; // 最大値
      let exp_min_number = 0; // 最小値
      let exp_min_to_max = 0; // 最小値～最大値

      // ドロップダウンリスト設定処理_取得経験値範囲計算
      if (this.rank.name != "") {
        var i;
        switch (this.rank.name) {
          case "D": {
            exp_max_number = exp_center_rank_D + exp_range_rank_D; // 最大値設定
            exp_min_number = exp_center_rank_D - exp_range_rank_D; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "C": {
            exp_max_number = exp_center_rank_C + exp_range_rank_C; // 最大値設定
            exp_min_number = exp_center_rank_C - exp_range_rank_C; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "B": {
            exp_max_number = exp_center_rank_B + exp_range_rank_B; // 最大値設定
            exp_min_number = exp_center_rank_B - exp_range_rank_B; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "A": {
            exp_max_number = exp_center_rank_A + exp_range_rank_A; // 最大値設定
            exp_min_number = exp_center_rank_A - exp_range_rank_A; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "S": {
            exp_max_number = exp_center_rank_S + exp_range_rank_S; // 最大値設定
            exp_min_number = exp_center_rank_S - exp_range_rank_S; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "SS": {
            exp_max_number = exp_center_rank_SS + exp_range_rank_SS; // 最大値設定
            exp_min_number = exp_center_rank_SS - exp_range_rank_SS; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "SSS": {
            exp_max_number = exp_center_rank_SSS + exp_range_rank_SSS; // 最大値設定
            exp_min_number = exp_center_rank_SSS - exp_range_rank_SSS; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
        }
        // ドロップダウンリスト設定処理_ループ
        this.d_exp_list.length = 0;
        for (i = 0; i < exp_min_to_max; i++) {
          this.d_exp_list.push({
            // ID(画面非表示)
            itf_exp_id: i,
            // 獲得経験値
            itf_exp_name: String(exp_min_number + i),
          });
        }

        // ドロップダウン表示初期値初期化
        this.exp_get_value.itf_exp_id = 0;
        this.exp_get_value.itf_exp_name = "";
      }
    },
    ////////////////////////////////
    // ドロップダウンリスト変更処理
    // 取得ダイヤ
    ////////////////////////////////
    change_dropdownlist_dia() {
      //////////////////
      // 変数宣言
      //////////////////
      // ランク別ダイヤデフォルト
      let dia_center_rank_D = 10;
      let dia_center_rank_C = 21;
      let dia_center_rank_B = 37;
      let dia_center_rank_A = 48;
      let dia_center_rank_S = 79;
      let dia_center_rank_SS = 120;
      let dia_center_rank_SSS = 146;
      // ランク別取得ダイヤ範囲
      let dia_range_rank_D = 5;
      let dia_range_rank_C = 5;
      let dia_range_rank_B = 10;
      let dia_range_rank_A = 10;
      let dia_range_rank_S = 20;
      let dia_range_rank_SS = 20;
      let dia_range_rank_SSS = 25;
      // 取得ダイヤ最大値最小値
      let dia_max_number = 0; // 最大値
      let dia_min_number = 0; // 最小値
      let dia_min_to_max = 0; // 最小値～最大値

      // ドロップダウンリスト設定処理_取得ダイヤ範囲計算
      if (this.rank.name != "") {
        var i;
        switch (this.rank.name) {
          case "D": {
            dia_max_number = dia_center_rank_D + dia_range_rank_D; // 最大値設定
            dia_min_number = dia_center_rank_D - dia_range_rank_D; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "C": {
            dia_max_number = dia_center_rank_C + dia_range_rank_C; // 最大値設定
            dia_min_number = dia_center_rank_C - dia_range_rank_C; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "B": {
            dia_max_number = dia_center_rank_B + dia_range_rank_B; // 最大値設定
            dia_min_number = dia_center_rank_B - dia_range_rank_B; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "A": {
            dia_max_number = dia_center_rank_A + dia_range_rank_A; // 最大値設定
            dia_min_number = dia_center_rank_A - dia_range_rank_A; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "S": {
            dia_max_number = dia_center_rank_S + dia_range_rank_S; // 最大値設定
            dia_min_number = dia_center_rank_S - dia_range_rank_S; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "SS": {
            dia_max_number = dia_center_rank_SS + dia_range_rank_SS; // 最大値設定
            dia_min_number = dia_center_rank_SS - dia_range_rank_SS; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "SSS": {
            dia_max_number = dia_center_rank_SSS + dia_range_rank_SSS; // 最大値設定
            dia_min_number = dia_center_rank_SSS - dia_range_rank_SSS; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
        }
        // ドロップダウンリスト設定処理_ループ
        this.d_dia_list.length = 0;
        for (i = 0; i < dia_min_to_max; i++) {
          this.d_dia_list.push({
            // ID(画面非表示)
            itf_dia_id: i,
            // 取得ダイヤ
            itf_dia_name: String(dia_min_number + i),
          });
        }

        // ドロップダウン表示初期値初期化
        this.dia_get_value.itf_dia_id = 0;
        this.dia_get_value.itf_dia_name = "";
      }
    },
    onChangeGuildSelect() {
      this.resetClientList();
      this.fetchClients(this.d_quest_data.itf_quest_guild.id);
    },
    resetClientList() {
      this.d_quest_data.itf_quest_client.id = 0;
      this.d_quest_data.itf_quest_client.name = "";
      this.d_client_list_data.splice(0);
    },
    fetchClients(guild_id: number): void {
      ApiService.getClientList({ guildId: guild_id })
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            const clientlist = res.data;
            for (var i = 0; i < clientlist.length; i++) {
              this.d_client_list_data.push({
                id: clientlist[i].client_id,
                name:
                  clientlist[i].client_last_name +
                  clientlist[i].client_first_name,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // クエスト状態が完了、または、失敗かどうか
    isQuestCompletedOrFailed(status: number): boolean {
      return status === 4 || status === 5;
    },
  },
});
</script>

<style>
/* テーブル全体 */
#quest_hunter_list_table {
  width: 100%;
  font-size: 12px;
  margin: 2.5% 0;
  border: 1px solid #939393;
  border-collapse: collapse;
}

/* テーブル_ヘッダー_1セルずつ */
#quest_hunter_list_table thead tr th {
  color: #333333;
  font-weight: bold;
  background-color: #d4d4d4;
  padding: 0.5rem 0;
  border: 1px solid #939393;
}

/* テーブル_内容_1セルずつ */
#quest_hunter_list_table tbody tr td {
  color: #333333;
  font-weight: bold;
  padding: 0.5rem;
  border: 1px solid #939393;
}

/* チェックボックス_クエスト応募者 */
#f_check_quest_applicant {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

[id^="f_check_quest_applicant"] {
  vertical-align: middle;
}

/* ハンター削除アイコン_画像 */
.img_cancel_icon {
  text-align: center;
}

.img_cancel_icon img {
  width: 40%;
  height: 40%;
  cursor: pointer;
}

.img_cancel_icon .disabled_icon {
  pointer-events: none;
  filter: opacity(20%);
}
</style>
