import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
  // APIのURI
  baseURL:
    process.env.HOSTNAME != undefined
      ? "http://localhost:5000"
      : "https://test.monotsuku.co.jp:5000",
  // リクエストヘッダ
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

export default apiClient;
