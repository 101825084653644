import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid_main2_contents button_management_menu background_border_brueblack" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericButton = _resolveComponent("GenericButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GenericButton, {
      id_name: _ctx.id_name,
      message: _ctx.btton_message,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
    }, null, 8, ["id_name", "message"])
  ]))
}