import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "small_header" }
const _hoisted_2 = { id: "title_small_header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.show_button_return)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "button_return",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.returntransition && _ctx.returntransition(...args)))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "slot_small_header_title")
    ])
  ]))
}