import http from "@/http-common";

class ApiService {
  getTest(): Promise<any> {
    return http.get(`/api/test`);
  }

  /******** ログイン画面 ********/
  postLogin(data: any) {
    return http.post(`/api/login`, data);
  }

  /******** メイン画面 ********/
  postMain() {
    return http.post(`/api/account/main`);
  }

  /******** ヘッダー部分で使用 ********/
  // ハンター情報取得
  getHunter(hunterId: number) {
    return http.get(`/api/hunter/${hunterId}`);
  }
  // ハンターアイコン登録
  putHunterIcon(hunterId: number, data: any) {
    return http.put(`/api/hunter/${hunterId}/icon`, data);
  }

  /******** クエスト画面 ********/
  // クエスト一覧(最新)
  getQuestByHunterId(data: any) {
    return http.get(`/api/quest?hunterId=${data.hunterId}`);
  }
  // クエスト一覧(最終ログイン時の情報)
  getOldQuestByHunterId(data: any) {
    return http.get(`/api/quest/old?hunterId=${data.hunterId}`);
  }
  // クエスト新規申し込み
  postOrder(questId: number, data: any) {
    return http.post(`/api/quest/order/${questId}`, data);
  }
  // クエスト更新
  putQuest(questId: number, data: any) {
    return http.put(`/api/quest/${questId}`, data);
  }

  /******** クエスト管理画面 ********/
  // クエスト情報取得(一覧画面)
  getQuestByAuthorityId() {
    return http.get(`api/admin/quest`);
  }
  // 未使用
  getQuestList(data: any) {
    return http.post(`api/admin/quest`, data);
  }
  // クエスト情報取得(編集画面)
  getQuestModifyData(questId: any) {
    return http.get(`/api/admin/quest/${questId}`);
  }
  // クエスト情報登録
  putRegisterQuestData(data: any) {
    return http.put(`/api/admin/quest/create`, data);
  }
  // クエスト利用状況更新
  postQuestStatus(questId: any, data: any) {
    return http.post(`/api/admin/quest/${questId}/update`, data);
  }
  // クエスト利用状況更新
  deleteQuest(questId: any) {
    return http.post(`/api/admin/quest/${questId}/delete`);
  }
  // クエスト参加者削除
  deleteQuestOrders(hunterId: number, questId: any) {
    return http.post(`/api/admin/quest/${questId}/${hunterId}/delete`);
  }

  /******** ギルド管理画面 ********/
  // ギルド情報取得(一覧画面)
  getGuildList() {
    return http.get(`/api/admin/guild`);
  }
  // ギルド情報取得(編集画面)
  getGuildModifyData(guildId: number) {
    return http.get(`/api/admin/guild/${guildId}`);
  }
  // ギルド情報登録
  putRegisterGuildData(data: any) {
    return http.put(`/api/guild/create`, data);
  }
  // ギルド利用状況更新
  postGuildStatus(guildId: number, data: any) {
    return http.post(`/api/admin/guild/${guildId}/update`, data);
  }
  // ギルド利用状況更新
  deleteGuild(guildId: number, data: any) {
    return http.post(`/api/admin/guild/${guildId}/delete`, data);
  }

  /******** ハンター管理画面 ********/
  // ハンター情報取得(一覧画面)
  getHunterList(data: any) {
    return http.get(`/api/admin/hunter?guildId=${data.guildId ?? 0}`);
  }
  // ハンター登録処理
  postRegisterHunterData(data: any) {
    return http.post(`/api/hunter/create`, data);
  }
  // ハンター情報取得(編集削除画面)
  getHunterModifyData(hunterId: number, data: any) {
    return http.get(
      `/api/admin/hunter/${hunterId}?guildId=${data.guildId ?? 0}`
    );
  }
  // ハンター情報登録
  putRegisterHunterData(data: any) {
    return http.put(`/api/hunter/create`, data);
  }
  // ハンター更新処理(編集削除画面)
  postUpdateHunterData(hunterId: number, data: any) {
    return http.post(`/api/admin/hunter/${hunterId}/update`, data);
  }
  // ハンター削除処理(編集削除画面)
  postDeleteHunterData(hunterId: number, data: any) {
    return http.post(`/api/admin/hunter/${hunterId}/delete`, data);
  }

  /******** 依頼者管理画面 ********/
  // 依頼者管理画面表示(依頼者一覧画面)
  getClientList(data: any) {
    return http.get(`/api/admin/client?guildId=${data.guildId ?? 0}`);
  }
  // 依頼者登録実行(依頼者登録画面)
  postClientRegister(data: any) {
    return http.post(`/api/admin/client/create`, data);
  }
  // 依頼者情報取得(依頼者編集削除画面)
  getClientModifyData(clientId: number, data: any) {
    return http.get(
      `/api/admin/client/${clientId}?guildId=${data.guildId ?? 0}`
    );
  }
  // 依頼者編集実行(依頼者編集削除画面)
  putUpdateClientData(clientId: number, data: any) {
    return http.put(`/api/admin/client/${clientId}/update`, data);
  }
  // 依頼者削除実行(依頼者編集削除画面)
  deleteDeleteClientData(clientId: number, data: any) {
    return http.delete(`/api/admin/client/${clientId}/delete`, data);
  }

  /******** 共通処理 ********/
  // セッションチェック
  postLoginCheck() {
    return http.post(`/api/login/check`);
  }
  // メール送信対象取得(ハンター協会)
  getSendMailTargetByAdministorator() {
    return http.get(`/api/mail/target/admin`);
  }
  // メール送信対象取得(ギルドに所属する人たち)
  getSendMailTargetByHunterName(data: any) {
    return http.post(`/api/mail/target`, data);
  }
  // メール送信
  postMail(data: any) {
    return http.post(`/api/mail`, data);
  }
  // メール送信（複数宛名）
  postMails(data: any) {
    return http.post(`/api/mails`, data);
  }
  // 都道府県一覧取得
  getPrefectureData() {
    return http.get(`/api/prefecture`);
  }
  // ギルド一覧取得
  getGuildListData() {
    return http.get(`/api/guild/name`);
  }
}

export default new ApiService();
