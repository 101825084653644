<template>
  <!-- ギルド登録確認画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ギルド管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>登録内容確認</p>
      </div>
      <!-- フォーム(表示) -->
      <form class="scroll_form">
        <!-- ギルド情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">ギルド情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              ギルド名
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_regist_guild_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              ログインID
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_id
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              パスワード
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_password
            }}</span>
          </p>
        </fieldset>
        <!-- 担当者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">担当者情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              担当者名(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_last_name +
              d_regist_check_data.ls_account_first_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              担当者名(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_last_name_kana +
              d_regist_check_data.ls_account_first_name_kana
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              メールアドレス
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_mail
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              電話番号
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_tel
            }}</span>
          </p>
        </fieldset>
        <!-- 会社情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">会社情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              施設名・社名
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_guild_operating
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              代表者名(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_guild_responsible_person_last_name +
              d_regist_check_data.ls_guild_responsible_person_first_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              代表者名(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_guild_responsible_person_last_name_kana +
              d_regist_check_data.ls_guild_responsible_person_first_name_kana
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              電話番号
            </span>
            <br />
            <span class="f_fieldset_contents_value">
              {{ d_regist_check_data.ls_guild_tel }}
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">住所</span>
            <br />
            <span class="f_fieldset_contents_value">
              {{ "〒 " + d_regist_check_data.ls_guild_zipcode }}
              <br />
              {{
                d_regist_check_data.ls_guild_prefecture_name +
                d_regist_check_data.ls_guild_address
              }}
              <br />
              {{ d_regist_check_data.ls_guild_building }}
            </span>
          </p>
        </fieldset>
        <div class="page_regist_message">
          <p>上記内容で登録しますか?</p>
        </div>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            v-on:click="return_transition()"
          />
          <!-- ボタン_登録 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            v-on:click="regist_data_guild()"
          />
        </div>
      </form>
    </div>
    <ModalWindow
      modal_title="完了"
      :msg_1="'ギルド名 :' + d_regist_check_data.ls_regist_guild_name"
      msg_2="の登録を受け付けました"
      msg_4="ご登録いただいたメールアドレス宛てに登録受付メールを送信しました。"
      button_ok="OK"
      v-if="flg_show_modal_comp"
      @modal-ok="modalokoya()"
    />
    <ModalWindow
      modal_title="失敗"
      :msg_1="'ギルド名 :' + d_regist_check_data.ls_regist_guild_name"
      msg_2="は既に登録されています。"
      button_ok="OK"
      v-if="flg_show_modal_duplication"
      @modal-ok="modalDuplication()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";
import ModalWindow from "../components/Modal_2.vue";

export default defineComponent({
  // データ
  data() {
    // 登録データ取得
    const st_guild_data = JSON.parse(
      localStorage.getItem("regist_guild_data")!
    );

    return {
      class_name:
        "operation_button button_white manage_registmodifydelete_operationbutton",
      message: "戻る",
      class_name2:
        "operation_button button_blue manage_registmodifydelete_operationbutton",
      message2: "登録",
      d_regist_check_data: st_guild_data,
      flg_show_modal_comp: false,
      flg_show_modal_duplication: false,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // メソッド
  methods: {
    // 戻るボタンクリック処理
    return_transition() {
      this.$router.push({
        name: "manageguildregister",
      });
    },
    // 登録ボタンクリック処理
    regist_data_guild() {
      ApiService.putRegisterGuildData(this.d_regist_check_data)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            ApiService.getSendMailTargetByAdministorator()
              .then((res) => {
                const data = res.data;
                // ギルド担当者へメール送信
                if (res.status === 200) {
                  ApiService.postMail({
                    mail: this.d_regist_check_data.ls_mail,
                    status: "10",
                    login_id: this.d_regist_check_data.ls_account_id,
                    guild_name: this.d_regist_check_data.ls_guild_name,
                    hunter_name: this.d_regist_check_data.ls_hunter_name,
                  });
                }
                // ハンター協会管理者へメール送信
                if (data.length > 0) {
                  ApiService.postMails({
                    mail: data,
                    status: "11",
                    login_id: this.d_regist_check_data.ls_account_id,
                    guild_name: this.d_regist_check_data.ls_guild_name,
                    hunter_name: this.d_regist_check_data.ls_hunter_name,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
            // モーダル表示_完了モーダル
            this.flg_show_modal_comp = true;
          } else {
            // モーダル表示_失敗モーダル
            this.flg_show_modal_duplication = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // ボタンクリック処理_モーダル_OK
    modalokoya() {
      // モーダル非表示_完了モーダル
      this.flg_show_modal_comp = false;
      this.flg_show_modal_duplication = false;
      // 画面遷移_ログイン画面
      this.$router.push({
        name: "manageguildlist",
      });
    },
    modalDuplication() {
      // モーダル非表示
      this.flg_show_modal_duplication = false;
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "manageguildregister",
      });
    },
  },
});
</script>

<style></style>
