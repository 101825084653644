<template>
  <!-- ナビゲーション -->
  <nav>
    <div id="nav_contents">
      <!-- メイン画面 -->
      <router-link to="/main" class="router_text">
        <div class="nav_button nav_button_backcolor1">
          <img src="@/public/menuButtonMain.svg" alt="" /><br />
          <div class="nav_text">メイン</div>
        </div>
      </router-link>
      <!-- クエスト画面 -->
      <router-link to="/quest" class="router_text">
        <div class="nav_button nav_button_backcolor1" id="quest">
          <div class="img_newarrival_quest_icon menu">
            <img src="@/public/icon_Attention.png" alt="" />
          </div>

          <img src="@/public/menuButtonQuest.svg" alt="" /><br />
          <div class="nav_text">クエスト</div>
        </div>
      </router-link>
      <!-- ギルド画面 -->
      <router-link to="/guild" class="router_text">
        <div class="nav_button nav_button_backcolor1">
          <img src="@/public/menuButtonGuild.svg" alt="" /><br />
          <div class="nav_text">ギルド</div>
        </div>
      </router-link>
      <!-- ランキング画面 -->
      <router-link to="/ranking" class="router_text">
        <div class="nav_button nav_button_backcolor1">
          <img src="@/public/menuButtonRanking.svg" alt="" /><br />
          <div class="nav_text">ランキング</div>
        </div>
      </router-link>
      <!-- プレゼント画面 -->
      <router-link to="/present" class="router_text">
        <div class="nav_button nav_button_backcolor1">
          <img src="@/public/menuButtonPresent.svg" alt="" /><br />
          <div class="nav_text">プレゼント</div>
        </div>
      </router-link>
      <!-- アイテム画面 -->
      <router-link to="/item" class="router_text">
        <div class="nav_button nav_button_backcolor1">
          <img src="@/public/menuButtonItem.svg" alt="" /><br />
          <div class="nav_text">アイテム</div>
        </div>
      </router-link>
      <!-- イラスト(ドット絵)画面 -->
      <router-link to="/illust" class="router_text">
        <div class="nav_button nav_button_backcolor1 oekaki">
          <img src="@/public/menuButtonOekaki.svg" alt="" /><br />
          <div class="nav_text">おえかき</div>
        </div>
      </router-link>
      <!-- お知らせ画面 -->
      <router-link to="/information" class="router_text">
        <div class="nav_button nav_button_backcolor2">
          <img src="@/public/menuButtonNotice.svg" alt="" /><br />
          <div class="nav_text">お知らせ</div>
        </div>
      </router-link>
      <!-- アンケート画面 -->
      <router-link to="/search" class="router_text">
        <div class="nav_button nav_button_backcolor2">
          <img src="@/public/menuButtonQuestionnaire.svg" alt="" /><br />
          <div class="nav_text">アンケート</div>
        </div>
      </router-link>
      <!-- マイページ画面 -->
      <router-link to="/mypage" class="router_text">
        <div class="nav_button nav_button_backcolor2">
          <img src="@/public/menuButtonMypage.svg" alt="" /><br />
          <div class="nav_text">マイページ</div>
        </div>
      </router-link>
      <!-- 設定画面 -->
      <router-link to="/setting" class="router_text">
        <div class="nav_button nav_button_backcolor2">
          <img src="@/public/menuButtonSetting.svg" alt="" /><br />
          <div class="nav_text">せってい</div>
        </div>
      </router-link>
      <router-link
        to="/management"
        class="router_text"
        v-if="
          flg_show_button_management === '0' ||
          flg_show_button_management === '1'
        "
      >
        <div class="nav_button nav_button_backcolor2">
          <img src="@/public/menuButtonManage.svg" alt="" /><br />
          <div class="nav_text">かんり</div>
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "../service/ApiService";

// コンポーネント読み込み
export default defineComponent({
  name: "App",
  // データ
  data() {
    var flg_show_button_management_switch = "";
    var cookie = document.cookie;
    var cookiesArray = cookie.split(";");
    for (var c of cookiesArray) {
      var cArray = c.split("=");
      cArray[0] = cArray[0].trim();
      if (cArray[0] === "authority-id") {
        flg_show_button_management_switch = cArray[1];
      }
    }

    // 管理者か
    const isAdmin: boolean =
      flg_show_button_management_switch === "0" ||
      flg_show_button_management_switch === "1";
    // ハンターか
    const isHunter: boolean = flg_show_button_management_switch === "2";

    return {
      // 管理ボタン表示切替用
      flg_show_button_management: flg_show_button_management_switch,
      isAdmin: isAdmin,
      isHunter: isHunter,
      intervalId: 0,
    };
  },
  methods: {
    ////////////////////////////////
    // データ取得保存処理_最新クエスト一覧
    // 新着比較用。最新クエスト一覧を取得し保持する。
    ////////////////////////////////
    async get_newarrival_quest() {
      ////////////
      // データ取得処理
      // クエスト一覧
      ////////////
      // クエスト一覧(最新)
      var newarrival_quest_data = [
        {
          itf_new_quest_id: 0,
          itf_new_quest_status: 0,
          itf_new_quest_orders_status: 0,
          itf_new_quest_orders_orders_status: 0,
          itf_new_quest_rank: "",
          itf_new_quest_insert_date: "",
          itf_new_quest_title: "",
          itf_new_quest_capacity: "",
          itf_new_quest_category: "",
          itf_new_quest_dia: 0,
          itf_new_quest_exp: 0,
          itf_new_quest_client: "",
          itf_new_quest_requestcontents: "",
          itf_new_quest_location: "",
          itf_new_quest_start_date: "",
          itf_new_quest_limit_date: "",
          itf_new_quest_targetage: 0,
        },
      ];

      // クエスト一覧(前回最終ログイン時の情報)
      var last_quest_data = [
        {
          itf_new_quest_id: 0,
          itf_new_quest_status: 0,
          itf_new_quest_orders_status: 0,
          itf_new_quest_orders_orders_status: 0,
          itf_new_quest_rank: "",
          itf_new_quest_insert_date: "",
          itf_new_quest_title: "",
          itf_new_quest_capacity: "",
          itf_new_quest_category: "",
          itf_new_quest_dia: 0,
          itf_new_quest_exp: 0,
          itf_new_quest_client: "",
          itf_new_quest_requestcontents: "",
          itf_new_quest_location: "",
          itf_new_quest_start_date: "",
          itf_new_quest_limit_date: "",
          itf_new_quest_targetage: 0,
        },
      ];

      // データ取得_ハンターID(ローカルストレージ)
      const st_hunter_data = JSON.parse(localStorage.getItem("hunter_data")!);
      const user = {
        hunterId: st_hunter_data.ls_hunter_id,
      };

      // データ取得処理呼び出し
      await ApiService.getQuestByHunterId(user)
        .then(async (res) => {
          if (res.status === 200 && res.statusText === "OK") {
            const quests = res.data;
            for (var i = 0; i < quests.length; i++) {
              newarrival_quest_data.push({
                itf_new_quest_id: quests[i].quest_id,
                itf_new_quest_status: quests[i].status,
                itf_new_quest_orders_status: quests[i].quest_orders_status || 0,
                itf_new_quest_orders_orders_status:
                  quests[i].quest_orders_orders_status || 0,
                itf_new_quest_rank: quests[i].rank,
                itf_new_quest_insert_date: quests[i].formatted_insert_date,
                itf_new_quest_title: quests[i].title,
                itf_new_quest_capacity: quests[i].capacity_range,
                itf_new_quest_category: quests[i].sub_title,
                itf_new_quest_dia: quests[i].diamond,
                itf_new_quest_exp: quests[i].point,
                itf_new_quest_client: quests[i].owner,
                itf_new_quest_requestcontents: quests[i].contents,
                itf_new_quest_location: quests[i].location,
                itf_new_quest_start_date: quests[i].formatted_start_date,
                itf_new_quest_limit_date: quests[i].formatted_limit_date,
                itf_new_quest_targetage: quests[i].min_age,
              });
            }
            // データ取得処理呼び出し
            await ApiService.getOldQuestByHunterId(user)
              .then((res) => {
                if (res.status === 200 && res.statusText === "OK") {
                  const quests = res.data;
                  for (var i = 0; i < quests.length; i++) {
                    last_quest_data.push({
                      itf_new_quest_id: quests[i].quest_id,
                      itf_new_quest_status: quests[i].status,
                      itf_new_quest_orders_status:
                        quests[i].quest_orders_status || 0,
                      itf_new_quest_orders_orders_status:
                        quests[i].quest_orders_orders_status || 0,
                      itf_new_quest_rank: quests[i].rank,
                      itf_new_quest_insert_date:
                        quests[i].formatted_insert_date,
                      itf_new_quest_title: quests[i].title,
                      itf_new_quest_capacity: quests[i].capacity_range,
                      itf_new_quest_category: quests[i].sub_title,
                      itf_new_quest_dia: quests[i].diamond,
                      itf_new_quest_exp: quests[i].point,
                      itf_new_quest_client: quests[i].owner,
                      itf_new_quest_requestcontents: quests[i].contents,
                      itf_new_quest_location: quests[i].location,
                      itf_new_quest_start_date: quests[i].formatted_start_date,
                      itf_new_quest_limit_date: quests[i].formatted_limit_date,
                      itf_new_quest_targetage: quests[i].min_age,
                    });
                  }

                  if (
                    localStorage.getItem("lasttime_quest_data") === null ||
                    localStorage.getItem("lasttime_quest_data") === ""
                  ) {
                    // 前回データが存在しない場合(初回1回目)
                    ////////////
                    // ローカルストレージへデータ保存
                    ////////////
                    // 以前のデータが残っている場合削除(最新クエスト)
                    localStorage.removeItem("newarrival_quest_data");
                    // データ保存
                    localStorage.setItem(
                      "lasttime_quest_data",
                      JSON.stringify(newarrival_quest_data)
                    );
                    localStorage.setItem(
                      "first_quest_data",
                      JSON.stringify(last_quest_data)
                    );
                  } else {
                    // 前回データが存在する場合(2回目以降)
                    ////////////
                    // ローカルストレージへデータ保存
                    ////////////
                    // 以前のデータが残っている場合削除(最新クエスト)
                    localStorage.removeItem("newarrival_quest_data");
                    // データ保存
                    localStorage.setItem(
                      "newarrival_quest_data",
                      JSON.stringify(newarrival_quest_data)
                    );
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ////////////////////////////////
    // データ比較処理_新着クエスト
    // 新旧クエストの一覧を比較し新着表示を行う
    ////////////////////////////////
    check_newarrival_quest() {
      // 処理呼び出し_データ取得保存処理_最新クエスト一覧
      this.get_newarrival_quest();

      if (
        localStorage.getItem("newarrival_quest_data") === null ||
        localStorage.getItem("newarrival_quest_data") === ""
      ) {
        // 最新クエスト一覧がない場合
        // 処理終了
        return;
      } else {
        // データ取得_前回クエスト一覧(ローカルストレージ)
        var st_lasttime_quest = JSON.parse(
          localStorage.getItem("lasttime_quest_data")!
        );
        if (localStorage.getItem("first_quest_data") !== null) {
          // データ取得_前回クエスト一覧(ローカルストレージ)
          st_lasttime_quest = JSON.parse(
            localStorage.getItem("first_quest_data")!
          );
        }
        // データ取得_最新クエスト一覧(ローカルストレージ)
        const st_newarrival_quest = JSON.parse(
          localStorage.getItem("newarrival_quest_data")!
        );

        ////////////
        // データ比較処理
        ////////////
        // 差分データ取得
        var newarrival_quest_list = st_newarrival_quest.filter(
          (
            newarrival_quest_row = {
              itf_new_quest_id: 0,
              itf_new_quest_status: 0,
              itf_new_quest_orders_status: 0,
              itf_new_quest_orders_orders_status: 0,
              itf_new_quest_rank: "",
              itf_new_quest_insert_date: "",
              itf_new_quest_title: "",
              itf_new_quest_capacity: "",
              itf_new_quest_category: "",
              itf_new_quest_dia: 0,
              itf_new_quest_exp: 0,
              itf_new_quest_client: "",
              itf_new_quest_requestcontents: "",
              itf_new_quest_location: "",
              itf_new_quest_start_date: "",
              itf_new_quest_limit_date: "",
              itf_new_quest_targetage: 0,
            }
          ) =>
            st_lasttime_quest.filter(
              (
                lasttime_quest_row = {
                  itf_new_quest_id: 0,
                  itf_new_quest_status: 0,
                  itf_new_quest_orders_status: 0,
                  itf_new_quest_orders_orders_status: 0,
                  itf_new_quest_rank: "",
                  itf_new_quest_insert_date: "",
                  itf_new_quest_title: "",
                  itf_new_quest_capacity: "",
                  itf_new_quest_category: "",
                  itf_new_quest_dia: 0,
                  itf_new_quest_exp: 0,
                  itf_new_quest_client: "",
                  itf_new_quest_requestcontents: "",
                  itf_new_quest_location: "",
                  itf_new_quest_start_date: "",
                  itf_new_quest_limit_date: "",
                  itf_new_quest_targetage: 0,
                }
              ) =>
                newarrival_quest_row.itf_new_quest_id ===
                  lasttime_quest_row.itf_new_quest_id &&
                newarrival_quest_row.itf_new_quest_status ===
                  lasttime_quest_row.itf_new_quest_status &&
                newarrival_quest_row.itf_new_quest_orders_status ===
                  lasttime_quest_row.itf_new_quest_orders_status &&
                newarrival_quest_row.itf_new_quest_orders_orders_status ===
                  lasttime_quest_row.itf_new_quest_orders_orders_status &&
                newarrival_quest_row.itf_new_quest_rank ===
                  lasttime_quest_row.itf_new_quest_rank &&
                newarrival_quest_row.itf_new_quest_insert_date ===
                  lasttime_quest_row.itf_new_quest_insert_date &&
                newarrival_quest_row.itf_new_quest_title ===
                  lasttime_quest_row.itf_new_quest_title &&
                newarrival_quest_row.itf_new_quest_capacity ===
                  lasttime_quest_row.itf_new_quest_capacity &&
                newarrival_quest_row.itf_new_quest_category ===
                  lasttime_quest_row.itf_new_quest_category &&
                newarrival_quest_row.itf_new_quest_dia ===
                  lasttime_quest_row.itf_new_quest_dia &&
                newarrival_quest_row.itf_new_quest_exp ===
                  lasttime_quest_row.itf_new_quest_exp &&
                newarrival_quest_row.itf_new_quest_client ===
                  lasttime_quest_row.itf_new_quest_client &&
                newarrival_quest_row.itf_new_quest_requestcontents ===
                  lasttime_quest_row.itf_new_quest_requestcontents &&
                newarrival_quest_row.itf_new_quest_location ===
                  lasttime_quest_row.itf_new_quest_location &&
                newarrival_quest_row.itf_new_quest_start_date ===
                  lasttime_quest_row.itf_new_quest_start_date &&
                newarrival_quest_row.itf_new_quest_limit_date ===
                  lasttime_quest_row.itf_new_quest_limit_date &&
                newarrival_quest_row.itf_new_quest_targetage ===
                  lasttime_quest_row.itf_new_quest_targetage
            ).length === 0
        );

        ////////////
        // 新着確認&新着アイコン表示
        ////////////
        // 差分クエストがすべて不参加、かつ、クリアかくにん中以上の場合通知なし
        if (
          this.isHunter &&
          newarrival_quest_list.every(
            (element: any) =>
              element.itf_new_quest_status >= 3 &&
              element.itf_new_quest_orders_orders_status === 0
          )
        ) {
          return;
        }

        if (newarrival_quest_list.length !== 0) {
          // 差分あり
          const mune_quest = document.querySelector(".menu");
          // アイコン表示
          if (mune_quest !== null) {
            if (mune_quest.classList.contains("now_newarrival_quest")) {
              return;
            }
            mune_quest.classList.toggle("now_newarrival_quest");
          }
        }

        this.quest_list_icon_display(newarrival_quest_list);

        // クエスト画面以外の場合はローカルストレージ更新しない
        if (this.$route.path !== "/quest") {
          return;
        }

        // データ保存
        // 最新のデータを前回データ用に
        // 以前のデータが残っている場合削除(最新クエスト)
        localStorage.removeItem("lasttime_quest_data");
        localStorage.setItem(
          "lasttime_quest_data",
          JSON.stringify(st_newarrival_quest)
        );
      }
    },
    quest_list_icon_display(newarrival_quest_list: any) {
      if (this.$route.path === "/quest") {
        // クエスト画面起動時はアイコン非表示
        const mune_quest = document.querySelector(".menu");
        if (mune_quest !== null) {
          mune_quest.classList.remove("now_newarrival_quest");
        }

        ////////////
        // 新着確認&新着アイコン表示
        ////////////
        if (newarrival_quest_list.length !== 0) {
          // 差分データがある場合
          // ぼしゅう中差分確認
          if (
            (this.isAdmin &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) => newarrival_quest_list_row.itf_new_quest_status === 0
              ).length !== 0) ||
            (this.isHunter &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) =>
                  newarrival_quest_list_row.itf_new_quest_status === 0 &&
                  newarrival_quest_list_row.itf_new_quest_orders_status === 0
              ).length !== 0)
          ) {
            // ぼしゅう中差分あり
            // 対象タブ取得
            const newarrival_quest_tab_1_now = document.querySelector(
              "#tab_1 .img_newarrival_quest_icon"
            );
            // アイコン表示
            if (newarrival_quest_tab_1_now !== null) {
              newarrival_quest_tab_1_now.classList.toggle(
                "now_newarrival_quest"
              );
            }
          }
          // おうぼ中差分確認
          if (
            (this.isAdmin &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) => newarrival_quest_list_row.itf_new_quest_status === 1
              ).length !== 0) ||
            (this.isHunter &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) =>
                  (newarrival_quest_list_row.itf_new_quest_status === 0 &&
                    newarrival_quest_list_row.itf_new_quest_orders_status ===
                      4) ||
                  newarrival_quest_list_row.itf_new_quest_status === 1
              ).length !== 0)
          ) {
            // おうぼ中差分あり
            // 対象タブ取得
            const newarrival_quest_tab_2_now = document.querySelector(
              "#tab_2 .img_newarrival_quest_icon"
            );
            // アイコン表示
            if (newarrival_quest_tab_2_now !== null) {
              if (
                newarrival_quest_tab_2_now.classList.contains(
                  "now_newarrival_quest"
                )
              )
                return;

              newarrival_quest_tab_2_now.classList.toggle(
                "now_newarrival_quest"
              );
            }
          }
          // しんこう中差分確認
          if (
            (this.isAdmin &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) => newarrival_quest_list_row.itf_new_quest_status === 2
              ).length !== 0) ||
            (this.isHunter &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) =>
                  (newarrival_quest_list_row.itf_new_quest_status === 2 ||
                    newarrival_quest_list_row.itf_new_quest_status === 3) &&
                  newarrival_quest_list_row.itf_new_quest_orders_status === 1
              ).length !== 0)
          ) {
            // しんこう中差分あり
            // 対象タブ取得
            const newarrival_quest_tab_3_now = document.querySelector(
              "#tab_3 .img_newarrival_quest_icon"
            );
            // アイコン表示
            if (newarrival_quest_tab_3_now !== null) {
              newarrival_quest_tab_3_now.classList.toggle(
                "now_newarrival_quest"
              );
            }
          }
          // クリアかくにん中差分確認
          if (
            (this.isAdmin &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) => newarrival_quest_list_row.itf_new_quest_status === 3
              ).length !== 0) ||
            (this.isHunter &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) =>
                  newarrival_quest_list_row.itf_new_quest_status === 3 &&
                  newarrival_quest_list_row.itf_new_quest_orders_status === 4
              ).length !== 0)
          ) {
            // クリアかくにん中差分あり
            // 対象タブ取得
            const newarrival_quest_tab_4_now = document.querySelector(
              "#tab_4 .img_newarrival_quest_icon"
            );
            // アイコン表示
            if (newarrival_quest_tab_4_now !== null) {
              newarrival_quest_tab_4_now.classList.toggle(
                "now_newarrival_quest"
              );
            }
          }
          // かんりょう差分確認
          if (
            (this.isAdmin &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) =>
                  newarrival_quest_list_row.itf_new_quest_status === 4 ||
                  newarrival_quest_list_row.itf_new_quest_status === 5
              ).length !== 0) ||
            (this.isHunter &&
              newarrival_quest_list.filter(
                (
                  newarrival_quest_list_row = {
                    itf_new_quest_id: 0,
                    itf_new_quest_status: 0,
                    itf_new_quest_orders_status: 0,
                    itf_new_quest_orders_orders_status: 0,
                    itf_new_quest_rank: "",
                    itf_new_quest_insert_date: "",
                    itf_new_quest_title: "",
                    itf_new_quest_capacity: "",
                    itf_new_quest_category: "",
                    itf_new_quest_dia: 0,
                    itf_new_quest_exp: 0,
                    itf_new_quest_client: "",
                    itf_new_quest_requestcontents: "",
                    itf_new_quest_location: "",
                    itf_new_quest_start_date: "",
                    itf_new_quest_limit_date: "",
                    itf_new_quest_targetage: 0,
                  }
                ) =>
                  (newarrival_quest_list_row.itf_new_quest_status === 2 &&
                    newarrival_quest_list_row.itf_new_quest_orders_orders_status ===
                      0) ||
                  (newarrival_quest_list_row.itf_new_quest_status === 4 &&
                    newarrival_quest_list_row.itf_new_quest_orders_orders_status ===
                      1) ||
                  (newarrival_quest_list_row.itf_new_quest_status === 5 &&
                    newarrival_quest_list_row.itf_new_quest_orders_orders_status ===
                      1)
              ).length !== 0)
          ) {
            // かんりょう差分あり
            // 対象タブ取得
            const newarrival_quest_tab_5_now = document.querySelector(
              "#tab_5 .img_newarrival_quest_icon"
            );
            // アイコン表示
            if (newarrival_quest_tab_5_now !== null) {
              newarrival_quest_tab_5_now.classList.toggle(
                "now_newarrival_quest"
              );
            }
          }
        }
        // データ保存
        // 最新のデータを前回データ用に
        // 以前のデータが残っている場合削除(最新クエスト)
        localStorage.removeItem("lasttime_quest_data");
        localStorage.setItem(
          "lasttime_quest_data",
          JSON.stringify(newarrival_quest_list)
        );

        localStorage.removeItem("first_quest_data");
        // localStorage.setItem(
        //   "first_quest_data",
        //   JSON.stringify(newarrival_quest_list)
        // );
      }
    },
  },
  mounted() {
    // 初回はすぐに実行する
    this.check_newarrival_quest();
    // 宣言したタイマーにsetIntervalで実行する処理を代入
    this.intervalId = setInterval(() => {
      // console.log("intervalのcallback実行2");
      this.check_newarrival_quest();
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
});
</script>

<style>
@import "../css/style.scss";
@import "../css/Footer.scss";
/* 新着アイコン */
.img_newarrival_quest_icon {
  width: 20px;
  height: 20px;
  display: none; /* アイコンは基本非表示 */
  position: absolute;
  top: -10px;
}

/* 新着アイコン_新着 */
.img_newarrival_quest_icon.now_newarrival_quest {
  display: block; /* アイコンを表示 */
}

/* 新着アイコン_画像 */
.img_newarrival_quest_icon img {
  width: 100%;
  height: 100%;
}
</style>
