<template>
  <button :class="class_name" :id="id_name">
    {{ message }}
  </button>
</template>

<script lang="ts">
export default {
  props: ["class_name", "id_name", "message"],
};
</script>

<style scoped></style>
