import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal2 = _resolveComponent("Modal2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Modal2, {
      modal_title: "モーダルタイトル",
      msg_1: "メッセージ1",
      msg_2: "メッセージ2",
      msg_3: "メッセージ3",
      msg_4: "メッセージ4",
      button_no: "キャンセル",
      button_yes: "更新",
      onModalNo: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalnooya())),
      onModalYes: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalyesoya()))
    })
  ]))
}