<template>
  <!-- ギルド編集画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ギルド管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>ギルド編集</p>
      </div>
      <!-- 入力フォーム -->
      <form class="scroll_form" @submit.prevent>
        <!-- ギルド情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">ギルド情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              ギルド名
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_guild_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              ログインID
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_guild_login_id
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              パスワード
            </span>
            <br />
            <span class="f_fieldset_contents_value">＊＊＊＊＊</span>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_guild_Usage_situation"
              class="input_contents_label label_border_blue"
            >
              利用状況
            </label>
            <br />
            <span class="radio_selection_item f_input_radio_left">
              <label>
                <input
                  type="radio"
                  name="f_guild_Usage_situation"
                  class="f_input_radio"
                  value="1"
                  v-model="selected_status"
                  :checked="d_guild_data.itf_guild_Usage_situation_id === 1"
                  v-on:change="changeRadioButton()"
                  required
                />
                利用
              </label>
              <!-- <span>利用</span> -->
            </span>
            <span class="radio_selection_item">
              <label>
                <input
                  type="radio"
                  name="f_guild_Usage_situation"
                  class="f_input_radio"
                  value="2"
                  v-model="selected_status"
                  :checked="d_guild_data.itf_guild_Usage_situation_id === 2"
                  v-on:change="changeRadioButton()"
                  required
                />
                停止
              </label>
              <!-- <span>停止</span> -->
            </span>
            <span class="radio_selection_item">
              <label v-if="d_guild_data.itf_guild_Usage_situation_id === 0">
                <input
                  type="radio"
                  name="f_guild_Usage_situation"
                  class="f_input_radio"
                  value="0"
                  v-model="selected_status"
                  :checked="d_guild_data.itf_guild_Usage_situation_id === 0"
                  v-on:change="changeRadioButton()"
                  required
                />
                登録待ち
              </label>
              <!-- <span>停止</span> -->
            </span>
          </p>
          <!-- ギルドポイントは仕様が未確定のため一旦コメントアウト -->
          <!-- <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              ギルドポイント
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_guild_guild_point
            }}</span>
          </p> -->
        </fieldset>
        <!-- 担当者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">担当者情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              担当者名(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_manager_manager_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              担当者名(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_manager_manager_name_kana
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              メールアドレス
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_manager_mail
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              電話番号
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_manager_tel
            }}</span>
          </p>
        </fieldset>
        <!-- 団体情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">団体情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              施設名・社名
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_group_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              代表者名(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_group_administrator_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              代表者名(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_group_administrator_name_kana
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">
              電話番号
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_guild_data.itf_group_tel
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_glay">住所</span>
            <br />
            <span class="f_fieldset_contents_value">
              {{ "〒" + d_guild_data.itf_group_zipcode }}
              <br />
              {{
                d_guild_data.itf_group_prefectures +
                d_guild_data.itf_group_address
              }}
              <br />
              {{ d_guild_data.itf_group_building }}
            </span>
          </p>
        </fieldset>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_削除 -->
          <GenericButton
            :class_name="class_name"
            :message="message"
            v-on:click="delete_data_guild()"
          />
          <!-- ボタン_更新 -->
          <GenericButton
            :class_name="class_name2"
            :message="message2"
            v-on:click="update_data_guild()"
          />
        </div>
      </form>
    </div>
    <ModalWindow
      modal_title="確認"
      :msg_1="'ギルド名 :' + d_guild_data.itf_guild_name"
      msg_2="利用状況を以下の内容に更新しますか?"
      :msg_3="'利用状況 :' + guild_Usage_status_string"
      button_no="キャンセル"
      button_yes="更新"
      v-if="flg_show_modal_update"
      @modal-no="modalnoupdate()"
      @modal-yes="modalyesupdate()"
    />
    <ModalWindow
      modal_title="更新完了"
      :msg_1="'ギルド名 :' + d_guild_data.itf_guild_name"
      msg_2="を更新完了しました。"
      button_ok="OK"
      v-if="flg_show_modal_update_comp"
      @modal-ok="modalnoupdatecomp()"
    />
    <ModalWindow
      modal_title="確認"
      :msg_1="'ギルド名 :' + d_guild_data.itf_guild_name"
      msg_2="のデータを削除しますか?"
      button_no="キャンセル"
      button_yes="削除"
      v-if="flg_show_modal_delete"
      @modal-no="modalnodelete()"
      @modal-yes="modalyesdelete()"
    />
    <ModalWindow
      modal_title="削除完了"
      :msg_1="'ギルド名 :' + d_guild_data.itf_guild_name"
      msg_2="を削除完了しました。"
      button_ok="OK"
      v-if="flg_show_modal_delete_comp"
      @modal-ok="modalnodeletecomp()"
    />
    <ModalWindow
      modal_title="削除失敗"
      :msg_1="'ギルド名 :' + d_guild_data.itf_guild_name"
      :msg_2="delete_failed_message"
      button_ok="OK"
      v-if="flg_show_modal_delete_failure"
      @modal-ok="modalnodeletefailure()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";
import ModalWindow from "../components/Modal_2.vue";

// データ受取用_ギルド情報
interface guild_data_catch {
  // ギルドID(画面非表示)
  itf_guild_id: number;
  // ギルド情報_ギルド名
  itf_guild_name: string;
  // ギルド情報_ログインID
  itf_guild_login_id: string;
  // ギルド情報_利用状況ID
  itf_guild_Usage_situation_id: number;
  // ギルド情報_利用状況
  itf_guild_Usage_situation: string;
  // ギルド情報_利用状況(データ編集用)
  // itf_guild_Usage_situation_set: string;
  // ギルド情報_ギルドポイント
  itf_guild_guild_point: number;

  // 担当者情報_担当者名(漢字)_名字+名前(表示用)
  itf_manager_manager_name: string;
  // 担当者情報_担当者名(よみがな)_名字+名前(表示用)
  itf_manager_manager_name_kana: string;
  // 担当者情報_メールアドレス
  itf_manager_mail: string;
  // 担当者情報_電話番号
  itf_manager_tel: string;

  // 会社情報_施設名・社名
  itf_group_name: string;
  // 会社情報_代表者名(漢字)_名字+名前(表示用)
  itf_group_administrator_name: string;
  // 会社情報_代表者名(よみがな)_名字+名前
  itf_group_administrator_name_kana: string;
  // 会社情報_電話番号
  itf_group_tel: string;
  // 会社情報_住所(郵便番号)
  itf_group_zipcode: string;
  // 会社情報_住所(都道府県)
  itf_group_prefectures: string;
  // 会社情報_住所(住所)
  itf_group_address: string;
  // 会社情報_住所(建物名・部屋番)
  itf_group_building: string;

  // ギルド管理者のハンターID(削除用)
  itf_hunter_id: number;
}
interface GuildState {
  guild_data: guild_data_catch;
}
interface requestBody {
  status: number;
}

export default defineComponent({
  // データ
  data() {
    //////////////////////
    // ギルドデータ取得処理
    //////////////////////
    // 初期化_データ受取用
    var c_guild_data = reactive<GuildState>({
      guild_data: {
        itf_guild_id: 0,
        // itf_guild_Usage_situation_set: "",
        itf_guild_name: "",
        itf_group_name: "",
        itf_group_administrator_name: "",
        itf_manager_manager_name: "",
        itf_guild_Usage_situation_id: 0,
        itf_guild_Usage_situation: "",
        itf_guild_login_id: "",
        itf_guild_guild_point: 0,
        itf_manager_manager_name_kana: "",
        itf_manager_mail: "",
        itf_manager_tel: "",
        itf_group_administrator_name_kana: "",
        itf_group_tel: "",
        itf_group_zipcode: "",
        itf_group_prefectures: "",
        itf_group_address: "",
        itf_group_building: "",
        itf_hunter_id: 0,
      },
    });
    const guild_data = computed(() => c_guild_data.guild_data);

    // ギルドID取得
    const guildId = Number(this.$route.query.guildId);

    // ギルドデータ取得処理
    ApiService.getGuildModifyData(guildId)
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const guilds = res.data[0];
          // ギルドID(画面非表示)
          c_guild_data.guild_data.itf_guild_id = guilds.guild_id;
          // ギルド情報_ギルド名
          c_guild_data.guild_data.itf_guild_name = guilds.guild_name;
          // ギルド情報_ログインID
          c_guild_data.guild_data.itf_guild_login_id =
            guilds.account_account_id;
          // ギルド情報_利用状況ID
          c_guild_data.guild_data.itf_guild_Usage_situation_id =
            guilds.guild_status;
          // ギルド情報_利用状況
          c_guild_data.guild_data.itf_guild_Usage_situation =
            guilds.guild_statusStr;
          // ギルド情報_ギルドポイント★仮データ設定、後で修正
          c_guild_data.guild_data.itf_guild_guild_point = guilds.guild_point;
          // 担当者情報_担当者名(漢字)_名字+名前(表示用)
          c_guild_data.guild_data.itf_manager_manager_name =
            guilds.account_last_name + guilds.account_first_name;
          // 担当者情報_担当者名(よみがな)_名字+名前
          c_guild_data.guild_data.itf_manager_manager_name_kana =
            guilds.account_last_name_kana + guilds.account_first_name_kana;
          // 担当者情報_メールアドレス
          c_guild_data.guild_data.itf_manager_mail = guilds.mail;
          // 担当者情報_電話番号
          c_guild_data.guild_data.itf_manager_tel = guilds.account_tel;
          // 会社情報_施設名・社名
          c_guild_data.guild_data.itf_group_name = guilds.operating;
          // 会社情報_代表者名(漢字)_名字+名前(表示用)
          c_guild_data.guild_data.itf_group_administrator_name =
            guilds.guild_responsible_person_last_name +
            guilds.guild_responsible_person_first_name;
          // 会社情報_代表者名(よみがな)_名字+名前
          c_guild_data.guild_data.itf_group_administrator_name_kana =
            guilds.guild_responsible_person_last_name_kana +
            guilds.guild_responsible_person_first_name_kana;
          // 会社情報_電話番号
          c_guild_data.guild_data.itf_group_tel = guilds.guild_tel;
          // 会社情報_住所(郵便番号)
          c_guild_data.guild_data.itf_group_zipcode = guilds.guild_zipcode;
          // 会社情報_住所(都道府県)
          c_guild_data.guild_data.itf_group_prefectures =
            guilds.guild_prefecture;
          // 会社情報_住所(住所)
          c_guild_data.guild_data.itf_group_address = guilds.guild_address;
          // 会社情報_住所(建物名・部屋番)
          c_guild_data.guild_data.itf_group_building = guilds.guild_building;
          // ギルド管理者のハンターID(削除用)
          c_guild_data.guild_data.itf_hunter_id = guilds.hunter_id;

          //////////////////////////////////
          // ギルド利用状況初期値設定
          //////////////////////////////////
          this.radiobutton_farst_value = guilds.guild_status;
          this.radiobutton_farst_str_value = guilds.guild_statusStr;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    const guild_statusStr_arr = ["登録待ち", "利用", "停止"];

    return {
      class_name: "operation_button button_red",
      message: "削除",
      class_name2: "operation_button button_blue",
      message2: "更新",
      class_name3: "operation_button button_gray",
      d_guild_data: guild_data,
      d_group_prefectures_name: guild_data,
      selected_status: "",
      guild_statusStr_arr: guild_statusStr_arr,
      guild_Usage_status_string: "利用",
      // フラグ_更新確認モーダル_表示非表示
      flg_show_modal_update: false,
      // フラグ_削除確認モーダル_表示非表示
      flg_show_modal_delete: false,
      // フラグ_更新完了モーダル_表示非表示
      flg_show_modal_update_comp: false,
      // フラグ_削除完了モーダル_表示非表示
      flg_show_modal_delete_comp: false,
      // フラグ_ラジオボタン選択_利用状況
      flg_radiobutton_change: false,
      // ラジオボタン初期値格納用_id
      radiobutton_farst_value: "",
      // ラジオボタン初期値格納用_文字列
      radiobutton_farst_str_value: "",
      // フラグ_削除失敗モーダル_ハンター所属している_表示非表示
      flg_show_modal_delete_failure: false,
      // 削除失敗メッセージ
      delete_failed_message: "",
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // メソッド
  methods: {
    // 削除ボタンクリック処理
    delete_data_guild() {
      // モーダル表示_削除確認モーダル
      this.flg_show_modal_delete = true;
    },
    // 更新ボタンクリック処理
    update_data_guild() {
      // 更新確認モーダル表示用_利用状況文字列
      // ラジオボタン選択確認処理
      if (!this.flg_radiobutton_change) {
        // ラジオボタンを選択していなかった場合
        // 初期値の文字列を設定
        this.guild_Usage_status_string = this.radiobutton_farst_str_value;
      } else {
        // ラジオボタンを選択した場合
        if (this.selected_status === "1") {
          this.guild_Usage_status_string = "利用";
        } else if (this.selected_status === "2") {
          this.guild_Usage_status_string = "停止";
        }
      }

      // モーダル表示_更新確認モーダル
      this.flg_show_modal_update = true;
    },
    // ボタンクリック処理_モーダル_更新確認_キャンセル
    modalnoupdate() {
      // モーダル非表示_更新確認モーダル
      this.flg_show_modal_update = false;
    },
    // ボタンクリック処理_モーダル_更新確認_更新
    modalyesupdate() {
      // ラジオボタン選択確認処理
      if (!this.flg_radiobutton_change) {
        // ラジオボタンを選択していなかった場合
        // 初期値を設定
        this.selected_status = this.radiobutton_farst_value;
      }

      const body: requestBody = { status: Number(this.selected_status) };

      // データ更新処理
      ApiService.postGuildStatus(this.d_guild_data.itf_guild_id, body)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // モーダル非表示_更新確認モーダル
            this.flg_show_modal_update = false;
            // モーダル表示_更新完了モーダル
            this.flg_show_modal_update_comp = true;
          } else {
            // todo 編集失敗モーダルを表示 現在はalertで仮置き start ---------
            alert("更新失敗");
            // todo 編集失敗モーダルを表示 現在はalertで仮置き end ---------
          }
        })
        .catch((err) => {
          // エラー表示
          console.log(err);
          // todo 編集失敗モーダルを表示?? 現在はalertで仮置き start ---------
          alert("更新失敗");
          // todo 編集失敗モーダルを表示?? 現在はalertで仮置き end ---------
        });
    },
    // ボタンクリック処理_モーダル_更新完了
    modalnoupdatecomp() {
      // モーダル非表示_更新完了モーダル
      ApiService.postMail({
        mail: this.d_guild_data.itf_manager_mail,
        status: "12",
        login_id: this.d_guild_data.itf_guild_login_id,
        guild_name: this.d_guild_data.itf_guild_name,
        hunter_name: "",
      });

      this.flg_show_modal_update_comp = false;
      this.$router.push({ name: "manageguildlist" });
    },
    // ボタンクリック処理_モーダル_削除確認_キャンセル
    modalnodelete() {
      // モーダル非表示_削除確認モーダル
      this.flg_show_modal_delete = false;
    },
    // ボタンクリック処理_モーダル_削除確認_削除
    modalyesdelete() {
      const hunterData = {
        hunterId: this.d_guild_data.itf_hunter_id,
      };
      // データ削除処理
      ApiService.deleteGuild(this.d_guild_data.itf_guild_id, hunterData)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // モーダル非表示_削除確認モーダル
            this.flg_show_modal_delete = false;
            // モーダル表示_削除完了モーダル
            this.flg_show_modal_delete_comp = true;
          }
        })
        .catch((err) => {
          // エラー表示
          // console.dir(err);
          // 削除失敗メッセージ
          this.delete_failed_message = err.response.data;
          // モーダル非表示_削除確認モーダル
          this.flg_show_modal_delete = false;
          // モーダル表示_削除失敗モーダル_所属ハンターあり
          this.flg_show_modal_delete_failure = true;
        });
      // モーダル非表示_削除確認モーダル
      this.flg_show_modal_delete = false;
    },
    // ボタンクリック処理_モーダル_削除完了
    modalnodeletecomp() {
      // モーダル非表示_削除完了モーダル
      this.flg_show_modal_delete_comp = false;
      this.$router.push({ name: "manageguildlist" });
    },
    // ラジオボタン選択時処理_利用状況
    changeRadioButton() {
      // フラグON
      this.flg_radiobutton_change = true;
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "manageguildlist",
      });
    },
    // ボタンクリック処理_モーダル_削除失敗_所属ハンターあり
    modalnodeletefailure() {
      // 削除失敗メッセージリセット
      this.delete_failed_message = "";
      // モーダル非表示_削除失敗モーダル
      this.flg_show_modal_delete_failure = false;
    },
  },
});
</script>

<style></style>
