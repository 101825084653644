<template>
  <!-- 依頼者新規登録確認画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー_小 -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="transition_return_icon()"
      >
        <template v-slot:slot_small_header_title>依頼者管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- タイトル -->
      <div class="page_title">
        <p>登録内容確認</p>
      </div>
      <!-- フォーム(表示) -->
      <form class="scroll_form" @submit.prevent>
        <!-- 依頼者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">依頼者情報</legend>
          <!-- 依頼者情報_お名前(漢字) -->
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              お名前(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_client_data_st.ls_client_last_name +
              d_regist_check_client_data_st.ls_client_first_name
            }}</span>
          </p>
          <!-- 依頼者情報_お名前(よみがな) -->
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              お名前(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_client_data_st.ls_client_last_name_kana +
              d_regist_check_client_data_st.ls_client_first_name_kana
            }}</span>
          </p>
          <!-- 依頼者情報_誕生日 -->
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">誕生日</span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_client_data_st.ls_client_birthday"
            >
              {{ d_regist_check_client_data_st.ls_client_birthday }}
            </span>
            <span class="f_fieldset_contents_value" v-else>未登録</span>
          </p>
          <!-- 依頼者情報_性別 -->
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">性別</span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_client_data_st.ls_client_gender
            }}</span>
          </p>
          <!-- 依頼者情報_電話番号 -->
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">電話番号</span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_client_data_st.ls_client_tel
            }}</span>
          </p>
          <!-- 依頼者情報_住所 -->
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">住所</span>
            <br />
            <span class="f_fieldset_contents_value">
              {{ "〒 " + d_regist_check_client_data_st.ls_client_zipcode }}
              <br />
              {{ d_regist_check_client_data_st.ls_client_prefecture_name }}
              <br />
              {{ d_regist_check_client_data_st.ls_client_address }}
              <br />
              {{ d_regist_check_client_data_st.ls_client_building }}
            </span>
          </p>
          <!-- 依頼者情報_登録ギルド -->
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue"
              >登録ギルド</span
            >
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_client_data_st.ls_guild_name
            }}</span>
          </p>
        </fieldset>
        <!-- 確認メッセージ -->
        <div class="page_regist_message">
          <p>上記内容で登録しますか?</p>
        </div>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            tabindex="1"
            v-on:click="transition_return()"
          />
          <!-- ボタン_登録 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            tabindex="2"
            v-on:click="regist_data_client()"
          />
        </div>
      </form>
    </div>
    <!-- 登録完了モーダル -->
    <ModalWindow
      modal_title="完了"
      :msg_1="
        '依頼者名 :' +
        d_regist_check_client_data_st.ls_client_last_name +
        d_regist_check_client_data_st.ls_client_first_name
      "
      msg_2="を登録しました"
      button_ok="OK"
      v-if="flg_show_modal_comp"
      @modal-ok="modal_ok()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ModalWindow from "../components/Modal_2.vue";
import ApiService from "../service/ApiService";

export default defineComponent({
  // データ
  data() {
    ///////////////////////////////
    // データ取得
    // 登録情報インプットデータ
    ////////////////////////////////
    // データ取得(ローカルストレージ)_登録情報インプットデータ
    const st_regist_client_data = JSON.parse(
      localStorage.getItem("regist_client_data")!
    );

    // データ設定
    return {
      // データ_登録情報インプットデータ
      d_regist_check_client_data_st: st_regist_client_data,
      // ボタン設定_戻る
      class_name:
        "operation_button button_white manage_registmodifydelete_operationbutton",
      message: "戻る",
      // ボタン設定_登録
      class_name2:
        "operation_button button_blue manage_registmodifydelete_operationbutton",
      message2: "登録",
      // フラグ_表示非表示_登録完了モーダル
      flg_show_modal_comp: false,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // メソッド
  methods: {
    /////////////////////////////
    // ボタンクリック処理_戻るアイコン(ヘッダー)
    /////////////////////////////
    transition_return_icon() {
      // 画面遷移_前画面
      this.$router.push({
        name: "manageclientregister",
      });
    },
    /////////////////////////////
    // ボタンクリック処理_戻る
    /////////////////////////////
    transition_return() {
      // 画面遷移_前画面
      this.$router.push({
        name: "manageclientregister",
      });
    },
    /////////////////////////////
    // ボタンクリック処理_登録
    /////////////////////////////
    regist_data_client() {
      // データ登録処理呼び出し
      ApiService.postClientRegister(this.d_regist_check_client_data_st)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // 表示_登録完了モーダル
            this.flg_show_modal_comp = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /////////////////////////////
    // ボタンクリック処理_完了モーダル_OK
    /////////////////////////////
    modal_ok() {
      // 非表示_登録完了モーダル
      this.flg_show_modal_comp = false;
      // 画面遷移_依頼者管理画面(依頼者一覧)
      this.$router.push({
        name: "manageclientlist",
      });
    },
  },
});
</script>
