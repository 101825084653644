<template>
  <!-- 1クエスト -->
  <div class="quest_board_oneline background_border_brueblack">
    <!-- クエスト_概要 -->
    <div
      class="quest_board_oneline_grid"
      v-bind:id="'no_' + questNo"
      @click="onQuestOnelineClick($event)"
    >
      <!-- クエスト_ランク -->
      <div class="quest_rank_grid">
        <div v-bind:class="'quest_rank ' + plusClassRank">
          <div class="quest_rank_text">
            <slot name="quest_rank_slot"></slot>
          </div>
        </div>
      </div>
      <!-- クエスト_登録日 -->
      <p class="quest_registration_date_grid">
        <slot name="quest_registration_date_slot"></slot>
      </p>
      <!-- クエスト_タイトル -->
      <p class="quest_quest_contents_grid">
        <slot name="quest_contents_slot"></slot>
      </p>
      <div class="quest_point_grid">
        <!-- クエスト_ダイヤ -->
        <span class="quest_dia_number">
          <div id="quest_board_dia_inner_img"></div>
          <div class="quest_board_dia_exp_number">
            <slot name="quest_dia_slot"></slot>
          </div>
        </span>
        <!-- クエスト_ポイント -->
        <span class="quest_point_numer">
          <span class="quest_exp_title">EXP</span>
          <div class="quest_board_dia_exp_number">
            <slot name="quest_point_slot"></slot>
          </div>
        </span>
      </div>
      <!-- クエスト_人数 -->
      <div class="quest_number_of_grid">
        <div class="quest_number_of">
          <slot name="quest_number_of_slot"></slot>
        </div>
      </div>
      <!-- クエスト_条件 -->
      <div class="quest_conditions_grid">
        <div class="quest_conditions">
          <slot name="quest_conditions_slot"></slot>
        </div>
      </div>
    </div>

    <!-- クエスト_詳細 -->
    <div v-bind:id="'no_' + questNo">
      <div class="quest_board_detail is_close">
        <div class="quest_board_detail_title">
          <p>
            【<ruby>依頼者<rt>いらいしゃ</rt></ruby>
            】
          </p>
        </div>
        <p class="quest_board_detail_contents">
          <slot name="quest_board_detail_client_slot"></slot>
        </p>
        <div class="quest_board_detail_title">
          <p>
            【<ruby>依頼内容<rt>いらいないよう</rt></ruby>
            】
          </p>
        </div>
        <p class="quest_board_detail_contents">
          <slot name="quest_board_detail_request_slot"></slot>
        </p>
        <div class="quest_board_detail_title">
          <p>
            【<ruby>作業場所<rt>さぎょうばしょ</rt></ruby>
            】
          </p>
        </div>
        <p class="quest_board_detail_contents">
          <slot name="quest_board_detail_place_slot"></slot>
        </p>
        <div class="quest_board_detail_title">
          <p>
            【<ruby>作業日時<rt>さぎょうにちじ</rt></ruby>
            】
          </p>
        </div>
        <p class="quest_board_detail_contents">
          <slot name="quest_board_detail_dateandtime_start_slot"></slot>
          ～
          <slot name="quest_board_detail_dateandtime_limit_slot"></slot>
        </p>
        <div class="quest_board_detail_title">
          <p>
            【<ruby>対象年齢<rt>たいしょうねんれい</rt></ruby>
            】
          </p>
        </div>
        <p class="quest_board_detail_contents">
          <span v-if="p_orders_subject_age === 0">なし</span>
          <span v-if="p_orders_subject_age > 0">
            <slot name="quest_board_detail_subject_age_slot"></slot>
            さい<ruby>以上<rt>いじょう</rt></ruby>
          </span>
        </p>
        <!-- 引き受けるボタン -->
        <GenericButton
          :class_name="class_name"
          :message="message"
          v-if="
            tabKinds === '1' &&
            questOrdersStatus === 0 &&
            p_acount_authority === '2'
          "
          v-on:click="openModal(tabKinds)"
        />
        <ModalWindow
          modal_title="確認"
          msg_1="クエストにおうぼしますか?"
          button_no="やめる"
          button_yes="おうぼする"
          v-if="showContent"
          @modal-no="modal_cancel(tabKinds)"
          @modal-yes="updateQuestStatus(tabKinds, 1)"
        />
        <!-- おうぼ済みボタン -->
        <GenericButton
          :class_name="class_name7"
          :message="message2"
          v-if="
            tabKinds === '1' &&
            questOrdersStatus === 1 &&
            p_acount_authority === '2'
          "
        />
        <!-- かくにん中ボタン -->
        <GenericButton
          :class_name="class_name2"
          :message="message2"
          v-if="tabKinds === '2' && p_acount_authority === '2'"
        />
        <!-- クエストかんりょうボタン -->
        <GenericButton
          :class_name="class_name3"
          :message="message3"
          v-if="tabKinds === '3' && p_acount_authority === '2'"
          v-on:click="openModal(tabKinds)"
        />
        <ModalWindow
          modal_title="確認"
          msg_1="クエストはクリアしましたか？"
          button_no="いいえ"
          button_yes="クリアした"
          v-if="showContent2"
          @modal-no="modal_cancel(tabKinds)"
          @modal-yes="updateQuestStatus(tabKinds, 3)"
        />
        <!-- しんさ中ボタン -->
        <GenericButton
          :class_name="class_name7"
          :message="message7"
          v-if="tabKinds === '4' && p_acount_authority === '2'"
        />
        <!-- クリア済みボタン -->
        <!-- <GenericButton
          :class_name="class_name4"
          :message="message4"
          v-if="p_quest_status === 4"
        /> -->
        <!-- クリア失敗ボタン -->
        <!-- <GenericButton
          :class_name="class_name4"
          :message="message9"
          v-if="p_quest_status === 5"
        /> -->
        <!-- クリア済みボタン -->
        <GenericButton
          :class_name="class_name4"
          :message="message4"
          v-if="p_quest_status === 4 && (isJoinedHunter || isAdmin)"
        />
        <!-- クリア失敗ボタン -->
        <GenericButton
          :class_name="class_name4"
          :message="message9"
          v-if="p_quest_status === 5 && (isJoinedHunter || isAdmin)"
        />
        <!-- クエスト不参加ボタン -->
        <GenericButton
          :class_name="class_name4"
          :message="message10"
          v-if="p_quest_status >= 2 && isUnJoinedHunter"
        />
        <!-- おうぼ承認ボタン -->
        <GenericButton
          :class_name="class_name5"
          :message="message5"
          v-if="tabKinds === '6'"
          v-on:click="openModal(tabKinds)"
        />
        <!-- おうぼ承認モーダルウィンドウ -->
        <ModalWindow
          msg="おうぼを承認しますか？"
          msgOk="承認する"
          msgCancel="やめる"
          v-if="showAcceptQuestModal"
          @modal-ok="updateQuestStatus(tabKinds, 2)"
          @modal-cancel="modal_cancel(tabKinds)"
        />
        <!-- クリア承認ボタン -->
        <GenericButton
          :class_name="class_name6"
          :message="message6"
          v-if="tabKinds === '7'"
          v-on:click="openModal(tabKinds)"
        />
        <!-- クリア承認モーダルウィンドウ -->
        <ModalWindow
          msg="クリアを承認しますか？"
          msgOk="承認する"
          msgCancel="やめる"
          v-if="showClearQuestModal"
          @modal-ok="updateQuestStatus(tabKinds, 4)"
          @modal-cancel="modal_cancel(tabKinds)"
        />
        <!-- クエスト受付失敗 -->
        <ModalWindow
          msg="しっぱいしました。"
          msgOk="はい"
          v-if="showContent3"
          @modal-ok="modal_cancel('4')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GenericButton from "../components/GenericButton.vue";
import ModalWindow from "../components/Modal_2.vue";
import ApiService from "../service/ApiService";

export default defineComponent({
  name: "App",
  // セットアップ
  setup(props) {
    // ランク装飾設定
    const plusClassRank = "quest_rank_" + props.questRank;
    // 管理者か
    const isAdmin: boolean = Number(props.p_acount_authority) < 2;
    // クエストに参加したハンターか
    const isJoinedHunter: boolean =
      Number(props.p_acount_authority) == 2 &&
      props.p_quest_orders_orders_status == 1;
    // クエストに参加できなかったハンターか
    const isUnJoinedHunter: boolean =
      Number(props.p_acount_authority) == 2 &&
      props.p_quest_orders_orders_status == 0;

    return { plusClassRank, isAdmin, isJoinedHunter, isUnJoinedHunter };
  },
  // データ
  data() {
    return {
      class_name: "quest_button_solid_black",
      message: "おうぼする",
      class_name2: "quest_button_solid_white button_white",
      message2: "かくにん中　",
      class_name3: "quest_button_solid_black",
      message3: "クエストかんりょう",
      class_name4: "quest_button_solid_white button_white",
      message4: "クリア済み",
      message9: "クリア失敗",
      message10: "クエスト不参加",
      class_name5: "quest_button_solid_pink",
      message5: "おうぼ承認する",
      class_name6: "quest_button_solid_pink",
      message6: "クリア承認する",
      class_name7: "quest_button_solid_white",
      message7: "しんさ中",
      class_name8: "quest_button_solid_white button_white",
      message8: "おうぼ済み",
      showContent: false,
      showContent2: false,
      showContent3: false,
      showAcceptQuestModal: false, // おうぼ承認時モーダル用フラグ
      showClearQuestModal: false, // クリア承認時モーダル用フラグ
    };
  },
  // プロパティ
  props: {
    questNo: {
      type: Number,
      required: true,
    },
    questRank: {
      type: String,
      required: true,
    },
    tabKinds: {
      type: String,
      default: "1",
    },
    questOrdersStatus: {
      type: Number,
      required: true,
    },
    // 対象年齢
    p_orders_subject_age: {
      type: Number,
      default: 0,
    },
    // クエストのステータス
    p_quest_status: {
      type: Number,
      default: 0,
    },
    // クエスト受注のステータス(0 = 不参加者、1 = 参加者)
    p_quest_orders_orders_status: {
      type: Number,
      default: 0,
    },
    // アカウント権限
    p_acount_authority: {
      type: String,
      default: "",
    },
  },
  // メソッド
  methods: {
    // アコーディオンを閉じる処理_クラス追加削除
    plusClassClose(el: any) {
      // 対象クラスにactiveを追加または削除
      el.classList.toggle("is_close");
    },
    // アコーディオン開閉処理
    onQuestOnelineClick(e: any) {
      let id = e.currentTarget.getAttribute("id");
      // クラス名追加対象要素のクラス名取得
      const plusClass_1 = document.querySelector(
        "#" + id + " .quest_board_detail"
      );
      this.plusClassClose(plusClass_1);
    },
    // タブ表示設定セット処理
    tabSwitchingSet(pushButton: string) {
      // 現在のタブ確認
      if (pushButton === "1") {
        // ぼしゅう中の場合
        // モーダルウィンドウを消す
        this.showContent = false;
        // メイン画面の場合はクエスト画面へ遷移
        this.$emit("tab-switch", "2");
        this.$emit("quest-recruitment");
      } else if (pushButton === "3") {
        // しんこう中の場合
        // モーダルウィンドウを消す
        this.showContent2 = false;
      } else if (pushButton === "6") {
        // おうぼ承認時
        this.showAcceptQuestModal = false;
        this.$emit("tab-switch", "6");
      }
    },
    // モーダルウィンドウ表示処理
    openModal(pushButtonModal: string) {
      if (pushButtonModal === "1") {
        // ぼしゅう中の場合
        this.showContent = true;
      } else if (pushButtonModal === "3") {
        // しんこう中の場合
        this.showContent2 = true;
      } else if (pushButtonModal === "6") {
        // ぼしゅう承認時
        this.showAcceptQuestModal = true;
      } else if (pushButtonModal === "7") {
        // クリア承認時
        this.showClearQuestModal = true;
      }
    },
    // モーダルウィンドウキャンセル処理
    modal_cancel(pushButtonModalCansel: string) {
      if (pushButtonModalCansel === "1") {
        // ぼしゅう中の場合
        // モーダルウィンドウを非表示
        this.showContent = false;
      } else if (pushButtonModalCansel === "3") {
        // しんこう中の場合
        // モーダルウィンドウを非表示
        this.showContent2 = false;
      } else if (pushButtonModalCansel === "4") {
        // モーダルウィンドウを非表示
        this.showContent3 = false;
      } else if (pushButtonModalCansel === "6") {
        // ぼしゅう承認モーダルを閉じる
        this.showAcceptQuestModal = false;
      } else if (pushButtonModalCansel === "7") {
        // ぼしゅう承認モーダルを閉じる
        this.showClearQuestModal = false;
      }
    },

    // クエストステータスの更新、タブの切り替え
    updateQuestStatus(pushButton: string, requestStatus: number): void {
      // requestStatus: 0->1=新規応募時、1->2=おうぼ承認時 2->3=クリア申請時、3->4=クリア了解時
      const st_hunter_data = JSON.parse(localStorage.getItem("hunter_data")!);
      const user = {
        hunterId: st_hunter_data.ls_hunter_id,
        requestStatus: requestStatus,
        questOrdersStatus: 4,
      };

      if (requestStatus === 1) {
        // 1=新規応募時(クエスト、クエスト受注更新)
        ApiService.postOrder(this.questNo, user)
          .then((res) => {
            if (res.status === 200 && res.statusText === "OK") {
              if (res.data.quest_orders_status === 4) {
                this.tabSwitchingSet(pushButton);
                return;
              }
              if (pushButton == "7" && res.data.status == "4") {
                this.tabSwitchingSet(pushButton);
                this.$emit("tab-switch", "2");
                return;
              }
              this.showContent = false;
              this.$emit("tab-switch", "1");
              this.$emit("quest-recruitment");
            }
          })
          .catch((err) => {
            this.showContent = false;
            this.showContent2 = false;
            this.$emit("tab-switch", "1");
            this.showContent3 = true;
            console.log(err.response.data.message);
          });
      } else if (requestStatus === 2 || requestStatus === 3) {
        // 2=おうぼ承認時、3=クリア申告時
        ApiService.putQuest(this.questNo, user)
          .then((res) => {
            if (res.status === 200 && res.statusText === "OK") {
              this.tabSwitchingSet(pushButton);
              if (pushButton == "6") {
                this.$emit("tab-switch", "1");
              } else if (pushButton == "7") {
                this.$emit("tab-switch", "2");
              } else if (pushButton == "3") {
                this.$emit("tab-switch", "4");
              }
            }
          })
          .catch((err) => {
            this.showContent = false;
            this.showContent2 = false;
            this.$emit("tab-switch", "1");
            this.showContent3 = true;
            console.log(err.response.data.message);
          });
      }
    },
  },
  // コンポーネント
  components: {
    GenericButton,
    ModalWindow,
  },
});
</script>

<style>
@import "../css/style.scss";
@import "../css/QuestBoardOneline.scss";
</style>
