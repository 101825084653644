<template>
  <!-- 確認・テスト用画面 -->
  <div id="all">
    <Modal2
      modal_title="モーダルタイトル"
      msg_1="メッセージ1"
      msg_2="メッセージ2"
      msg_3="メッセージ3"
      msg_4="メッセージ4"
      button_no="キャンセル"
      button_yes="更新"
      @modal-no="modalnooya()"
      @modal-yes="modalyesoya()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import Modal2 from "../components/Modal_2.vue";

export default defineComponent({
  // コンポーネント
  components: {
    Modal2,
  },
  // メソッド
  methods: {
    // ボタンクリック処理_キャンセル系
    modalnooya() {
      // モーダル呼び出し先の時処理呼び出し
      alert("キャンセル");
    },
    // ボタンクリック処理_登録・更新系
    modalyesoya() {
      // モーダル呼び出し先の時処理呼び出し
      alert("更新");
    },
  },
});
</script>

<style>
#all {
  position: relative;
  width: 90%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* テスト1_テキストボックス */
#input_text_1 {
  margin: 0 auto;
}
/* テスト3_テーブル_セル */
#header_small {
  width: 100%;
  height: 15%;
}
</style>
