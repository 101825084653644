<template>
  <!-- モーダルウィンドウ -->
  <teleport to="body">
    <div class="modal_all">
      <div id="modal_window">
        <div id="modal_title" v-if="modal_title">{{ modal_title }}</div>
        <div id="message_group">
          <!-- 更新ギルド名等 -->
          <p v-if="msg_1">{{ msg_1 }}</p>
          <!-- メッセージ -->
          <p v-if="msg_2">{{ msg_2 }}</p>
          <!-- 更新項目等 -->
          <p v-if="msg_3">{{ msg_3 }}</p>
          <!-- 細かいメッセージ -->
          <p id="modal_message_4" v-if="msg_4">{{ msg_4 }}</p>
        </div>
        <div id="button_group">
          <button
            class="button_modai"
            id="button_modai_no"
            v-if="button_no"
            v-on:click="modalno"
          >
            {{ button_no }}
          </button>
          <button
            class="button_modai"
            id="button_modai_yes"
            v-if="button_yes"
            v-on:click="modalyes"
          >
            {{ button_yes }}
          </button>
          <button
            class="button_modai"
            id="button_modai_del"
            v-if="button_del"
            v-on:click="modaldel"
          >
            {{ button_del }}
          </button>
          <button
            class="button_modai_2"
            id="button_modai_ok"
            v-if="button_ok"
            v-on:click="modalok"
          >
            {{ button_ok }}
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";

export default defineComponent({
  // プロップス
  props: {
    modal_title: String,
    msg_1: String,
    msg_2: String,
    msg_3: String,
    msg_4: String,
    button_no: String,
    button_yes: String,
    button_del: String,
    button_ok: String,
  },
  emits: ["modal-no", "modal-yes", "modal-del", "modal-ok"],
  // メソッド
  methods: {
    // ボタンクリック処理_キャンセル系
    modalno() {
      // モーダル呼び出し先の時処理呼び出し
      this.$emit("modal-no");
    },
    // ボタンクリック処理_登録・更新系
    modalyes() {
      // モーダル呼び出し先の時処理呼び出し
      this.$emit("modal-yes");
    },
    // ボタンクリック処理_削除系
    modaldel() {
      // モーダル呼び出し先の時処理呼び出し
      this.$emit("modal-del");
    },
    // ボタンクリック処理_OK系
    modalok() {
      // モーダル呼び出し先の時処理呼び出し
      this.$emit("modal-ok");
    },
  },
});
</script>

<style scoped>
/* モーダル_全体(背景) */
.modal_all {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* モーダル_ウィンドウ */
#modal_window {
  width: 80%;
  height: 40%;
  max-width: 370px;
  min-width: 250px;
  min-height: 300px;
  background-color: #ffffff;
  border: 2px ridge #002c6f;
  box-sizing: border-box;
}

/* モーダル_ウィンドウ_タイトル */
#modal_title {
  width: 100%;
  height: 20%;
  background: linear-gradient(#002c6f, #00193d);
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* モーダル_ウィンドウ_メッセージ全体 */
#message_group {
  width: 90%;
  height: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* モーダル_ウィンドウ_メッセージ_1文 */
#message_group p {
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.8rem;
}

/* モーダル_ウィンドウ_メッセージ_4_細かな文字 */
#message_group p#modal_message_4 {
  font-size: 16px;
}

/* モーダル_ウィンドウ_ボタン全体 */
#button_group {
  width: 90%;
  height: 20%;
  position: relative;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* モーダル_ウィンドウ_ボタン_2個の場合 */
.button_modai {
  width: 40%;
  height: 38px;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 800;
}

/* モーダル_ウィンドウ_ボタン_1個の場合 */
.button_modai_2 {
  width: 80%;
  height: 38px;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 800;
}

/* モーダル_ウィンドウ_ボタン_キャンセル系 */
#button_modai_no {
  background-color: #ffffff;
  color: #002c6f;
  border: 2px solid #002c6f;
  box-sizing: border-box;
}
/* モーダル_ウィンドウ_ボタン_登録・更新系 */
#button_modai_yes {
  background-color: #002c6f;
  color: #ffffff;
  border: 2px solid #001a3e;
  box-sizing: border-box;
}
/* モーダル_ウィンドウ_ボタン_削除系 */
#button_modai_del {
  background-color: #ff0000;
  color: #ffffff;
  border: 2px solid #002c6f;
  box-sizing: border-box;
}
/* モーダル_ウィンドウ_ボタン_OK系 */
#button_modai_ok {
  background-color: #002c6f;
  color: #ffffff;
  border: 2px solid #001a3e;
  box-sizing: border-box;
}
</style>
