<template>
  <!-- クエスト画面 -->
  <div class="contents_quest background_border_black">
    <!-- クエスト掲示板ヘッダー -->
    <div id="quest_board_header">
      <div id="quest_board_title">クエスト掲示板</div>
      <div id="quest_board_number">
        <div v-if="tab_active === '1'">
          けんすう : {{ quest_data_filtering_1.length }}けん
        </div>
        <div v-if="tab_active === '2'">
          けんすう : {{ quest_data_filtering_2.length }}けん
        </div>
        <div v-if="tab_active === '3'">
          けんすう : {{ quest_data_filtering_3.length }}けん
        </div>
        <div v-if="tab_active === '4'">
          けんすう : {{ quest_data_filtering_4.length }}けん
        </div>
        <div v-if="tab_active === '5'">
          けんすう : {{ quest_data_filtering_5.length }}けん
        </div>
      </div>
    </div>
    <!-- タブボタン -->
    <ul id="tab_contents">
      <li
        id="tab_1"
        class="tab_button_solid_black tab_button_select"
        v-on:click="changeTab('1', $event)"
      >
        ぼしゅう中
        <div class="img_newarrival_quest_icon">
          <img src="@/public/icon_Attention.png" alt="" />
        </div>
        <br />
      </li>
      <li
        id="tab_2"
        class="tab_button_solid_black"
        v-on:click="changeTab('2', $event)"
      >
        おうぼ中
        <div class="img_newarrival_quest_icon">
          <img src="@/public/icon_Attention.png" alt="" />
        </div>
        <br />
      </li>
      <li
        id="tab_3"
        class="tab_button_solid_black"
        v-on:click="changeTab('3', $event)"
      >
        しんこう中
        <div class="img_newarrival_quest_icon">
          <img src="@/public/icon_Attention.png" alt="" />
        </div>
        <br />
      </li>
      <li
        id="tab_4"
        class="tab_button_solid_black"
        v-on:click="changeTab('4', $event)"
      >
        クリア<br />かくにん中
        <div class="img_newarrival_quest_icon">
          <img src="@/public/icon_Attention.png" alt="" />
        </div>
        <br />
      </li>
      <li
        id="tab_5"
        class="tab_button_solid_black"
        v-on:click="changeTab('5', $event)"
      >
        かんりょう
        <div class="img_newarrival_quest_icon">
          <img src="@/public/icon_Attention.png" alt="" />
        </div>
        <br />
      </li>
    </ul>
    <!-- クエスト掲示板一覧 -->
    <div id="quest_board_list_back">
      <div id="quest_board_list_quest" ref="quest_board">
        <!-- ぼしゅう中 -->
        <div v-if="tab_active === '1'">
          <!-- 1クエスト_コンポーネント -->
          <QuestBoardOnelineComponents
            v-for="list_No in quest_data_filtering_1"
            :key="list_No.no"
            v-bind:questNo="list_No.no"
            v-bind:questRank="list_No.rank_data"
            v-bind:tabKinds="tab_active"
            v-bind:questOrdersStatus="list_No.questOrdersStatus"
            v-bind:p_orders_subject_age="list_No.quest_orders_subject_age"
            v-bind:p_acount_authority="flg_show_button_management"
            @tab-switch="changeTab2"
          >
            <template v-slot:quest_rank_slot>
              {{ list_No.rank_data }}
            </template>
            <template v-slot:quest_registration_date_slot>
              {{ list_No.registration_date_data }}
            </template>
            <template v-slot:quest_contents_slot>
              {{ list_No.contents_data }}
            </template>
            <template v-slot:quest_point_slot>
              {{ list_No.point_data }}
            </template>
            <template v-slot:quest_dia_slot>
              {{ list_No.quest_orders_diamond }}
            </template>
            <template v-slot:quest_number_of_slot>
              {{ list_No.number_ofdata }}
            </template>
            <template v-slot:quest_conditions_slot>
              {{ list_No.conditions_data }}
            </template>
            <template v-slot:quest_board_detail_client_slot>
              {{ list_No.detail_client_data }}
            </template>
            <template v-slot:quest_board_detail_request_slot>
              {{ list_No.detail_request_data }}
            </template>
            <template v-slot:quest_board_detail_place_slot>
              {{ list_No.quest_orders_location_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_start_slot>
              {{ list_No.quest_orders_start_date_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_limit_slot>
              <br />{{ list_No.quest_orders_limit_date_data }}
            </template>
            <template v-slot:quest_board_detail_subject_age_slot>
              {{ list_No.quest_orders_subject_age }}
            </template>
          </QuestBoardOnelineComponents>
        </div>
        <!-- おうぼ中 -->
        <div v-if="tab_active === '2'">
          <!-- 1クエスト_コンポーネント -->
          <QuestBoardOnelineComponents
            v-for="list_No in quest_data_filtering_2"
            :key="list_No.no"
            v-bind:questNo="list_No.no"
            v-bind:questRank="list_No.rank_data"
            v-bind:tabKinds="tab_active"
            v-bind:questOrdersStatus="list_No.questOrdersStatus"
            v-bind:p_orders_subject_age="list_No.quest_orders_subject_age"
            v-bind:p_acount_authority="flg_show_button_management"
          >
            <template v-slot:quest_rank_slot>
              {{ list_No.rank_data }}
            </template>
            <template v-slot:quest_registration_date_slot>
              {{ list_No.registration_date_data }}
            </template>
            <template v-slot:quest_contents_slot>
              {{ list_No.contents_data }}
            </template>
            <template v-slot:quest_point_slot>
              {{ list_No.point_data }}
            </template>
            <template v-slot:quest_dia_slot>
              {{ list_No.quest_orders_diamond }}
            </template>
            <template v-slot:quest_number_of_slot>
              {{ list_No.number_ofdata }}
            </template>
            <template v-slot:quest_conditions_slot>
              {{ list_No.conditions_data }}
            </template>
            <template v-slot:quest_board_detail_client_slot>
              {{ list_No.detail_client_data }}
            </template>
            <template v-slot:quest_board_detail_request_slot>
              {{ list_No.detail_request_data }}
            </template>
            <template v-slot:quest_board_detail_place_slot>
              {{ list_No.quest_orders_location_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_start_slot>
              {{ list_No.quest_orders_start_date_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_limit_slot>
              <br />{{ list_No.quest_orders_limit_date_data }}
            </template>
            <template v-slot:quest_board_detail_subject_age_slot>
              {{ list_No.quest_orders_subject_age }}
            </template>
          </QuestBoardOnelineComponents>
        </div>
        <!-- しんこう中 -->
        <div v-if="tab_active === '3'">
          <!-- 1クエスト_コンポーネント -->
          <QuestBoardOnelineComponents
            v-for="list_No in quest_data_filtering_3"
            :key="list_No.no"
            v-bind:questNo="list_No.no"
            v-bind:questRank="list_No.rank_data"
            v-bind:tabKinds="tab_active"
            v-bind:questOrdersStatus="list_No.questOrdersStatus"
            v-bind:p_orders_subject_age="list_No.quest_orders_subject_age"
            v-bind:p_acount_authority="flg_show_button_management"
            @tab-switch="changeTab2"
          >
            <template v-slot:quest_rank_slot>
              {{ list_No.rank_data }}
            </template>
            <template v-slot:quest_registration_date_slot>
              {{ list_No.registration_date_data }}
            </template>
            <template v-slot:quest_contents_slot>
              {{ list_No.contents_data }}
            </template>
            <template v-slot:quest_point_slot>
              {{ list_No.point_data }}
            </template>
            <template v-slot:quest_dia_slot>
              {{ list_No.quest_orders_diamond }}
            </template>
            <template v-slot:quest_number_of_slot>
              {{ list_No.number_ofdata }}
            </template>
            <template v-slot:quest_conditions_slot>
              {{ list_No.conditions_data }}
            </template>
            <template v-slot:quest_board_detail_client_slot>
              {{ list_No.detail_client_data }}
            </template>
            <template v-slot:quest_board_detail_request_slot>
              {{ list_No.detail_request_data }}
            </template>
            <template v-slot:quest_board_detail_place_slot>
              {{ list_No.quest_orders_location_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_start_slot>
              {{ list_No.quest_orders_start_date_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_limit_slot>
              <br />{{ list_No.quest_orders_limit_date_data }}
            </template>
            <template v-slot:quest_board_detail_subject_age_slot>
              {{ list_No.quest_orders_subject_age }}
            </template>
          </QuestBoardOnelineComponents>
        </div>
        <!-- しんさ中 -->
        <div v-if="tab_active === '4'">
          <!-- 1クエスト_コンポーネント -->
          <QuestBoardOnelineComponents
            v-for="list_No in quest_data_filtering_4"
            :key="list_No.no"
            v-bind:questNo="list_No.no"
            v-bind:questRank="list_No.rank_data"
            v-bind:tabKinds="tab_active"
            v-bind:questOrdersStatus="list_No.questOrdersStatus"
            v-bind:p_orders_subject_age="list_No.quest_orders_subject_age"
            v-bind:p_acount_authority="flg_show_button_management"
          >
            <template v-slot:quest_rank_slot>
              {{ list_No.rank_data }}
            </template>
            <template v-slot:quest_registration_date_slot>
              {{ list_No.registration_date_data }}
            </template>
            <template v-slot:quest_contents_slot>
              {{ list_No.contents_data }}
            </template>
            <template v-slot:quest_point_slot>
              {{ list_No.point_data }}
            </template>
            <template v-slot:quest_dia_slot>
              {{ list_No.quest_orders_diamond }}
            </template>
            <template v-slot:quest_number_of_slot>
              {{ list_No.number_ofdata }}
            </template>
            <template v-slot:quest_conditions_slot>
              {{ list_No.conditions_data }}
            </template>
            <template v-slot:quest_board_detail_client_slot>
              {{ list_No.detail_client_data }}
            </template>
            <template v-slot:quest_board_detail_request_slot>
              {{ list_No.detail_request_data }}
            </template>
            <template v-slot:quest_board_detail_place_slot>
              {{ list_No.quest_orders_location_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_start_slot>
              {{ list_No.quest_orders_start_date_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_limit_slot>
              <br />{{ list_No.quest_orders_limit_date_data }}
            </template>
            <template v-slot:quest_board_detail_subject_age_slot>
              {{ list_No.quest_orders_subject_age }}
            </template>
          </QuestBoardOnelineComponents>
        </div>
        <!-- クリアずみ -->
        <div v-if="tab_active === '5'">
          <!-- 1クエスト_コンポーネント -->
          <QuestBoardOnelineComponents
            v-for="list_No in quest_data_filtering_5"
            :key="list_No.no"
            v-bind:questNo="list_No.no"
            v-bind:questRank="list_No.rank_data"
            v-bind:tabKinds="tab_active"
            v-bind:p_quest_status="list_No.status"
            v-bind:p_quest_orders_orders_status="
              list_No.questOrdersOrdersStatus
            "
            v-bind:questOrdersStatus="list_No.questOrdersStatus"
            v-bind:p_orders_subject_age="list_No.quest_orders_subject_age"
            v-bind:p_acount_authority="flg_show_button_management"
          >
            <template v-slot:quest_rank_slot>
              {{ list_No.rank_data }}
            </template>
            <template v-slot:quest_registration_date_slot>
              {{ list_No.registration_date_data }}
            </template>
            <template v-slot:quest_contents_slot>
              {{ list_No.contents_data }}
            </template>
            <template v-slot:quest_point_slot>
              {{ list_No.point_data }}
            </template>
            <template v-slot:quest_dia_slot>
              {{ list_No.quest_orders_diamond }}
            </template>
            <template v-slot:quest_number_of_slot>
              {{ list_No.number_ofdata }}
            </template>
            <template v-slot:quest_conditions_slot>
              {{ list_No.conditions_data }}
            </template>
            <template v-slot:quest_board_detail_client_slot>
              {{ list_No.detail_client_data }}
            </template>
            <template v-slot:quest_board_detail_request_slot>
              {{ list_No.detail_request_data }}
            </template>
            <template v-slot:quest_board_detail_place_slot>
              {{ list_No.quest_orders_location_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_start_slot>
              {{ list_No.quest_orders_start_date_data }}
            </template>
            <template v-slot:quest_board_detail_dateandtime_limit_slot>
              <br />{{ list_No.quest_orders_limit_date_data }}
            </template>
            <template v-slot:quest_board_detail_subject_age_slot>
              {{ list_No.quest_orders_subject_age }}
            </template>
          </QuestBoardOnelineComponents>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import QuestBoardOneline from "../components/QuestBoardOneline.vue";
import ApiService from "../service/ApiService";

interface questObj {
  no: number;
  rank_data: string;
  contents_data: string;
  registration_date_data: string;
  point_data: number;
  number_ofdata: string;
  conditions_data: string;
  detail_client_data: string;
  detail_request_data: string;
  status: number;
  questOrdersStatus: number;
  questOrdersOrdersStatus: number;
  quest_orders_location_data: string;
  quest_orders_start_date_data: string;
  quest_orders_limit_date_data: string;
  quest_orders_diamond: number;
  quest_orders_subject_age: number;
}
interface QuestState {
  quest: questObj[];
}

export default defineComponent({
  name: "App",
  // setup() {},
  data() {
    const st_hunter_data = JSON.parse(localStorage.getItem("hunter_data")!);
    const state = reactive<QuestState>({
      quest: [],
    });
    const user = {
      hunterId: st_hunter_data.ls_hunter_id,
    };
    const quest = computed(() => state.quest);
    ApiService.getQuestByHunterId(user)
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const quests = res.data;
          for (var i = 0; i < quests.length; i++) {
            state.quest.push({
              no: quests[i].quest_id,
              rank_data: quests[i].rank,
              contents_data: quests[i].title,
              registration_date_data: quests[i].formatted_insert_date,
              point_data: quests[i].point,
              number_ofdata: quests[i].capacity_range,
              conditions_data: quests[i].sub_title,
              detail_client_data: quests[i].client_name,
              detail_request_data: quests[i].contents,
              status: quests[i].status,
              questOrdersStatus: quests[i].quest_orders_status || 0,
              questOrdersOrdersStatus:
                quests[i].quest_orders_orders_status || 0,
              quest_orders_location_data: quests[i].location,
              quest_orders_start_date_data: quests[i].formatted_start_date,
              quest_orders_limit_date_data: quests[i].formatted_limit_date,
              quest_orders_diamond: quests[i].diamond,
              quest_orders_subject_age: quests[i].min_age,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // アカウント権限取得
    var flg_show_button_management_switch = "";
    var cookie = document.cookie;
    var cookiesArray = cookie.split(";");
    for (var c of cookiesArray) {
      var cArray = c.split("=");
      cArray[0] = cArray[0].trim();
      if (cArray[0] === "authority-id") {
        flg_show_button_management_switch = cArray[1];
      }
    }

    return {
      tab_active: "1",
      quest_data: quest,
      user: user,
      state: state,
      flg_kensu_show: 0,
      // アカウント権限
      flg_show_button_management: flg_show_button_management_switch,
      intervalId: 0,
    };
  },
  components: {
    QuestBoardOnelineComponents: QuestBoardOneline,
  },
  watch: {
    tab_active() {
      ApiService.getQuestByHunterId(this.user)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            const quests = res.data;
            this.state.quest = [];
            for (var i = 0; i < quests.length; i++) {
              this.state.quest.push({
                no: quests[i].quest_id,
                rank_data: quests[i].rank,
                contents_data: quests[i].title,
                registration_date_data: quests[i].formatted_insert_date,
                point_data: quests[i].point,
                number_ofdata: quests[i].capacity_range,
                conditions_data: quests[i].sub_title,
                detail_client_data: quests[i].owner,
                detail_request_data: quests[i].contents,
                status: quests[i].status,
                questOrdersStatus: quests[i].quest_orders_status || 0,
                questOrdersOrdersStatus:
                  quests[i].quest_orders_orders_status || 0,
                quest_orders_location_data: quests[i].location,
                quest_orders_start_date_data: quests[i].formatted_start_date,
                quest_orders_limit_date_data: quests[i].formatted_limit_date,
                quest_orders_diamond: quests[i].diamond,
                quest_orders_subject_age: quests[i].min_age,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  methods: {
    // タブ切り替え処理
    changeTab(tabNo: string, e: any) {
      let id = e.currentTarget.getAttribute("id");
      // 選択中以外のタブ取得
      const plusClass_1 = document.querySelector(
        "#" + id + ".tab_button_solid_black"
      );
      // 選択中タブ取得
      const plusClass_2 = document.querySelector(
        ".tab_button_solid_black.tab_button_select"
      );

      ////////////
      // 新着アイコン非表示処理
      ////////////
      // 選択されたタブ(新着表示されている)取得
      const newarrival_quest_tab = document.querySelector(
        "#" + id + " .img_newarrival_quest_icon.now_newarrival_quest"
      );

      // 新着アイコン非表示
      if (newarrival_quest_tab !== null) {
        newarrival_quest_tab.classList.toggle("now_newarrival_quest");
      }

      // タブの装飾変更(選択中タブの切り替え)
      if (plusClass_2 !== null) {
        plusClass_2.classList.toggle("tab_button_select");
      }
      if (plusClass_1 !== null) {
        plusClass_1.classList.toggle("tab_button_select");
      }

      // タブを切り替え
      this.tab_active = tabNo;
    },
    // タブ切り替え処理_クエストおうぼ_かんりょう時
    changeTab2(tabNoNext: string) {
      // スクロールを先頭に移動する
      if (
        this.$refs["quest_board"] != null ||
        this.$refs["quest_board"] !== undefined
      ) {
        (this.$refs["quest_board"] as any).scrollTop = 0;
      }

      const plusClass_1 = document.querySelector(
        "#tab_" + tabNoNext + ".tab_button_solid_black"
      );
      const plusClass_2 = document.querySelector(
        ".tab_button_solid_black.tab_button_select"
      );

      if (plusClass_2 !== null) {
        plusClass_2.classList.toggle("tab_button_select");
      }
      if (plusClass_1 !== null) {
        plusClass_1.classList.toggle("tab_button_select");
      }

      // タブを切り替え
      this.tab_active = tabNoNext;
    },

    ////////////////////////////////
    // データ取得保存処理_最新クエスト一覧
    // 新着比較用。最新クエスト一覧を取得し保持する。
    ////////////////////////////////
    get_newarrival_quest() {
      ////////////
      // データ取得処理
      // クエスト一覧
      ////////////
      // クエスト一覧(新着比較用)
      var newarrival_quest_data = [
        {
          itf_new_quest_id: 0,
          itf_new_quest_status: 0,
          itf_new_quest_orders_status: 0,
          itf_new_quest_orders_orders_status: 0,
          itf_new_quest_rank: "",
          itf_new_quest_insert_date: "",
          itf_new_quest_title: "",
          itf_new_quest_capacity: "",
          itf_new_quest_category: "",
          itf_new_quest_dia: 0,
          itf_new_quest_exp: 0,
          itf_new_quest_client: "",
          itf_new_quest_requestcontents: "",
          itf_new_quest_location: "",
          itf_new_quest_start_date: "",
          itf_new_quest_limit_date: "",
          itf_new_quest_targetage: 0,
        },
      ];

      // データ取得_ハンターID(ローカルストレージ)
      const st_hunter_data = JSON.parse(localStorage.getItem("hunter_data")!);
      const user = {
        hunterId: st_hunter_data.ls_hunter_id,
      };

      // データ取得処理呼び出し
      ApiService.getQuestByHunterId(user)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            const quests = res.data;
            for (var i = 0; i < quests.length; i++) {
              newarrival_quest_data.push({
                itf_new_quest_id: quests[i].quest_id,
                itf_new_quest_status: quests[i].status,
                itf_new_quest_orders_status: quests[i].quest_orders_status || 0,
                itf_new_quest_orders_orders_status:
                  quests[i].quest_orders_orders_status || 0,
                itf_new_quest_rank: quests[i].rank,
                itf_new_quest_insert_date: quests[i].formatted_insert_date,
                itf_new_quest_title: quests[i].title,
                itf_new_quest_capacity: quests[i].capacity_range,
                itf_new_quest_category: quests[i].sub_title,
                itf_new_quest_dia: quests[i].diamond,
                itf_new_quest_exp: quests[i].point,
                itf_new_quest_client: quests[i].owner,
                itf_new_quest_requestcontents: quests[i].contents,
                itf_new_quest_location: quests[i].location,
                itf_new_quest_start_date: quests[i].formatted_start_date,
                itf_new_quest_limit_date: quests[i].formatted_limit_date,
                itf_new_quest_targetage: quests[i].min_age,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    // データフィルタリング処理_ぼしゅう中
    quest_data_filtering_1(): any {
      if (
        this.flg_show_button_management === "0" ||
        this.flg_show_button_management === "1"
      ) {
        return this.quest_data.filter(function (list_No) {
          return list_No.status === 0;
        });
      } else {
        return this.quest_data.filter(function (list_No) {
          return list_No.status === 0 && list_No.questOrdersStatus === 0;
        });
      }
    },
    // データフィルタリング処理_おうぼ中
    quest_data_filtering_2(): any {
      if (
        this.flg_show_button_management === "0" ||
        this.flg_show_button_management === "1"
      ) {
        return this.quest_data.filter(function (list_No) {
          return list_No.status === 1;
        });
      } else {
        return this.quest_data.filter(function (list_No) {
          return (
            (list_No.status === 0 && list_No.questOrdersStatus === 4) ||
            list_No.status === 1
          );
        });
      }
    },
    // データフィルタリング処理_しんこう中
    quest_data_filtering_3(): any {
      if (
        this.flg_show_button_management === "0" ||
        this.flg_show_button_management === "1"
      ) {
        return this.quest_data.filter(function (list_No) {
          return list_No.status === 2;
        });
      } else {
        return this.quest_data.filter(function (list_No) {
          return (
            (list_No.status === 2 || list_No.status === 3) &&
            list_No.questOrdersStatus === 1
          );
        });
      }
    },
    // データフィルタリング処理_しんさ中
    quest_data_filtering_4(): any {
      if (
        this.flg_show_button_management === "0" ||
        this.flg_show_button_management === "1"
      ) {
        return this.quest_data.filter(function (list_No) {
          return list_No.status === 3;
        });
      } else {
        return this.quest_data.filter(function (list_No) {
          return list_No.status === 3 && list_No.questOrdersStatus === 4;
        });
      }
    },
    // データフィルタリング処理_クリアずみ
    quest_data_filtering_5(): any {
      if (
        this.flg_show_button_management === "0" ||
        this.flg_show_button_management === "1"
      ) {
        return this.quest_data.filter(function (list_No) {
          return list_No.status === 4 || list_No.status === 5;
        });
      } else {
        return this.quest_data.filter(function (list_No) {
          return (
            (list_No.status === 2 && list_No.questOrdersOrdersStatus === 0) ||
            (list_No.status === 3 && list_No.questOrdersOrdersStatus === 0) ||
            list_No.status === 4 ||
            list_No.status === 5
          );
        });
      }
    },
  },
  mounted() {
    // 画面下部分のメニューの新着アイコン非表示
    const mune_quest = document.querySelector(".menu");
    // アイコン非表示
    if (mune_quest !== null) {
      mune_quest.classList.remove("now_newarrival_quest");
    }
  },
});
</script>

<style>
@import "../css/style.scss";
@import "../css/Quest.scss";

/* タブ_1タブ */
.tab_button_solid_black {
  position: relative;
}

/* 新着アイコン */
.img_newarrival_quest_icon {
  width: 20px;
  height: 20px;
  display: none; /* アイコンは基本非表示 */
  position: absolute;
  top: -10px;
}

/* 新着アイコン_新着 */
.img_newarrival_quest_icon.now_newarrival_quest {
  display: block; /* アイコンを表示 */
}

/* 新着アイコン_画像 */
.img_newarrival_quest_icon img {
  width: 100%;
  height: 100%;
}
</style>
