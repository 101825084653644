import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "main_grid" }
const _hoisted_2 = {
  key: 0,
  id: "app_head_grid"
}
const _hoisted_3 = {
  key: 1,
  id: "app_infomation_grid"
}
const _hoisted_4 = {
  key: 2,
  id: "app_nav_grid"
}
const _hoisted_5 = { id: "loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadUserInfo = _resolveComponent("HeadUserInfo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Info = _resolveComponent("Info")!
  const _component_NavManu = _resolveComponent("NavManu")!
  const _component_LoadVuew = _resolveComponent("LoadVuew")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass({ main: _ctx.isActive })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.displaySwitchingHeader == true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_HeadUserInfo)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        id: "app_mein_grid",
        class: _normalizeClass({ two: _ctx.isActiveTwo, three: _ctx.isActiveThree })
      }, [
        _createVNode(_component_router_view)
      ], 2),
      (_ctx.displaySwitchingInformation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Info)
          ]))
        : _createCommentVNode("", true),
      (_ctx.displaySwitchingFooter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_NavManu)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_LoadVuew)
      ])
    ])
  ], 2))
}