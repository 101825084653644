<template>
  <!-- 登録選択画面 -->
  <div>
    <div class="main2">
      <div class="grid_small_header">
        <!-- ヘッダー -->
        <SmallHeader
          show_button_return="OK"
          @return-transition="oyareturntrantransition()"
        >
          <template v-slot:slot_small_header_title>
            <ruby>登録<rt>とうろく</rt></ruby>
          </template>
        </SmallHeader>
      </div>
      <!-- ボタン_各管理画面遷移 -->
      <div class="grid_main2_contents">
        <div id="regist_menu">
          <div class="background_border_brueblack" id="regist_menu_contents">
            <!-- ボタン_ハンター登録画面へ -->
            <GenericButton
              :class_name="class_name1"
              :id_name="id_name1"
              :message="message_1"
              v-on:click="transition_hunter_regist()"
            />
          </div>
          <!-- ボタン_ギルド登録画面へ -->
          <GenericButton
            :id_name="id_name2"
            :message="message_2"
            v-on:click="transition_guild_regist()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";

export default defineComponent({
  // データ
  data() {
    return {
      id_name1: "button_transition_hunter_regist",
      class_name1: "background_border_black",
      message_1: "ハンターとうろく",
      id_name2: "button_transition_guild_regist",
      message_2: "ギルド新規登録はこちら",
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
  },
  // メソッド
  methods: {
    // ボタンクリック処理_ハンター登録
    transition_hunter_regist() {
      // ローカルストレージにデータがあれば削除
      localStorage.removeItem("regist_hunter_data");
      // 画面遷移_ギルド登録確認画面
      this.$router.push({
        name: "managehunterregister",
      });
    },
    // ボタンクリック処理_ギルド担当者の方はこちら
    transition_guild_regist() {
      // 画面遷移_ギルド登録画面
      localStorage.removeItem("regist_guild_data");
      this.$router.push({
        name: "manageguildregister",
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "login",
      });
    },
  },
});
</script>

<style>
@import "../css/style.scss";
@import "../css/loginfromregisterselect.scss";
</style>
