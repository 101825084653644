<template>
  <!-- クエスト管理画面(クエスト一覧) -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>クエスト管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear table_main">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>クエスト一覧</p>
      </div>
      <!-- テーブル -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table bordered"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          nextLabel: '次へ',
          prevLabel: '前へ',
          perPageDropdownEnabled: false,
          infoFn: (params) =>
            `${params.totalRecords} 件中 ${params.firstRecordOnPage} ～ ${params.lastRecordOnPage}`,
        }"
        :search-options="{
          enabled: true,
        }"
        @row-click="onRowClick"
      />
      <!-- ボタン_新規登録 -->
      <div class="vgt-registration-button">
        <GenericButton
          type="button"
          :class_name="class_name"
          :message="message"
          v-on:click="transition_quest_regist()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import { VueGoodTable } from "vue-good-table-next";
import ApiService from "../service/ApiService";
import GenericButton from "../components/GenericButton.vue";

// データ受取用
interface quest_data_catch {
  itf_quest_id: string;
  itf_quest_rank: string;
  itf_quest_regist_date: string;
  itf_quest_title: string;
  itf_quest_number_of_people: string;
  itf_quest_category: string;
  itf_quest_requester: string;
  itf_quest_start_date: string;
  itf_quest_limit_date: string;
  itf_quest_point: string;
  itf_quest_status: string;
  itf_quest_status_id: string;
  itf_quest_guild_name: string;
  itf_quest_guild_id: string;
}
interface quest_data {
  quest: quest_data_catch[];
}

export default defineComponent({
  data() {
    localStorage.removeItem("quest_data");
    const state = reactive<quest_data>({
      quest: [],
    });

    const quests_list = computed(() => state.quest);
    ApiService.getQuestByAuthorityId()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const quests = res.data;
          for (var i = 0; i < quests.length; i++) {
            state.quest.push({
              itf_quest_id: quests[i].quest_id,
              itf_quest_rank: quests[i].rank,
              itf_quest_regist_date: quests[i].formatted_insert_date,
              itf_quest_title: quests[i].title,
              itf_quest_number_of_people: quests[i].upper_capacity,
              itf_quest_category: quests[i].sub_title,
              itf_quest_requester: quests[i].client_name,
              itf_quest_start_date: quests[i].formatted_start_date,
              itf_quest_limit_date: quests[i].formatted_limit_date,
              itf_quest_point: quests[i].point,
              itf_quest_status: quests[i].formatted_status,
              itf_quest_status_id: quests[i].status,
              itf_quest_guild_name: quests[i].guild_name,
              itf_quest_guild_id: quests[i].guild_id,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // データ設定
    return {
      // 列ヘッダー
      columns: [
        {
          label: "状態",
          field: "itf_quest_status",
        },
        {
          label: "ランク",
          field: "itf_quest_rank",
        },
        {
          label: "タイトル",
          field: "itf_quest_title",
        },
        {
          label: "募集人数",
          field: "itf_quest_number_of_people",
        },
        {
          label: "カテゴリ",
          field: "itf_quest_category",
        },
        {
          label: "依頼者",
          field: "itf_quest_requester",
        },
        {
          label: "作業日時（開始）",
          field: "itf_quest_start_date",
        },
        {
          label: "作業日時（終了）",
          field: "itf_quest_limit_date",
        },
        {
          label: "獲得経験値",
          field: "itf_quest_point",
        },
        {
          label: "登録日",
          field: "itf_quest_regist_date",
        },
        {
          label: "登録ギルド",
          field: "itf_quest_guild_name",
        },
      ],
      // テーブルデータ
      rows: quests_list,
      class_name: "operation_button button_black operation_button_quest_regist",
      message: "新規登録",
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    VueGoodTable,
    GenericButton,
  },
  // メソッド
  methods: {
    onRowClick(params: any) {
      localStorage.setItem(
        "quest_data",
        JSON.stringify({
          ls_quest_id: params.row.itf_quest_id,
          ls_quest_status: params.row.itf_quest_status_id,
          ls_quest_status_str: params.row.itf_quest_status,
          ls_quest_guild_id: params.row.itf_quest_guild_id,
        })
      );
      this.$router.push({
        name: "managequestmodify",
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "management",
      });
    },
    // ボタンクリック処理_新規登録
    transition_quest_regist() {
      // 以前の更新データが残っている場合削除
      localStorage.removeItem("regist_quest_data");
      this.$router.push({
        name: "managequestregister",
      });
    },
  },
});
</script>

<style></style>
