<template>
  <!-- クエスト登録確認画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>クエスト登録</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>登録内容確認</p>
      </div>
      <!-- フォーム(表示) -->
      <form class="scroll_form" @submit.prevent>
        <p v-show="isHunterAsscociation" class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">
            対象ギルド
          </span>
          <br />
          <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_guild_name
          }}</span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">依頼者</span>
          <br />
          <!-- <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_quest_requester
          }}</span> -->
          <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_client_name
          }}</span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">
            クエストタイトル
          </span>
          <br />
          <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_quest_title
          }}</span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">
            クエストランク
          </span>
          <br />
          <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_quest_rank
          }}</span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">
            獲得経験値
          </span>
          <br />
          <span class="f_fieldset_contents_value">
            {{ d_regist_check_data.ls_quest_point }}
          </span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">
            取得ダイヤ
          </span>
          <br />
          <span class="f_fieldset_contents_value">
            {{ d_regist_check_data.ls_quest_diamond }}
          </span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">募集人数</span>
          <br />
          <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_quest_number_of_people
          }}</span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">カテゴリ</span>
          <br />
          <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_quest_category
          }}</span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">依頼内容</span>
          <br />
          <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_quest_contents
          }}</span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">作業場所</span>
          <br />
          <span class="f_fieldset_contents_value">{{
            d_regist_check_data.ls_quest_location
          }}</span>
        </p>
        <p
          class="f_fieldset_contents"
          v-if="d_regist_check_data.ls_quest_min_age > 0"
        >
          <span class="show_contents_label label_border_blue">対象年齢</span>
          <br />
          <span class="f_fieldset_contents_value">
            {{ d_regist_check_data.ls_quest_min_age + "歳以上" }}
          </span>
        </p>
        <p class="f_fieldset_contents" v-else>
          <span class="show_contents_label label_border_blue">対象年齢</span>
          <br />
          <span class="f_fieldset_contents_value">なし</span>
        </p>
        <p class="f_fieldset_contents">
          <span class="show_contents_label label_border_blue">作業日時</span>
          <br />
          <span
            class="f_fieldset_contents_value f_fieldset_contents_value_quest_date"
          >
            {{ d_regist_check_data.ls_quest_date }}
          </span>
        </p>
        <div class="page_regist_message">
          <p>こちらの内容で登録しますか?</p>
        </div>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            v-on:click="return_transition()"
          />
          <!-- ボタン_登録 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            v-on:click="regist_data_quest()"
          />
        </div>
      </form>
    </div>
    <ModalWindow
      modal_title="完了"
      msg_1="クエスト名"
      :msg_2="d_regist_check_data.ls_quest_title"
      msg_3="の登録完了しました"
      button_ok="OK"
      v-if="flg_show_modal_comp"
      @modal-ok="modalokoya()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";
import ModalWindow from "../components/Modal_2.vue";

export default defineComponent({
  // データ
  data() {
    // ハンター協会管理者フラグ
    let isHunterAsscociation = false;
    const cookie: string = document.cookie;
    const cookiesArray: string[] = cookie.split(";");
    for (let c of cookiesArray) {
      const cArray: string[] = c.split("=");
      cArray[0] = cArray[0].trim();
      if (cArray[0] === "authority-id") {
        isHunterAsscociation = cArray[1] == "0";
      }
    }

    // 登録データ取得
    const st_quest_data = JSON.parse(
      localStorage.getItem("regist_quest_data")!
    );

    return {
      class_name: "operation_button button_white_blue_blue",
      message: "戻る",
      class_name2: "operation_button button_blue",
      message2: "登録",
      d_regist_check_data: st_quest_data,
      flg_show_modal_comp: false,
      isHunterAsscociation: isHunterAsscociation,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // メソッド
  methods: {
    // 戻るボタンクリック処理
    return_transition() {
      this.$router.push({
        name: "managequestregister",
      });
    },
    // 登録ボタンクリック処理
    regist_data_quest() {
      ApiService.putRegisterQuestData(this.d_regist_check_data)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // モーダル表示_完了モーダル
            this.flg_show_modal_comp = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // ボタンクリック処理_モーダル_OK
    modalokoya() {
      // モーダル非表示_完了モーダル
      this.flg_show_modal_comp = false;
      // 以前の更新データが残っている場合削除
      localStorage.removeItem("regist_quest_data");
      // 画面遷移_クエスト管理(一覧)
      this.$router.push({
        name: "managequestlist",
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "managequestregister",
      });
    },
  },
});
</script>

<style>
.quest_contents_check {
  font-size: 14px;
  margin-top: 2.5%;
}
</style>
