<template>
  <!-- 依頼者新規登録画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー_小 -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="transition_return_icon()"
      >
        <template v-slot:slot_small_header_title>依頼者管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- タイトル -->
      <div class="page_title">
        <p>依頼者登録</p>
      </div>
      <!-- フォーム(入力) -->
      <form class="scroll_form" @submit.prevent>
        <!-- 依頼者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">依頼者情報</legend>
          <!-- 依頼者情報_お名前(漢字) -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_name"
              class="input_contents_label label_border_blue"
            >
              お名前(漢字)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">姓</span>
                <input
                  type="text"
                  name="f_client_name_last"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_client_name"
                  placeholder="田中"
                  tabindex="1"
                  maxlength="10"
                  v-model="d_client_data.itf_client_name_last"
                  @keyup="check_input_1(1)"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[0]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">名</span>
                <input
                  type="text"
                  name="f_client_name_first"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_client_name"
                  placeholder="太郎"
                  tabindex="2"
                  maxlength="10"
                  v-model="d_client_data.itf_client_name_first"
                  @keyup="check_input_1(2)"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[1]
              }}</label>
            </span>
          </p>
          <!-- 依頼者情報_お名前(よみがな) -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_name_kana"
              class="input_contents_label label_border_blue"
            >
              お名前(よみがな)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">せい</span>
                <input
                  type="text"
                  name="f_client_name_kana_last"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_client_name_kana"
                  placeholder="たなか"
                  tabindex="3"
                  maxlength="10"
                  v-model="d_client_data.itf_client_name_kana_last"
                  @keyup="check_input_2(1)"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[2]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">めい</span>
                <input
                  type="text"
                  name="f_client_name_kana_first"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_client_name_kana"
                  placeholder="たろう"
                  tabindex="4"
                  maxlength="10"
                  v-model="d_client_data.itf_client_name_kana_first"
                  @keyup="check_input_2(2)"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[3]
              }}</label>
            </span>
          </p>
          <!-- 誕生日 -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_birthday"
              class="input_contents_label label_border_blue"
            >
              誕生日
            </label>
            <br />
            <input
              type="date"
              name="f_client_birthday"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_client_birthday"
              max="9999-12-31"
              tabindex="5"
              v-model="d_client_data.itf_client_birthday"
            />
          </p>
          <!-- 性別 -->
          <p class="f_fieldset_contents" id="guild_list">
            <label
              for="f_client_gender"
              class="input_contents_label label_border_blue"
            >
              性別
            </label>
            <span class="required_icon"></span>
            <br />
            <select v-model="gender_get_value" tabindex="6">
              <option
                v-for="genderlist in d_gender_list"
                v-bind:key="genderlist.itf_gender_id"
                :value="genderlist"
              >
                {{ genderlist.itf_gender }}
              </option>
            </select>
            <br />
            <label class="err_message_red">{{ d_error_message[4] }}</label>
          </p>
          <!-- 電話番号 -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_tel"
              class="input_contents_label label_border_blue"
            >
              電話番号
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="tel"
              name="f_client_tel"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_client_tel"
              placeholder="08012345678"
              tabindex="7"
              maxlength="15"
              v-model="d_client_data.itf_client_tel"
              @keyup="check_input_3(1)"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[5] }}</label>
            <label class="err_message_red">{{ d_error_message[10] }}</label>
          </p>
          <!-- 住所 -->
          <fieldset class="input_fieldset input_fieldset_1">
            <legend class="legend_style">住所</legend>
            <p class="f_fieldset_contents">
              <span class="f_fieldset_contents_position_row">
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_client_zipcode"
                    class="input_contents_label label_border_blue"
                  >
                    郵便番号
                    <span class="required_icon"></span>
                  </label>
                  <input
                    type="text"
                    name="f_client_zipcode"
                    class="f_input_text f_input_size_3 manage_registmodifydelete_f_input_text"
                    id="f_client_zipcode"
                    placeholder="9041234"
                    tabindex="8"
                    maxlength="7"
                    inputmode="numeric"
                    v-model="d_client_data.itf_client_zipcode"
                    @keyup="check_input_3(2)"
                  />
                  <label class="err_message_red">
                    {{ d_error_message[6] }}
                  </label>
                  <label class="err_message_red">
                    {{ d_error_message[9] }}
                  </label>
                </span>
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_client_prefecture"
                    class="input_contents_label label_border_blue"
                  >
                    都道府県
                  </label>
                  <select
                    v-model="prefecture_get_value"
                    tabindex="9"
                    class="prefecture"
                  >
                    <option
                      v-for="prefecture_list in d_prefecture_list"
                      v-bind:key="prefecture_list.itf_prefecture_id"
                      :value="prefecture_list"
                    >
                      {{ prefecture_list.itf_prefecture_name }}
                    </option>
                  </select>
                </span>
              </span>
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_client_address"
                class="input_contents_label label_border_blue"
              >
                市区町村・番地
              </label>
              <span class="required_icon"></span>
              <br />
              <input
                type="text"
                name="f_client_address"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_client_address"
                placeholder="沖縄市999番地"
                tabindex="10"
                maxlength="100"
                v-model="d_client_data.itf_client_address"
              />
              <br />
              <label class="err_message_red">
                {{ d_error_message[7] }}
              </label>
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_client_building"
                class="input_contents_label label_border_blue"
              >
                建物名・部屋番号
              </label>
              <br />
              <input
                type="text"
                name="f_client_building"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_client_building"
                placeholder="コーポ123"
                tabindex="11"
                maxlength="50"
                v-model="d_client_data.itf_client_building"
              />
            </p>
          </fieldset>
          <!-- 登録ギルド -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_name"
              class="input_contents_label label_border_blue"
            >
              登録ギルド
            </label>
            <span v-if="isHunterAsscociation" class="required_icon"></span>
            <br />
            <select
              v-if="isHunterAsscociation"
              v-model="d_client_data.itf_client_guild"
            >
              <option
                v-for="guild_list in d_guild_list"
                v-bind:key="guild_list.itf_guildlist_guild_id"
                :value="guild_list"
              >
                {{ guild_list.itf_guildlist_guild_name }}
              </option>
            </select>
            <span v-else class="f_fieldset_contents_value">
              {{ d_client_data.itf_client_guild.itf_guildlist_guild_name }}
            </span>
            <label class="err_message_red">{{ d_error_message[8] }}</label>
          </p>
        </fieldset>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            tabindex="12"
            v-on:click="transition_return()"
          />
          <!-- ボタン_確認 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            tabindex="13"
            v-on:click="transition_confirmation()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";

////////////////////////////////
// interface(データ入れ物)
// 登録情報インプットデータ
////////////////////////////////
interface client_data_catch {
  ////////////
  // 依頼者情報
  ////////////
  // お名前(漢字)_姓
  itf_client_name_last: string;
  // お名前(漢字)_名
  itf_client_name_first: string;
  // お名前(よみがな)_姓
  itf_client_name_kana_last: string;
  // お名前(よみがな)_名
  itf_client_name_kana_first: string;
  // 誕生日
  itf_client_birthday: string;
  // 電話番号
  itf_client_tel: string;
  // 住所(郵便番号)
  itf_client_zipcode: string;
  // 住所(市区町村・番地)
  itf_client_address: string;
  // 住所(建物名・部屋番号)
  itf_client_building: string;
  // ギルド情報
  itf_client_guild: guildlist_data_catch;
}
interface ClientState {
  client_item: client_data_catch;
}

////////////////////////////////
// interface(データ入れ物)
// 性別ドロップダウン
////////////////////////////////
interface gender_data_catch {
  // 性別ID(画面非表示)
  itf_gender_id: number;
  // 性別
  itf_gender: string;
}
interface GenderState {
  gender_item: gender_data_catch[];
}
////////////////////////////////
// interface(データ入れ物)
// 都道府県ドロップダウン
////////////////////////////////
interface prefecture_data_catch {
  // 県ID(画面非表示)
  itf_prefecture_id: number;
  // 県名
  itf_prefecture_name: string;
}
interface PrefectureState {
  prefecture_item: prefecture_data_catch[];
}

////////////////////////////////////////
// データ受取用_ギルド一覧ドロップダウン用
////////////////////////////////////////
interface guildlist_data_catch {
  // ギルドID(画面非表示)
  itf_guildlist_guild_id: number;
  // ギルド名
  itf_guildlist_guild_name: string;
}
interface GuildListState {
  guildlist_item: guildlist_data_catch[];
}

export default defineComponent({
  // データ
  data() {
    ////////////////////////////////
    // 変数宣言
    ////////////////////////////////
    // ハンター協会管理者フラグ
    let isHunterAsscociation = false;
    const cookie: string = document.cookie;
    const cookiesArray: string[] = cookie.split(";");
    for (let c of cookiesArray) {
      const cArray: string[] = c.split("=");
      cArray[0] = cArray[0].trim();
      if (cArray[0] === "authority-id") {
        isHunterAsscociation = cArray[1] == "0";
      }
    }

    // ギルド情報
    const itf_client_guild: guildlist_data_catch = {
      itf_guildlist_guild_id: 0,
      itf_guildlist_guild_name: "",
    };

    // 都道府県ドロップダウン初期値設定用
    let first_prefecture_id = 1;
    let first_prefecture_name = "北海道";
    // 性別ドロップダウン初期値設定用
    let first_gender_id = 0;
    let first_gender_name = "";
    // エラーメッセージ用
    var error_message: string[] = new Array(8);

    ///////////////////////////////
    // データ設定処理
    // 登録情報インプットデータ
    ////////////////////////////////
    // reactive(データ監視)
    // 登録情報インプットデータ
    // 初期化_データ受取用
    var client = reactive<ClientState>({
      client_item: {
        itf_client_name_last: "",
        itf_client_name_first: "",
        itf_client_name_kana_last: "",
        itf_client_name_kana_first: "",
        itf_client_birthday: "",
        itf_client_tel: "",
        itf_client_zipcode: "",
        itf_client_address: "",
        itf_client_building: "",
        itf_client_guild: itf_client_guild,
      },
    });
    const client_data = computed(() => client.client_item);

    ////////////////////////////////
    // データ設定処理
    // 性別ドロップダウン
    ////////////////////////////////
    // reactive(データ監視)
    // 性別ドロップダウン
    var gender_list = reactive<GenderState>({
      gender_item: [],
    });
    const gender_data = computed(() => gender_list.gender_item);

    // 性別データ設定
    const genderdata = [
      {
        genderid: 1,
        gender: "男",
      },
      {
        genderid: 2,
        gender: "女",
      },
      {
        genderid: 3,
        gender: "その他",
      },
    ];

    // 性別データセット
    for (
      var i_gender_list = 0;
      i_gender_list < genderdata.length;
      i_gender_list++
    ) {
      gender_list.gender_item.push({
        // 性別ID(画面非表示)
        itf_gender_id: genderdata[i_gender_list].genderid,
        // 性別
        itf_gender: genderdata[i_gender_list].gender,
      });
    }

    ////////////////////////////////
    // データ取得処理
    // 都道府県ドロップダウン
    ////////////////////////////////
    // reactive(データ監視)
    // 都道府県ドロップダウン
    var prefecture_list = reactive<PrefectureState>({
      prefecture_item: [],
    });
    const prefecture_data = computed(() => prefecture_list.prefecture_item);

    // データ取得処理_都道府県一覧
    ApiService.getPrefectureData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const prefectures = res.data;
          for (var i = 0; i < prefectures.length; i++) {
            prefecture_list.prefecture_item.push({
              // 県ID(画面非表示)
              itf_prefecture_id: prefectures[i].id,
              // 県名
              itf_prefecture_name: prefectures[i].name,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    ////////////////////////////////
    // ギルド一覧取得(ドロップダウン用)
    ////////////////////////////////
    // リスト選択から固定表示に変更のためコメントアウト。念のためコメントとして残しておく 2022/09/26
    // 初期化_データ受取用
    var guild_list = reactive<GuildListState>({
      guildlist_item: [],
    });
    const guildlist_item = computed(() => guild_list.guildlist_item);

    ////////////////////////////////
    // データ取得処理
    // 登録ギルド名
    ////////////////////////////////
    // データ取得(ローカルストレージ)_現在ログインアカウントの所属ギルドid
    let user_guild_id = 0;
    if (localStorage.getItem("guild_data")) {
      user_guild_id = Number(
        JSON.parse(localStorage.getItem("guild_data")!).ls_guild_id
      );
    }

    // データ取得処理_現在ログインアカウントの所属ギルドデータ
    ApiService.getGuildListData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const guilds = res.data;
          for (var i = 0; i < guilds.length; i++) {
            if (guilds[i].status != 1) continue;
            guild_list.guildlist_item.push({
              // ギルドID(画面非表示)
              itf_guildlist_guild_id: guilds[i].guild_id,
              // ギルド名
              itf_guildlist_guild_name: guilds[i].guild_name,
            });
            if (
              !isHunterAsscociation &&
              user_guild_id > 0 &&
              guilds[i].guild_id === user_guild_id
            ) {
              client.client_item.itf_client_guild.itf_guildlist_guild_id =
                guilds[i].guild_id;
              client.client_item.itf_client_guild.itf_guildlist_guild_name =
                guilds[i].guild_name;
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    ////////////////////////////////
    // 登録確認から戻ってきた場合処理
    ////////////////////////////////
    // 登録情報インプットデータ存在確認(ローカルストレージ)
    if (localStorage.getItem("regist_client_data")) {
      // 既にデータが存在する場合(登録確認から戻ってきた場合)
      // データ取得(ローカルストレージ)_登録情報インプットデータ
      const st_regist_client_data = JSON.parse(
        localStorage.getItem("regist_client_data")!
      );

      // 登録情報インプットデータを入力項目にセット
      client.client_item.itf_client_name_last =
        st_regist_client_data.ls_client_last_name;
      client.client_item.itf_client_name_first =
        st_regist_client_data.ls_client_first_name;
      client.client_item.itf_client_name_kana_last =
        st_regist_client_data.ls_client_last_name_kana;
      client.client_item.itf_client_name_kana_first =
        st_regist_client_data.ls_client_first_name_kana;
      client.client_item.itf_client_birthday =
        st_regist_client_data.ls_client_birthday;
      first_gender_id = st_regist_client_data.ls_client_gender_id;
      first_gender_name = st_regist_client_data.ls_client_gender;
      client.client_item.itf_client_tel = st_regist_client_data.ls_client_tel;
      client.client_item.itf_client_zipcode =
        st_regist_client_data.ls_client_zipcode;
      first_prefecture_id = st_regist_client_data.ls_client_prefecture_id;
      first_prefecture_name = st_regist_client_data.ls_client_prefecture_name;
      client.client_item.itf_client_address =
        st_regist_client_data.ls_client_address;
      client.client_item.itf_client_building =
        st_regist_client_data.ls_client_building;
      client.client_item.itf_client_guild.itf_guildlist_guild_id =
        st_regist_client_data.ls_guild_id;
      client.client_item.itf_client_guild.itf_guildlist_guild_name =
        st_regist_client_data.ls_guild_name;
    }

    ////////////////////////////////
    // data設定
    ////////////////////////////////
    return {
      // データ_登録情報インプットデータ
      d_client_data: client_data,
      // データ_性別ドロップダウン表示用
      d_gender_list: gender_data,
      // データ_都道府県データプルダウン表示用
      d_prefecture_list: prefecture_data,
      // ギルド一覧プルダウン表示用
      // リスト選択から固定表示に変更のためコメントアウト。念のためコメントとして残しておく 2022/09/26
      d_guild_list: guildlist_item,
      // 都道府県データ渡す用
      prefecture_get_value: {
        itf_prefecture_id: first_prefecture_id,
        itf_prefecture_name: first_prefecture_name,
      },
      // 性別データ渡す用
      gender_get_value: {
        itf_gender_id: first_gender_id,
        itf_gender: first_gender_name,
      },
      // エラーメッセージ用
      d_error_message: error_message,
      // ボタン設定_戻る
      class_name:
        "operation_button button_white manage_registmodifydelete_operationbutton",
      message: "戻る",
      // ボタン設定_確認
      class_name2:
        "operation_button button_black manage_registmodifydelete_operationbutton",
      message2: "確認",
      isHunterAsscociation: isHunterAsscociation,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
  },
  // メソッド
  methods: {
    ////////////////////////////////
    // ボタンクリック処理_戻るアイコン(ヘッダー)
    ////////////////////////////////
    transition_return_icon() {
      // 画面遷移_前画面
      this.$router.push({
        name: "manageclientlist",
      });
    },
    ////////////////////////////////
    // ボタンクリック処理_戻る
    ////////////////////////////////
    transition_return() {
      // 画面遷移_前画面
      this.$router.push({
        name: "manageclientlist",
      });
    },
    ////////////////////////////////
    // ボタンクリック処理_確認
    ////////////////////////////////
    transition_confirmation() {
      ////////////
      // 変数宣言と初期化
      ////////////
      // 入力チェック結果用
      var result_check_input = false;

      ////////////
      // 入力チェック
      ////////////
      // 処理呼び出し_入力チェック
      result_check_input = this.check_input();
      // 入力チェックエラーが有った場合
      // 処理終了
      if (result_check_input) return;

      ////////////
      // ローカルストレージ保存
      ////////////
      // 処理呼び出し_ローカルストレージ保存処理
      this.set_input_storage();

      // 画面遷移_登録内容確認画面
      this.$router.push({
        name: "manageclientregistercheck",
      });
    },
    ////////////////////////////////
    // 入力チェック処理
    ////////////////////////////////
    check_input() {
      ////////////
      // 変数宣言と初期化
      ////////////
      // 戻り値用
      var result_check = false;
      // エラーメッセージ用
      this.d_error_message = new Array(10);

      ////////////
      // 入力チェック処理
      ////////////
      // 依頼者情報
      if (this.d_client_data.itf_client_name_last.length < 1)
        this.d_error_message[0] = "姓を入力してください";
      if (this.d_client_data.itf_client_name_first.length < 1)
        this.d_error_message[1] = "名を入力してください";
      if (this.d_client_data.itf_client_name_kana_last.length < 1)
        this.d_error_message[2] = "せいを入力してください";
      if (this.d_client_data.itf_client_name_kana_first.length < 1)
        this.d_error_message[3] = "めいを入力してください";
      if (this.gender_get_value.itf_gender == "")
        this.d_error_message[4] = "性別を選択してください";
      if (this.d_client_data.itf_client_tel.length < 1)
        this.d_error_message[5] = "電話番号を入力してください";
      if (this.d_client_data.itf_client_zipcode.length < 1)
        this.d_error_message[6] = "郵便番号を入力してください";
      if (this.d_client_data.itf_client_address.length < 1)
        this.d_error_message[7] = "市区町村・番地を入力してください";
      if (
        this.d_client_data.itf_client_guild.itf_guildlist_guild_name.length < 1
      )
        this.d_error_message[8] = "登録ギルドを選択してください";
      if (this.d_client_data.itf_client_zipcode.length >= 1) {
        if (this.d_client_data.itf_client_zipcode.length < 7) {
          this.d_error_message[9] = "7桁で入力してください";
        }
      }
      if (this.d_client_data.itf_client_tel.length >= 1) {
        if (this.d_client_data.itf_client_tel.length < 8) {
          this.d_error_message[10] = "8～15桁で入力してください";
        }
      }

      ////////////
      // エラー存在チェック
      ////////////
      this.d_error_message.forEach((d_error_message) => {
        result_check = d_error_message.length > 0 ? true : false;
      });

      // 戻り値を返す
      return result_check;
    },
    ////////////////////////////////
    // ローカルストレージ保存処理
    // 入力項目
    ////////////////////////////////
    set_input_storage() {
      ////////////
      // ローカルストレージへデータ保存
      ////////////
      localStorage.setItem(
        "regist_client_data",
        JSON.stringify({
          ls_client_last_name: this.d_client_data.itf_client_name_last,
          ls_client_first_name: this.d_client_data.itf_client_name_first,
          ls_client_last_name_kana:
            this.d_client_data.itf_client_name_kana_last,
          ls_client_first_name_kana:
            this.d_client_data.itf_client_name_kana_first,
          ls_client_birthday: this.d_client_data.itf_client_birthday,
          ls_client_gender_id: this.gender_get_value.itf_gender_id,
          ls_client_gender: this.gender_get_value.itf_gender,
          ls_client_tel: this.d_client_data.itf_client_tel,
          ls_client_zipcode: this.d_client_data.itf_client_zipcode,
          ls_client_prefecture_id: this.prefecture_get_value.itf_prefecture_id,
          ls_client_prefecture_name:
            this.prefecture_get_value.itf_prefecture_name,
          ls_client_address: this.d_client_data.itf_client_address,
          ls_client_building: this.d_client_data.itf_client_building,
          ls_guild_id:
            this.d_client_data.itf_client_guild.itf_guildlist_guild_id,
          ls_guild_name:
            this.d_client_data.itf_client_guild.itf_guildlist_guild_name,
        })
      );
    },
    ////////////////////////////////
    // 入力制御_1
    ////////////////////////////////
    check_input_1(flg_input_kinds: number) {
      switch (flg_input_kinds) {
        case 1: {
          // お名前(漢字)_姓
          const tmp_value = this.d_client_data.itf_client_name_last;
          if (tmp_value) {
            this.d_client_data.itf_client_name_last = tmp_value.replace(
              /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
              ""
            );
          }
          break;
        }
        case 2: {
          // お名前(漢字)_名
          const tmp_value = this.d_client_data.itf_client_name_first;
          if (tmp_value) {
            this.d_client_data.itf_client_name_first = tmp_value.replace(
              /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
              ""
            );
          }
          break;
        }
      }
    },
    ////////////////////////////////
    // 入力制御_2
    ////////////////////////////////
    check_input_2(flg_input_kinds: number) {
      switch (flg_input_kinds) {
        case 1: {
          // お名前(よみがな)_せい
          const tmp_value = this.d_client_data.itf_client_name_kana_last;
          if (tmp_value) {
            this.d_client_data.itf_client_name_kana_last = tmp_value.replace(
              /[^ぁ-んー]/g,
              ""
            );
          }
          break;
        }
        case 2: {
          // お名前(よみがな)_めい
          const tmp_value = this.d_client_data.itf_client_name_kana_first;
          if (tmp_value) {
            this.d_client_data.itf_client_name_kana_first = tmp_value.replace(
              /[^ぁ-んー]/g,
              ""
            );
          }
          break;
        }
      }
    },
    ////////////////////////////////
    // 入力制御_3
    ////////////////////////////////
    check_input_3(flg_input_kinds: number) {
      switch (flg_input_kinds) {
        case 1: {
          // 電話番号
          const tmp_value = this.d_client_data.itf_client_tel;
          if (tmp_value) {
            this.d_client_data.itf_client_tel = tmp_value.replace(
              /[^0-9]/g,
              ""
            );
          }
          break;
        }
        case 2: {
          // 郵便番号
          const tmp_value = this.d_client_data.itf_client_zipcode;
          if (tmp_value) {
            this.d_client_data.itf_client_zipcode = tmp_value.replace(
              /[^0-9]/g,
              ""
            );
          }
          break;
        }
      }
    },
  },
});
</script>
