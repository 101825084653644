<template>
  <div
    class="grid_main2_contents button_management_menu background_border_brueblack"
  >
    <GenericButton
      :id_name="id_name"
      :message="btton_message"
      v-on:click="logout()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GenericButton from "../components/GenericButton.vue";
import LocalData from "../util/LocalData";

export default defineComponent({
  // データ
  data() {
    return {
      id_name: "form_button_login",
      btton_message: "ログアウト",
    };
  },
  // コンポーネント
  components: {
    GenericButton,
  },
  // メソッド
  methods: {
    logout() {
      LocalData.delete();
      // 画面遷移_ログイン画面
      this.$router.push({
        name: "login",
      });
    },
  },
});
</script>

<style>
/* スタイル読み込み */
@import "../css/style.scss";
@import "../css/login.scss";
</style>
