<template>
  <!-- スタート画面 -->
  <div id="main_contents_start">
    <div id="main_title_img_start">
      <img src="../public/login_img_title.png" alt="タイトル" />
    </div>
    <div id="start_form">
      <!-- スタートボタン -->
      <router-link to="/login" id="button_start_text">スタート</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LocalData from "../util/LocalData";

export default defineComponent({
  name: "App",
  setup() {
    LocalData.delete();
  },
});
</script>

<style scoped>
@import "../css/style.scss";
@import "../css/start.scss";
</style>
