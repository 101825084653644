<template>
  <!-- ユーザー情報 -->
  <div id="userInfo">
    <div id="userInfo_hunter">
      <!-- ハンター情報 -->
      <div class="userInfo_contents">
        <div class="userInfo_userIcon">
          <div
            v-if="
              userIcon === '' || userIcon === 'undefined' || userIcon === null
            "
          ></div>
          <div v-else>
            <img :src="userIcon" alt="ハンターアイコン" />
          </div>
          <input
            ref="file"
            class="button_icon_select"
            type="file"
            @change="upload"
          />
        </div>
        <div class="userInfo-inner userInfo_userName userInfo_font12">
          名前 : {{ user_data.username }}
        </div>
        <div class="userInfo-inner userInfo_dia">
          <div id="userInfo_dia_inner">
            <div id="userInfo_dia_inner_img"></div>
            <div id="userInfo_dia_inner_text">{{ user_data.juel }}</div>
          </div>
        </div>
        <div
          class="userInfo-inner userInfo_guildName_guildlevel userInfo_font12"
        >
          <div id="userInfo_guildName">所属 : {{ user_data.guildname }}</div>
        </div>
        <div class="userInfo_guildLv">Lv.{{ user_data.guildlevel }}</div>
      </div>
      <!-- ハンターランク・レベル -->
      <div class="userRank">
        <div class="userRank_userBatch">
          <img src="@/public/00_guild_icon.png" alt="ハンターバッチ" />
        </div>
        <div class="userRank-inner userRank_userRank userInfo_font12">
          {{ user_data.hunterrunk }}級ハンター:Lv.{{ user_data.hunterlevel }}
        </div>
        <div class="userRank-inner2 userRank_userPoint">
          ハンターポイント:{{ user_data.hunterpoint }}
        </div>
        <div class="userRank-inner2 userRank_userPoint-bar">
          <progress id="userPoint_bar" value="50" max="100">0%</progress>
        </div>
        <div class="userRank_userPoint-count">
          次のレベルまで<br />あと{{ user_data.nextlevel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "../service/ApiService";
import store from "../vuex/store";

export default defineComponent({
  name: "App",
  // データ
  data() {
    return {
      // ユーザー情報
      user_data: {},
      // ★仮データ
      quest_data: {
        username: "田中ハント",
        juel: "999,999",
        guildname: "田中ギルド",
        guildlevel: "99",
        hunterrunk: "S",
        hunterlevel: "99",
        hunterpoint: "9,999",
        nextlevel: "1,000",
      },
      // ユーザーアイコン画像
      userIcon: "",
    };
  },
  mounted() {
    // 画面ロード時処理
    // 取得したデータをdataにセット
    this.user_data = this.quest_data;
    const st_hunter_data = JSON.parse(localStorage.getItem("hunter_data")!);
    const id_user_exp_point: HTMLInputElement = document.getElementById(
      "userPoint_bar"
    ) as HTMLInputElement;

    ApiService.getHunter(st_hunter_data.ls_hunter_id)
      .then((res) => {
        // 処理実行判定
        if (res.status === 200 && res.statusText === "OK") {
          // 正常終了の場合
          const data = res.data;
          (this.quest_data.username = data.hunter_name),
            (this.quest_data.guildname = data.guild_name
              ? data.guild_name
              : data.account_authority_id === 0
              ? "ハンター協会"
              : "ギルド未加入"),
            (this.quest_data.guildlevel =
              data.guild_level || this.quest_data.guildlevel),
            (this.quest_data.hunterrunk = data.hunter_rank),
            (this.quest_data.hunterlevel = data.hunter_level),
            (this.quest_data.hunterpoint = data.hunter_point),
            (this.quest_data.nextlevel = data.hunter_point_to_next_level),
            (this.quest_data.juel = data.hunter_diamond),
            (this.userIcon = data.hunter_icon_img);
          // 経験値バー_現在の経験値
          // 今まで取得した経験値の合計 - 前レベルになるまでに必要だった経験値の合計
          const now_hunter_exp_point =
            data.hunter_point - data.hunter_curr_level_start_point;
          id_user_exp_point.value = now_hunter_exp_point.toString();
          // 経験値バー_次のレベルまでに必要な経験値
          // 次のレベルまでに必要な経験値の合計 - 前レベルになるまでに必要だった経験値の合計
          const now_hunter_exp_point2 =
            data.hunter_next_level_point - data.hunter_curr_level_start_point;
          id_user_exp_point.max = now_hunter_exp_point2.toString();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  watch: {
    userIcon(newIcon, oldIcon) {
      // 画像を変更したら検知
    },
  },
  methods: {
    // アップロード処理
    async upload(event: any) {
      // 変数設定
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];

      // 画像データ変換とdataにセット処理
      // 画像ファイルのチェック判定
      if (this.checkFile(file)) {
        // 画像ファイルチェックOKの場合
        // 画像ファイルを「ファイル変換(エンコード)処理」で変更しdataセット
        this.getBase64(files[0]).then((imgDataBase64: any) => {
          this.userIcon = imgDataBase64;

          const user = {
            iconImg: this.userIcon,
          };
          const st_hunter_data = JSON.parse(
            localStorage.getItem("hunter_data")!
          );
          ApiService.putHunterIcon(st_hunter_data.ls_hunter_id, user)
            .then((res) => {
              // 登録成功
            })
            .catch((err) => {
              console.log(err.response.data.message);
            });
        });
      }
    },

    // アップロードファイルチェック処理
    checkFile(file: any) {
      let result = true; // 処理結果
      const SIZE_LIMIT = 5000000; // 最大ファイルサイズ(5MB)

      // ファイル取得結果判定
      if (!file) {
        // 取得できていない場合
        // 処理終了
        result = false;
      }

      // ファイルサイズ判定
      if (file && file.size > SIZE_LIMIT) {
        // 最大値を超えている場合
        // 処理終了
        result = false;
      }

      // 戻り値を返す
      return result;
    },

    // ファイル変換(エンコード)処理
    getBase64(file: any) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
});
</script>

<style>
@import "../css/Header.scss";
</style>
