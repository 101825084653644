<template>
  <!-- コンポーネント_ヘッダー_小 -->
  <div id="small_header">
    <div
      id="button_return"
      v-if="show_button_return"
      v-on:click="returntransition"
    ></div>
    <div id="title_small_header">
      <slot name="slot_small_header_title"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";

export default defineComponent({
  // プロップス
  props: {
    show_button_return: String,
  },
  emits: ["return-transition"],
  // メソッド
  methods: {
    // ボタンクリック処理_戻るボタン
    returntransition() {
      // モーダル呼び出し先の時処理呼び出し
      this.$emit("return-transition");
    },
  },
});
</script>

<style>
/* ヘッダー_全体 */
#small_header {
  width: 100%;
  height: 100%;
  background-image: url("../public/statusBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  white-space: nowrap;
}
/* タイトル_ヘッダー_小 */
#title_small_header {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* ボタン_戻る */
#button_return {
  width: 10%;
  max-width: 38px;
  max-height: 38px;
  background-image: url("../public/buttonOekaki_return.png");
  background-size: 100% 100%; /* 背景_サイズを要素幅に合わせて自動調節 */
  background-repeat: no-repeat; /* 背景_繰り返し表示をしない設定 */
  position: relative;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  display: block;
}
/* ボタン_戻る_サイズ縦横比維持設定 */
#button_return::before {
  content: "";
  display: block;
  padding-top: 100%;
}
</style>
