<template>
  <!-- ロード画面 -->
  <div id="main_load">
    <!-- アイコン -->
    <div id="load_icon_text">
      <img src="../public/load_icon.png" alt="ローディング" /><br />
      Loading...
    </div>
    <!-- ロード状況 -->
    <div id="load_status">
      Loading.<br />
      Loading..<br />
      Loading...<br />
    </div>
  </div>
</template>

<script lang="ts"></script>

<style scoped>
@import "../css/load.scss";
</style>
