<template>
  <!-- 依頼者編集削除画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- 画面タイトル -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>依頼者編集</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- メインタイトル -->
      <div class="page_title">
        <p>依頼者編集</p>
      </div>
      <!-- フォーム -->
      <form class="scroll_form" @submit.prevent>
        <!-- 依頼者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">依頼者情報</legend>
          <!-- 依頼者情報_お名前(漢字) -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_name"
              class="input_contents_label label_border_blue"
            >
              お名前(漢字)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">姓</span>
                <input
                  type="text"
                  name="f_client_name_last"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_client_name"
                  placeholder="田中"
                  tabindex="1"
                  maxlength="10"
                  v-model="d_client_data.itf_client_name_last"
                  @keyup="check_input_1(1)"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[0]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">名</span>
                <input
                  type="text"
                  name="f_client_name_first"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_client_name"
                  placeholder="太郎"
                  tabindex="2"
                  maxlength="10"
                  v-model="d_client_data.itf_client_name_first"
                  @keyup="check_input_1(2)"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[1]
              }}</label>
            </span>
          </p>
          <!-- 依頼者情報_お名前(よみがな) -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_name_kana"
              class="input_contents_label label_border_blue"
            >
              お名前(よみがな)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">せい</span>
                <input
                  type="text"
                  name="f_client_name_kana_last"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_client_name_kana"
                  placeholder="たなか"
                  tabindex="3"
                  maxlength="10"
                  v-model="d_client_data.itf_client_name_kana_last"
                  @keyup="check_input_1(3)"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[2]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">めい</span>
                <input
                  type="text"
                  name="f_client_name_kana_first"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_client_name_kana"
                  placeholder="たろう"
                  tabindex="4"
                  maxlength="10"
                  v-model="d_client_data.itf_client_name_kana_first"
                  @keyup="check_input_1(4)"
                />
              </span>
              <label class="err_message_red input_name">{{
                d_error_message[3]
              }}</label>
            </span>
          </p>
          <!-- 誕生日 -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_birthday"
              class="input_contents_label label_border_blue"
            >
              誕生日
            </label>
            <br />
            <input
              type="date"
              name="f_client_birthday"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_client_birthday"
              max="9999-12-31"
              tabindex="5"
              v-model="d_client_data.itf_client_birthday"
            />
          </p>
          <!-- 性別 -->
          <p class="f_fieldset_contents" id="guild_list">
            <label
              for="f_client_gender"
              class="input_contents_label label_border_blue"
            >
              性別
            </label>
            <span class="required_icon"></span>
            <br />
            <select v-model="gender_get_value" tabindex="6">
              <option
                v-for="genderlist in d_gender_list"
                v-bind:key="genderlist.itf_gender_id"
                :value="genderlist"
              >
                {{ genderlist.itf_gender }}
              </option>
            </select>
            <br />
            <label class="err_message_red">{{ d_error_message[4] }}</label>
          </p>
          <!-- 電話番号 -->
          <p class="f_fieldset_contents">
            <label
              for="f_client_tel"
              class="input_contents_label label_border_blue"
            >
              電話番号
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="tel"
              name="f_client_tel"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_client_tel"
              placeholder="08012345678"
              tabindex="7"
              maxlength="15"
              v-model="d_client_data.itf_client_tel"
              @keyup="check_input_1(5)"
            />
            <br />
            <label class="err_message_red">{{ d_error_message[5] }}</label>
            <label class="err_message_red">{{ d_error_message[10] }}</label>
          </p>
          <!-- 住所 -->
          <fieldset class="input_fieldset input_fieldset_1">
            <legend class="legend_style">住所</legend>
            <p class="f_fieldset_contents">
              <span class="f_fieldset_contents_position_row">
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_client_zipcode"
                    class="input_contents_label label_border_blue"
                  >
                    郵便番号
                    <span class="required_icon"></span>
                  </label>
                  <input
                    type="text"
                    name="f_client_zipcode"
                    class="f_input_text f_input_size_3 manage_registmodifydelete_f_input_text"
                    id="f_client_zipcode"
                    placeholder="9041234"
                    tabindex="8"
                    maxlength="7"
                    inputmode="numeric"
                    v-model="d_client_data.itf_client_zipcode"
                    @keyup="check_input_1(6)"
                  />
                  <label class="err_message_red">
                    {{ d_error_message[6] }}
                  </label>
                  <label class="err_message_red">
                    {{ d_error_message[9] }}
                  </label>
                </span>
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_client_prefecture"
                    class="input_contents_label label_border_blue"
                  >
                    都道府県
                  </label>
                  <select
                    v-model="prefecture_get_value"
                    tabindex="9"
                    class="prefecture"
                  >
                    <option
                      v-for="prefecture_list in d_prefecture_list"
                      v-bind:key="prefecture_list.itf_prefecture_id"
                      :value="prefecture_list"
                    >
                      {{ prefecture_list.itf_prefecture_name }}
                    </option>
                  </select>
                </span>
              </span>
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_client_address"
                class="input_contents_label label_border_blue"
              >
                市区町村・番地
              </label>
              <span class="required_icon"></span>
              <br />
              <input
                type="text"
                name="f_client_address"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_client_address"
                placeholder="沖縄市999番地"
                tabindex="10"
                maxlength="100"
                v-model="d_client_data.itf_client_address"
              />
              <br />
              <label class="err_message_red">
                {{ d_error_message[7] }}
              </label>
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_client_building"
                class="input_contents_label label_border_blue"
              >
                建物名・部屋番号
              </label>
              <br />
              <input
                type="text"
                name="f_client_building"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_client_building"
                placeholder="コーポ123"
                tabindex="11"
                maxlength="50"
                v-model="d_client_data.itf_client_building"
              />
            </p>
          </fieldset>
          <p class="f_fieldset_contents" id="guild_list">
            <label
              for="f_client_guild"
              class="input_contents_label label_border_blue"
            >
              登録ギルド
            </label>
            <span class="required_icon"></span>
            <br />
            <select v-model="d_client_data.itf_client_guild">
              <option
                v-for="guild_list in d_guild_list_data"
                v-bind:key="guild_list.id"
                :value="guild_list"
              >
                {{ guild_list.name }}
              </option>
            </select>
            <label class="err_message_red">
              {{ d_error_message[8] }}
            </label>
          </p>
        </fieldset>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_削除 -->
          <GenericButton
            :class_name="class_name"
            :message="message"
            v-on:click="delete_data_client()"
          />
          <!-- ボタン_更新 -->
          <GenericButton
            :class_name="class_name2"
            :message="message2"
            v-on:click="update_data_client()"
          />
        </div>
      </form>
    </div>

    <!-- モーダル画面 -->
    <ModalWindow
      modal_title="確認"
      :msg_1="
        '依頼者名 :' +
        d_client_data.itf_client_name_last +
        d_client_data.itf_client_name_first
      "
      msg_2="のデータを削除しますか?"
      button_no="キャンセル"
      button_yes="削除"
      v-if="flg_show_modal_delete"
      @modal-no="modalnodelete()"
      @modal-yes="modalyesdelete()"
    />
    <ModalWindow
      modal_title="削除完了"
      :msg_1="
        '依頼者名 :' +
        d_client_data.itf_client_name_last +
        d_client_data.itf_client_name_first
      "
      msg_2="を削除完了しました。"
      button_ok="OK"
      v-if="flg_show_modal_delete_comp"
      @modal-ok="modalnodeletecomp()"
    />
    <ModalWindow
      modal_title="削除失敗"
      :msg_1="
        '依頼者名 :' +
        d_client_data.itf_client_name_last +
        d_client_data.itf_client_name_first
      "
      msg_2="クエスト依頼中のため削除できません。"
      button_ok="OK"
      v-if="flg_show_modal_delete_failure"
      @modal-ok="modalnodeletefailure()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ModalWindow from "../components/Modal_2.vue";
import ApiService from "../service/ApiService";

/////////////////////////////
// interface(データ入れ物)
// 更新用データ_依頼者データ
/////////////////////////////
interface input_client_data {
  ////////////
  // 依頼者情報
  ////////////
  // 依頼者ID(画面非表示)
  itf_client_id: number;
  // お名前(漢字)_姓
  itf_client_name_last: string;
  // お名前(漢字)_名
  itf_client_name_first: string;
  // お名前(よみがな)_姓
  itf_client_name_kana_last: string;
  // お名前(よみがな)_名
  itf_client_name_kana_first: string;
  // 誕生日
  itf_client_birthday: string;
  // 電話番号
  itf_client_tel: string;
  // 住所(郵便番号)
  itf_client_zipcode: string;
  // 住所(市区町村・番地)
  itf_client_address: string;
  // 住所(建物名・部屋番号)
  itf_client_building: string;
  // 登録ギルド
  itf_client_guild: {
    id: number;
    name: string;
  };
}
interface ModifyClientData {
  client_item: input_client_data;
}

////////////////////////////////
// interface(データ入れ物)
// 性別ドロップダウン
////////////////////////////////
interface gender_data_catch {
  // 性別ID(画面非表示)
  itf_gender_id: number;
  // 性別
  itf_gender: string;
}
interface GenderData {
  gender_item: gender_data_catch[];
}
// 性別データ渡す用
interface GenderGetState {
  itf_gender_id: number;
  itf_gender: string;
}

////////////////////////////////
// interface(データ入れ物)
// 都道府県ドロップダウン
////////////////////////////////
interface prefecture_data_catch {
  // 県ID(画面非表示)
  itf_prefecture_id: number;
  // 県名
  itf_prefecture_name: string;
}
interface PrefectureData {
  prefecture_item: prefecture_data_catch[];
}
// 都道府県データ渡す用
interface PrefectureGetState {
  itf_prefecture_id: number;
  itf_prefecture_name: string;
}

// 登録ギルドリスト用データ
interface input_guild_list_data {
  // ギルドID(画面非表示)
  id: number;
  // ギルド名
  name: string;
}
interface ModifyGuildListData {
  guild_list_data: input_guild_list_data[];
}

export default defineComponent({
  // データ
  data() {
    ////////////////////////////////
    // 変数宣言
    ////////////////////////////////
    // エラーメッセージ用
    var error_message: string[] = new Array(8);

    ///////////////////////////////
    // データ設定処理
    // 更新用データ_依頼者データ
    ////////////////////////////////
    // reactive(データ監視)
    // 初期化_データ受取用
    var r_modify_client_data = reactive<ModifyClientData>({
      client_item: {
        itf_client_id: 0,
        itf_client_name_last: "",
        itf_client_name_first: "",
        itf_client_name_kana_last: "",
        itf_client_name_kana_first: "",
        itf_client_birthday: "",
        itf_client_tel: "",
        itf_client_zipcode: "",
        itf_client_address: "",
        itf_client_building: "",
        itf_client_guild: {
          id: 0,
          name: "",
        },
      },
    });
    const client_data = computed(() => r_modify_client_data.client_item);

    ////////////////////////////////
    // データ設定処理
    // 性別ドロップダウン
    ////////////////////////////////
    // reactive(データ監視)
    // 性別ドロップダウン
    var gender_list = reactive<GenderData>({
      gender_item: [],
    });
    const gender_data = computed(() => gender_list.gender_item);

    // 性別データ設定
    const genderdata = [
      {
        genderid: 1,
        gender: "男",
      },
      {
        genderid: 2,
        gender: "女",
      },
      {
        genderid: 3,
        gender: "その他",
      },
    ];

    // 性別データセット
    for (
      var i_gender_list = 0;
      i_gender_list < genderdata.length;
      i_gender_list++
    ) {
      gender_list.gender_item.push({
        // 性別ID(画面非表示)
        itf_gender_id: genderdata[i_gender_list].genderid,
        // 性別
        itf_gender: genderdata[i_gender_list].gender,
      });
    }

    ////////////////////////////////////////
    // 性別データ渡す用
    ////////////////////////////////////////
    var gender_get_value = reactive<GenderGetState>({
      itf_gender_id: 0,
      itf_gender: "",
    });

    ////////////////////////////////
    // データ取得処理
    // 都道府県ドロップダウン
    ////////////////////////////////
    // reactive(データ監視)
    // 都道府県ドロップダウン
    var prefecture_list = reactive<PrefectureData>({
      prefecture_item: [],
    });
    const prefecture_data = computed(() => prefecture_list.prefecture_item);

    // データ取得処理_都道府県一覧
    ApiService.getPrefectureData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const prefectures = res.data;
          for (var i = 0; i < prefectures.length; i++) {
            prefecture_list.prefecture_item.push({
              // 県ID(画面非表示)
              itf_prefecture_id: prefectures[i].id,
              // 県名
              itf_prefecture_name: prefectures[i].name,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    ////////////////////////////////////////
    // 都道府県データ渡す用
    ////////////////////////////////////////
    var prefecture_get_value = reactive<PrefectureGetState>({
      itf_prefecture_id: 0,
      itf_prefecture_name: "",
    });

    /////////////////////////////
    // データ取得処理
    // ギルド一覧(ドロップダウン用)
    /////////////////////////////
    // reactive(データ変更監視)
    // 所属ギルドリスト用データ
    var r_modify_guild_list_data = reactive<ModifyGuildListData>({
      guild_list_data: [],
    });
    const guild_list_data = computed(
      () => r_modify_guild_list_data.guild_list_data
    );

    // データ取得処理呼び出し
    ApiService.getGuildListData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const guildlist = res.data;
          for (var i = 0; i < guildlist.length; i++) {
            if (guildlist[i].status != 1) continue;
            r_modify_guild_list_data.guild_list_data.push({
              id: guildlist[i].guild_id,
              name: guildlist[i].guild_name,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /////////////////////////////
    // 表示データ設定処理
    // 確認画面から戻ってきたか確認
    /////////////////////////////
    if (localStorage.getItem("update_client_data")) {
      // 確認画面から戻ってきた場合
      ////////////
      // データ取得処理
      // 依頼者データ(ローカルストレージ)
      ////////////
      const st_regist_client_data = JSON.parse(
        localStorage.getItem("update_client_data")!
      );

      ////////////
      // データ設定
      ////////////
      r_modify_client_data.client_item.itf_client_name_last =
        st_regist_client_data.ls_client_last_name;
      r_modify_client_data.client_item.itf_client_name_first =
        st_regist_client_data.ls_client_first_name;
      r_modify_client_data.client_item.itf_client_name_kana_last =
        st_regist_client_data.ls_client_last_name_kana;
      r_modify_client_data.client_item.itf_client_name_kana_first =
        st_regist_client_data.ls_client_first_name_kana;
      r_modify_client_data.client_item.itf_client_birthday =
        st_regist_client_data.ls_client_birthday;
      gender_get_value.itf_gender_id =
        st_regist_client_data.ls_client_gender_id;
      gender_get_value.itf_gender = st_regist_client_data.ls_client_gender;
      r_modify_client_data.client_item.itf_client_tel =
        st_regist_client_data.ls_client_tel;
      r_modify_client_data.client_item.itf_client_zipcode =
        st_regist_client_data.ls_client_zipcode;
      prefecture_get_value.itf_prefecture_id =
        st_regist_client_data.ls_client_prefecture_id;
      prefecture_get_value.itf_prefecture_name =
        st_regist_client_data.ls_client_prefecture_name;
      r_modify_client_data.client_item.itf_client_address =
        st_regist_client_data.ls_client_address;
      r_modify_client_data.client_item.itf_client_building =
        st_regist_client_data.ls_client_building;
      r_modify_client_data.client_item.itf_client_guild.id =
        st_regist_client_data.ls_guild_id;
      r_modify_client_data.client_item.itf_client_guild.name =
        st_regist_client_data.ls_guild_name;
    } else {
      // 初期表示の場合
      ////////////
      // データ取得処理
      // 依頼者情報
      ////////////
      // 依頼者ID取得(ローカルストレージ)
      const st_client_data = JSON.parse(
        localStorage.getItem("hunter_client_data")!
      );
      const param_client_id = {
        client_id: st_client_data.ls_client_id,
      };

      // ギルドデータ(ローカルストレージ)
      const st_guild_data = JSON.parse(localStorage.getItem("guild_data")!);
      const param = {
        guildId: st_guild_data.ls_guild_id,
      };

      // データ取得処理呼び出し
      ApiService.getClientModifyData(param_client_id.client_id, param)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            const clients = res.data[0];
            r_modify_client_data.client_item.itf_client_id = clients.client_id;
            r_modify_client_data.client_item.itf_client_name_last =
              clients.client_last_name;
            r_modify_client_data.client_item.itf_client_name_first =
              clients.client_first_name;
            r_modify_client_data.client_item.itf_client_name_kana_last =
              clients.client_last_name_kana;
            r_modify_client_data.client_item.itf_client_name_kana_first =
              clients.client_first_name_kana;
            // 誕生日(フォーマット変換)
            let birthday_Y = new Date(clients.client_birthday).getFullYear();
            let birthday_M = new Date(clients.client_birthday).getMonth() + 1;
            let birthday_M2 = birthday_M.toString().padStart(2, "0");
            let individual_birthday_D = new Date(clients.client_birthday)
              .getDate()
              .toString()
              .padStart(2, "0");
            r_modify_client_data.client_item.itf_client_birthday =
              birthday_Y + "-" + birthday_M2 + "-" + individual_birthday_D;
            gender_get_value.itf_gender_id = Number(clients.client_gender);
            gender_get_value.itf_gender = clients.client_genderStr;
            r_modify_client_data.client_item.itf_client_tel =
              clients.client_tel;
            r_modify_client_data.client_item.itf_client_zipcode =
              clients.client_zipcode;
            prefecture_get_value.itf_prefecture_id = clients.prefecture_id;
            prefecture_get_value.itf_prefecture_name = clients.prefecture_name;
            r_modify_client_data.client_item.itf_client_address =
              clients.client_address;
            r_modify_client_data.client_item.itf_client_building =
              clients.client_building;
            r_modify_client_data.client_item.itf_client_guild.id =
              clients.guild_id;
            r_modify_client_data.client_item.itf_client_guild.name =
              clients.guild_name;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    ////////////////////////////////
    // data設定
    ////////////////////////////////
    return {
      // データ_登録更新削除表示対象
      d_client_data: client_data,
      // データ_性別ドロップダウン表示用
      d_gender_list: gender_data,
      // データ_都道府県データプルダウン表示用
      d_prefecture_list: prefecture_data,
      // エラーメッセージ用
      d_error_message: error_message,
      // 性別データ渡す用
      gender_get_value,
      // 都道府県データ渡す用
      prefecture_get_value,
      // ボタン設定
      class_name: "operation_button button_red",
      message: "削除",
      class_name2: "operation_button button_blue",
      message2: "更新",
      class_name3: "operation_button button_gray",
      // フラグ_表示非表示_削除確認モーダル
      flg_show_modal_delete: false,
      // フラグ_表示非表示_削除完了モーダル
      flg_show_modal_delete_comp: false,
      // フラグ_表示非表示_削除失敗モーダル_クエスト参加中
      flg_show_modal_delete_failure: false,
      // 登録ギルド_リスト用データ
      d_guild_list_data: guild_list_data,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // メソッド
  methods: {
    /////////////////////////////
    // ボタンクリック処理_ヘッダー戻るアイコン
    /////////////////////////////
    oyareturntrantransition() {
      // 画面遷移_依頼者管理(一覧)へ
      this.$router.push({
        name: "manageclientlist",
      });
    },
    /////////////////////////////
    // ボタンクリック処理_削除
    /////////////////////////////
    delete_data_client() {
      // モーダル表示_削除確認
      this.flg_show_modal_delete = true;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除確認_キャンセル
    /////////////////////////////
    modalnodelete() {
      // モーダル非表示_削除確認
      this.flg_show_modal_delete = false;
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除確認_削除
    /////////////////////////////
    modalyesdelete() {
      // モーダル非表示_削除確認
      this.flg_show_modal_delete = false;

      ////////////
      // データ削除処理
      // 依頼者削除
      ////////////
      // データ取得_ギルドデータ(ローカルストレージ)
      const st_guild_data = JSON.parse(localStorage.getItem("guild_data")!);
      const param = {
        guild_id: st_guild_data.ls_guild_id,
      };

      // データ取得_依頼者ID(ローカルストレージ)
      const st_client_data = JSON.parse(
        localStorage.getItem("hunter_client_data")!
      );

      // データ削除処理呼び出し
      ApiService.deleteDeleteClientData(st_client_data.ls_client_id, param)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // モーダル非表示_削除確認
            this.flg_show_modal_delete = false;
            // モーダル表示_削除完了
            this.flg_show_modal_delete_comp = true;
          }
        })
        .catch((err) => {
          // エラー表示
          console.log(err);
          // モーダル非表示_削除確認
          this.flg_show_modal_delete = false;
          // モーダル表示_削除失敗_参加中クエストあり
          this.flg_show_modal_delete_failure = true;
        });
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除完了
    /////////////////////////////
    modalnodeletecomp() {
      // モーダル非表示_削除完了
      this.flg_show_modal_delete_comp = false;

      // 画面遷移_依頼者管理(一覧)画面
      this.$router.push({ name: "manageclientlist" });
    },
    /////////////////////////////
    // ボタンクリック処理_モーダル_削除失敗_参加中クエストあり
    /////////////////////////////
    modalnodeletefailure() {
      // モーダル非表示_削除失敗
      this.flg_show_modal_delete_failure = false;
    },
    /////////////////////////////
    // ボタンクリック処理_更新ボタン
    /////////////////////////////
    update_data_client() {
      ////////////
      // 変数宣言と初期化
      ////////////
      // フラグ_エラー有無
      var flg_check = false;

      ////////////
      // 入力チェック
      ////////////
      // 入力チェック処理呼び出し
      flg_check = this.check_input();
      // 入力チェックエラーが有った場合
      // 処理終了
      if (flg_check) return;

      ////////////
      // ローカルストレージ保存
      ////////////
      // 処理呼び出し_ローカルストレージ保存処理
      this.set_input_storage();
      // 画面遷移_依頼者編集確認画面
      this.$router.push({ name: "manageclientmodifycheck" });
    },

    ////////////////////////////////
    // 入力チェック処理
    ////////////////////////////////
    check_input() {
      ////////////
      // 変数宣言と初期化
      ////////////
      // 戻り値用(フラグ_エラー有無)
      var result_check = false;
      // エラーメッセージ保存用
      this.d_error_message = new Array(9);

      ////////////
      // 入力チェック処理
      ////////////
      // 依頼者情報
      if (this.d_client_data.itf_client_name_last.length < 1)
        this.d_error_message[0] = "姓を入力してください";
      if (this.d_client_data.itf_client_name_first.length < 1)
        this.d_error_message[1] = "名を入力してください";
      if (this.d_client_data.itf_client_name_kana_last.length < 1)
        this.d_error_message[2] = "せいを入力してください";
      if (this.d_client_data.itf_client_name_kana_first.length < 1)
        this.d_error_message[3] = "めいを入力してください";
      if (this.gender_get_value.itf_gender == "")
        this.d_error_message[4] = "性別を選択してください";
      if (this.d_client_data.itf_client_tel.length < 1)
        this.d_error_message[5] = "電話番号を入力してください";
      if (this.d_client_data.itf_client_zipcode.length < 1)
        this.d_error_message[6] = "郵便番号を入力してください";
      if (this.d_client_data.itf_client_address.length < 1)
        this.d_error_message[7] = "市区町村・番地を入力してください";
      if (this.d_client_data.itf_client_guild.name.length < 1)
        this.d_error_message[8] = "登録ギルドを選択してください";
      if (this.d_client_data.itf_client_zipcode.length >= 1) {
        if (this.d_client_data.itf_client_zipcode.length < 7) {
          this.d_error_message[9] = "7桁で入力してください";
        }
      }
      if (this.d_client_data.itf_client_tel.length >= 1) {
        if (this.d_client_data.itf_client_tel.length < 8) {
          this.d_error_message[10] = "8～15桁で入力してください";
        }
      }

      ////////////
      // エラー存在チェック
      ////////////
      this.d_error_message.forEach((d_error_message) => {
        result_check = d_error_message.length > 0 ? true : false;
      });

      // 戻り値を返す
      return result_check;
    },

    ////////////////////////////////
    // ローカルストレージ保存処理
    // 入力項目
    ////////////////////////////////
    set_input_storage() {
      ////////////
      // ローカルストレージへデータ保存
      ////////////
      localStorage.setItem(
        "update_client_data",
        JSON.stringify({
          ls_client_last_name: this.d_client_data.itf_client_name_last,
          ls_client_first_name: this.d_client_data.itf_client_name_first,
          ls_client_last_name_kana:
            this.d_client_data.itf_client_name_kana_last,
          ls_client_first_name_kana:
            this.d_client_data.itf_client_name_kana_first,
          ls_client_birthday: this.d_client_data.itf_client_birthday,
          ls_client_gender_id: this.gender_get_value.itf_gender_id,
          ls_client_gender: this.gender_get_value.itf_gender,
          ls_client_tel: this.d_client_data.itf_client_tel,
          ls_client_zipcode: this.d_client_data.itf_client_zipcode,
          ls_client_prefecture_id: this.prefecture_get_value.itf_prefecture_id,
          ls_client_prefecture_name:
            this.prefecture_get_value.itf_prefecture_name,
          ls_client_address: this.d_client_data.itf_client_address,
          ls_client_building: this.d_client_data.itf_client_building,
          ls_guild_id: this.d_client_data.itf_client_guild.id,
          ls_guild_name: this.d_client_data.itf_client_guild.name,
        })
      );
    },

    ////////////////////////////////
    // 入力制御
    ////////////////////////////////
    check_input_1(flg_input_kinds: number) {
      switch (flg_input_kinds) {
        case 1: {
          // お名前(漢字)_姓
          const tmp_value = this.d_client_data.itf_client_name_last;
          if (tmp_value) {
            this.d_client_data.itf_client_name_last = tmp_value.replace(
              /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
              ""
            );
          }
          break;
        }
        case 2: {
          // お名前(漢字)_名
          const tmp_value = this.d_client_data.itf_client_name_first;
          if (tmp_value) {
            this.d_client_data.itf_client_name_first = tmp_value.replace(
              /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
              ""
            );
          }
          break;
        }
        case 3: {
          // お名前(よみがな)_せい
          const tmp_value = this.d_client_data.itf_client_name_kana_last;
          if (tmp_value) {
            this.d_client_data.itf_client_name_kana_last = tmp_value.replace(
              /[^ぁ-んー]/g,
              ""
            );
          }
          break;
        }
        case 4: {
          // お名前(よみがな)_めい
          const tmp_value = this.d_client_data.itf_client_name_kana_first;
          if (tmp_value) {
            this.d_client_data.itf_client_name_kana_first = tmp_value.replace(
              /[^ぁ-んー]/g,
              ""
            );
          }
          break;
        }
        case 5: {
          // 電話番号
          const tmp_value = this.d_client_data.itf_client_tel;
          if (tmp_value) {
            this.d_client_data.itf_client_tel = tmp_value.replace(
              /[^0-9]/g,
              ""
            );
          }
          break;
        }
        case 6: {
          // 郵便番号
          const tmp_value = this.d_client_data.itf_client_zipcode;
          if (tmp_value) {
            this.d_client_data.itf_client_zipcode = tmp_value.replace(
              /[^0-9]/g,
              ""
            );
          }
          break;
        }
      }
    },
  },
});
</script>

<style></style>
