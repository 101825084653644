<template>
  <!-- ギルド管理画面(ギルド一覧) -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ギルド管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear table_main">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>ギルド一覧</p>
      </div>
      <!-- テーブル -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table bordered"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          nextLabel: '次へ',
          prevLabel: '前へ',
          perPageDropdownEnabled: false,
          infoFn: (params) =>
            `${params.totalRecords} 件中 ${params.firstRecordOnPage} ～ ${params.lastRecordOnPage}`,
        }"
        :search-options="{
          enabled: true,
        }"
        @row-click="onRowClick"
      />
      <!-- ボタン_新規登録 -->
      <div class="vgt-registration-button">
        <GenericButton
          type="button"
          :class_name="class_name"
          :message="message"
          v-on:click="transition_guild_regist()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import { VueGoodTable } from "vue-good-table-next";
import ApiService from "../service/ApiService";
import GenericButton from "../components/GenericButton.vue";

// データ受取用
interface guild_data_catch {
  // ギルドID(画面非表示)
  itf_guild_id: number;
  // ギルド情報_ギルド名
  itf_guild_name: string;
  // 会社情報_施設名・社名
  itf_group_name: string;
  // 担当者情報_担当者名(漢字)_名字+名前(表示用)
  itf_manager_manager_name: string;
  // ギルド情報_利用状況
  itf_guild_Usage_situation: string;
}
interface guild_data {
  guild: guild_data_catch[];
}

export default defineComponent({
  // データ
  data() {
    //////////////////////
    // ギルドデータ取得処理
    //////////////////////
    const state = reactive<guild_data>({
      guild: [],
    });
    const guilds_list = computed(() => state.guild);
    // ギルドデータ取得処理
    ApiService.getGuildList()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const guilds = res.data;
          for (var i = 0; i < guilds.length; i++) {
            state.guild.push({
              // ギルドID(画面非表示)
              itf_guild_id: guilds[i].guild_guild_id,
              // ギルド情報_ギルド名
              itf_guild_name: guilds[i].guild_name,
              // 会社情報_施設名・社名
              itf_group_name: guilds[i].guild_operating,
              // 担当者情報_担当者名(漢字)_名字+名前(表示用)
              itf_manager_manager_name:
                guilds[i].account_last_name + guilds[i].account_first_name,
              // ギルド情報_利用状況
              itf_guild_Usage_situation: guilds[i].guild_statusStr,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // データ設定
    return {
      // 列ヘッダー
      columns: [
        {
          label: "ギルド名",
          field: "itf_guild_name",
        },
        {
          label: "施設名・社名",
          field: "itf_group_name",
        },
        {
          label: "担当者名",
          field: "itf_manager_manager_name",
        },
        {
          label: "ギルド利用状況",
          field: "itf_guild_Usage_situation",
        },
      ],
      // テーブルデータ
      rows: guilds_list,
      class_name: "operation_button button_black operation_button_quest_regist",
      message: "新規登録",
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    VueGoodTable,
    GenericButton,
  },
  // メソッド
  methods: {
    // テーブル行クリック時処理
    onRowClick(params: any) {
      // 画面遷移_ギルド編集画面
      this.$router.push({
        name: "manageguildmodify",
        query: { guildId: params.row.itf_guild_id },
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "management",
      });
    },
    // ボタンクリック処理_新規登録
    transition_guild_regist() {
      // 以前の更新データが残っている場合削除
      localStorage.removeItem("regist_guild_data");
      this.$router.push({
        name: "manageguildregister",
      });
    },
  },
});
</script>

<style></style>
