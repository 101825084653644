<template>
  <!-- ハンター登録確認画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ハンター管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>登録内容確認</p>
      </div>
      <!-- フォーム(表示) -->
      <form class="scroll_form">
        <!-- ハンター情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">ハンター情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              ハンター名
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_hunter_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              ログインID
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_id
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              パスワード
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_password
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              所属ギルド
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_guild_name
            }}</span>
          </p>
        </fieldset>
        <!-- 個人情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">個人情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              お名前(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_last_name +
              d_regist_check_data.ls_account_first_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              お名前(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_last_name_kana +
              d_regist_check_data.ls_account_first_name_kana
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">誕生日</span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_account_birthday
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">性別</span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_data.ls_account_gender_name"
            >
              {{ d_regist_check_data.ls_account_gender_name }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_regist_check_data.ls_account_gender_name"
            >
              未登録
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              メールアドレス
            </span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_data.ls_account_mail"
            >
              {{ d_regist_check_data.ls_account_mail }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_regist_check_data.ls_account_mail"
            >
              未登録
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              電話番号
            </span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_data.ls_account_tel"
            >
              {{ d_regist_check_data.ls_account_tel }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_regist_check_data.ls_account_tel"
            >
              未登録
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">住所</span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_data.ls_account_zipcode"
            >
              {{ "〒 " + d_regist_check_data.ls_account_zipcode }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="
                d_regist_check_data.ls_account_prefecture_name &&
                d_regist_check_data.ls_account_address
              "
            >
              {{
                d_regist_check_data.ls_account_prefecture_name +
                d_regist_check_data.ls_account_address
              }}
            </span>

            <span
              v-if="
                d_regist_check_data.ls_account_prefecture_name &&
                d_regist_check_data.ls_account_address
              "
            >
            </span>

            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_data.ls_account_building"
            >
              {{ d_regist_check_data.ls_account_building }}
            </span>

            <span
              class="f_fieldset_contents_value"
              v-if="
                !d_regist_check_data.ls_account_zipcode &&
                !d_regist_check_data.ls_account_prefecture_name &&
                !d_regist_check_data.ls_account_address &&
                !d_regist_check_data.ls_account_building
              "
            >
              未登録
            </span>
          </p>
        </fieldset>
        <!-- 保護者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">保護者情報</legend>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              お名前(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_parent_last_name +
              d_regist_check_data.ls_parent_first_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              お名前(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_parent_last_name_kana +
              d_regist_check_data.ls_parent_first_name_kana
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">誕生日</span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_data.ls_parent_birthday"
            >
              {{ d_regist_check_data.ls_parent_birthday }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_regist_check_data.ls_parent_birthday"
            >
              未登録
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">性別</span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_data.ls_parent_gender_name"
            >
              {{ d_regist_check_data.ls_parent_gender_name }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_regist_check_data.ls_parent_gender_name"
            >
              未登録
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              メールアドレス
            </span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_regist_check_data.ls_parent_mail"
            >
              {{ d_regist_check_data.ls_parent_mail }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_regist_check_data.ls_parent_mail"
            >
              未登録
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="show_contents_label label_border_blue">
              電話番号
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_regist_check_data.ls_parent_tel
            }}</span>
          </p>
        </fieldset>
        <div class="page_regist_message">
          <p>こちらの内容で登録しますか?</p>
        </div>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            v-on:click="return_transition()"
          />
          <!-- ボタン_登録 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            v-on:click="regist_data_hunter()"
          />
        </div>
      </form>
    </div>
    <ModalWindow
      modal_title="完了"
      :msg_1="'ハンター名 :' + d_regist_check_data.ls_hunter_name"
      msg_2="を登録しました"
      button_ok="OK"
      v-if="flg_show_modal_comp"
      @modal-ok="modalokoya()"
    />
    <ModalWindow
      modal_title="失敗"
      :msg_1="'ハンター名 :' + d_regist_check_data.ls_hunter_name"
      msg_2="は既に登録されています。"
      button_ok="OK"
      v-if="flg_show_modal_duplication"
      @modal-ok="modalDuplication()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";
import ModalWindow from "../components/Modal_2.vue";

export default defineComponent({
  // データ
  data() {
    // 登録データ取得
    const st_hunter_data = JSON.parse(
      localStorage.getItem("regist_hunter_data")!
    );

    return {
      class_name:
        "operation_button button_white manage_registmodifydelete_operationbutton",
      message: "戻る",
      class_name2:
        "operation_button button_blue manage_registmodifydelete_operationbutton",
      message2: "登録",
      d_regist_check_data: st_hunter_data,
      flg_show_modal_comp: false,
      flg_show_modal_duplication: false,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // メソッド
  methods: {
    // 戻るボタンクリック処理
    return_transition() {
      this.$router.push({
        name: "managehunterregister",
      });
    },
    // 登録ボタンクリック処理
    regist_data_hunter() {
      ApiService.putRegisterHunterData(this.d_regist_check_data)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // ApiService.getSendMailTargetByHunterName({
            //   hunter_name: this.d_regist_check_data.ls_hunter_name,
            // })
            //   .then((res) => {
            //     const data = res.data;
            //     if (res.status === 200) {
            //       ApiService.postMail({
            //         mail: this.d_regist_check_data.ls_account_mail,
            //         status: "20",
            //         login_id: this.d_regist_check_data.ls_account_id,
            //         guild_name: this.d_regist_check_data.ls_guild_name,
            //         hunter_name: this.d_regist_check_data.ls_hunter_name,
            //       });
            //     }
            //     if (data.length > 0) {
            //       ApiService.postMails({
            //         mail: data,
            //         status: "21",
            //         login_id: this.d_regist_check_data.ls_account_id,
            //         guild_name: this.d_regist_check_data.ls_guild_name,
            //         hunter_name: this.d_regist_check_data.ls_hunter_name,
            //       });
            //     }
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
            // モーダル表示_完了モーダル
            this.flg_show_modal_comp = true;
          } else {
            this.flg_show_modal_duplication = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // this.flg_show_modal_comp = true;
    },
    // ボタンクリック処理_モーダル_OK
    modalDuplication() {
      // モーダル非表示
      this.flg_show_modal_duplication = false;
    },
    // ボタンクリック処理_モーダル_OK
    modalokoya() {
      // モーダル非表示_完了モーダル
      this.flg_show_modal_comp = false;
      // 画面遷移_ログイン画面
      this.$router.push({
        name: "managehunterlist",
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "managehunterregister",
      });
    },
  },
});
</script>

<style></style>
