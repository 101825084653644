<template>
  <!-- 依頼者管理画面(依頼者一覧) -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー_小 -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>依頼者管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear table_main">
      <!-- タイトル -->
      <div class="page_title">
        <p>依頼者一覧</p>
      </div>
      <!-- テーブル -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        styleClass="vgt-table bordered"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          nextLabel: '次へ',
          prevLabel: '前へ',
          perPageDropdownEnabled: false,
          infoFn: (params) =>
            `${params.totalRecords} 件中 ${params.firstRecordOnPage} ～ ${params.lastRecordOnPage}`,
        }"
        :search-options="{
          enabled: true,
        }"
        @row-click="onRowClick"
      />
      <!-- ボタン_新規登録 -->
      <div class="vgt-registration-button">
        <GenericButton
          type="button"
          :class_name="class_name"
          :message="message"
          v-on:click="transition_hunter_regist()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import { VueGoodTable } from "vue-good-table-next";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";

/////////////////////////////
// interface(データ入れ物)
// 表示用データ(テーブル)_依頼者一覧データ
/////////////////////////////
interface clientlist_data_catch {
  // 依頼者ID(画面非表示)
  itf_client_id: number;
  // 依頼者氏名
  itf_client_name: string;
  // 依頼者氏名(ふりがな)
  itf_client_name_kana: string;
  // 住所(都道府県)
  itf_client_prefecture: string;
  // 電話番号
  itf_client_tel: string;
  // 登録ギルド
  itf_client_registerguild: string;
}
interface ClientlistState {
  clientlist_item: clientlist_data_catch[];
}

export default defineComponent({
  // データ
  data() {
    //////////////////////
    // データ取得処理
    // 依頼者一覧データ
    //////////////////////
    // reactive(データ監視)
    // 表示用データ(テーブル)_依頼者一覧データ
    const clientlist_list = reactive<ClientlistState>({
      clientlist_item: [],
    });
    const lientlist_data = computed(() => clientlist_list.clientlist_item);

    /////////////////////////////
    // データ取得(ローカルストレージ)
    /////////////////////////////
    // ギルドデータ
    const st_guild_data = JSON.parse(localStorage.getItem("guild_data")!);
    const param = {
      guildId: st_guild_data.ls_guild_id,
    };

    // データ取得処理呼び出し
    ApiService.getClientList(param)
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const clients = res.data;
          for (var i = 0; i < clients.length; i++) {
            clientlist_list.clientlist_item.push({
              itf_client_id: clients[i].client_id,
              itf_client_name:
                clients[i].client_last_name + clients[i].client_first_name,
              itf_client_name_kana:
                clients[i].client_last_name_kana +
                clients[i].client_first_name_kana,
              itf_client_prefecture: clients[i].prefecture_name,
              itf_client_tel: clients[i].client_tel,
              itf_client_registerguild: clients[i].guild_name,
            });
          }
        }
      })
      .catch((err) => {
        // エラー表示
        console.log(err);
      });

    // データ設定
    return {
      // テーブル_列先頭
      columns: [
        {
          label: "氏名",
          field: "itf_client_name",
        },
        {
          label: "氏名(ふりがな)",
          field: "itf_client_name_kana",
        },
        {
          label: "住所",
          field: "itf_client_prefecture",
        },
        {
          label: "電話番号",
          field: "itf_client_tel",
        },
        {
          label: "登録ギルド",
          field: "itf_client_registerguild",
        },
      ],
      // テーブル_データ
      rows: lientlist_data,
      // ボタン設定_新規登録
      class_name: "operation_button button_black operation_button_quest_regist",
      message: "新規登録",
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    VueGoodTable,
    GenericButton,
  },
  // メソッド
  methods: {
    /////////////////////////////
    // テーブル行クリック時処理
    /////////////////////////////
    onRowClick(params: any) {
      // ローカルストレージにデータ保存
      localStorage.setItem(
        "hunter_client_data",
        JSON.stringify({
          ls_client_id: params.row.itf_client_id,
        })
      );
      // 以前の更新データが残っている場合削除
      localStorage.removeItem("update_client_data");
      // 画面遷移_依頼者編集削除画面
      this.$router.push({
        name: "manageclientmodify",
      });
    },
    /////////////////////////////
    // ボタンクリック処理_戻るアイコン
    /////////////////////////////
    oyareturntrantransition() {
      // 画面遷移_前画面
      this.$router.push({
        name: "management",
      });
    },
    /////////////////////////////
    // ボタンクリック処理_新規登録
    /////////////////////////////
    transition_hunter_regist() {
      // 以前のデータが残っている場合削除
      localStorage.removeItem("regist_client_data");
      // 画面遷移_依頼者新規登録画面
      this.$router.push({
        name: "manageclientregister",
      });
    },
  },
});
</script>
