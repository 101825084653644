<template>
  <!-- ハンター登録画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ハンター管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>ハンター登録</p>
      </div>
      <!-- フォーム(入力) -->
      <form class="scroll_form">
        <!-- ハンター情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">ハンター情報</legend>
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_name"
              class="input_contents_label label_border_blue"
            >
              ハンター名
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_hunter_name"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_hunter_name"
              placeholder="ハンター田中"
              tabindex="1"
              maxlength="13"
              @keyup="check_hunter_name"
              v-model="d_hunter_data.itf_hunter_hunter_name"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[0] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_login_id"
              class="input_contents_label label_border_blue"
            >
              ログインID
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_hunter_login_id"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_hunter_login_id"
              placeholder="tanaka01"
              tabindex="2"
              maxlength="8"
              @keyup="check_hunter_login_id"
              v-model="d_hunter_data.itf_hunter_login_id"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[1] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_password"
              class="input_contents_label label_border_blue"
            >
              パスワード
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_hunter_password"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_hunter_password"
              tabindex="3"
              maxlength="12"
              @keyup="check_hunter_password"
              v-model="d_hunter_data.itf_hunter_password"
            />
            <label class="err_message_red">{{ errorMessage[2] }}<br /></label>
            <label class="err_message_red">{{ errorMessage[17] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_hunter_guild"
              class="input_contents_label label_border_blue"
            >
              所属ギルド
            </label>
            <span v-if="isHunterAsscociation" class="required_icon"></span>
            <br />
            <select
              v-if="isHunterAsscociation"
              v-model="d_hunter_data.itf_hunter_guild"
            >
              <option
                v-for="guild_list in d_guild_list"
                v-bind:key="guild_list.itf_guildlist_guild_id"
                :value="guild_list"
              >
                {{ guild_list.itf_guildlist_guild_name }}
              </option>
            </select>
            <span v-else class="f_fieldset_contents_value">
              {{ d_hunter_data.itf_hunter_guild.itf_guildlist_guild_name }}
            </span>
            <label class="err_message_red">{{ errorMessage[18] }}</label>
          </p>
        </fieldset>
        <!-- 個人情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">個人情報</legend>
          <p class="f_fieldset_contents">
            <label
              for="f_individual_name"
              class="input_contents_label label_border_blue"
            >
              お名前(漢字)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">姓</span>
                <input
                  type="text"
                  name="f_individual_name"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_individual_name"
                  placeholder="田中"
                  tabindex="5"
                  maxlength="10"
                  @keyup="check_individual_name_last"
                  v-model="d_hunter_data.itf_individual_name_last"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[4]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">名</span>
                <input
                  type="text"
                  name="f_individual_name"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_individual_name"
                  placeholder="太郎"
                  tabindex="6"
                  maxlength="10"
                  @keyup="check_individual_name_first"
                  v-model="d_hunter_data.itf_individual_name_first"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[5]
              }}</label>
            </span>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_individual_name_kana"
              class="input_contents_label label_border_blue"
            >
              お名前(よみがな)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">せい</span>
                <input
                  type="text"
                  name="f_individual_name_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_individual_name_kana"
                  placeholder="たなか"
                  tabindex="7"
                  maxlength="10"
                  @keyup="check_individual_name_last_kana"
                  v-model="d_hunter_data.itf_individual_name_last_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[6]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">めい</span>
                <input
                  type="text"
                  name="f_individual_name_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_individual_name_kana"
                  placeholder="たろう"
                  tabindex="8"
                  maxlength="10"
                  @keyup="check_individual_name_first_kana"
                  v-model="d_hunter_data.itf_individual_name_first_kana"
                />
              </span>
            </span>
            <label class="err_message_red input_name">{{
              errorMessage[7]
            }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_individual_birthday"
              class="input_contents_label label_border_blue"
            >
              誕生日
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="date"
              name="f_individual_birthday"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_individual_birthday"
              max="9999-12-31"
              tabindex="9"
              v-model="d_hunter_data.itf_individual_birthday"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[8] }}</label>
          </p>
          <p class="f_fieldset_contents" id="guild_list">
            <label
              for="f_gender_individual"
              class="input_contents_label label_border_blue"
            >
              性別
            </label>
            <br />
            <select v-model="gender_get_value_individual" tabindex="10">
              <option></option>
              <option
                v-for="genderlist in d_gender_list"
                v-bind:key="genderlist.itf_gender_id"
                :value="genderlist"
              >
                {{ genderlist.itf_gender }}
              </option>
            </select>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_individual_mail"
              class="input_contents_label label_border_blue"
            >
              メールアドレス
            </label>
            <br />
            <input
              type="email"
              name="f_individual_mail"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_individual_mail"
              placeholder="mailadress123@example.com"
              tabindex="11"
              maxlength="50"
              @keyup="check_individual_mail"
              v-model="d_hunter_data.itf_individual_mail"
            />
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_individual_tel"
              class="input_contents_label label_border_blue"
            >
              電話番号
            </label>
            <br />
            <input
              type="tel"
              name="f_individual_tel"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_individual_tel"
              placeholder="08012345678"
              tabindex="12"
              maxlength="15"
              @keyup="check_individual_tel"
              v-model="d_hunter_data.itf_individual_tel"
            />
            <br />
            <label class="err_message_red">
              {{ errorMessage[20] }}
            </label>
          </p>
          <fieldset class="input_fieldset input_fieldset_1">
            <legend class="legend_style">住所</legend>
            <p class="f_fieldset_contents">
              <span class="f_fieldset_contents_position_row">
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_individual_zipcode"
                    class="input_contents_label label_border_blue"
                  >
                    郵便番号
                  </label>
                  <input
                    type="text"
                    name="f_individual_zipcode"
                    class="f_input_text f_input_size_3 manage_registmodifydelete_f_input_text"
                    id="f_individual_zipcode"
                    placeholder="9041234"
                    tabindex="13"
                    maxlength="7"
                    inputmode="numeric"
                    @keyup="check_individual_zipcode"
                    v-model="d_hunter_data.itf_individual_zipcode"
                  />
                  <label class="err_message_red">
                    {{ errorMessage[19] }}
                  </label>
                </span>
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_individual_prefecture"
                    class="input_contents_label label_border_blue"
                  >
                    都道府県
                  </label>
                  <select
                    v-model="prefecture_get_value"
                    tabindex="14"
                    class="prefecture"
                  >
                    <option></option>
                    <option
                      v-for="fruit in prefecture_list"
                      v-bind:key="fruit.itf_prefecture_id"
                      :value="fruit"
                    >
                      {{ fruit.itf_prefecture_name }}
                    </option>
                  </select>
                </span>
              </span>
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_individual_address"
                class="input_contents_label label_border_blue"
              >
                市区町村・番地
              </label>
              <br />
              <input
                type="text"
                name="f_individual_address"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_individual_address"
                placeholder="沖縄市999番地"
                tabindex="15"
                maxlength="100"
                v-model="d_hunter_data.itf_individual_address"
              />
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_individual_building"
                class="input_contents_label label_border_blue"
              >
                建物名・部屋番号
              </label>
              <br />
              <input
                type="text"
                name="f_individual_building"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_individual_building"
                placeholder="コーポ123"
                tabindex="16"
                maxlength="50"
                v-model="d_hunter_data.itf_individual_building"
              />
            </p>
          </fieldset>
        </fieldset>
        <!-- 保護者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">保護者情報</legend>
          <p class="f_fieldset_contents">
            <label
              for="f_parents_name"
              class="input_contents_label label_border_blue"
            >
              お名前(漢字)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">姓</span>
                <input
                  type="text"
                  name="f_parents_name"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_parents_name"
                  placeholder="田中"
                  tabindex="17"
                  maxlength="10"
                  @keyup="check_parents_name_last"
                  v-model="d_hunter_data.itf_parents_name_last"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[11]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">名</span>
                <input
                  type="text"
                  name="f_parents_name"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_parents_name"
                  placeholder="信長"
                  tabindex="18"
                  maxlength="10"
                  @keyup="check_parents_name_first"
                  v-model="d_hunter_data.itf_parents_name_first"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[12]
              }}</label>
            </span>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_parents_name_kana"
              class="input_contents_label label_border_blue"
            >
              お名前(よみがな)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">せい</span>
                <input
                  type="text"
                  name="f_parents_name_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_parents_name_kana"
                  placeholder="たなか"
                  tabindex="19"
                  maxlength="10"
                  @keyup="check_parents_name_last_kana"
                  v-model="d_hunter_data.itf_parents_name_last_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[13]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">めい</span>
                <input
                  type="text"
                  name="f_parents_name_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_parents_name_kana"
                  placeholder="のぶなが"
                  tabindex="20"
                  maxlength="10"
                  @keyup="check_parents_name_first_kana"
                  v-model="d_hunter_data.itf_parents_name_first_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[14]
              }}</label>
            </span>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_parents_birthday"
              class="input_contents_label label_border_blue"
            >
              誕生日
            </label>
            <br />
            <input
              type="date"
              name="f_parents_birthday"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_parents_birthday"
              max="9999-12-31"
              tabindex="21"
              v-model="d_hunter_data.itf_parents_birthday"
            />
          </p>
          <p class="f_fieldset_contents" id="guild_list">
            <label
              for="f_parents_gender"
              class="input_contents_label label_border_blue"
            >
              性別
            </label>
            <br />
            <select v-model="gender_get_value_parents" tabindex="22">
              <option></option>
              <option
                v-for="genderlist in d_gender_list"
                v-bind:key="genderlist.itf_gender_id"
                :value="genderlist"
              >
                {{ genderlist.itf_gender }}
              </option>
            </select>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_parents_mail"
              class="input_contents_label label_border_blue"
            >
              メールアドレス
            </label>
            <br />
            <input
              type="email"
              name="f_parents_mail"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_parents_mail"
              placeholder="mailadress123@example.com"
              tabindex="23"
              maxlength="50"
              @keyup="check_parents_mail"
              v-model="d_hunter_data.itf_parents_mail"
            />
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_parents_tel"
              class="input_contents_label label_border_blue"
            >
              電話番号
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="tel"
              name="f_parents_tel"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_parents_tel"
              placeholder="09012345678"
              tabindex="24"
              maxlength="15"
              @keyup="check_parents_tel"
              v-model="d_hunter_data.itf_parents_tel"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[16] }}</label>
            <label class="err_message_red">{{ errorMessage[21] }}</label>
          </p>
        </fieldset>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            v-on:click="return_transition()"
          />
          <!-- ボタン_確認 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            v-on:click="confirmation_data_hunter()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";

///////////////////////////
// データ受取用_ハンター情報
///////////////////////////
interface hunter_data_catch {
  //////////////
  // ハンター情報
  //////////////
  // ハンター名
  itf_hunter_hunter_name: string;
  // ハンターID(画面非表示)
  itf_hunter_hunter_id: number;
  // ログインID
  itf_hunter_login_id: string;
  // パスワード
  itf_hunter_password: string;
  // ギルド情報
  itf_hunter_guild: guildlist_data_catch;
  //////////////
  // 個人情報
  //////////////
  // 名前(漢字)_名字
  itf_individual_name_last: string;
  // 名前(漢字)_名前
  itf_individual_name_first: string;
  // 名前(よみがな)_名字
  itf_individual_name_last_kana: string;
  // 名前(よみがな)_名前
  itf_individual_name_first_kana: string;
  // 生年月日
  itf_individual_birthday: string;
  // 性別
  itf_individual_gender: number;
  // メールアドレス
  itf_individual_mail: string;
  // 電話番号
  itf_individual_tel: string;
  // 住所(郵便番号)
  itf_individual_zipcode: string;
  // 住所(市区町村・番地)
  itf_individual_address: string;
  // 住所(建物名・部屋番号)
  itf_individual_building: string;
  //////////////
  // 保護者情報
  //////////////
  // 名前(漢字)_名字
  itf_parents_name_last: string;
  // 名前(漢字)_名前
  itf_parents_name_first: string;
  // 名前(よみがな)_名字
  itf_parents_name_last_kana: string;
  // 名前(よみがな)_名前
  itf_parents_name_first_kana: string;
  // 生年月日
  itf_parents_birthday: string;
  // 性別
  itf_parents_gender: number;
  // メールアドレス
  itf_parents_mail: string;
  // 電話番号
  itf_parents_tel: string;
}
interface HunterState {
  hunter_item: hunter_data_catch;
}

////////////////////////////////////////
// データ受取用_ギルド一覧ドロップダウン用
////////////////////////////////////////
interface guildlist_data_catch {
  // ギルドID(画面非表示)
  itf_guildlist_guild_id: number;
  // ギルド名
  itf_guildlist_guild_name: string;
}
interface GuildListState {
  guildlist_item: guildlist_data_catch[];
}

////////////////////////////////////////
// 性別ドロップダウン用
////////////////////////////////////////
interface gender_data_catch {
  // ギルドID(画面非表示)
  itf_gender_id: number;
  // ギルド名
  itf_gender: string;
}
interface GenderState {
  gender_item: gender_data_catch[];
}

////////////////////////////////////////
// データ受取用_都道府県一覧
////////////////////////////////////////
interface prefecture_data_catch {
  // 県ID(画面非表示)
  itf_prefecture_id: number;
  // 県名
  itf_prefecture_name: string;
}
interface PrefectureState {
  prefecture_data: prefecture_data_catch[];
}

export default defineComponent({
  // データ
  data() {
    // ハンター協会管理者フラグ
    let isHunterAsscociation = false;
    const cookie: string = document.cookie;
    const cookiesArray: string[] = cookie.split(";");
    for (let c of cookiesArray) {
      const cArray: string[] = c.split("=");
      cArray[0] = cArray[0].trim();
      if (cArray[0] === "authority-id") {
        isHunterAsscociation = cArray[1] == "0";
      }
    }

    // ギルド情報
    const itf_hunter_guild: guildlist_data_catch = {
      itf_guildlist_guild_id: 0,
      itf_guildlist_guild_name: "",
    };

    // ローカルストレージより自分のギルドID取得
    let user_guild_id = 0;
    if (localStorage.getItem("guild_data")) {
      user_guild_id = Number(
        JSON.parse(localStorage.getItem("guild_data")!).ls_guild_id
      );
    }

    let first_prefecture_id = 0;
    let first_prefecture_name = "";
    let first_account_gender_id = 0;
    let first_account_gender_name = "";
    let first_parent_gender_id = 0;
    let first_parent_gender_name = "";
    // エラーメッセージ用
    var error_message: string[] = new Array(16);

    // 初期化_データ受取用
    var hunter = reactive<HunterState>({
      hunter_item: {
        itf_hunter_hunter_name: "",
        itf_hunter_hunter_id: 0,
        itf_hunter_login_id: "",
        itf_hunter_password: "",
        itf_hunter_guild: itf_hunter_guild,
        itf_individual_name_last: "",
        itf_individual_name_first: "",
        itf_individual_name_last_kana: "",
        itf_individual_name_first_kana: "",
        itf_individual_birthday: "",
        itf_individual_gender: 0,
        itf_individual_mail: "",
        itf_individual_tel: "",
        itf_individual_zipcode: "",
        itf_individual_address: "",
        itf_individual_building: "",
        itf_parents_name_last: "",
        itf_parents_name_first: "",
        itf_parents_name_last_kana: "",
        itf_parents_name_first_kana: "",
        itf_parents_birthday: "",
        itf_parents_gender: 0,
        itf_parents_mail: "",
        itf_parents_tel: "",
      },
    });
    const hunter_datta = computed(() => hunter.hunter_item);

    ///////////////////////////////
    // 登録確認から戻ってきた場合処理
    ///////////////////////////////
    // 登録データ存在確認
    if (localStorage.getItem("regist_hunter_data")) {
      // 既にデータが存在する場合(登録確認から戻ってきた場合)
      // データ取得
      const st_hunter_data = JSON.parse(
        localStorage.getItem("regist_hunter_data")!
      );
      hunter.hunter_item.itf_hunter_hunter_name = st_hunter_data.ls_hunter_name;
      hunter.hunter_item.itf_hunter_login_id = st_hunter_data.ls_account_id;
      hunter.hunter_item.itf_hunter_password =
        st_hunter_data.ls_account_password;
      hunter.hunter_item.itf_individual_name_last =
        st_hunter_data.ls_account_last_name;
      hunter.hunter_item.itf_individual_name_last =
        st_hunter_data.ls_account_last_name;
      hunter.hunter_item.itf_individual_name_first =
        st_hunter_data.ls_account_first_name;
      hunter.hunter_item.itf_individual_name_last_kana =
        st_hunter_data.ls_account_last_name_kana;
      hunter.hunter_item.itf_individual_name_first_kana =
        st_hunter_data.ls_account_first_name_kana;
      hunter.hunter_item.itf_individual_birthday =
        st_hunter_data.ls_account_birthday;

      hunter.hunter_item.itf_individual_gender =
        st_hunter_data.ls_account_gender;
      hunter.hunter_item.itf_individual_mail = st_hunter_data.ls_account_mail;
      hunter.hunter_item.itf_individual_tel = st_hunter_data.ls_account_tel;
      hunter.hunter_item.itf_individual_zipcode =
        st_hunter_data.ls_account_zipcode;
      hunter.hunter_item.itf_individual_address =
        st_hunter_data.ls_account_address;
      hunter.hunter_item.itf_individual_building =
        st_hunter_data.ls_account_building;
      hunter.hunter_item.itf_parents_name_last =
        st_hunter_data.ls_parent_last_name;
      hunter.hunter_item.itf_parents_name_first =
        st_hunter_data.ls_parent_first_name;
      hunter.hunter_item.itf_parents_name_last_kana =
        st_hunter_data.ls_parent_last_name_kana;
      hunter.hunter_item.itf_parents_name_first_kana =
        st_hunter_data.ls_parent_first_name_kana;
      hunter.hunter_item.itf_parents_birthday =
        st_hunter_data.ls_parent_birthday;
      hunter.hunter_item.itf_parents_gender = st_hunter_data.ls_parent_gender;
      hunter.hunter_item.itf_parents_mail = st_hunter_data.ls_parent_mail;
      hunter.hunter_item.itf_parents_tel = st_hunter_data.ls_parent_tel;
      hunter.hunter_item.itf_hunter_guild.itf_guildlist_guild_id =
        st_hunter_data.ls_guild_id;
      hunter.hunter_item.itf_hunter_guild.itf_guildlist_guild_name =
        st_hunter_data.ls_guild_name;

      first_prefecture_id = st_hunter_data.ls_account_prefecture_id;
      first_prefecture_name = st_hunter_data.ls_account_prefecture_name;
      first_account_gender_id = st_hunter_data.ls_account_gender_id;
      first_account_gender_name = st_hunter_data.ls_account_gender_name;
      first_parent_gender_id = st_hunter_data.ls_parent_gender_id;
      first_parent_gender_name = st_hunter_data.ls_parent_gender_name;
    }

    ////////////////////////////////
    // ギルド一覧取得(ドロップダウン用)
    ////////////////////////////////
    // リスト選択から固定表示に変更のためコメントアウト。念のためコメントとして残しておく 2022/09/26
    // 初期化_データ受取用
    var guild_list = reactive<GuildListState>({
      guildlist_item: [],
    });
    const guildlist_item = computed(() => guild_list.guildlist_item);

    //////////////////////
    // 都道府県一覧取得処理
    //////////////////////
    // 初期化_データ受取用
    var c_prefecture_data = reactive<PrefectureState>({
      prefecture_data: [],
    });
    const prefecture_data = computed(() => c_prefecture_data.prefecture_data);

    // 都道府県一覧取得処理
    ApiService.getPrefectureData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const prefectures = res.data;
          for (var i = 0; i < prefectures.length; i++) {
            c_prefecture_data.prefecture_data.push({
              // 県ID(画面非表示)
              itf_prefecture_id: prefectures[i].id,
              // 県名
              itf_prefecture_name: prefectures[i].name,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    ////////////////////////////////
    // 性別ドロップダウン表示用
    ////////////////////////////////
    // 初期化_データ受取用
    var gender_list = reactive<GenderState>({
      gender_item: [],
    });
    const gender_data = computed(() => gender_list.gender_item);

    // 性別データ
    const genderdata = [
      {
        genderid: 1,
        gender: "男",
      },
      {
        genderid: 2,
        gender: "女",
      },
      {
        genderid: 3,
        gender: "その他",
      },
    ];

    const st_guild_data = JSON.parse(localStorage.getItem("guild_data")!);

    // 全ギルド名一覧取得処理
    ApiService.getGuildListData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const guilds = res.data;
          for (var i = 0; i < guilds.length; i++) {
            if (guilds[i].status != 1) continue;
            guild_list.guildlist_item.push({
              // ギルドID(画面非表示)
              itf_guildlist_guild_id: guilds[i].guild_id,
              // ギルド名
              itf_guildlist_guild_name: guilds[i].guild_name,
            });
            if (
              !isHunterAsscociation &&
              user_guild_id > 0 &&
              guilds[i].guild_id === user_guild_id
            ) {
              hunter.hunter_item.itf_hunter_guild.itf_guildlist_guild_id =
                guilds[i].guild_id;
              hunter.hunter_item.itf_hunter_guild.itf_guildlist_guild_name =
                guilds[i].guild_name;
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // 性別プルダウン設定処理
    for (var i2 = 0; i2 < genderdata.length; i2++) {
      gender_list.gender_item.push({
        // 性別ID(画面非表示)
        itf_gender_id: genderdata[i2].genderid,
        // 性別
        itf_gender: genderdata[i2].gender,
      });
    }

    return {
      // データ
      d_hunter_data: hunter_datta,
      // 都道府県データプルダウン表示用
      prefecture_list: prefecture_data,
      // ギルド一覧プルダウン表示用
      // リスト選択から固定表示に変更のためコメントアウト。念のためコメントとして残しておく 2022/09/26
      d_guild_list: guildlist_item,
      // 性別プルダウン表示用
      d_gender_list: gender_data,
      // 都道府県データ渡す用
      prefecture_get_value: {
        itf_prefecture_id: first_prefecture_id,
        itf_prefecture_name: first_prefecture_name,
      },
      // リスト選択から固定表示に変更のためコメントアウト。念のためコメントとして残しておく 2022/09/26
      // 所属ギルドデータ渡す用
      // guild_get_value: {
      //   itf_guildlist_guild_id: first_guild_id,
      //   itf_guildlist_guild_name: first_guild_name,
      // },
      // 性別データ渡す用_個人
      gender_get_value_individual: {
        itf_gender_id: first_account_gender_id,
        itf_gender: first_account_gender_name,
      },
      // 性別データ渡す用_保護者
      gender_get_value_parents: {
        itf_gender_id: first_parent_gender_id,
        itf_gender: first_parent_gender_name,
      },
      class_name:
        "operation_button button_white manage_registmodifydelete_operationbutton",
      message: "戻る",
      class_name2:
        "operation_button button_black manage_registmodifydelete_operationbutton",
      message2: "確認",
      errorMessage: error_message,
      isHunterAsscociation: isHunterAsscociation,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
  },
  // メソッド
  methods: {
    // 戻るボタンクリック処理
    return_transition() {
      this.$router.push({
        name: "managehunterlist",
      });
    },
    // 確認ボタンクリック処理
    confirmation_data_hunter() {
      //////////////////
      // 入力チェック処理
      //////////////////
      this.errorMessage = new Array(21);
      // ハンター情報
      if (this.d_hunter_data.itf_hunter_hunter_name.length < 1)
        this.errorMessage[0] = "ハンター名を入力してください";
      if (this.d_hunter_data.itf_hunter_login_id.length < 1)
        this.errorMessage[1] = "ログインIDを入力してください";
      const regPass = new RegExp(/^[A-Za-z0-9]{4,}/);
      if (this.d_hunter_data.itf_hunter_password.length < 1)
        this.errorMessage[2] = "パスワードを入力してください";
      if (this.d_hunter_data.itf_hunter_password.length > 0) {
        if (
          this.d_hunter_data.itf_hunter_password.length < 4 ||
          this.d_hunter_data.itf_hunter_password.length > 12
        )
          this.errorMessage[17] = "4文字～12文字の間で入力してください";
      }

      // 個人情報
      if (this.d_hunter_data.itf_individual_name_last.length < 1)
        this.errorMessage[4] = "姓を入力してください";
      if (this.d_hunter_data.itf_individual_name_first.length < 1)
        this.errorMessage[5] = "名を入力してください";
      if (this.d_hunter_data.itf_individual_name_last_kana.length < 1)
        this.errorMessage[6] = "せいを入力してください";
      if (this.d_hunter_data.itf_individual_name_first_kana.length < 1)
        this.errorMessage[7] = "めいを入力してください";
      if (this.d_hunter_data.itf_individual_birthday.length < 1)
        this.errorMessage[8] = "誕生日を入力してください";

      // 保護者情報
      if (this.d_hunter_data.itf_parents_name_last.length < 1)
        this.errorMessage[11] = "姓を入力してください";
      if (this.d_hunter_data.itf_parents_name_first.length < 1)
        this.errorMessage[12] = "名を入力してください";
      if (this.d_hunter_data.itf_parents_name_last_kana.length < 1)
        this.errorMessage[13] = "せいを入力してください";
      if (this.d_hunter_data.itf_parents_name_first_kana.length < 1)
        this.errorMessage[14] = "めいを入力してください";
      const regAdress2 = new RegExp(
        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      );
      if (
        this.d_hunter_data.itf_parents_tel.length < 1 ||
        isNaN(Number(this.d_hunter_data.itf_parents_tel))
      )
        this.errorMessage[16] = "電話番号を入力してください";
      if (
        this.d_hunter_data.itf_hunter_guild.itf_guildlist_guild_name.length < 1
      )
        this.errorMessage[18] = "所属ギルドを選択してください";
      if (this.d_hunter_data.itf_individual_zipcode.length >= 1) {
        if (this.d_hunter_data.itf_individual_zipcode.length < 7) {
          this.errorMessage[19] = "7桁で入力してください";
        }
      }
      if (this.d_hunter_data.itf_individual_tel.length >= 1) {
        if (this.d_hunter_data.itf_individual_tel.length < 8) {
          this.errorMessage[20] = "8～15桁で入力してください";
        }
      }
      if (this.d_hunter_data.itf_parents_tel.length >= 1) {
        if (this.d_hunter_data.itf_parents_tel.length < 8) {
          this.errorMessage[21] = "8～15桁で入力してください";
        }
      }

      var confirm_flag = true;
      this.errorMessage.forEach((errorMessage) => {
        confirm_flag = errorMessage.length > 0 ? false : true;
      });
      // エラーが有った場合、処理終了
      if (!confirm_flag) return;

      // データ保持
      localStorage.setItem(
        "regist_hunter_data",
        JSON.stringify({
          ls_hunter_name: this.d_hunter_data.itf_hunter_hunter_name,
          ls_account_id: this.d_hunter_data.itf_hunter_login_id,
          ls_account_password: this.d_hunter_data.itf_hunter_password,
          ls_account_last_name: this.d_hunter_data.itf_individual_name_last,
          ls_account_first_name: this.d_hunter_data.itf_individual_name_first,
          ls_account_last_name_kana:
            this.d_hunter_data.itf_individual_name_last_kana,
          ls_account_first_name_kana:
            this.d_hunter_data.itf_individual_name_first_kana,
          ls_account_birthday: this.d_hunter_data.itf_individual_birthday,
          ls_account_mail: this.d_hunter_data.itf_individual_mail,
          ls_account_tel: this.d_hunter_data.itf_individual_tel,
          ls_account_zipcode: this.d_hunter_data.itf_individual_zipcode,
          ls_account_address: this.d_hunter_data.itf_individual_address,
          ls_account_building: this.d_hunter_data.itf_individual_building,

          ls_parent_last_name: this.d_hunter_data.itf_parents_name_last,
          ls_parent_first_name: this.d_hunter_data.itf_parents_name_first,
          ls_parent_last_name_kana:
            this.d_hunter_data.itf_parents_name_last_kana,
          ls_parent_first_name_kana:
            this.d_hunter_data.itf_parents_name_first_kana,
          ls_parent_birthday: this.d_hunter_data.itf_parents_birthday,
          ls_parent_mail: this.d_hunter_data.itf_parents_mail,
          ls_parent_tel: this.d_hunter_data.itf_parents_tel,
          ls_guild_id:
            this.d_hunter_data.itf_hunter_guild.itf_guildlist_guild_id,
          ls_guild_name:
            this.d_hunter_data.itf_hunter_guild.itf_guildlist_guild_name,
          ls_account_gender_id: this.gender_get_value_individual.itf_gender_id,
          ls_account_gender_name: this.gender_get_value_individual.itf_gender,
          ls_account_prefecture_id: this.prefecture_get_value.itf_prefecture_id,
          ls_account_prefecture_name:
            this.prefecture_get_value.itf_prefecture_name,
          ls_parent_gender_id: this.gender_get_value_parents.itf_gender_id,
          ls_parent_gender_name: this.gender_get_value_parents.itf_gender,
        })
      );

      // 画面遷移_登録確認画面
      this.$router.push({
        name: "managehunterregistercheck",
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "managehunterlist",
      });
    },
    // 入力制御_ハンター情報_ハンター名
    check_hunter_name() {
      const tmp_value = this.d_hunter_data.itf_hunter_hunter_name;
      if (tmp_value) {
        this.d_hunter_data.itf_hunter_hunter_name = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    // 入力制御_ハンター情報_ログインID
    check_hunter_login_id() {
      const tmp_value = this.d_hunter_data.itf_hunter_login_id;
      if (tmp_value) {
        this.d_hunter_data.itf_hunter_login_id = tmp_value.replace(
          /[^0-9a-zA-Z-_]/g,
          ""
        );
      }
    },
    // 入力制御_ハンター情報_パスワード
    check_hunter_password() {
      const tmp_value = this.d_hunter_data.itf_hunter_password;
      if (tmp_value) {
        this.d_hunter_data.itf_hunter_password = tmp_value.replace(
          /[^0-9a-zA-Z-_]/g,
          ""
        );
      }
    },
    // 入力制御_個人情報_お名前(漢字)_姓
    check_individual_name_last() {
      const tmp_value = this.d_hunter_data.itf_individual_name_last;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_name_last = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    // 入力制御_個人情報_お名前(漢字)_名
    check_individual_name_first() {
      const tmp_value = this.d_hunter_data.itf_individual_name_first;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_name_first = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    // 入力制御_個人情報_お名前(よみがな)_せい
    check_individual_name_last_kana() {
      const tmp_value = this.d_hunter_data.itf_individual_name_last_kana;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_name_last_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    // 入力制御_個人情報_お名前(よみがな)_めい
    check_individual_name_first_kana() {
      const tmp_value = this.d_hunter_data.itf_individual_name_first_kana;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_name_first_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    // 入力制御_個人情報_メールアドレス
    check_individual_mail() {
      const tmp_value = this.d_hunter_data.itf_individual_mail;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_mail = tmp_value.replace(
          /[^0-9a-zA-Z-_.@]/g,
          ""
        );
      }
    },
    // 入力制御_個人情報_電話番号
    check_individual_tel() {
      const tmp_value = this.d_hunter_data.itf_individual_tel;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_tel = tmp_value.replace(
          /[^0-9]/g,
          ""
        );
      }
    },
    // 入力制御_個人情報_郵便番号
    check_individual_zipcode() {
      const tmp_value = this.d_hunter_data.itf_individual_zipcode;
      if (tmp_value) {
        this.d_hunter_data.itf_individual_zipcode = tmp_value.replace(
          /[^0-9]/g,
          ""
        );
      }
    },
    // 入力制御_保護者情報_お名前(漢字)_姓
    check_parents_name_last() {
      const tmp_value = this.d_hunter_data.itf_parents_name_last;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_name_last = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    // 入力制御_保護者情報_お名前(漢字)_名
    check_parents_name_first() {
      const tmp_value = this.d_hunter_data.itf_parents_name_first;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_name_first = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    // 入力制御_保護者情報_お名前(よみがな)_せい
    check_parents_name_last_kana() {
      const tmp_value = this.d_hunter_data.itf_parents_name_last_kana;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_name_last_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    // 入力制御_保護者情報_お名前(よみがな)_めい
    check_parents_name_first_kana() {
      const tmp_value = this.d_hunter_data.itf_parents_name_first_kana;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_name_first_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    // 入力制御_保護者情報_メールアドレス
    check_parents_mail() {
      const tmp_value = this.d_hunter_data.itf_parents_mail;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_mail = tmp_value.replace(
          /[^0-9a-zA-Z-_.@]/g,
          ""
        );
      }
    },
    // 入力制御_保護者情報_電話番号
    check_parents_tel() {
      const tmp_value = this.d_hunter_data.itf_parents_tel;
      if (tmp_value) {
        this.d_hunter_data.itf_parents_tel = tmp_value.replace(/[^0-9]/g, "");
      }
    },
  },
});
</script>

<style></style>
