<template>
  <!-- ギルド登録画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ギルド管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>ギルド登録</p>
      </div>
      <!-- フォーム -->
      <form class="scroll_form">
        <!-- ギルド情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">ギルド情報</legend>
          <p class="f_fieldset_contents">
            <label
              for="f_guild_name"
              class="input_contents_label label_border_blue"
            >
              ギルド名
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_guild_name"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_guild_name"
              placeholder="田中ギルド"
              tabindex="1"
              maxlength="16"
              v-model="d_guild_data.itf_regist_guild_name"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[0] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_guild_account_id"
              class="input_contents_label label_border_blue"
            >
              ログインID
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_guild_account_id"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_guild_account_id"
              placeholder="tanaka01"
              tabindex="2"
              maxlength="8"
              @keyup="check_guild_login_id"
              v-model="d_guild_data.itf_regist_guild_login_id"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[1] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_guild_pass"
              class="input_contents_label label_border_blue"
            >
              パスワード
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_guild_pass"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_guild_pass"
              maxlength="12"
              tabindex="3"
              @keyup="check_guild_pass"
              v-model="d_guild_data.itf_regist_guild_pass"
            />
            <label class="err_message_red">{{ errorMessage[2] }}<br /></label>
            <label class="err_message_red">{{ errorMessage[17] }}</label>
          </p>
        </fieldset>
        <!-- 担当者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">担当者情報</legend>
          <p class="f_fieldset_contents">
            <label
              for="f_manager_name"
              class="input_contents_label label_border_blue"
            >
              担当者名(漢字)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">姓</span>
                <input
                  type="text"
                  name="f_manager_name"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_manager_name"
                  placeholder="鈴木"
                  maxlength="10"
                  tabindex="4"
                  @keyup="check_manager_name_last"
                  v-model="d_guild_data.itf_regist_manager_name_last"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[3]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">名</span>
                <input
                  type="text"
                  name="f_manager_name_2"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_manager_name"
                  placeholder="一郎"
                  maxlength="10"
                  tabindex="5"
                  @keyup="check_manager_name_first"
                  v-model="d_guild_data.itf_regist_manager_name_first"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[4]
              }}</label>
            </span>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_manager_name_kana"
              class="input_contents_label label_border_blue"
            >
              担当者名(よみがな)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">せい</span>
                <input
                  type="text"
                  name="f_manager_name_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_manager_name_kana"
                  placeholder="すずき"
                  maxlength="10"
                  tabindex="6"
                  @keyup="check_manager_name_last_kana"
                  v-model="d_guild_data.itf_regist_manager_name_last_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[5]
              }}</label>

              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">めい</span>
                <input
                  type="text"
                  name="f_manager_name_kana_2"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_manager_name_kana"
                  placeholder="いちろう"
                  maxlength="10"
                  tabindex="7"
                  @keyup="check_manager_name_first_kana"
                  v-model="d_guild_data.itf_regist_manager_name_first_kana"
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[6]
              }}</label>
            </span>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_manager_mail"
              class="input_contents_label label_border_blue"
            >
              メールアドレス
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="mail"
              name="f_manager_mail"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_manager_mail"
              placeholder="mailadress123@example.com"
              maxlength="50"
              tabindex="8"
              inputmode="email"
              @keyup="check_manager_mail"
              v-model="d_guild_data.itf_regist_manager_mail"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[7] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_manager_tel"
              class="input_contents_label label_border_blue"
            >
              電話番号
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="tel"
              name="f_manager_tel"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_manager_tel"
              placeholder="09012345678"
              maxlength="15"
              tabindex="9"
              @keyup="check_manager_tel"
              v-model="d_guild_data.itf_regist_manager_tel"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[8] }}</label>
            <label class="err_message_red">{{ errorMessage[18] }}</label>
          </p>
        </fieldset>
        <!-- 団体情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">団体情報</legend>
          <p class="f_fieldset_contents">
            <label
              for="f_group_name"
              class="input_contents_label label_border_blue"
            >
              施設名・社名
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="text"
              name="f_group_name"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_group_name"
              placeholder="一般社団法人たなか食堂"
              maxlength="50"
              tabindex="10"
              v-model="d_guild_data.itf_regist_group_name"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[9] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_group_administrator_name"
              class="input_contents_label label_border_blue"
            >
              代表者名(漢字)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">姓</span>
                <input
                  type="text"
                  name="f_group_administrator_name"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_group_administrator_name"
                  placeholder="田中"
                  maxlength="10"
                  tabindex="11"
                  @keyup="check_administrator_name_last"
                  v-model="
                    d_guild_data.itf_regist_group_administrator_name_last
                  "
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[10]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">名</span>
                <input
                  type="text"
                  name="f_group_administrator_name_2"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_group_administrator_name"
                  placeholder="秀吉"
                  maxlength="10"
                  tabindex="12"
                  @keyup="check_administrator_name_first"
                  v-model="
                    d_guild_data.itf_regist_group_administrator_name_first
                  "
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[11]
              }}</label>
            </span>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_group_administrator_name_kana"
              class="input_contents_label label_border_blue"
            >
              代表者名(よみがな)
            </label>
            <span class="required_icon"></span>
            <br />
            <span class="f_fieldset_contents_position_column">
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">せい</span>
                <input
                  type="text"
                  name="f_group_administrator_name_kana"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_group_administrator_name_kana"
                  placeholder="たなか"
                  maxlength="10"
                  tabindex="13"
                  @keyup="check_administrator_name_last_kana"
                  v-model="
                    d_guild_data.itf_regist_group_administrator_name_last_kana
                  "
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[12]
              }}</label>
              <span
                class="f_fieldset_contents_position_right input_contents_label"
              >
                <span class="input_name_sublabel">めい</span>
                <input
                  type="text"
                  name="f_group_administrator_name_kana_2"
                  class="f_input_text f_input_size_2 manage_registmodifydelete_f_input_text"
                  id="f_group_administrator_name_kana"
                  placeholder="ひでよし"
                  maxlength="10"
                  tabindex="14"
                  @keyup="check_administrator_name_first_kana"
                  v-model="
                    d_guild_data.itf_regist_group_administrator_name_first_kana
                  "
                />
              </span>
              <label class="err_message_red input_name">{{
                errorMessage[13]
              }}</label>
            </span>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_group_tel"
              class="input_contents_label label_border_blue"
            >
              電話番号
            </label>
            <span class="required_icon"></span>
            <br />
            <input
              type="tel"
              name="f_group_tel"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_group_tel"
              placeholder="0981234567"
              maxlength="15"
              tabindex="15"
              @keyup="check_group_tel"
              v-model="d_guild_data.itf_regist_group_tel"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[14] }}</label>
            <label class="err_message_red">{{ errorMessage[19] }}</label>
          </p>
          <!-- 会社情報 -->
          <fieldset class="input_fieldset input_fieldset_1">
            <legend class="legend_style">住所</legend>
            <p class="f_fieldset_contents">
              <span class="f_fieldset_contents_position_row">
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_group_zipcode"
                    class="input_contents_label label_border_blue"
                  >
                    郵便番号
                    <span class="required_icon"></span>
                  </label>
                  <input
                    type="text"
                    name="f_group_zipcode"
                    class="f_input_text f_input_size_3 manage_registmodifydelete_f_input_text"
                    id="f_group_zipcode"
                    placeholder="9041234"
                    maxlength="7"
                    tabindex="16"
                    inputmode="numeric"
                    @keyup="check_group_zipcode"
                    v-model="d_guild_data.itf_regist_group_zipcode"
                  />
                  <label class="err_message_red">{{ errorMessage[15] }}</label>
                  <label class="err_message_red">{{ errorMessage[17] }}</label>
                </span>
                <span class="f_fieldset_contents_position_column">
                  <label
                    for="f_group_prefecture"
                    class="input_contents_label label_border_blue"
                  >
                    都道府県
                  </label>
                  <select
                    v-model="prefecture_get_value"
                    tabindex="17"
                    class="prefecture"
                  >
                    <option
                      v-for="fruit in prefecture_list"
                      v-bind:key="fruit.itf_prefecture_id"
                      :value="fruit"
                    >
                      {{ fruit.itf_prefecture_name }}
                    </option>
                  </select>
                </span>
              </span>
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_group_address"
                class="input_contents_label label_border_blue"
              >
                市区町村・番地
              </label>
              <span class="required_icon"></span>
              <br />
              <input
                type="text"
                name="f_group_address"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_group_address"
                placeholder="沖縄市999番地"
                maxlength="100"
                tabindex="18"
                v-model="d_guild_data.itf_regist_group_address"
              />
              <br />
              <label class="err_message_red">{{ errorMessage[16] }}</label>
            </p>
            <p class="f_fieldset_contents">
              <label
                for="f_group_building"
                class="input_contents_label label_border_blue"
              >
                建物名・部屋番号
              </label>
              <br />
              <input
                type="text"
                name="f_group_building"
                class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
                id="f_group_building"
                placeholder="コーポ123"
                maxlength="50"
                tabindex="19"
                v-model="d_guild_data.itf_regist_group_building"
              />
            </p>
          </fieldset>
        </fieldset>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            tabindex="20"
            v-on:click="return_transition()"
          />
          <!-- ボタン_確認 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            tabindex="21"
            v-on:click="confirmation_data_guild()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";

interface input_guild_data {
  // ギルド情報_ギルド名
  itf_regist_guild_name: string;
  // ギルド情報_ログインID
  itf_regist_guild_login_id: string;
  // ギルド情報_パスワード
  itf_regist_guild_pass: string;

  // 担当者情報_担当者名(漢字)_名字
  itf_regist_manager_name_last: string;
  // 担当者情報_担当者名(漢字)_名前
  itf_regist_manager_name_first: string;
  // 担当者情報_担当者名(よみがな)_名字
  itf_regist_manager_name_last_kana: string;
  // 担当者情報_担当者名(よみがな)_名前
  itf_regist_manager_name_first_kana: string;
  // 担当者情報_メールアドレス
  itf_regist_manager_mail: string;
  // 担当者情報_電話番号
  itf_regist_manager_tel: string;

  // 会社情報_施設名・社名
  itf_regist_group_name: string;
  // 会社情報_代表者名(漢字)_名字
  itf_regist_group_administrator_name_last: string;
  // 会社情報_代表者名(漢字)_名前
  itf_regist_group_administrator_name_first: string;
  // 会社情報_代表者名(よみがな)_名字
  itf_regist_group_administrator_name_last_kana: string;
  // 会社情報_代表者名(よみがな)_名前
  itf_regist_group_administrator_name_first_kana: string;
  // 会社情報_電話番号
  itf_regist_group_tel: string;
  // 会社情報_住所(郵便番号)
  itf_regist_group_zipcode: string;
  // 会社情報_住所(都道府県ID)(画面非表示・登録用)
  itf_regist_group_prefectures_id: number;
  // 会社情報_住所(市区町村・番地)
  itf_regist_group_address: string;
  // 会社情報_住所(建物名・部屋番号)
  itf_regist_group_building: string;
}
interface RegistGuildState {
  guild_data: input_guild_data;
}

// データ受取用_都道府県一覧
interface prefecture_data_catch {
  // 県ID(画面非表示)
  itf_prefecture_id: number;
  // 県名
  itf_prefecture_name: string;
}
interface PrefectureState {
  prefecture_data: prefecture_data_catch[];
}

export default defineComponent({
  // データ
  data() {
    // 都道府県ドロップダウン初期値
    var farst_prefecture_id = 1;
    var farst_prefecture_name = "北海道";
    var error_message: string[] = new Array(16);

    var guild = reactive<RegistGuildState>({
      guild_data: {
        itf_regist_guild_name: "",
        itf_regist_guild_login_id: "",
        itf_regist_guild_pass: "",
        itf_regist_manager_name_last: "",
        itf_regist_manager_name_first: "",
        itf_regist_manager_name_last_kana: "",
        itf_regist_manager_name_first_kana: "",
        itf_regist_manager_mail: "",
        itf_regist_manager_tel: "",
        itf_regist_group_name: "",
        itf_regist_group_administrator_name_last: "",
        itf_regist_group_administrator_name_first: "",
        itf_regist_group_administrator_name_last_kana: "",
        itf_regist_group_administrator_name_first_kana: "",
        itf_regist_group_tel: "",
        itf_regist_group_zipcode: "",
        itf_regist_group_prefectures_id: 0,
        itf_regist_group_address: "",
        itf_regist_group_building: "",
      },
    });
    const guild_state = computed(() => guild.guild_data);

    ///////////////////////////////
    // 登録確認から戻ってきた場合処理
    ///////////////////////////////
    // 登録データ存在確認
    if (localStorage.getItem("regist_guild_data")) {
      // 既にデータが存在する場合(登録確認から戻ってきた場合)
      // データ取得
      const st_guild_data = JSON.parse(
        localStorage.getItem("regist_guild_data")!
      );
      guild.guild_data.itf_regist_guild_name =
        st_guild_data.ls_regist_guild_name;
      guild.guild_data.itf_regist_guild_login_id = st_guild_data.ls_account_id;
      guild.guild_data.itf_regist_guild_pass =
        st_guild_data.ls_account_password;

      guild.guild_data.itf_regist_manager_name_last =
        st_guild_data.ls_account_last_name;
      guild.guild_data.itf_regist_manager_name_first =
        st_guild_data.ls_account_first_name;
      guild.guild_data.itf_regist_manager_name_last_kana =
        st_guild_data.ls_account_last_name_kana;
      guild.guild_data.itf_regist_manager_name_first_kana =
        st_guild_data.ls_account_first_name_kana;
      guild.guild_data.itf_regist_manager_mail = st_guild_data.ls_mail;
      guild.guild_data.itf_regist_manager_tel = st_guild_data.ls_tel;
      guild.guild_data.itf_regist_group_name = st_guild_data.ls_guild_operating;
      guild.guild_data.itf_regist_group_administrator_name_last =
        st_guild_data.ls_guild_responsible_person_last_name;
      guild.guild_data.itf_regist_group_administrator_name_first =
        st_guild_data.ls_guild_responsible_person_first_name;
      guild.guild_data.itf_regist_group_administrator_name_last_kana =
        st_guild_data.ls_guild_responsible_person_last_name_kana;
      guild.guild_data.itf_regist_group_administrator_name_first_kana =
        st_guild_data.ls_guild_responsible_person_first_name_kana;
      guild.guild_data.itf_regist_group_tel = st_guild_data.ls_guild_tel;
      guild.guild_data.itf_regist_group_zipcode =
        st_guild_data.ls_guild_zipcode;
      guild.guild_data.itf_regist_group_address =
        st_guild_data.ls_guild_address;
      guild.guild_data.itf_regist_group_building =
        st_guild_data.ls_guild_building;

      farst_prefecture_id = st_guild_data.ls_guild_prefecture_id;
      farst_prefecture_name = st_guild_data.ls_guild_prefecture_name;
    }

    //////////////////////
    // 都道府県一覧取得処理
    //////////////////////
    // 初期化_データ受取用
    var c_prefecture_data = reactive<PrefectureState>({
      prefecture_data: [],
    });
    const prefecture_data = computed(() => c_prefecture_data.prefecture_data);

    // 都道府県一覧取得処理
    ApiService.getPrefectureData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const prefectures = res.data;
          for (var i = 0; i < prefectures.length; i++) {
            c_prefecture_data.prefecture_data.push({
              // 県ID(画面非表示)
              itf_prefecture_id: prefectures[i].id,
              // 県名
              itf_prefecture_name: prefectures[i].name,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // データ設定
    return {
      class_name:
        "operation_button button_white manage_registmodifydelete_operationbutton",
      message: "戻る",
      class_name2:
        "operation_button button_black manage_registmodifydelete_operationbutton",
      message2: "確認",
      selectedFruits: "",
      // 都道府県データプルダウン表示用
      prefecture_list: prefecture_data,
      // 都道府県データ渡す用
      prefecture_get_value: {
        itf_prefecture_id: farst_prefecture_id,
        itf_prefecture_name: farst_prefecture_name,
      },
      d_guild_data: guild_state,
      flg_show_modal_update: false,
      errorMessage: error_message,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
  },
  // メソッド
  methods: {
    // 戻るボタンクリック処理
    return_transition() {
      this.$router.push({
        name: "manageguildlist",
      });
    },
    // 確認ボタンクリック処理
    confirmation_data_guild() {
      this.errorMessage = new Array(20);

      if (this.d_guild_data.itf_regist_guild_name.length < 1)
        this.errorMessage[0] = "ギルド名を入力してください";
      if (this.d_guild_data.itf_regist_guild_login_id.length < 1)
        this.errorMessage[1] = "ログインIDを入力してください";

      const regPass = new RegExp(/^[A-Za-z0-9]{4,}/);
      if (this.d_guild_data.itf_regist_guild_pass.length < 1)
        this.errorMessage[2] = "パスワードを入力してください";
      if (this.d_guild_data.itf_regist_guild_pass.length > 0) {
        if (
          this.d_guild_data.itf_regist_guild_pass.length < 4 ||
          this.d_guild_data.itf_regist_guild_pass.length > 12
        )
          this.errorMessage[17] = "4文字～12文字の間で入力してください";
      }
      if (this.d_guild_data.itf_regist_manager_name_last.length < 1)
        this.errorMessage[3] = "姓を入力してください";
      if (this.d_guild_data.itf_regist_manager_name_first.length < 1)
        this.errorMessage[4] = "名を入力してください";
      if (this.d_guild_data.itf_regist_manager_name_last_kana.length < 1)
        this.errorMessage[5] = "せいを入力してください";
      if (this.d_guild_data.itf_regist_manager_name_first_kana.length < 1)
        this.errorMessage[6] = "めいを入力してください";
      const regAdress = new RegExp(
        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      );
      if (!regAdress.test(this.d_guild_data.itf_regist_manager_mail))
        this.errorMessage[7] = "メールアドレスを入力してください";

      if (
        this.d_guild_data.itf_regist_manager_tel.length < 1 ||
        isNaN(Number(this.d_guild_data.itf_regist_manager_tel))
      )
        this.errorMessage[8] = "電話番号を入力してください";
      if (this.d_guild_data.itf_regist_group_name.length < 1)
        this.errorMessage[9] = "施設名・社名を入力してください";
      if (this.d_guild_data.itf_regist_group_administrator_name_last.length < 1)
        this.errorMessage[10] = "姓を入力してください";
      if (
        this.d_guild_data.itf_regist_group_administrator_name_first.length < 1
      )
        this.errorMessage[11] = "名を入力してください";
      if (
        this.d_guild_data.itf_regist_group_administrator_name_last_kana.length <
        1
      )
        this.errorMessage[12] = "せいを入力してください";
      if (
        this.d_guild_data.itf_regist_group_administrator_name_first_kana
          .length < 1
      )
        this.errorMessage[13] = "めいを入力してください";
      if (
        this.d_guild_data.itf_regist_group_tel.length < 1 ||
        isNaN(Number(this.d_guild_data.itf_regist_group_tel))
      )
        this.errorMessage[14] = "電話番号を入力してください";
      if (this.d_guild_data.itf_regist_group_zipcode.length < 1)
        this.errorMessage[15] = "郵便番号を入力してください";
      if (this.d_guild_data.itf_regist_group_address.length < 1)
        this.errorMessage[16] = "市区町村を入力してください";
      if (this.d_guild_data.itf_regist_group_zipcode.length >= 1) {
        if (this.d_guild_data.itf_regist_group_zipcode.length < 7) {
          this.errorMessage[17] = "7桁で入力してください";
        }
      }
      if (this.d_guild_data.itf_regist_manager_tel.length >= 1) {
        if (this.d_guild_data.itf_regist_manager_tel.length < 8) {
          this.errorMessage[18] = "8～15桁で入力してください";
        }
      }
      if (this.d_guild_data.itf_regist_group_tel.length >= 1) {
        if (this.d_guild_data.itf_regist_group_tel.length < 8) {
          this.errorMessage[19] = "8～15桁で入力してください";
        }
      }

      var confirm_flag = true;
      this.errorMessage.forEach((errorMessage) => {
        confirm_flag = errorMessage.length > 0 ? false : true;
      });
      if (!confirm_flag) return;

      localStorage.setItem(
        "regist_guild_data",
        JSON.stringify({
          ls_regist_guild_name: this.d_guild_data.itf_regist_guild_name,
          ls_account_id: this.d_guild_data.itf_regist_guild_login_id,
          ls_account_password: this.d_guild_data.itf_regist_guild_pass,
          ls_account_last_name: this.d_guild_data.itf_regist_manager_name_last,
          ls_account_first_name:
            this.d_guild_data.itf_regist_manager_name_first,
          ls_account_last_name_kana:
            this.d_guild_data.itf_regist_manager_name_last_kana,
          ls_account_first_name_kana:
            this.d_guild_data.itf_regist_manager_name_first_kana,
          ls_mail: this.d_guild_data.itf_regist_manager_mail,
          ls_tel: this.d_guild_data.itf_regist_manager_tel,
          ls_guild_operating: this.d_guild_data.itf_regist_group_name,
          ls_guild_responsible_person_last_name:
            this.d_guild_data.itf_regist_group_administrator_name_last,
          ls_guild_responsible_person_first_name:
            this.d_guild_data.itf_regist_group_administrator_name_first,
          ls_guild_responsible_person_last_name_kana:
            this.d_guild_data.itf_regist_group_administrator_name_last_kana,
          ls_guild_responsible_person_first_name_kana:
            this.d_guild_data.itf_regist_group_administrator_name_first_kana,
          ls_guild_tel: this.d_guild_data.itf_regist_group_tel,
          ls_guild_zipcode: this.d_guild_data.itf_regist_group_zipcode,
          ls_guild_prefecture_id: this.prefecture_get_value.itf_prefecture_id,
          ls_guild_prefecture_name:
            this.prefecture_get_value.itf_prefecture_name,
          ls_guild_address: this.d_guild_data.itf_regist_group_address,
          ls_guild_building: this.d_guild_data.itf_regist_group_building,
        })
      );
      this.$router.push({
        name: "manageguildregistercheck",
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "manageguildlist",
      });
    },
    // 入力制御_ギルド情報_ログインID
    check_guild_login_id() {
      const tmp_value = this.d_guild_data.itf_regist_guild_login_id;
      if (tmp_value) {
        this.d_guild_data.itf_regist_guild_login_id = tmp_value.replace(
          /[^0-9a-zA-Z-_]/g,
          ""
        );
      }
    },
    // 入力制御_ギルド情報_パスワード
    check_guild_pass() {
      const tmp_value = this.d_guild_data.itf_regist_guild_pass;
      if (tmp_value) {
        this.d_guild_data.itf_regist_guild_pass = tmp_value.replace(
          /[^0-9a-zA-Z-_]/g,
          ""
        );
      }
    },
    // 入力制御_担当者情報_担当者名(漢字)_姓
    check_manager_name_last() {
      const tmp_value = this.d_guild_data.itf_regist_manager_name_last;
      if (tmp_value) {
        this.d_guild_data.itf_regist_manager_name_last = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    // 入力制御_担当者情報_担当者名(漢字)_名
    check_manager_name_first() {
      const tmp_value = this.d_guild_data.itf_regist_manager_name_first;
      if (tmp_value) {
        this.d_guild_data.itf_regist_manager_name_first = tmp_value.replace(
          /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    // 入力制御_担当者情報_担当者名(よみがな)_せい
    check_manager_name_last_kana() {
      const tmp_value = this.d_guild_data.itf_regist_manager_name_last_kana;
      if (tmp_value) {
        this.d_guild_data.itf_regist_manager_name_last_kana = tmp_value.replace(
          /[^ぁ-んー]/g,
          ""
        );
      }
    },
    // 入力制御_担当者情報_担当者名(よみがな)_めい
    check_manager_name_first_kana() {
      const tmp_value = this.d_guild_data.itf_regist_manager_name_first_kana;
      if (tmp_value) {
        this.d_guild_data.itf_regist_manager_name_first_kana =
          tmp_value.replace(/[^ぁ-んー]/g, "");
      }
    },
    // 入力制御_担当者情報_メールアドレス
    check_manager_mail() {
      const tmp_value = this.d_guild_data.itf_regist_manager_mail;
      if (tmp_value) {
        this.d_guild_data.itf_regist_manager_mail = tmp_value.replace(
          /[^0-9a-zA-Z-_.@]/g,
          ""
        );
      }
    },
    // 入力制御_担当者情報_電話番号
    check_manager_tel() {
      const tmp_value = this.d_guild_data.itf_regist_manager_tel;
      if (tmp_value) {
        this.d_guild_data.itf_regist_manager_tel = tmp_value.replace(
          /[^0-9]/g,
          ""
        );
      }
    },
    // 入力制御_会社情報_代表者名(漢字)_姓
    check_administrator_name_last() {
      const tmp_value =
        this.d_guild_data.itf_regist_group_administrator_name_last;
      if (tmp_value) {
        this.d_guild_data.itf_regist_group_administrator_name_last =
          tmp_value.replace(
            /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
            ""
          );
      }
    },
    // 入力制御_会社情報_代表者名(漢字)_名
    check_administrator_name_first() {
      const tmp_value =
        this.d_guild_data.itf_regist_group_administrator_name_first;
      if (tmp_value) {
        this.d_guild_data.itf_regist_group_administrator_name_first =
          tmp_value.replace(
            /[^0-9a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
            ""
          );
      }
    },
    // 入力制御_会社情報_代表者名(よみがな)_せい
    check_administrator_name_last_kana() {
      const tmp_value =
        this.d_guild_data.itf_regist_group_administrator_name_last_kana;
      if (tmp_value) {
        this.d_guild_data.itf_regist_group_administrator_name_last_kana =
          tmp_value.replace(/[^ぁ-んー]/g, "");
      }
    },
    // 入力制御_会社情報_代表者名(よみがな)_めい
    check_administrator_name_first_kana() {
      const tmp_value =
        this.d_guild_data.itf_regist_group_administrator_name_first_kana;
      if (tmp_value) {
        this.d_guild_data.itf_regist_group_administrator_name_first_kana =
          tmp_value.replace(/[^ぁ-んー]/g, "");
      }
    },
    // 入力制御_会社情報_電話番号
    check_group_tel() {
      const tmp_value = this.d_guild_data.itf_regist_group_tel;
      if (tmp_value) {
        this.d_guild_data.itf_regist_group_tel = tmp_value.replace(
          /[^0-9]/g,
          ""
        );
      }
    },
    // 入力制御_会社情報_郵便番号
    check_group_zipcode() {
      const tmp_value = this.d_guild_data.itf_regist_group_zipcode;
      if (tmp_value) {
        this.d_guild_data.itf_regist_group_zipcode = tmp_value.replace(
          /[^0-9]/g,
          ""
        );
      }
    },
  },
});
</script>

<style></style>
