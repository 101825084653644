<template>
  <div class="infomation">
    <ul class="infomation-list">
      <li class="info-box box1">
        <img src="@/public/info_img_01.png" alt="お知らせ1" />
      </li>
      <li class="info-box box1">
        <img src="@/public/info_img_01.png" alt="お知らせ1" />
      </li>
      <li class="info-box box1">
        <img src="@/public/info_img_01.png" alt="お知らせ1" />
      </li>
      <li class="info-box box1">
        <img src="@/public/info_img_01.png" alt="お知らせ1" />
      </li>
      <li class="info-box box1">
        <img src="@/public/info_img_01.png" alt="お知らせ1" />
      </li>
    </ul>
  </div>
  <!-- <hooper>
    <slide> slide 1 </slide>
    <slide> slide 2 </slide>
    <slide> slide 3 </slide>
    <slide> slide 4 </slide>
    <slide> slide 5 </slide>
    <slide> slide 6 </slide>
  </hooper> -->
</template>
<script lang="ts">
export default {
  name: "App",
  // コンポーネント
  components: {},
};
</script>
<style>
@import "../css/InformationSlider.scss";
</style>
