<template>
  <main v-bind:class="{ main: isActive }">
    <div id="main_grid">
      <!-- ヘッダー -->
      <div id="app_head_grid" v-if="displaySwitchingHeader == true">
        <HeadUserInfo></HeadUserInfo>
      </div>
      <!-- メイン -->
      <div
        id="app_mein_grid"
        v-bind:class="{ two: isActiveTwo, three: isActiveThree }"
      >
        <router-view />
      </div>
      <!-- インフォメーション -->
      <div id="app_infomation_grid" v-if="displaySwitchingInformation">
        <Info></Info>
      </div>
      <!-- ナビゲーション -->
      <div id="app_nav_grid" v-if="displaySwitchingFooter">
        <NavManu></NavManu>
      </div>
      <!-- ロード -->
      <div id="loader">
        <LoadVuew></LoadVuew>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Load from "./views/LoadView.vue";
import NavManu from "./views/NavigationView.vue";
import HeadUserInfo from "./views/UserInformationView.vue";
import Info from "./views/InformationSliderView.vue";

// コンポーネント読み込み
export default defineComponent({
  name: "App",
  // データ
  data() {
    return {
      // 表示切替用
      displaySwitchingHeader: false,
      displaySwitchingInformation: false,
      displaySwitchingFooter: false,
      isActive: false, //メイン画面判別用
      isActiveTwo: false, //画面中央グリッド表示用
      isActiveThree: false, //画面中央グリッド表示用
    };
  },
  // コンポーネント
  components: {
    LoadVuew: Load,
    NavManu: NavManu,
    HeadUserInfo: HeadUserInfo,
    Info: Info,
  },
  // 動き監視
  watch: {
    // 画面が切り替わった時処理
    $route: function (to, from) {
      switch (this.$route.path) {
        case "/start":
          // ログイン画面
          this.displaySwitchingHeader = false;
          this.displaySwitchingInformation = false;
          this.displaySwitchingFooter = false;
          this.isActive = false;
          this.isActiveTwo = true;
          this.isActiveThree = false;
          break;
        case "/login":
          // ログイン画面
          this.displaySwitchingHeader = false;
          this.displaySwitchingInformation = false;
          this.displaySwitchingFooter = false;
          this.isActive = false;
          this.isActiveTwo = true;
          this.isActiveThree = false;
          break;
        case "/main":
          // メイン画面
          this.displaySwitchingHeader = false;
          this.displaySwitchingInformation = false;
          this.displaySwitchingFooter = true;
          this.isActive = true;
          this.isActiveTwo = false;
          this.isActiveThree = true;
          break;
        case "/illust":
        case "/management":
        case "/manageguildlist":
        case "/manageguildmodify":
        case "/manageguildregister":
        case "/manageguildregistercheck":
        case "/managequestlist":
        case "/managequestmodify":
        case "/managequestregister":
        case "/managequestregistercheck":
        case "/loginfromregisterselect":
        case "/managehunterlist":
        case "/managehunterregister":
        case "/managehunterregistercheck":
        case "/managehuntermodify":
        case "/managehuntermodifycheck":
        case "/manageclientlist":
        case "/manageclientregister":
        case "/manageclientregistercheck":
        case "/manageclientmodify":
        case "/manageclientmodifycheck":
          // イラスト画面、管理画面、登録選択画面(ログイン画面から)
          // ギルド管理画面、ギルド登録画面、ギルド登録確認画面、ギルド編集画面、
          // クエスト管理画面、クエスト登録画面、クエスト登録確認画面、クエスト編集画面
          // ハンター管理画面、ハンター登録画面、ハンター登録確認画面、ハンター編集画面、ハンター編集確認画面
          // 依頼者管理画面、依頼者新規登録画面、依頼者新規登録確認画面、依頼者編集削除画面、依頼者編集確認画面
          this.displaySwitchingHeader = false;
          this.displaySwitchingInformation = false;
          this.displaySwitchingFooter = false;
          this.isActiveTwo = true;
          this.isActiveThree = false;
          break;
        default:
          // その他画面
          this.displaySwitchingHeader = true;
          this.displaySwitchingInformation = false;
          this.displaySwitchingFooter = true;
          this.isActive = false;
          this.isActiveTwo = false;
          this.isActiveThree = false;
          break;
      }
    },
  },
});

window.onload = () => {
  // 画面ロード時処理

  // ローディング画面を非表示(画面開いた時は表示されている)
  const loader = document.getElementById("loader");
  loader!.classList.add("loaded");
};
</script>

<style>
/* スタイル読み込み */
@import "./css/style.scss";
</style>
