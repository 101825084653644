<template>
  <!-- ハンター更新内容確認画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>ハンター管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>編集内容確認</p>
      </div>
      <!-- フォーム(表示) -->
      <form class="scroll_form">
        <!-- ハンター情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">ハンター情報</legend>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              ハンター名
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_hunter_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              ログインID
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_account_id
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              新しいパスワード
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_account_password
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              所属ギルド
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_guild_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              ハンターランク
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_hunter_rank
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              ポイント
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_hunter_point
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">ダイヤ</span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_hunter_dia
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              利用状況
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_status_str
            }}</span>
          </p>
        </fieldset>

        <!-- 個人情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">個人情報</legend>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              名前(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_account_last_name +
              d_update_check_data.ls_account_first_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              名前(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_account_last_name_kana +
              d_update_check_data.ls_account_first_name_kana
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">誕生日</span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_account_birthday
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">性別</span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_account_gender_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              メールアドレス
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_account_mail
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              電話番号
            </span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_update_check_data.ls_account_tel"
            >
              {{ d_update_check_data.ls_account_tel }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_update_check_data.ls_account_tel"
            >
              未登録
            </span>
          </p>
          <!-- 住所 -->
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">住所</span>
            <br />

            <span
              class="f_fieldset_contents_value"
              v-if="d_update_check_data.ls_account_zipcode"
            >
              <!-- 郵便番号 -->
              {{ "〒 " + d_update_check_data.ls_account_zipcode }}
            </span>

            <span
              class="f_fieldset_contents_value"
              v-if="
                d_update_check_data.ls_account_prefecture_name &&
                d_update_check_data.ls_account_address
              "
            >
              <!-- 都道府県・市区町村・番地 -->
              {{
                d_update_check_data.ls_account_prefecture_name +
                d_update_check_data.ls_account_address
              }}
            </span>

            <span
              class="f_fieldset_contents_value"
              v-if="d_update_check_data.ls_account_building"
            >
              <!-- 建物名 -->
              {{ d_update_check_data.ls_account_building }}
            </span>

            <span
              class="f_fieldset_contents_value"
              v-if="
                !d_update_check_data.ls_account_zipcode &&
                !d_update_check_data.ls_account_prefecture_name &&
                !d_update_check_data.ls_account_address &&
                !d_update_check_data.ls_account_building
              "
            >
              未登録
            </span>
          </p>
        </fieldset>
        <!-- 保護者情報 -->
        <fieldset class="input_fieldset input_fieldset_1">
          <legend class="legend_style">保護者情報</legend>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              名前(漢字)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_parent_last_name +
              d_update_check_data.ls_parent_first_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              名前(よみがな)
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_parent_last_name_kana +
              d_update_check_data.ls_parent_first_name_kana
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              生年月日
            </span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_update_check_data.ls_parent_birthday"
            >
              {{ d_update_check_data.ls_parent_birthday }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_update_check_data.ls_parent_birthday"
            >
              未登録
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">性別</span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_parent_gender_name
            }}</span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              メールアドレス
            </span>
            <br />
            <span
              class="f_fieldset_contents_value"
              v-if="d_update_check_data.ls_parent_mail"
            >
              {{ d_update_check_data.ls_parent_mail }}
            </span>
            <span
              class="f_fieldset_contents_value"
              v-if="!d_update_check_data.ls_parent_mail"
            >
              未登録
            </span>
          </p>
          <p class="f_fieldset_contents">
            <span class="input_contents_label label_border_blue">
              電話番号
            </span>
            <br />
            <span class="f_fieldset_contents_value">{{
              d_update_check_data.ls_parent_tel
            }}</span>
          </p>
        </fieldset>
        <div class="page_regist_message">
          <p>こちらの内容で更新しますか?</p>
        </div>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            v-on:click="return_transition()"
          />
          <!-- ボタン_更新 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            v-on:click="update_data_hunter()"
          />
        </div>
      </form>
    </div>
    <ModalWindow
      modal_title="完了"
      :msg_1="'ハンター名 :' + d_update_check_data.ls_hunter_name"
      msg_2="の更新を完了しました"
      button_ok="OK"
      v-if="flg_show_modal_comp"
      @modal-ok="modalokoya()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ModalWindow from "../components/Modal_2.vue";
import ApiService from "../service/ApiService";

export default defineComponent({
  // データ
  data() {
    // 登録データ取得
    const st_hunter_data = JSON.parse(
      localStorage.getItem("update_hunter_data")!
    );

    return {
      class_name: "operation_button button_white_blue_blue",
      message: "戻る",
      class_name2: "operation_button button_blue",
      message2: "更新",
      // データ
      d_update_check_data: st_hunter_data,
      // フラグ_モーダル表示
      flg_show_modal_comp: false,
    };
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
    ModalWindow,
  },
  // メソッド
  methods: {
    // 戻るボタンクリック処理
    return_transition() {
      this.$router.push({
        name: "managehuntermodify",
      });
    },
    // 更新ボタンクリック処理
    update_data_hunter() {
      // ハンターID取得
      const st_hunter_data = JSON.parse(
        localStorage.getItem("hunter_modify_data")!
      );
      // 更新ハンターデータ取得
      const st_update_hunter_data = JSON.parse(
        localStorage.getItem("update_hunter_data")!
      );

      // データ更新処理
      ApiService.postUpdateHunterData(
        st_hunter_data.ls_hunter_id,
        st_update_hunter_data
      )
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            // モーダル表示_更新完了モーダル
            this.flg_show_modal_comp = true;
          } else {
            // todo 編集失敗モーダルを表示 現在はalertで仮置き start ---------
            alert("更新失敗");
            // todo 編集失敗モーダルを表示 現在はalertで仮置き end ---------
          }
        })
        .catch((err) => {
          // エラー表示
          console.log(err);
          // todo 編集失敗モーダルを表示?? 現在はalertで仮置き start ---------
          alert("更新失敗");
          // todo 編集失敗モーダルを表示?? 現在はalertで仮置き end ---------
        });
    },
    // ボタンクリック処理_モーダル_OK
    modalokoya() {
      // モーダル非表示_完了モーダル
      this.flg_show_modal_comp = false;
      // 更新ハンターデータ取得
      const st_update_hunter_data = JSON.parse(
        localStorage.getItem("update_hunter_data")!
      );

      // if (st_update_hunter_data.ls_status_str == "利用") {
      //   ApiService.postMail({
      //     mail: this.d_update_check_data.ls_account_mail,
      //     status: "22",
      //     login_id: this.d_update_check_data.ls_account_id,
      //     guild_name: this.d_update_check_data.itf_guild_name,
      //     hunter_name: this.d_update_check_data.ls_hunter_name,
      //   });
      // } else if (st_update_hunter_data.ls_status_str == "停止") {
      //   ApiService.postMail({
      //     mail: this.d_update_check_data.ls_account_mail,
      //     status: "24",
      //     login_id: this.d_update_check_data.ls_account_id,
      //     guild_name: this.d_update_check_data.itf_guild_name,
      //     hunter_name: this.d_update_check_data.ls_hunter_name,
      //   });
      // }

      // 以前の更新データが残っている場合削除
      localStorage.removeItem("update_hunter_data");
      localStorage.removeItem("hunter_modify_data");
      localStorage.removeItem("old_hunter_status");
      // 画面遷移_管理画面_ハンター一覧
      this.$router.push({
        name: "managehunterlist",
      });
    },
    // ボタンクリック処理_ヘッダー_戻るボタン
    oyareturntrantransition() {
      this.$router.push({
        name: "managehuntermodify",
      });
    },
  },
});
</script>

<style></style>
