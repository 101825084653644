<template>
  <div
    v-bind:class="
      'contents_main' +
      ' contents_main_background_' +
      d_hunter_data.itf_hunter_rank
    "
  >
    <div id="contents_main_inner">
      <!-- ランク -->
      <div id="background_waku_rank">
        <div id="background_waku_rank_position">
          <div id="rank_text">
            <div
              v-bind:class="'rank_text_' + d_hunter_data.itf_hunter_rank"
              id="rank_text_1"
            >
              ランク
            </div>
            <div
              v-bind:class="'rank_text_' + d_hunter_data.itf_hunter_rank"
              id="rank_text_2"
            >
              {{ d_hunter_data.itf_hunter_rank }}
            </div>
          </div>
          <div id="waku_rank_position">
            <div v-bind:id="'waku_rank_' + d_hunter_data.itf_hunter_rank"></div>
          </div>
        </div>
      </div>
      <div id="background_waku_rank_left">
        <div
          v-bind:id="'waku_rank_left_' + d_hunter_data.itf_hunter_rank"
        ></div>
      </div>
      <div id="background_waku_rank_right">
        <div
          v-bind:id="'waku_rank_right_' + d_hunter_data.itf_hunter_rank"
        ></div>
      </div>

      <!-- ハンターアイコン -->
      <div id="background_waku_huntericon">
        <div id="background_waku_huntericon_position">
          <div
            v-if="
              userIcon === '' || userIcon === 'undefined' || userIcon === null
            "
            v-bind:id="'huntericon_background_' + d_hunter_data.itf_hunter_rank"
          >
            <!-- アイコン未設定 -->
            <!-- デフォルトアイコン -->
            <div class="user_Icon_sizesetting"></div>
          </div>
          <div
            v-else
            v-bind:id="'huntericon_background_' + d_hunter_data.itf_hunter_rank"
          >
            <!-- アイコン設定済み -->
            <!-- ハンターアイコン -->
            <div class="user_Icon_sizesetting">
              <img :src="userIcon" alt="ハンターアイコン" />
            </div>
          </div>
          <!-- ハンターアイコン枠 -->
          <div id="waku_huntericon_position">
            <div
              v-bind:id="'waku_huntericon_' + d_hunter_data.itf_hunter_rank"
            ></div>
          </div>
          <input
            ref="file"
            class="button_icon_select"
            type="file"
            @change="upload"
          />
          <!-- ハンターレベル -->
          <div
            v-bind:id="
              'background_hunter_level_' + d_hunter_data.itf_hunter_rank
            "
          >
            <div id="hunter_level_Lv_level">
              <div
                v-bind:id="'hunter_level_Lv_' + d_hunter_data.itf_hunter_rank"
              >
                Lv
              </div>
              <div class="text_setting_all" id="hunter_level_level">
                {{ d_hunter_data.itf_hunter_level }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 称号 -->
      <div id="background_waku_syogo">
        <div id="waku_syogo_position">
          <div v-bind:id="'waku_syogo_' + d_hunter_data.itf_hunter_rank">
            <div class="text_setting_all" id="syogo_text">
              {{ d_hunter_data.itf_hunter_syougou }}
            </div>
          </div>
        </div>
      </div>
      <!-- ステータス -->
      <div id="background_status">
        <div id="status_hunter_flexcontainer">
          <div class="text_setting_all">
            {{ d_hunter_data.itf_hunter_name }}
          </div>
        </div>
        <div id="status_flexcontainer">
          <div
            v-bind:class="'status_underline_' + d_hunter_data.itf_hunter_rank"
          >
            <div v-bind:id="'status_exp_icon_' + d_hunter_data.itf_hunter_rank">
              <div id="status_exp_icon_text">EXP</div>
            </div>
            <div
              v-bind:class="
                'title_text_setting_' +
                d_hunter_data.itf_hunter_rank +
                ' status_flex_item status_flex_item_left'
              "
            >
              けいけんち
            </div>
            <div
              class="status_flex_item status_flex_item_right text_setting_all"
            >
              {{ d_hunter_data.itf_hunterstatus_exp }}
            </div>
          </div>
          <div
            v-bind:class="'status_underline_' + d_hunter_data.itf_hunter_rank"
          >
            <div id="status_dia_icon"></div>
            <div
              v-bind:class="
                'title_text_setting_' +
                d_hunter_data.itf_hunter_rank +
                ' status_flex_item status_flex_item_left'
              "
            >
              ダイヤ
            </div>
            <div
              class="status_flex_item status_flex_item_right text_setting_all"
            >
              {{ d_hunter_data.itf_hunterstatus_dia }}
              <span
                v-bind:class="
                  'title_text_setting_' + d_hunter_data.itf_hunter_rank
                "
              >
                個
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- 所属ギルド -->
      <div id="background_waku_guild">
        <img src="@/public/00_guild_icon.png" alt="ギルドアイコン" />
      </div>
      <div id="background_waku_guild_left">
        <div
          v-bind:id="'waku_guild_left_' + d_hunter_data.itf_hunter_rank"
        ></div>
      </div>
      <div id="background_waku_guild_right">
        <div
          v-bind:id="'waku_guild_right_' + d_hunter_data.itf_hunter_rank"
        ></div>
      </div>
      <div id="background_guildname">
        <div
          v-bind:class="'status_underline_' + d_hunter_data.itf_hunter_rank"
          id="guildname_title_and_text"
        >
          <div
            v-bind:class="'title_text_setting_' + d_hunter_data.itf_hunter_rank"
            id="guildname_title"
          >
            <div class="guildname_title_inner">所属</div>
            <div class="guildname_title_inner">ギルド</div>
          </div>
          <div id="guildname">
            <div class="text_setting_all">
              {{ d_hunter_data.itf_hunterstatus_guildname }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import ApiService from "../service/ApiService";

/////////////////////////////
// interface(データ入れ物)
/////////////////////////////
// 表示用データ_ハンターデータ
interface show_hunter_data {
  // ハンター名
  itf_hunter_name: string;
  // ハンターランク
  itf_hunter_rank: string;
  // ハンターレベル
  itf_hunter_level: string;
  // 称号
  itf_hunter_syougou: string;
  // 所属ギルド名
  itf_hunterstatus_guildname: string;
  // 現在の全経験値
  itf_hunterstatus_exp: string;
  // つぎのレベルまでに必要な経験値
  itf_hunterstatus_nextlevelexp: string;
  // ダイヤ
  itf_hunterstatus_dia: string;
}
interface ShowHunterData {
  hunter_data: show_hunter_data;
}

export default defineComponent({
  // データ
  data() {
    /////////////////////////////
    // データ取得処理
    // ハンターデータ
    /////////////////////////////
    // reactive(データ変更監視)
    // 更新用データ_ハンターデータ
    var r_show_hunter_data = reactive<ShowHunterData>({
      hunter_data: {
        itf_hunter_name: "",
        itf_hunter_rank: "",
        itf_hunter_level: "",
        itf_hunter_syougou: "",
        itf_hunterstatus_guildname: "",
        itf_hunterstatus_exp: "",
        itf_hunterstatus_nextlevelexp: "",
        itf_hunterstatus_dia: "",
      },
    });
    const hunter_data = computed(() => r_show_hunter_data.hunter_data);

    // ハンターID取得(ローカルストレージ)
    const st_hunter_data = JSON.parse(localStorage.getItem("hunter_data")!);

    // データ取得処理呼び出し
    ApiService.getHunter(st_hunter_data.ls_hunter_id)
      .then((res) => {
        // 処理実行判定
        if (res.status === 200 && res.statusText === "OK") {
          // 正常終了の場合
          const data = res.data;
          this.userIcon = data.hunter_icon_img;
          r_show_hunter_data.hunter_data.itf_hunter_name = data.hunter_name;
          r_show_hunter_data.hunter_data.itf_hunter_rank = data.hunter_rank;
          r_show_hunter_data.hunter_data.itf_hunter_level = data.hunter_level;
          r_show_hunter_data.hunter_data.itf_hunterstatus_guildname =
            data.guild_name
              ? data.guild_name
              : data.account_authority_id === 0
              ? "ハンター協会"
              : "ギルド未加入";
          r_show_hunter_data.hunter_data.itf_hunterstatus_exp =
            data.hunter_point;
          r_show_hunter_data.hunter_data.itf_hunterstatus_nextlevelexp =
            data.hunter_point_to_next_level;
          r_show_hunter_data.hunter_data.itf_hunterstatus_dia =
            data.hunter_diamond;

          // 称号設定
          if (1 <= data.hunter_level && data.hunter_level <= 5) {
            r_show_hunter_data.hunter_data.itf_hunter_syougou =
              "みならいハンター";
          } else if (6 <= data.hunter_level && data.hunter_level <= 21) {
            r_show_hunter_data.hunter_data.itf_hunter_syougou =
              "かけだしハンター";
          } else if (22 <= data.hunter_level && data.hunter_level <= 39) {
            r_show_hunter_data.hunter_data.itf_hunter_syougou = "中級ハンター";
          } else if (40 <= data.hunter_level && data.hunter_level <= 71) {
            r_show_hunter_data.hunter_data.itf_hunter_syougou = "上級ハンター";
          } else if (72 <= data.hunter_level && data.hunter_level <= 80) {
            r_show_hunter_data.hunter_data.itf_hunter_syougou = "熟練ハンター";
          } else if (81 <= data.hunter_level && data.hunter_level <= 98) {
            r_show_hunter_data.hunter_data.itf_hunter_syougou =
              "伝説級ハンター";
          } else if (99 <= data.hunter_level) {
            r_show_hunter_data.hunter_data.itf_hunter_syougou =
              "神話級ハンター";
          }
        }
      })
      .catch((err) => {
        // 異常
        console.log(err);
      });

    /////////////////////////////
    // data設定
    /////////////////////////////
    return {
      // データ_登録更新削除表示対象
      d_hunter_data: hunter_data,
      // ユーザーアイコン画像
      userIcon: "",
    };
  },
  watch: {
    userIcon(newIcon, oldIcon) {
      // 画像を変更したら検知
    },
  },
  // メソッド
  methods: {
    /////////////////////////////
    // ハンターアイコン登録処理
    // ファイル選択で選択した画像を
    // 登録する。
    /////////////////////////////
    async upload(event: any) {
      // 変数設定
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];

      // 画像データ変換とdataにセット処理
      // 画像ファイルのチェック判定
      if (this.checkFile(file)) {
        // 画像ファイルチェックOKの場合
        // 画像ファイルを「ファイル変換(エンコード)処理」で変更しdataセット
        this.getBase64(files[0]).then((imgDataBase64: any) => {
          this.userIcon = imgDataBase64;

          const user = {
            iconImg: this.userIcon,
          };
          const st_hunter_data = JSON.parse(
            localStorage.getItem("hunter_data")!
          );
          ApiService.putHunterIcon(st_hunter_data.ls_hunter_id, user)
            .then((res) => {
              // 登録成功
            })
            .catch((err) => {
              console.log(err.response.data.message);
            });
        });
      }
    },

    /////////////////////////////
    // 登録ファイルチェック処理
    // 登録対象のハンターアイコンの
    // サイズチェック(最大5M)
    /////////////////////////////
    checkFile(file: any) {
      let result = true; // 処理結果
      const SIZE_LIMIT = 5000000; // 最大ファイルサイズ(5MB)

      // ファイル取得結果判定
      if (!file) {
        // 取得できていない場合
        // 処理終了
        result = false;
      }

      // ファイルサイズ判定
      if (file && file.size > SIZE_LIMIT) {
        // 最大値を超えている場合
        // 処理終了
        result = false;
      }

      // 戻り値を返す
      return result;
    },

    /////////////////////////////
    // ファイル変換(エンコード)処理
    // 登録対象のハンターアイコンを
    // DB用に変換する
    /////////////////////////////
    getBase64(file: any) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
  components: {},
});
</script>

<style>
/* フォント設定_全体 */
.text_setting_all {
  color: #333333;
}

/* フォント設定_ランク_項目名 */
#rank_text_1 {
  font-size: 12px;
}
/* フォント設定_ランク_ランク */
#rank_text_2 {
  font-size: 28px;
  line-height: 1.8rem;
}

/* フォント設定_ランク_ランクSSS */
.rank_text_SSS {
  color: rgba(159, 109, 25, 0.6);
  text-shadow: 2px 2px 0 #dfaa5e, 0 0 0 rgb(0, 0, 0);
}

/* フォント設定_ランク_ランクSS */
.rank_text_SS {
  color: rgba(117, 117, 117, 0.6);
  text-shadow: 2px 2px 0 #939293, 0 0 0 rgb(0, 0, 0);
}

/* フォント設定_ランク_ランクS */
.rank_text_S {
  color: rgba(91, 18, 5, 0.6);
  text-shadow: 2px 2px 0 #c37a6c, 0 0 0 rgb(0, 0, 0);
}

/* フォント設定_ランク_ランクA */
.rank_text_A {
  color: rgba(138, 45, 45, 0.6);
  text-shadow: 2px 2px 0 #efe2c3, 0 0 0 rgb(0, 0, 0);
}

/* フォント設定_ランク_ランクB */
.rank_text_B {
  color: rgba(0, 95, 99, 0.6);
  text-shadow: 2px 2px 0 #efe2c3, 0 0 0 rgb(0, 0, 0);
}

/* フォント設定_ランク_ランクC */
.rank_text_C {
  color: rgba(164, 73, 33, 0.6);
  text-shadow: 2px 2px 0 #efe2c3, 0 0 0 rgb(0, 0, 0);
}

/* フォント設定_ランク_ランクD */
.rank_text_D {
  color: rgba(91, 58, 2, 0.6);
  text-shadow: 2px 2px 0 #efe2c3, 0 0 0 rgb(0, 0, 0);
}

/* フォント設定_ステータス_各要素(flex) */
.status_flex_item {
  font-size: 14px;
  line-height: 1.2rem;
}

/* コンテンツ枠_メイン */
.contents_main {
  background-color: #ffffff;
  width: 95%;
  height: 95%;
  align-self: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ハンターカード_背景_ランク_SSS */
.contents_main.contents_main_background_SSS {
  background-image: url("../public/01_huntercard_background_SSS.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* ハンターカード_背景_ランク_SS */
.contents_main.contents_main_background_SS {
  background-image: url("../public/02_huntercard_background_SS.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* ハンターカード_背景_ランク_S */
.contents_main.contents_main_background_S {
  background-image: url("../public/03_huntercard_background_S.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* ハンターカード_背景_ランク_A */
.contents_main.contents_main_background_A {
  background-image: url("../public/04_huntercard_background_A.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* ハンターカード_背景_ランク_B */
.contents_main.contents_main_background_B {
  background-image: url("../public/05_huntercard_background_B.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* ハンターカード_背景_ランク_C */
.contents_main.contents_main_background_C {
  background-image: url("../public/06_huntercard_background_C.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* ハンターカード_背景_ランク_D */
.contents_main.contents_main_background_D {
  background-image: url("../public/07_huntercard_background_D.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* メイン_内容(グリッド) */
#contents_main_inner {
  width: 85%;
  height: 90%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: 35% 30% 35%;
  grid-template-rows: 10% 45% 6% 22% 8% 9%;
}

/* 装飾_ランク枠(グリッド) */
#background_waku_rank {
  grid-row: 1/2;
  grid-column: 2/3;
  text-align: center;
  white-space: nowrap;
}

/* 装飾_ランク枠_左(グリッド) */
#background_waku_rank_left {
  grid-row: 1/2;
  grid-column: 1/2;
}

/* 装飾_ランク枠_右(グリッド) */
#background_waku_rank_right {
  grid-row: 1/2;
  grid-column: 3/4;
}

/* 装飾_ハンターアイコン(グリッド) */
#background_waku_huntericon {
  grid-row: 2/3;
  grid-column: 1/4;
}

/* 装飾_称号(グリッド) */
#background_waku_syogo {
  grid-row: 3/4;
  grid-column: 1/4;
}

/* 装飾_ステータス(グリッド) */
#background_status {
  grid-row: 4/5;
  grid-column: 1/4;
}

/* 装飾_ギルド枠(グリッド) */
#background_waku_guild {
  grid-row: 5/6;
  grid-column: 2/3;
}

/* 装飾_ギルド枠_左(グリッド) */
#background_waku_guild_left {
  grid-row: 5/6;
  grid-column: 1/2;
}

/* 装飾_ギルド枠_右(グリッド) */
#background_waku_guild_right {
  grid-row: 5/6;
  grid-column: 3/4;
}

/* 装飾_ギルド名(グリッド) */
#background_guildname {
  grid-row: 6/7;
  grid-column: 1/4;
}

/* ギルドアイコン */
#background_waku_guild img {
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_ランク全体_サイズ位置調整 */
#background_waku_rank_position {
  width: 100%;
  height: 100%;
  position: relative;
}

/* 装飾_ランク文字_サイズ位置調整 */
#rank_text {
  position: relative;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_ランク枠_サイズ位置調整 */
#waku_rank_position {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_ランク枠_SSS */
#background_waku_rank #waku_rank_position #waku_rank_SSS {
  width: 100%;
  height: 100%;
  min-width: 85px;
  min-height: 50px;
  margin: -20% 0%;
  padding: 20% 0%;
  background-image: url("../public/01_huntercard_rankwaku_SSS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/* 装飾_ランク枠_SS */
#background_waku_rank #waku_rank_position #waku_rank_SS {
  width: 100%;
  height: 100%;
  margin: -20% 0%;
  padding: 20% 0%;
  margin: -20% 0%;
  padding: 20% 0%;
  background-image: url("../public/02_huntercard_rankwaku_SS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/* 装飾_ランク枠_S */
#background_waku_rank #waku_rank_position #waku_rank_S {
  width: 100%;
  height: 100%;
  margin: -20% 0%;
  padding: 20% 0%;
  margin: -20% 0%;
  padding: 20% 0%;
  background-image: url("../public/03_huntercard_rankwaku_S.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/* 装飾_ランク枠_A */
#background_waku_rank #waku_rank_position #waku_rank_A {
  width: 100%;
  height: 100%;
  margin: -20% 0%;
  padding: 20% 0%;
  margin: -20% 0%;
  padding: 20% 0%;
  background-image: url("../public/04_huntercard_rankwaku_A.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/* 装飾_ランク枠_B */
#background_waku_rank #waku_rank_position #waku_rank_B {
  width: 100%;
  height: 100%;
  margin: -20% 0%;
  padding: 20% 0%;
  margin: -20% 0%;
  padding: 20% 0%;
  background-image: url("../public/05_huntercard_rankwaku_B.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/* 装飾_ランク枠_C */
#background_waku_rank #waku_rank_position #waku_rank_C {
  width: 100%;
  height: 100%;
  margin: -20% 0%;
  padding: 20% 0%;
  margin: -20% 0%;
  padding: 20% 0%;
  background-image: url("../public/06_huntercard_rankwaku_C.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/* 装飾_ランク枠_D */
#background_waku_rank #waku_rank_position #waku_rank_D {
  width: 100%;
  height: 100%;
  margin: -20% 0%;
  padding: 20% 0%;
  margin: -20% 0%;
  padding: 20% 0%;
  background-image: url("../public/07_huntercard_rankwaku_D.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* 装飾_ランク枠_左_ランク_SSS */
#background_waku_rank_left #waku_rank_left_SSS {
  width: 100%;
  height: 100%;
  background-image: url("../public/01_huntercard_rank_wakurine_01_SSS.png");
  background-repeat: no-repeat;
  background-size: 90% auto;
  background-position: left;
}
/* 装飾_ランク枠_右_ランク_SSS */
#background_waku_rank_right #waku_rank_right_SSS {
  width: 100%;
  height: 100%;
  background-image: url("../public/01_huntercard_rank_wakurine_02_SSS.png");
  background-repeat: no-repeat;
  background-size: 90% auto;
  background-position: right;
}

/* 装飾_ランク枠_左_ランク_SS */
#background_waku_rank_left #waku_rank_left_SS {
  width: 90%;
  height: 100%;
  background-image: url("../public/02_huntercard_rank_wakurine_01_SS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
/* 装飾_ランク枠_右_ランク_SS */
#background_waku_rank_right #waku_rank_right_SS {
  width: 90%;
  height: 100%;
  background-image: url("../public/02_huntercard_rank_wakurine_02_SS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_ランク枠_左_ランク_S */
#background_waku_rank_left #waku_rank_left_S {
  width: 90%;
  height: 100%;
  background-image: url("../public/03_huntercard_rank_wakurine_01_S.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
/* 装飾_ランク枠_右_ランク_S */
#background_waku_rank_right #waku_rank_right_S {
  width: 90%;
  height: 100%;
  background-image: url("../public/03_huntercard_rank_wakurine_02_S.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_ランク枠_左_ランク_A */
#background_waku_rank_left #waku_rank_left_A {
  width: 90%;
  height: 100%;
  background-image: url("../public/04_huntercard_rank_wakurine_01_A.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
/* 装飾_ランク枠_右_ランク_A */
#background_waku_rank_right #waku_rank_right_A {
  width: 90%;
  height: 100%;
  background-image: url("../public/04_huntercard_rank_wakurine_02_A.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_ランク枠_左_ランク_B */
#background_waku_rank_left #waku_rank_left_B {
  width: 90%;
  height: 100%;
  background-image: url("../public/05_huntercard_rank_wakurine_01_B.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
/* 装飾_ランク枠_右_ランク_B */
#background_waku_rank_right #waku_rank_right_B {
  width: 90%;
  height: 100%;
  background-image: url("../public/05_huntercard_rank_wakurine_02_B.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_ランク枠_左_ランク_C */
#background_waku_rank_left #waku_rank_left_C {
  width: 90%;
  height: 100%;
  background-image: url("../public/06_huntercard_rank_wakurine_01_C.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
/* 装飾_ランク枠_右_ランク_C */
#background_waku_rank_right #waku_rank_right_C {
  width: 90%;
  height: 100%;
  background-image: url("../public/06_huntercard_rank_wakurine_02_C.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_ランク枠_左_ランク_D */
#background_waku_rank_left #waku_rank_left_D {
  width: 90%;
  height: 100%;
  background-image: url("../public/07_huntercard_rank_wakurine_01_D.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
/* 装飾_ランク枠_右_ランク_D */
#background_waku_rank_right #waku_rank_right_D {
  width: 90%;
  height: 100%;
  background-image: url("../public/07_huntercard_rank_wakurine_02_D.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_称号_テキスト */
#syogo_text {
  font-size: 13px;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_称号_位置設定 */
#waku_syogo_position {
  width: 100%;
  height: 100%;
  max-height: 55px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_称号_ランク_SSS */
#background_waku_syogo #waku_syogo_position #waku_syogo_SSS {
  width: 60%;
  height: 100%;
  background-image: url("../public/01_huntercard_syougo_SSS.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_称号_ランク_SS */
#background_waku_syogo #waku_syogo_position #waku_syogo_SS {
  width: 60%;
  height: 100%;
  background-image: url("../public/02_huntercard_syougo_SS.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_称号_ランク_S */
#background_waku_syogo #waku_syogo_position #waku_syogo_S {
  width: 60%;
  height: 100%;
  background-image: url("../public/03_huntercard_syougo_S.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_称号_ランク_A以下 */
#background_waku_syogo #waku_syogo_position #waku_syogo_A,
#waku_syogo_B,
#waku_syogo_C,
#waku_syogo_D {
  width: 60%;
  height: 100%;
  background-image: url("../public/04_huntercard_syougo_A_D.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_ハンターアイコン枠_サイズ位置設定 */
#background_waku_huntericon_position {
  width: 80%;
  height: 100%;
  max-width: 250px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* 装飾_ハンターアイコン_位置設定 */
#background_waku_huntericon_position img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_ハンターアイコン背景_ランク_SSS*/
#huntericon_background_SSS {
  width: 75%;
  background-color: #efefef;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#huntericon_background_SSS:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* 装飾_ハンターアイコン背景_ランク_SS*/
#huntericon_background_SS {
  width: 75%;
  background-color: #efefef;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#huntericon_background_SS:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* 装飾_ハンターアイコン背景_ランク_S*/
#huntericon_background_S {
  width: 75%;
  background-color: #efefef;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#huntericon_background_S:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* 装飾_ハンターアイコン背景_ランク_A*/
#huntericon_background_A {
  width: 75%;
  background-color: #efefef;
  border-radius: 20px 20px 20px 20px;
  border: 3px solid #f24545;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#huntericon_background_A:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* 装飾_ハンターアイコン背景_ランク_B*/
#huntericon_background_B {
  width: 75%;
  background-color: #efefef;
  border-radius: 20px 20px 20px 20px;
  border: 3px solid #00979d;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#huntericon_background_B:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* 装飾_ハンターアイコン背景_ランク_C*/
#huntericon_background_C {
  width: 75%;
  background-color: #efefef;
  border-radius: 20px 20px 20px 20px;
  border: 3px solid #f0813d;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#huntericon_background_C:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* 装飾_ハンターアイコン背景_ランク_D*/
#huntericon_background_D {
  width: 75%;
  background-color: #efefef;
  border-radius: 20px 20px 20px 20px;
  border: 3px solid #8a3802;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#huntericon_background_D:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* 装飾_ハンターアイコン_サイズ位置設定 */
.user_Icon_sizesetting {
  width: 80%;
  height: 80%;
  max-width: 120px;
  max-height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_ハンターアイコン_画像サイズ設定 */
.user_Icon_sizesetting img {
  max-height: 100%;
}

/* 装飾_ハンターアイコン枠_サイズ位置設定 */
#waku_huntericon_position {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_ハンターアイコン枠_ランク_SSS */
#background_waku_huntericon #waku_huntericon_position #waku_huntericon_SSS {
  width: 100%;
  height: 100%;
  margin: -10% -9%;
  padding: 10% 6%;
  background-image: url("../public/01_huntercard_iconwaku_SSS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_ハンターアイコン枠_ランク_SS */
#background_waku_huntericon #waku_huntericon_position #waku_huntericon_SS {
  width: 95%;
  height: 100%;
  margin: -3% -4%;
  padding: 3% 4%;
  background-image: url("../public/02_huntercard_iconwaku_SS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_ハンターアイコン枠_ランク_S */
#background_waku_huntericon #waku_huntericon_position #waku_huntericon_S {
  width: 90%;
  height: 100%;
  margin: -5% 0%;
  padding: 5% 5%;
  background-image: url("../public/03_huntercard_iconwaku_S.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* ハンターレベル_背景_ランク_SSS */
#background_hunter_level_SSS {
  width: 25%;
  max-width: 50px;
  max-height: 50px;
  background-color: #ffffff;
  border: double 5px #6d4709;
  border-radius: 150px;
  position: absolute;
  top: 5%;
  right: 5%;
  text-align: center;
}
#background_hunter_level_SSS:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* ハンターレベル_背景_ランク_SS */
#background_hunter_level_SS {
  width: 25%;
  max-width: 50px;
  max-height: 50px;
  background-color: #ffffff;
  border: double 5px #575756;
  border-radius: 150px;
  position: absolute;
  top: 5%;
  right: 5%;
  text-align: center;
}
#background_hunter_level_SS:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* ハンターレベル_背景_ランク_S */
#background_hunter_level_S {
  width: 25%;
  max-width: 50px;
  max-height: 50px;
  background-color: #ffffff;
  border: double 5px #5b1205;
  border-radius: 150px;
  position: absolute;
  top: 5%;
  right: 5%;
  text-align: center;
}
#background_hunter_level_S:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* ハンターレベル_背景_ランク_A */
#background_hunter_level_A {
  width: 25%;
  max-width: 50px;
  max-height: 50px;
  background-color: #ffffff;
  border: double 5px #8a2d2d;
  border-radius: 150px;
  position: absolute;
  top: 5%;
  right: 5%;
  text-align: center;
}
#background_hunter_level_A:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* ハンターレベル_背景_ランク_B */
#background_hunter_level_B {
  width: 25%;
  max-width: 50px;
  max-height: 50px;
  background-color: #ffffff;
  border: double 5px #005f63;
  border-radius: 150px;
  position: absolute;
  top: 5%;
  right: 5%;
  text-align: center;
}
#background_hunter_level_B:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* ハンターレベル_背景_ランク_C */
#background_hunter_level_C {
  width: 25%;
  max-width: 50px;
  max-height: 50px;
  background-color: #ffffff;
  border: double 5px #a44921;
  border-radius: 150px;
  position: absolute;
  top: 5%;
  right: 5%;
  text-align: center;
}
#background_hunter_level_C:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* ハンターレベル_背景_ランク_D */
#background_hunter_level_D {
  width: 25%;
  max-width: 50px;
  max-height: 50px;
  background-color: #ffffff;
  border: double 5px #5b3a02;
  border-radius: 150px;
  position: absolute;
  top: 5%;
  right: 5%;
  text-align: center;
}
#background_hunter_level_D:before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* ハンターレベル_Lvと数値 */
#hunter_level_Lv_level {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

/* ハンターレベル_Lv_ランク_SSS */
#hunter_level_Lv_SSS {
  font-size: 14px;
  color: #6d4709;
}

/* ハンターレベル_Lv_ランク_SS */
#hunter_level_Lv_SS {
  font-size: 14px;
  color: #575756;
}

/* ハンターレベル_Lv_ランク_S */
#hunter_level_Lv_S {
  font-size: 14px;
  color: #5b1205;
}

/* ハンターレベル_Lv_ランク_A */
#hunter_level_Lv_A {
  font-size: 14px;
  color: #8a2d2d;
}

/* ハンターレベル_Lv_ランク_B */
#hunter_level_Lv_B {
  font-size: 14px;
  color: #005f63;
}

/* ハンターレベル_Lv_ランク_C */
#hunter_level_Lv_C {
  font-size: 14px;
  color: #a44921;
}

/* ハンターレベル_Lv_ランク_D */
#hunter_level_Lv_D {
  font-size: 14px;
  color: #5b3a02;
}

/* ハンターレベル_数値 */
#hunter_level_level {
  font-size: 20px;
  margin-top: -20%;
}

/* 装飾_ハンター名_全体(flex) */
#status_hunter_flexcontainer {
  font-size: min(6.15vw, 21px);
  width: 85%;
  margin: 2.5% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  white-space: nowrap;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* 装飾_ステータス_全体(flex) */
#status_flexcontainer {
  width: 70%;
  min-width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* 装飾_ステータス_各要素_左側(flex) */
.status_flex_item_left {
  width: 50%;
}

/* 装飾_ステータス_各要素_右側(flex) */
.status_flex_item_right {
  width: 30%;
  text-align: right;
}

/* ステータス_けいけんち_アイコン_ランク_SSS(flex) */
#status_exp_icon_SSS {
  width: 12.5%;
  height: 38px;
  max-width: 26px;
  font-size: 10px;
  color: #6d4709;
  background-image: url("../public/01_huntercard_exp_background_SSS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* ステータス_けいけんち_アイコン_ランク_SS(flex) */
#status_exp_icon_SS {
  width: 12.5%;
  height: 38px;
  max-width: 26px;
  font-size: 10px;
  color: #575756;
  background-image: url("../public/02_huntercard_exp_background_SS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* ステータス_けいけんち_アイコン_ランク_S(flex) */
#status_exp_icon_S {
  width: 12.5%;
  height: 38px;
  max-width: 26px;
  font-size: 10px;
  color: #5b1205;
  background-image: url("../public/03_huntercard_exp_background_S.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* ステータス_けいけんち_アイコン_ランク_A(flex) */
#status_exp_icon_A {
  width: 12.5%;
  height: 38px;
  max-width: 26px;
  font-size: 10px;
  color: #8a2d2d;
  background-image: url("../public/04_huntercard_exp_background_A.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* ステータス_けいけんち_アイコン_ランク_B(flex) */
#status_exp_icon_B {
  width: 12.5%;
  height: 38px;
  max-width: 26px;
  font-size: 10px;
  color: #005f63;
  background-image: url("../public/05_huntercard_exp_background_B.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* ステータス_けいけんち_アイコン_ランク_C(flex) */
#status_exp_icon_C {
  width: 12.5%;
  height: 38px;
  max-width: 26px;
  font-size: 10px;
  color: #a44921;
  background-image: url("../public/06_huntercard_exp_background_C.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* ステータス_けいけんち_アイコン_ランク_D(flex) */
#status_exp_icon_D {
  width: 12.5%;
  height: 38px;
  max-width: 26px;
  font-size: 10px;
  color: #5b3a02;
  background-image: url("../public/07_huntercard_exp_background_D.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* ステータス_ダイヤ_アイコン */
#status_exp_icon_text {
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ステータス_ダイヤ_アイコン */
#status_dia_icon {
  width: 12.5%;
  height: 38px;
  max-width: 26px;
  background-image: url("../public/userInfo_userDia.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

/* 装飾_下線_ランク_SSS */
.status_underline_SSS {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid #6d4709;
}

/* 装飾_下線_ランク_SS */
.status_underline_SS {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid #575756;
}

/* 装飾_下線_ランク_S */
.status_underline_S {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid #5b1205;
}

/* 装飾_下線_ランク_A */
.status_underline_A {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid #8a2d2d;
}

/* 装飾_下線_ランク_B */
.status_underline_B {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid #005f63;
}

/* 装飾_下線_ランク_C */
.status_underline_C {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid #a44921;
}

/* 装飾_下線_ランク_D */
.status_underline_D {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid #5b3a02;
}

/* 装飾_ギルド枠_左_ランク_SSS */
#background_waku_guild_left #waku_guild_left_SSS {
  width: 100%;
  height: 100%;
  background-image: url("../public/01_huntercard_guild_wakurine_01_SSS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
}
/* 装飾_ギルド枠_右_ランク_SSS */
#background_waku_guild_right #waku_guild_right_SSS {
  width: 100%;
  height: 100%;
  background-image: url("../public/01_huntercard_guild_wakurine_02_SSS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right;
}

/* 装飾_ギルド枠_左_ランク_SS */
#background_waku_guild_left #waku_guild_left_SS {
  width: 100%;
  height: 100%;
  background-image: url("../public/02_huntercard_guild_wakurine_01_SS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
}
/* 装飾_ギルド枠_右_ランク_SS */
#background_waku_guild_right #waku_guild_right_SS {
  width: 100%;
  height: 100%;
  background-image: url("../public/02_huntercard_guild_wakurine_02_SS.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right;
}

/* 装飾_ギルド枠_左_ランク_S */
#background_waku_guild_left #waku_guild_left_S {
  width: 100%;
  height: 100%;
  background-image: url("../public/03_huntercard_guild_wakurine_01_S.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
}
/* 装飾_ギルド枠_右_ランク_S */
#background_waku_guild_right #waku_guild_right_S {
  width: 100%;
  height: 100%;
  background-image: url("../public/03_huntercard_guild_wakurine_02_S.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right;
}

/* 装飾_ギルド枠_左_ランク_A */
#background_waku_guild_left #waku_guild_left_A {
  width: 100%;
  height: 100%;
  background-image: url("../public/04_huntercard_guild_wakurine_01_A.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
}
/* 装飾_ギルド枠_右_ランク_A */
#background_waku_guild_right #waku_guild_right_A {
  width: 100%;
  height: 100%;
  background-image: url("../public/04_huntercard_guild_wakurine_02_A.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right;
}

/* 装飾_ギルド枠_左_ランク_B */
#background_waku_guild_left #waku_guild_left_B {
  width: 100%;
  height: 100%;
  background-image: url("../public/05_huntercard_guild_wakurine_01_B.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
}
/* 装飾_ギルド枠_右_ランク_B */
#background_waku_guild_right #waku_guild_right_B {
  width: 100%;
  height: 100%;
  background-image: url("../public/05_huntercard_guild_wakurine_02_B.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right;
}

/* 装飾_ギルド枠_左_ランク_C */
#background_waku_guild_left #waku_guild_left_C {
  width: 100%;
  height: 100%;
  background-image: url("../public/06_huntercard_guild_wakurine_01_C.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
}
/* 装飾_ギルド枠_右_ランク_C */
#background_waku_guild_right #waku_guild_right_C {
  width: 100%;
  height: 100%;
  background-image: url("../public/06_huntercard_guild_wakurine_02_C.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right;
}

/* 装飾_ギルド枠_左_ランク_D */
#background_waku_guild_left #waku_guild_left_D {
  width: 100%;
  height: 100%;
  background-image: url("../public/07_huntercard_guild_wakurine_01_D.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
}
/* 装飾_ギルド枠_右_ランク_D */
#background_waku_guild_right #waku_guild_right_D {
  width: 100%;
  height: 100%;
  background-image: url("../public/07_huntercard_guild_wakurine_02_D.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right;
}

/* 装飾_ギルド名_全体 */
#guildname_title_and_text {
  width: 70%;
  height: 80%;
  min-width: 200px;
  justify-content: start;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 装飾_ギルド名_タイトル */
#guildname_title {
  width: fit-content;
  font-size: 12px;
  margin-right: 2.5%;
}

/* 装飾_ギルド名_タイトル_所属 */
.guildname_title_inner {
  text-align-last: justify;
}

/* 装飾_ギルド名 */
#guildname {
  font-size: min(5.25vw, 20px);
  display: inline-block;
  white-space: normal;
  line-height: 1.2rem;
}

/* ハンターアイコン_ファイル選択ボタン */
.button_icon_select {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
}

/* 装飾_項目タイトル_全体_文字設定_ランクSSS */
.title_text_setting_SSS {
  color: #6d4709;
  display: inline-block;
}

/* 装飾_項目タイトル_全体_文字設定_ランクSS */
.title_text_setting_SS {
  color: #575756;
  display: inline-block;
}

/* 装飾_項目タイトル_全体_文字設定_ランクS */
.title_text_setting_S {
  color: #5b1205;
  display: inline-block;
}

/* 装飾_項目タイトル_全体_文字設定_ランクA */
.title_text_setting_A {
  color: #8a2d2d;
  display: inline-block;
}

/* 装飾_項目タイトル_全体_文字設定_ランクB */
.title_text_setting_B {
  color: #005f63;
  display: inline-block;
}

/* 装飾_項目タイトル_全体_文字設定_ランクC */
.title_text_setting_C {
  color: #a44921;
  display: inline-block;
}

/* 装飾_項目タイトル_全体_文字設定_ランクD */
.title_text_setting_D {
  color: #5b3a02;
  display: inline-block;
}
</style>
