<template>
  <!-- クエスト登録画面 -->
  <div class="main2 manage_registmodifydelete_contents_main">
    <!-- ヘッダー -->
    <div class="grid_small_header">
      <!-- ヘッダー -->
      <SmallHeader
        show_button_return="OK"
        @return-transition="oyareturntrantransition()"
      >
        <template v-slot:slot_small_header_title>クエスト管理</template>
      </SmallHeader>
    </div>
    <!-- メイン -->
    <div class="grid_main2_contents background_border_whiteclear">
      <!-- 画面タイトル -->
      <div class="page_title">
        <p>クエスト登録</p>
      </div>
      <!-- フォーム(入力) -->
      <form class="scroll_form">
        <!-- 所属ギルド -->
        <p
          v-show="isHunterAsscociation"
          class="f_fieldset_contents"
          id="guild_list"
        >
          <label
            for="f_quest_guild"
            class="input_contents_label label_border_blue"
          >
            対象ギルド
          </label>
          <span class="required_icon"></span>
          <br />
          <select
            v-model="d_quest_data.itf_quest_guild"
            v-on:change="onChangeGuildSelect()"
          >
            <option
              v-for="guild_list in d_guild_list_data"
              v-bind:key="guild_list.id"
              :value="guild_list"
            >
              {{ guild_list.name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ errorMessage[13] }}</label>
        </p>
        <!-- 依頼者 -->
        <p class="f_fieldset_contents" id="client_list">
          <label
            for="f_quest_client"
            class="input_contents_label label_border_blue"
          >
            依頼者
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="d_quest_data.itf_quest_client">
            <option
              v-for="client_list in d_client_list_data"
              v-bind:key="client_list.id"
              :value="client_list"
            >
              {{ client_list.name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ errorMessage[4] }}</label>
        </p>
        <p class="f_fieldset_contents">
          <label
            for="f_quest_title"
            class="input_contents_label label_border_blue"
          >
            クエストタイトル
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="text"
            name="f_quest_title"
            class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
            id="f_quest_title"
            tabindex="1"
            @keyup="check_quest_title"
            v-model="d_quest_data.itf_quest_title"
            maxlength="12"
          />
          <br />

          <label class="err_message_red">{{ errorMessage[0] }}</label>
        </p>
        <p class="f_fieldset_contents" id="quest_list">
          <label
            for="f_quest_rank"
            class="input_contents_label label_border_blue"
          >
            クエストランク
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="selected_rank" tabindex="2">
            <option
              v-for="rank in d_rank_data"
              v-bind:key="rank.id"
              :value="rank"
            >
              {{ rank.name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ errorMessage[1] }}</label>
        </p>
        <!-- 獲得経験値 -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_point"
            class="input_contents_label label_border_blue"
          >
            獲得経験値
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="exp_get_value" tabindex="3">
            <option
              v-for="exp_list in d_exp_list"
              v-bind:key="exp_list.itf_exp_id"
              :value="exp_list"
            >
              {{ exp_list.itf_exp_name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ errorMessage[14] }}</label>
        </p>
        <!-- 取得ダイヤ -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_point"
            class="input_contents_label label_border_blue"
          >
            獲得ダイヤ
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="dia_get_value" tabindex="4">
            <option
              v-for="dia_list in d_dia_list"
              v-bind:key="dia_list.itf_dia_id"
              :value="dia_list"
            >
              {{ dia_list.itf_dia_name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ errorMessage[15] }}</label>
        </p>
        <p class="f_fieldset_contents">
          <label
            for="f_quest_capacity"
            class="input_contents_label label_border_blue"
          >
            募集人数
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="number"
            name="f_quest_capacity"
            class="f_input_text f_input_size_4 manage_registmodifydelete_f_input_text"
            id="f_quest_capacity"
            tabindex="5"
            v-model="d_quest_data.itf_quest_lower_capacity"
            min="0"
            max="999"
          />
          <span class="quest_capacity_word f_fieldset_contents_value">
            名～
          </span>
          <input
            type="number"
            name="f_quest_capacity"
            class="f_input_text f_input_size_4 manage_registmodifydelete_f_input_text"
            id="f_quest_capacity"
            tabindex="6"
            v-model="d_quest_data.itf_quest_upper_capacity"
            min="0"
            max="999"
          />
          <span class="quest_capacity_word f_fieldset_contents_value">
            名
          </span>
          <br />
          <label class="err_message_red">
            {{ errorMessage[2] }}
          </label>
          <label class="err_message_red">
            {{ errorMessage[9] }}
          </label>
          <label class="err_message_red">
            {{ errorMessage[10] }}
          </label>
        </p>
        <p class="f_fieldset_contents" id="quest_list">
          <label
            for="f_quest_category"
            class="input_contents_label label_border_blue"
          >
            カテゴリ
          </label>
          <span class="required_icon"></span>
          <br />
          <select v-model="selected_category" tabindex="7">
            <option
              v-for="category in d_category_data"
              v-bind:key="category.id"
              :value="category"
            >
              {{ category.name }}
            </option>
          </select>
          <br />
          <label class="err_message_red">{{ errorMessage[3] }}</label>
        </p>
        <!-- <p class="f_fieldset_contents">
          <label
            for="f_quest_requester"
            class="input_contents_label label_border_blue"
          >
            依頼者
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="text"
            name="f_quest_requester"
            class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
            id="f_quest_requester"
            tabindex="8"
            @keyup="check_quest_requester"
            v-model="d_quest_data.itf_quest_requester"
            maxlength="12"
          />
          <label class="err_message_red">{{ errorMessage[4] }}</label>
        </p> -->
        <p class="f_fieldset_contents">
          <label
            for="f_quest_contents"
            class="input_contents_label label_border_blue"
          >
            依頼内容
          </label>
          <span class="required_icon"></span>
          <br />
          <textarea
            cols="30"
            rows="10"
            name="f_quest_contents"
            class="f_input_text f_input_textarea manage_registmodifydelete_f_input_text"
            id="f_quest_contents"
            tabindex="9"
            @keyup="check_quest_contents"
            v-model="d_quest_data.itf_quest_contents"
            maxlength="255"
          >
          </textarea>
          <br />
          <label class="err_message_red">{{ errorMessage[5] }}</label>
        </p>
        <p class="f_fieldset_contents">
          <label
            for="f_quest_location"
            class="input_contents_label label_border_blue"
          >
            作業場所
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="text"
            name="f_quest_location"
            class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
            id="f_quest_location"
            tabindex="10"
            @keyup="check_quest_location"
            v-model="d_quest_data.itf_quest_location"
            maxlength="12"
          />
          <br />
          <label class="err_message_red">{{ errorMessage[6] }}</label>
        </p>
        <p class="f_fieldset_contents" id="quest_list">
          <label
            for="f_age_limit"
            class="input_contents_label label_border_blue"
          >
            対象年齢
          </label>
          <span class="required_icon"></span>
          <br />
          <input
            type="number"
            name="f_age_limit"
            class="f_input_text f_input_size_4 manage_registmodifydelete_f_input_text"
            id="f_age_limit"
            tabindex="11"
            v-model="d_quest_data.itf_quest_age_limit"
            min="0"
            max="99"
          />
          <span class="quest_capacity_word f_fieldset_contents_value">
            歳以上
          </span>
          <br />
          <label class="err_message_red">{{ errorMessage[12] }}</label>
        </p>
        <fieldset class="input_fieldset input_fieldset_3">
          <legend class="legend_style">
            作業日時
            <span class="required_icon"></span>
          </legend>
          <p class="f_fieldset_contents">
            <label
              for="f_quest_start_date"
              class="input_contents_label label_border_blue"
            >
              開始
            </label>
            <br />
            <input
              type="datetime-local"
              name="f_quest_start_date"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_quest_start_date"
              max="9999-12-31T23:59"
              tabindex="12"
              v-model="d_quest_data.itf_quest_start_date"
            />
            <br />
            <label class="err_message_red">{{ errorMessage[7] }}</label>
          </p>
          <p class="f_fieldset_contents">
            <label
              for="f_quest_limit_date"
              class="input_contents_label label_border_blue"
            >
              終了
            </label>
            <br />
            <input
              type="datetime-local"
              name="f_quest_limit_date"
              class="f_input_text f_input_size_1 manage_registmodifydelete_f_input_text"
              id="f_quest_limit_date"
              max="9999-12-31T23:59"
              tabindex="13"
              v-model="d_quest_data.itf_quest_limit_date"
            />
            <br />
            <label class="err_message_red">
              {{ errorMessage[8] }}
            </label>
            <label class="err_message_red">
              {{ errorMessage[11] }}
            </label>
          </p>
        </fieldset>
        <!-- 操作ボタングループ -->
        <div class="operation_button_group">
          <!-- ボタン_戻る -->
          <GenericButton
            type="button"
            :class_name="class_name"
            :message="message"
            tabindex="14"
            v-on:click="return_transition()"
          />
          <!-- ボタン_確認 -->
          <GenericButton
            type="button"
            :class_name="class_name2"
            :message="message2"
            tabindex="15"
            v-on:click="confirmation_data_quest()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import SmallHeader from "../components/SmallHeader.vue";
import GenericButton from "../components/GenericButton.vue";
import ApiService from "../service/ApiService";

interface input_quest_data {
  itf_quest_title: string;
  itf_quest_lower_capacity: string;
  itf_quest_upper_capacity: string;
  itf_quest_category: string;
  // itf_quest_requester: string;
  itf_quest_contents: string;
  itf_quest_age_limit: string;
  itf_quest_location: string;
  itf_quest_start_date: string;
  itf_quest_limit_date: string;
  itf_quest_guild: input_guild_list_data;
  itf_quest_client: input_client_list_data;
}

interface RegistQuestState {
  quest_data: input_quest_data;
}

// 所属ギルドリスト用データ
interface input_guild_list_data {
  // ギルドID(画面非表示)
  id: number;
  // ギルド名
  name: string;
}
interface ModifyGuildListData {
  guild_list_data: input_guild_list_data[];
}

// 所属依頼者リスト用データ
interface input_client_list_data {
  // 依頼者ID(画面非表示)
  id: number;
  // 依頼者名
  name: string;
}
interface ModifyClientListData {
  client_list_data: input_client_list_data[];
}

////////////////////////////////////////
// 取得経験値ドロップダウン用
////////////////////////////////////////
interface exp_data_catch {
  // 取得経験値ID(画面非表示)
  itf_exp_id: number;
  // 取得経験値
  itf_exp_name: string;
}
interface ExpState {
  exp_item: exp_data_catch[];
}

////////////////////////////////////////
// 取得ダイヤドロップダウン用
////////////////////////////////////////
interface dia_data_catch {
  // 取得ダイヤD(画面非表示)
  itf_dia_id: number;
  // 取得ダイヤ
  itf_dia_name: string;
}
interface DiaState {
  dia_item: dia_data_catch[];
}

export default defineComponent({
  // データ
  data() {
    ////////////////////////////////
    // 変数宣言
    //////////////////////////////////
    // クエストランクドロップダウン初期値用
    var farst_questrank_id = 10;
    var farst_questrank_name = "D";
    // カテゴリドロップダウン初期値用
    var farst_category_id = 0;
    var farst_category_name = "";
    // 取得経験値ドロップダウン初期値設定用
    let first_exp_id = 0;
    let first_exp_name = "";
    // ランク別取得経験値デフォルト
    let exp_center_rank_D = 10;
    let exp_center_rank_C = 17;
    let exp_center_rank_B = 27;
    let exp_center_rank_A = 40;
    let exp_center_rank_S = 57;
    let exp_center_rank_SS = 78;
    let exp_center_rank_SSS = 104;
    // ランク別取得経験値範囲
    let exp_range_rank_D = 3;
    let exp_range_rank_C = 3;
    let exp_range_rank_B = 6;
    let exp_range_rank_A = 6;
    let exp_range_rank_S = 10;
    let exp_range_rank_SS = 10;
    let exp_range_rank_SSS = 15;
    // 取得経験値最大値最小値
    let exp_max_number = 0; // 最大値
    let exp_min_number = 0; // 最小値
    let exp_min_to_max = 0; // 最小値～最大値
    // 取得ダイヤドロップダウン初期値設定用
    let first_dia_id = 0;
    let first_dia_name = "";
    // ランク別ダイヤデフォルト
    let dia_center_rank_D = 10;
    let dia_center_rank_C = 21;
    let dia_center_rank_B = 37;
    let dia_center_rank_A = 48;
    let dia_center_rank_S = 79;
    let dia_center_rank_SS = 120;
    let dia_center_rank_SSS = 146;
    // ランク別取得ダイヤ範囲
    let dia_range_rank_D = 5;
    let dia_range_rank_C = 5;
    let dia_range_rank_B = 10;
    let dia_range_rank_A = 10;
    let dia_range_rank_S = 20;
    let dia_range_rank_SS = 20;
    let dia_range_rank_SSS = 25;
    // ダイヤ最大値最小値
    let dia_max_number = 0; // 最大値
    let dia_min_number = 0; // 最小値
    let dia_min_to_max = 0; // 最小値～最大値

    // ハンター協会管理者フラグ
    let isHunterAsscociation = false;
    const cookie: string = document.cookie;
    const cookiesArray: string[] = cookie.split(";");
    for (let c of cookiesArray) {
      const cArray: string[] = c.split("=");
      cArray[0] = cArray[0].trim();
      if (cArray[0] === "authority-id") {
        isHunterAsscociation = cArray[1] == "0";
      }
    }

    // ギルド情報
    const itf_quest_guild: input_guild_list_data = {
      id: 0,
      name: "",
    };
    // ローカルストレージより自分のギルドID取得
    let user_guild_id = 0;
    if (localStorage.getItem("guild_data")) {
      user_guild_id = Number(
        JSON.parse(localStorage.getItem("guild_data")!).ls_guild_id
      );
    }
    // 依頼者情報
    const itf_quest_client: input_client_list_data = {
      id: 0,
      name: "",
    };
    // ローカルストレージより自分の依頼者ID取得
    // let user_client_id = 0;
    // if (localStorage.getItem("client_data")) {
    //   user_client_id = Number(
    //     JSON.parse(localStorage.getItem("client_data")!).ls_client_id
    //   );
    // }

    // localStorage.removeItem("regist_quest_data");
    var rank_data = [
      { id: 10, name: "D" },
      { id: 15, name: "C" },
      { id: 20, name: "B" },
      { id: 30, name: "A" },
      { id: 50, name: "S" },
      { id: 75, name: "SS" },
      { id: 100, name: "SSS" },
    ];
    var category_data = [
      { id: 1, name: "そうじ" },
      { id: 2, name: "べんきょう" },
      { id: 3, name: "あらいもの" },
      { id: 4, name: "うんどう" },
      { id: 5, name: "ごみすて" },
      { id: 6, name: "おつかい" },
      { id: 7, name: "こもり" },
      { id: 8, name: "おはなし" },
      { id: 9, name: "ざつむ" },
    ];
    var error_message: string[] = new Array(16);
    var quest = reactive<RegistQuestState>({
      quest_data: {
        itf_quest_title: "",
        itf_quest_lower_capacity: "",
        itf_quest_upper_capacity: "",
        itf_quest_category: "",
        // itf_quest_requester: "",
        itf_quest_contents: "",
        itf_quest_age_limit: "0",
        itf_quest_location: "",
        itf_quest_start_date: "",
        itf_quest_limit_date: "",
        itf_quest_guild: itf_quest_guild,
        itf_quest_client: itf_quest_client,
      },
    });
    const quest_state = computed(() => quest.quest_data);

    if (localStorage.getItem("regist_quest_data")) {
      // 既にデータが存在する場合(登録確認から戻ってきた場合)
      // データ取得
      const st_quest_data = JSON.parse(
        localStorage.getItem("regist_quest_data")!
      );
      quest.quest_data.itf_quest_title = st_quest_data.ls_quest_title;
      first_exp_name = st_quest_data.ls_quest_point;
      first_dia_name = st_quest_data.ls_quest_diamond;
      quest.quest_data.itf_quest_lower_capacity =
        st_quest_data.ls_quest_lower_capacity;
      quest.quest_data.itf_quest_upper_capacity =
        st_quest_data.ls_quest_upper_capacity;
      quest.quest_data.itf_quest_category = st_quest_data.ls_quest_category;
      // quest.quest_data.itf_quest_requester = st_quest_data.ls_quest_requester;
      quest.quest_data.itf_quest_contents = st_quest_data.ls_quest_contents;
      quest.quest_data.itf_quest_age_limit = st_quest_data.ls_quest_min_age;
      quest.quest_data.itf_quest_location = st_quest_data.ls_quest_location;
      quest.quest_data.itf_quest_start_date = st_quest_data.ls_quest_start_date;
      quest.quest_data.itf_quest_limit_date = st_quest_data.ls_quest_limit_date;
      quest.quest_data.itf_quest_guild.id = st_quest_data.ls_guild_id;
      quest.quest_data.itf_quest_guild.name = st_quest_data.ls_guild_name;
      quest.quest_data.itf_quest_client.id = st_quest_data.ls_client_id;
      quest.quest_data.itf_quest_client.name = st_quest_data.ls_client_name;

      farst_questrank_id = st_quest_data.ls_rank_id;
      farst_questrank_name = st_quest_data.ls_quest_rank;
      farst_category_id = st_quest_data.ls_category_id;
      farst_category_name = st_quest_data.ls_quest_category;
      first_exp_id = st_quest_data.ls_exp_id;
      first_dia_id = st_quest_data.ls_dia_id;
    }

    /////////////////////////////
    // データ取得処理
    // ギルド一覧(ドロップダウン用)
    /////////////////////////////
    // reactive(データ変更監視)
    // 所属ギルドリスト用データ
    var r_modify_guild_list_data = reactive<ModifyGuildListData>({
      guild_list_data: [],
    });
    const guild_list_data = computed(
      () => r_modify_guild_list_data.guild_list_data
    );
    // データ取得処理呼び出し
    ApiService.getGuildListData()
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          const guildlist = res.data;
          for (var i = 0; i < guildlist.length; i++) {
            if (guildlist[i].status != 1) continue;
            r_modify_guild_list_data.guild_list_data.push({
              id: guildlist[i].guild_id,
              name: guildlist[i].guild_name,
            });
            if (
              !isHunterAsscociation &&
              user_guild_id > 0 &&
              guildlist[i].guild_id === user_guild_id
            ) {
              quest.quest_data.itf_quest_guild.id = guildlist[i].guild_id;
              quest.quest_data.itf_quest_guild.name = guildlist[i].guild_name;
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /////////////////////////////
    // データ取得処理
    // 依頼者一覧(ドロップダウン用)
    /////////////////////////////
    // reactive(データ変更監視)
    // 依頼者リスト用データ
    var r_modify_client_list_data = reactive<ModifyClientListData>({
      client_list_data: [],
    });
    const client_list_data = computed(
      () => r_modify_client_list_data.client_list_data
    );
    this.fetchClients(user_guild_id);

    ////////////////////////////////
    // データ設定処理
    // 取得経験値ドロップダウン
    ////////////////////////////////
    // 初期化_データ受取用
    var exp_list = reactive<ExpState>({
      exp_item: [],
    });
    const exp_data = computed(() => exp_list.exp_item);

    // ドロップダウンリスト設定処理_取得経験値範囲計算
    var i;
    switch (farst_questrank_name) {
      case "D": {
        exp_max_number = exp_center_rank_D + exp_range_rank_D; // 最大値設定
        exp_min_number = exp_center_rank_D - exp_range_rank_D; // 最小値設定
        exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "C": {
        exp_max_number = exp_center_rank_C + exp_range_rank_C; // 最大値設定
        exp_min_number = exp_center_rank_C - exp_range_rank_C; // 最小値設定
        exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "B": {
        exp_max_number = exp_center_rank_B + exp_range_rank_B; // 最大値設定
        exp_min_number = exp_center_rank_B - exp_range_rank_B; // 最小値設定
        exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "A": {
        exp_max_number = exp_center_rank_A + exp_range_rank_A; // 最大値設定
        exp_min_number = exp_center_rank_A - exp_range_rank_A; // 最小値設定
        exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "S": {
        exp_max_number = exp_center_rank_S + exp_range_rank_S; // 最大値設定
        exp_min_number = exp_center_rank_S - exp_range_rank_S; // 最小値設定
        exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "SS": {
        exp_max_number = exp_center_rank_SS + exp_range_rank_SS; // 最大値設定
        exp_min_number = exp_center_rank_SS - exp_range_rank_SS; // 最小値設定
        exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "SSS": {
        exp_max_number = exp_center_rank_SSS + exp_range_rank_SSS; // 最大値設定
        exp_min_number = exp_center_rank_SSS - exp_range_rank_SSS; // 最小値設定
        exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
    }
    // ドロップダウンリスト設定処理_ループ
    for (i = 0; i < exp_min_to_max; i++) {
      exp_list.exp_item.push({
        // ID(画面非表示)
        itf_exp_id: i,
        // 獲得経験値
        itf_exp_name: String(exp_min_number + i),
      });
    }

    ////////////////////////////////
    // データ設定処理
    // 取得ダイアドロップダウン
    ////////////////////////////////
    // 初期化_データ受取用
    var dia_list = reactive<DiaState>({
      dia_item: [],
    });
    const dia_data = computed(() => dia_list.dia_item);

    // ドロップダウンリスト設定処理_取得ダイア範囲計算
    var i2;
    switch (farst_questrank_name) {
      case "D": {
        dia_max_number = dia_center_rank_D + dia_range_rank_D; // 最大値設定
        dia_min_number = dia_center_rank_D - dia_range_rank_D; // 最小値設定
        dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "C": {
        dia_max_number = dia_center_rank_C + dia_range_rank_C; // 最大値設定
        dia_min_number = dia_center_rank_C - dia_range_rank_C; // 最小値設定
        dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "B": {
        dia_max_number = dia_center_rank_B + dia_range_rank_B; // 最大値設定
        dia_min_number = dia_center_rank_B - dia_range_rank_B; // 最小値設定
        dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "A": {
        dia_max_number = dia_center_rank_A + dia_range_rank_A; // 最大値設定
        dia_min_number = dia_center_rank_A - dia_range_rank_A; // 最小値設定
        dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "S": {
        dia_max_number = dia_center_rank_S + dia_range_rank_S; // 最大値設定
        dia_min_number = dia_center_rank_S - dia_range_rank_S; // 最小値設定
        dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "SS": {
        dia_max_number = dia_center_rank_SS + dia_range_rank_SS; // 最大値設定
        dia_min_number = dia_center_rank_SS - dia_range_rank_SS; // 最小値設定
        dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
      case "SSS": {
        dia_max_number = dia_center_rank_SSS + dia_range_rank_SSS; // 最大値設定
        dia_min_number = dia_center_rank_SSS - dia_range_rank_SSS; // 最小値設定
        dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

        break;
      }
    }
    // ドロップダウンリスト設定処理_ループ
    for (i2 = 0; i2 < dia_min_to_max; i2++) {
      dia_list.dia_item.push({
        // ID(画面非表示)
        itf_dia_id: i2,
        // 取得ダイヤ
        itf_dia_name: String(dia_min_number + i2),
      });
    }

    // データ設定
    return {
      class_name:
        "operation_button button_white manage_registmodifydelete_operationbutton",
      message: "戻る",
      class_name2:
        "operation_button button_black manage_registmodifydelete_operationbutton",
      message2: "確認",
      d_rank_data: rank_data,
      d_category_data: category_data,
      selected_rank: { id: farst_questrank_id, name: farst_questrank_name },
      selected_category: { id: farst_category_id, name: farst_category_name },
      d_quest_data: quest_state,
      flg_show_modal_update: false,
      errorMessage: error_message,
      d_guild_list_data: guild_list_data,
      d_client_list_data: client_list_data,
      isHunterAsscociation: isHunterAsscociation,
      // 取得経験値_リスト用データ
      d_exp_list: exp_data,
      // 取得経験値データ渡す用
      exp_get_value: {
        itf_exp_id: first_exp_id,
        itf_exp_name: first_exp_name,
      },
      // 取得ダイヤ_リスト用データ
      d_dia_list: dia_data,
      // 取得ダイヤデータ渡す用
      dia_get_value: {
        itf_dia_id: first_dia_id,
        itf_dia_name: first_dia_name,
      },
    };
  },
  watch: {
    // ランク選択時処理
    selected_rank() {
      this.change_dropdownlist_exp(); // ドロップダウンリスト変更処理_取得経験値呼び出し
      this.change_dropdownlist_dia(); // ドロップダウンリスト変更処理_取得経験値呼び出し
    },
    selected_category() {
      this.d_quest_data.itf_quest_category = this.selected_category.name;
    },
  },
  // コンポーネント
  components: {
    SmallHeader,
    GenericButton,
  },
  // メソッド
  methods: {
    // 戻るボタンクリック処理
    return_transition() {
      this.$router.push({
        name: "managequestlist",
      });
    },
    // 確認ボタンクリック処理
    confirmation_data_quest() {
      this.errorMessage = new Array(15);

      if (this.d_quest_data.itf_quest_title.length < 1)
        this.errorMessage[0] = "クエストタイトルを入力してください";
      if (this.selected_rank.name.length < 1)
        this.errorMessage[1] = "クエストランクを選択してください";
      if (this.d_quest_data.itf_quest_lower_capacity.length < 1)
        this.errorMessage[2] = "募集人数を入力してください";
      if (this.d_quest_data.itf_quest_upper_capacity.length < 1)
        this.errorMessage[2] = "募集人数を入力してください";
      if (this.d_quest_data.itf_quest_lower_capacity !== "") {
        if (
          Number(this.d_quest_data.itf_quest_lower_capacity) < 1 ||
          Number(this.d_quest_data.itf_quest_lower_capacity) > 999
        )
          this.errorMessage[9] = "1～999の間の数値を入力してください";
      }
      if (this.d_quest_data.itf_quest_upper_capacity !== "") {
        if (
          Number(this.d_quest_data.itf_quest_upper_capacity) < 1 ||
          Number(this.d_quest_data.itf_quest_upper_capacity) > 999
        )
          this.errorMessage[9] = "1～999の間の数値を入力してください";
      }
      if (
        this.d_quest_data.itf_quest_upper_capacity !== "" &&
        this.d_quest_data.itf_quest_upper_capacity !== ""
      ) {
        if (
          Number(this.d_quest_data.itf_quest_lower_capacity) >
          Number(this.d_quest_data.itf_quest_upper_capacity)
        )
          this.errorMessage[10] = "最小～最大で入力してください";
      }
      if (this.d_quest_data.itf_quest_category.length < 1)
        this.errorMessage[3] = "カテゴリを選択してください";
      // if (this.d_quest_data.itf_quest_requester.length < 1)
      if (this.d_quest_data.itf_quest_client.name.length < 1)
        this.errorMessage[4] = "依頼者を選択してください";
      if (this.d_quest_data.itf_quest_contents.length < 1)
        this.errorMessage[5] = "依頼内容を入力してください";
      if (this.d_quest_data.itf_quest_location.length < 1)
        this.errorMessage[6] = "作業場所を入力してください";
      if (this.d_quest_data.itf_quest_start_date.length < 1)
        this.errorMessage[7] = "作業日時(開始)を入力してください";
      if (this.d_quest_data.itf_quest_limit_date.length < 1)
        this.errorMessage[8] = "作業日時(終了)を入力してください";
      if (
        this.d_quest_data.itf_quest_start_date.length >= 1 &&
        this.d_quest_data.itf_quest_limit_date.length >= 1
      ) {
        if (
          this.d_quest_data.itf_quest_start_date >
          this.d_quest_data.itf_quest_limit_date
        )
          this.errorMessage[11] = "終了は開始以降の日時で入力してください";
      }
      if (this.d_quest_data.itf_quest_age_limit.length < 1)
        this.errorMessage[12] = "対象年齢を入力してください";
      if (this.d_quest_data.itf_quest_guild.name.length < 1)
        this.errorMessage[13] = "対象ギルドを選択してください";
      if (this.exp_get_value.itf_exp_name == "")
        this.errorMessage[14] = "獲得経験値を選択してください";
      if (this.dia_get_value.itf_dia_name == "")
        this.errorMessage[15] = "獲得ダイヤを選択してください";

      var confirm_flag = true;
      this.errorMessage.forEach((errorMessage) => {
        confirm_flag = errorMessage.length > 0 ? false : true;
      });
      // エラー有りの場合_処理終了
      if (!confirm_flag) return;

      /* 日付加工(テーブル登録用に)_開始日時 */
      var start_date_Y = new Date(
        this.d_quest_data.itf_quest_start_date
      ).getFullYear();
      var start_date_M =
        new Date(this.d_quest_data.itf_quest_start_date).getMonth() + 1;
      var start_date_M2 = start_date_M.toString().padStart(2, "0");
      var start_date_D = new Date(this.d_quest_data.itf_quest_start_date)
        .getDate()
        .toString()
        .padStart(2, "0");
      var start_date_h = new Date(this.d_quest_data.itf_quest_start_date)
        .getHours()
        .toString()
        .padStart(2, "0");
      var start_date_m = new Date(this.d_quest_data.itf_quest_start_date)
        .getMinutes()
        .toString()
        .padStart(2, "0");

      const start_date =
        start_date_Y +
        "-" +
        start_date_M2 +
        "-" +
        start_date_D +
        " " +
        start_date_h +
        ":" +
        start_date_m;

      /* 日付加工(テーブル登録用に)_終了日時 */
      var limit_date_Y = new Date(
        this.d_quest_data.itf_quest_limit_date
      ).getFullYear();
      var limit_date_M =
        new Date(this.d_quest_data.itf_quest_limit_date).getMonth() + 1;
      var limit_date_M2 = limit_date_M.toString().padStart(2, "0");
      var limit_date_D = new Date(this.d_quest_data.itf_quest_limit_date)
        .getDate()
        .toString()
        .padStart(2, "0");
      var limit_date_h = new Date(this.d_quest_data.itf_quest_limit_date)
        .getHours()
        .toString()
        .padStart(2, "0");
      var limit_date_m = new Date(this.d_quest_data.itf_quest_limit_date)
        .getMinutes()
        .toString()
        .padStart(2, "0");

      var limit_date =
        limit_date_Y +
        "-" +
        limit_date_M2 +
        "-" +
        limit_date_D +
        " " +
        limit_date_h +
        ":" +
        limit_date_m;

      localStorage.setItem(
        "regist_quest_data",
        JSON.stringify({
          ls_guild_id: this.d_quest_data.itf_quest_guild.id,
          ls_guild_name: this.d_quest_data.itf_quest_guild.name,
          ls_client_id: this.d_quest_data.itf_quest_client.id,
          ls_client_name: this.d_quest_data.itf_quest_client.name,
          ls_quest_title: this.d_quest_data.itf_quest_title,
          ls_quest_rank: this.selected_rank.name,
          ls_quest_point: this.exp_get_value.itf_exp_name,
          ls_quest_diamond: this.dia_get_value.itf_dia_name,
          ls_quest_lower_capacity: this.d_quest_data.itf_quest_lower_capacity,
          ls_quest_upper_capacity: this.d_quest_data.itf_quest_upper_capacity,
          ls_quest_number_of_people:
            this.d_quest_data.itf_quest_lower_capacity +
            "名～" +
            this.d_quest_data.itf_quest_upper_capacity +
            "名",
          ls_quest_category: this.d_quest_data.itf_quest_category,
          // ls_quest_requester: this.d_quest_data.itf_quest_requester,
          ls_quest_contents: this.d_quest_data.itf_quest_contents,
          ls_quest_min_age: this.d_quest_data.itf_quest_age_limit,
          ls_quest_location: this.d_quest_data.itf_quest_location,
          ls_quest_start_date: start_date,
          ls_quest_limit_date: limit_date,
          ls_quest_date: start_date + "～" + limit_date,

          ls_category_id: this.selected_category.id,
          ls_rank_id: this.selected_rank.id,
          ls_exp_id: this.exp_get_value.itf_exp_id,
          ls_dia_id: this.dia_get_value.itf_dia_id,
        })
      );
      this.$router.push({
        name: "managequestregistercheck",
      });
    },
    // 戻るボタンクリック処理
    oyareturntrantransition() {
      this.$router.push({
        name: "managequestlist",
      });
    },
    /////////////////////////////
    // 入力制御_クエストタイトル
    /////////////////////////////
    check_quest_title() {
      const tmp_value = this.d_quest_data.itf_quest_title;
      if (tmp_value) {
        this.d_quest_data.itf_quest_title = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_依頼者
    /////////////////////////////
    // check_quest_requester() {
    //   const tmp_value = this.d_quest_data.itf_quest_requester;
    //   if (tmp_value) {
    //     this.d_quest_data.itf_quest_requester = tmp_value.replace(
    //       /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
    //       ""
    //     );
    //   }
    // },
    /////////////////////////////
    // 入力制御_依頼内容
    /////////////////////////////
    check_quest_contents() {
      const tmp_value = this.d_quest_data.itf_quest_contents;
      if (tmp_value) {
        this.d_quest_data.itf_quest_contents = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    /////////////////////////////
    // 入力制御_作業場所
    /////////////////////////////
    check_quest_location() {
      const tmp_value = this.d_quest_data.itf_quest_location;
      if (tmp_value) {
        this.d_quest_data.itf_quest_location = tmp_value.replace(
          /[^0-9０-９a-zA-Zぁ-んーァ-ンヴｧ-ﾝﾞﾟ\u4E00-\u9FFF\u3005-\u3007-_]/g,
          ""
        );
      }
    },
    ////////////////////////////////
    // ドロップダウンリスト変更処理
    // 獲得経験値
    ////////////////////////////////
    change_dropdownlist_exp() {
      //////////////////
      // 変数宣言
      //////////////////
      // ランク別取得経験値デフォルト
      let exp_center_rank_D = 10;
      let exp_center_rank_C = 17;
      let exp_center_rank_B = 27;
      let exp_center_rank_A = 40;
      let exp_center_rank_S = 57;
      let exp_center_rank_SS = 78;
      let exp_center_rank_SSS = 104;
      // ランク別取得経験値範囲
      let exp_range_rank_D = 3;
      let exp_range_rank_C = 3;
      let exp_range_rank_B = 6;
      let exp_range_rank_A = 6;
      let exp_range_rank_S = 10;
      let exp_range_rank_SS = 10;
      let exp_range_rank_SSS = 15;
      // 取得経験値最大値最小値
      let exp_max_number = 0; // 最大値
      let exp_min_number = 0; // 最小値
      let exp_min_to_max = 0; // 最小値～最大値

      // ドロップダウンリスト設定処理_取得経験値範囲計算
      if (this.selected_rank.name != "") {
        var i;
        switch (this.selected_rank.name) {
          case "D": {
            exp_max_number = exp_center_rank_D + exp_range_rank_D; // 最大値設定
            exp_min_number = exp_center_rank_D - exp_range_rank_D; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "C": {
            exp_max_number = exp_center_rank_C + exp_range_rank_C; // 最大値設定
            exp_min_number = exp_center_rank_C - exp_range_rank_C; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "B": {
            exp_max_number = exp_center_rank_B + exp_range_rank_B; // 最大値設定
            exp_min_number = exp_center_rank_B - exp_range_rank_B; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "A": {
            exp_max_number = exp_center_rank_A + exp_range_rank_A; // 最大値設定
            exp_min_number = exp_center_rank_A - exp_range_rank_A; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "S": {
            exp_max_number = exp_center_rank_S + exp_range_rank_S; // 最大値設定
            exp_min_number = exp_center_rank_S - exp_range_rank_S; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "SS": {
            exp_max_number = exp_center_rank_SS + exp_range_rank_SS; // 最大値設定
            exp_min_number = exp_center_rank_SS - exp_range_rank_SS; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "SSS": {
            exp_max_number = exp_center_rank_SSS + exp_range_rank_SSS; // 最大値設定
            exp_min_number = exp_center_rank_SSS - exp_range_rank_SSS; // 最小値設定
            exp_min_to_max = exp_max_number - exp_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
        }
        // ドロップダウンリスト設定処理_ループ
        this.d_exp_list.length = 0;
        for (i = 0; i < exp_min_to_max; i++) {
          this.d_exp_list.push({
            // ID(画面非表示)
            itf_exp_id: i,
            // 獲得経験値
            itf_exp_name: String(exp_min_number + i),
          });
        }
      }
    },
    ////////////////////////////////
    // ドロップダウンリスト変更処理
    // 取得ダイヤ
    ////////////////////////////////
    change_dropdownlist_dia() {
      //////////////////
      // 変数宣言
      //////////////////
      // ランク別ダイヤデフォルト
      let dia_center_rank_D = 10;
      let dia_center_rank_C = 21;
      let dia_center_rank_B = 37;
      let dia_center_rank_A = 48;
      let dia_center_rank_S = 79;
      let dia_center_rank_SS = 120;
      let dia_center_rank_SSS = 146;
      // ランク別取得ダイヤ範囲
      let dia_range_rank_D = 5;
      let dia_range_rank_C = 5;
      let dia_range_rank_B = 10;
      let dia_range_rank_A = 10;
      let dia_range_rank_S = 20;
      let dia_range_rank_SS = 20;
      let dia_range_rank_SSS = 25;
      // ダイヤ最大値最小値
      let dia_max_number = 0; // 最大値
      let dia_min_number = 0; // 最小値
      let dia_min_to_max = 0; // 最小値～最大値

      // ドロップダウンリスト設定処理_取得ダイヤ範囲計算
      if (this.selected_rank.name != "") {
        var i;
        switch (this.selected_rank.name) {
          case "D": {
            dia_max_number = dia_center_rank_D + dia_range_rank_D; // 最大値設定
            dia_min_number = dia_center_rank_D - dia_range_rank_D; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "C": {
            dia_max_number = dia_center_rank_C + dia_range_rank_C; // 最大値設定
            dia_min_number = dia_center_rank_C - dia_range_rank_C; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "B": {
            dia_max_number = dia_center_rank_B + dia_range_rank_B; // 最大値設定
            dia_min_number = dia_center_rank_B - dia_range_rank_B; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "A": {
            dia_max_number = dia_center_rank_A + dia_range_rank_A; // 最大値設定
            dia_min_number = dia_center_rank_A - dia_range_rank_A; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "S": {
            dia_max_number = dia_center_rank_S + dia_range_rank_S; // 最大値設定
            dia_min_number = dia_center_rank_S - dia_range_rank_S; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "SS": {
            dia_max_number = dia_center_rank_SS + dia_range_rank_SS; // 最大値設定
            dia_min_number = dia_center_rank_SS - dia_range_rank_SS; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
          case "SSS": {
            dia_max_number = dia_center_rank_SSS + dia_range_rank_SSS; // 最大値設定
            dia_min_number = dia_center_rank_SSS - dia_range_rank_SSS; // 最小値設定
            dia_min_to_max = dia_max_number - dia_min_number + 1; // 最小値～最大値の範囲設定

            break;
          }
        }
        // ドロップダウンリスト設定処理_ループ
        this.d_dia_list.length = 0;
        for (i = 0; i < dia_min_to_max; i++) {
          this.d_dia_list.push({
            // ID(画面非表示)
            itf_dia_id: i,
            // 取得ダイヤ
            itf_dia_name: String(dia_min_number + i),
          });
        }
      }
    },
    onChangeGuildSelect() {
      this.resetClientList();
      this.fetchClients(this.d_quest_data.itf_quest_guild.id);
    },
    resetClientList() {
      this.d_quest_data.itf_quest_client.id = 0;
      this.d_quest_data.itf_quest_client.name = "";
      this.d_client_list_data.splice(0);
    },
    fetchClients(guild_id: number): void {
      ApiService.getClientList({ guildId: guild_id })
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            const clientlist = res.data;
            for (var i = 0; i < clientlist.length; i++) {
              this.d_client_list_data.push({
                id: clientlist[i].client_id,
                name:
                  clientlist[i].client_last_name +
                  clientlist[i].client_first_name,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
});
</script>

<style>
/* ドロップダウン_半分サイズ */
.f_fieldset_contents#quest_list select {
  width: 50%;
}

/* 募集人数_テキスト */
.quest_capacity_word {
  height: 30px;
  margin: 0 2.5%;
  line-height: 30px;
  display: inline-block;
}
</style>
