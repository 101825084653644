import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main2 manage_registmodifydelete_contents_main" }
const _hoisted_2 = { class: "grid_small_header" }
const _hoisted_3 = { class: "grid_main2_contents button_management_menu background_border_brueblack" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmallHeader = _resolveComponent("SmallHeader")!
  const _component_GenericButton = _resolveComponent("GenericButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SmallHeader, {
        show_button_return: "OK",
        onReturnTransition: _cache[0] || (_cache[0] = ($event: any) => (_ctx.oyareturntrantransition()))
      }, {
        slot_small_header_title: _withCtx(() => [
          _createTextVNode("管理画面")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.flg_show_button_management === '0')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/manageguildlist",
            class: "button_management_router"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_GenericButton, {
                class_name: _ctx.class_name,
                message: _ctx.management_btton_message_1
              }, null, 8, ["class_name", "message"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: "/managequestlist",
        class: "button_management_router"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_GenericButton, {
            class_name: _ctx.class_name,
            message: _ctx.management_btton_message_2
          }, null, 8, ["class_name", "message"])
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/managehunterlist",
        class: "button_management_router"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_GenericButton, {
            class_name: _ctx.class_name,
            message: _ctx.management_btton_message_3
          }, null, 8, ["class_name", "message"])
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/manageclientlist",
        class: "button_management_router"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_GenericButton, {
            class_name: _ctx.class_name,
            message: _ctx.management_btton_message_4
          }, null, 8, ["class_name", "message"])
        ]),
        _: 1
      })
    ])
  ]))
}