// ルート設定ファイル

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import TestView from "../views/TestView.vue";
import LoginView from "../views/LoginView.vue";
import MainView from "../views/MainView.vue";
import LoadView from "../views/LoadView.vue";
import StartView from "../views/StartView.vue";
import QuestView from "../views/QuestView.vue";
import GuildView from "../views/GuildView.vue";
import RankingView from "../views/RankingView.vue";
import PresentView from "../views/PresentView.vue";
import ItemView from "../views/ItemView.vue";
import InformationView from "../views/InformationView.vue";
import QuestionnaireView from "../views/QuestionnaireView.vue";
import IllustView from "../views/IllustView.vue";
import MyPageView from "../views/MyPageView.vue";
import SettingView from "../views/SettingView.vue";
import ManagementView from "../views/ManagementView.vue";
import ManageGuildListView from "../views/ManageGuildListView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import ManageGuildModifyView from "../views/ManageGuildModifyView.vue";
import ManageGuildRegisterView from "../views/ManageGuildRegisterView.vue";
import ManageGuildRegisterCheckView from "../views/ManageGuildRegisterCheckView.vue";
import LoginFromRegisterSelectView from "../views/LoginFromRegisterSelectView.vue";
import ManageHunterListView from "../views/ManageHunterListView.vue";
import ManageHunterRegisterView from "../views/ManageHunterRegisterView.vue";
import ManageHunterRegisterCheckView from "../views/ManageHunterRegisterCheckView.vue";
import ManageHunterModifyView from "../views/ManageHunterModifyView.vue";
import ManageHunterModifyCheckView from "../views/ManageHunterModifyCheckView.vue";
import ManageQuestListView from "../views/ManageQuestListView.vue";
import ManageQuestRegisterView from "../views/ManageQuestRegisterView.vue";
import ManageQuestRegisterCheckView from "../views/ManageQuestRegisterCheckView.vue";
import ManageQuestModifyView from "../views/ManageQuestModifyView.vue";
import ManageClientListView from "../views/ManageClientListView.vue";
import ManageClientRegisterView from "../views/ManageClientRegisterView.vue";
import ManageClientRegisterCheckView from "../views/ManageClientRegisterCheckView.vue";
import ManageClientModifyView from "../views/ManageClientModifyView.vue";
import ManageClientModifyCheckView from "../views/ManageClientModifyCheckView.vue";
import ApiService from "../service/ApiService";

const routes: Array<RouteRecordRaw> = [
  // 確認・テスト用画面
  {
    path: "/test",
    name: "test",
    components: {
      default: TestView,
    },
  },
  // ロード画面
  {
    path: "/load",
    name: "load",
    components: {
      default: LoadView,
    },
  },
  // スタート画面
  {
    path: "/start",
    name: "start",
    components: {
      default: StartView,
    },
  },
  // ログイン画面
  {
    path: "/login",
    name: "login",
    components: {
      default: LoginView,
    },
  },
  // メイン画面
  {
    path: "/main",
    name: "main",
    components: {
      default: MainView,
    },
  },
  // クエスト画面
  {
    path: "/quest",
    name: "quest",
    components: {
      default: QuestView,
    },
  },
  // ギルド画面
  {
    path: "/guild",
    name: "guild",
    components: {
      default: GuildView,
    },
  },
  // ランキング画面
  {
    path: "/ranking",
    name: "ranking",
    components: {
      default: RankingView,
    },
  },
  // プレゼント画面
  {
    path: "/present",
    name: "present",
    components: {
      default: PresentView,
    },
  },
  // アイテム画面
  {
    path: "/item",
    name: "item",
    components: {
      default: ItemView,
    },
  },
  // お知らせ画面
  {
    path: "/information",
    name: "information",
    components: {
      default: InformationView,
    },
  },
  // けんさく画面
  {
    path: "/search",
    name: "search",
    components: {
      default: QuestionnaireView,
    },
  },
  // イラスト画面
  {
    path: "/illust",
    name: "illust",
    components: {
      default: IllustView,
    },
  },
  // マイページ画面
  {
    path: "/mypage",
    name: "mypage",
    components: {
      default: MyPageView,
    },
  },
  // せってい画面
  {
    path: "/setting",
    name: "setting",
    components: {
      default: SettingView,
    },
  },
  // 管理画面
  {
    path: "/management",
    name: "management",
    components: {
      default: ManagementView,
    },
  },
  // ギルド管理画面_一覧
  {
    path: "/manageguildlist",
    name: "manageguildlist",
    components: {
      default: ManageGuildListView,
    },
  },
  // 404画面
  {
    path: "/NotFound",
    name: "NotFound",
    components: {
      default: NotFoundView,
    },
  },
  // ギルド管理画面_編集
  {
    path: "/manageguildmodify",
    name: "manageguildmodify",
    props: (route) => ({ guildId: route.query.guildId }),
    component: ManageGuildModifyView,
  },
  // ギルド登録画面
  {
    path: "/manageguildregister",
    name: "manageguildregister",
    props: true,
    components: {
      default: ManageGuildRegisterView,
    },
  },
  // ギルド登録確認画面
  {
    path: "/manageguildregistercheck",
    name: "manageguildregistercheck",
    props: true,
    components: {
      default: ManageGuildRegisterCheckView,
    },
  },
  // ログイン画面からのアカウント登録選択画面
  {
    path: "/loginfromregisterselect",
    name: "loginfromregisterselect",
    props: true,
    components: {
      default: LoginFromRegisterSelectView,
    },
  },
  // 管理画面_ハンター管理_一覧
  {
    path: "/managehunterlist",
    name: "managehunterlist",
    props: true,
    components: {
      default: ManageHunterListView,
    },
  },
  // ハンター登録画面(ログイン画面から遷移)
  {
    path: "/managehunterregister",
    name: "managehunterregister",
    props: true,
    components: {
      default: ManageHunterRegisterView,
    },
  },
  // ハンター登録確認画面
  {
    path: "/managehunterregistercheck",
    name: "managehunterregistercheck",
    props: true,
    components: {
      default: ManageHunterRegisterCheckView,
    },
  },
  // 管理画面_ハンター管理_ハンター編集削除画面
  {
    path: "/managehuntermodify",
    name: "managehuntermodify",
    props: true,
    components: {
      default: ManageHunterModifyView,
    },
  },
  // 管理画面_ハンター管理_ハンター編集確認画面
  {
    path: "/managehuntermodifycheck",
    name: "managehuntermodifycheck",
    props: true,
    components: {
      default: ManageHunterModifyCheckView,
    },
  },
  // 管理画面_クエスト管理_一覧
  {
    path: "/managequestlist",
    name: "managequestlist",
    props: true,
    components: {
      default: ManageQuestListView,
    },
  },
  // クエスト登録画面
  {
    path: "/managequestregister",
    name: "managequestregister",
    props: true,
    components: {
      default: ManageQuestRegisterView,
    },
  },
  // クエスト登録確認画面
  {
    path: "/managequestregistercheck",
    name: "managequestregistercheck",
    props: true,
    components: {
      default: ManageQuestRegisterCheckView,
    },
  },
  // 管理画面_クエスト管理_クエスト編集画面
  {
    path: "/managequestmodify",
    name: "managequestmodify",
    props: true,
    components: {
      default: ManageQuestModifyView,
    },
  },
  // 管理画面_依頼者管理_一覧
  {
    path: "/manageclientlist",
    name: "manageclientlist",
    props: true,
    components: {
      default: ManageClientListView,
    },
  },
  // 管理画面_依頼者管理_依頼者新規登録画面
  {
    path: "/manageclientregister",
    name: "manageclientregister",
    props: true,
    components: {
      default: ManageClientRegisterView,
    },
  },
  // 管理画面_依頼者管理_依頼者新規登録確認画面
  {
    path: "/manageclientregistercheck",
    name: "manageclientregistercheck",
    props: true,
    components: {
      default: ManageClientRegisterCheckView,
    },
  },
  // 管理画面_依頼者管理_依頼者編集削除画面
  {
    path: "/manageclientmodify",
    name: "manageclientmodify",
    props: true,
    components: {
      default: ManageClientModifyView,
    },
  },
  // 管理画面_依頼者管理_依頼者編集確認画面
  {
    path: "/manageclientmodifycheck",
    name: "manageclientmodifycheck",
    props: true,
    components: {
      default: ManageClientModifyCheckView,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// ブラウザバックを無効化
router.push(router.options.history.location);
addEventListener("popstate", () => {
  router.options.history.go(1);
});

router.beforeEach(async (to, from, next) => {
  router.options.history.destroy();

  let isValidSession = false;
  if (to.path !== "/" && to.path !== "/start" && to.path !== "/login") {
    if (window.navigator.onLine) {
      isValidSession = await checkSession();
    } else {
      isValidSession = true;
      console.log("オフライン接続" + isValidSession);
    }
  }

  if (to.path === "/") {
    next("/start");
  } else if (to.path === "/start") {
    // 次の遷移先が「スタート」画面の場合
    // 次の画面へ遷移
    next();
  } else if (to.path === "/login") {
    // 次の遷移先が「ログイン」画面の場合
    // セッション判定
    if (isValidSession) next("/main");
    else next();
  } else if (from.path === "/login") {
    // 次の遷移元が「ログイン」画面の場合
    // 次の画面へ遷移
    if (isValidSession) next();
    else next("/login");
  } else {
    // スタート、ログイン画面以外アクセス時共通処理
    // セッション判定
    if (isValidSession) next();
    else next("/login");
  }
});

async function checkSession(): Promise<boolean> {
  let sessionStatusCode = 400;
  await ApiService.postLoginCheck()
    .then((res) => {
      sessionStatusCode = res.status;
    })
    .catch((err) => {
      // セッションが無効な場合
      sessionStatusCode = err.response.status;
    });
  return sessionStatusCode === 200;
}

export default router;
